export class StoredFile {
  base64: string;
  mimetype: string;
  filename: string;
  file_extension: string;

  constructor(data?) {
    this.base64 = data && data.base64 || '';
    this.mimetype = data && data.mimetype || '';
    this.filename = data && data.filename || '';
    this.file_extension = data && data.file_extension || '';
  }
}

export class StoredFileResponse {
  status: string;
  stored_file_uuid: string;
  promise_uuid: string;
}

/**
 * DTO para descarga de ficheros.
 *
 * @export
 * @class StoredFileGet
 */
export class StoredFileGet {
  fileBase64: string;
  fileName: string;
  fileFullName: string;
  fileExtension: string;
  mimetype: string;
  uuid: string;

  constructor(data?: any) {
    this.fileBase64 = data && data.fileBase64 || null;
    this.fileName = data && data.fileName || null;
    this.fileFullName = data && data.fileFullName || null;
    this.fileExtension = data && data.fileExtension || null;
    this.mimetype = data && data.mimetype || null;
    this.uuid = data && data.uuid || null
  }

  static buildFromDto(data?: any): StoredFileGet {
    const storedFile = new StoredFileGet();
    storedFile.fileBase64 = data && data.file_base64 || null;
    storedFile.fileName = data && data.filename || null;
    storedFile.fileFullName = data && data.fullname || null;
    storedFile.fileExtension = data && data.extension || null;
    storedFile.mimetype = data && data.mimetype || null;
    storedFile.uuid = data && data.uuid || null;
    return storedFile;
  }
}
