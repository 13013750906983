<div class="row mt-4 justify-content-center">

  <mat-card class="col-11 col-lg-8 col-xl-7 mt-4">
    <form [formGroup]="fGroup">
      <app-inputs-generator [fGroup]="fGroup" [inputFields]="inputs"></app-inputs-generator>
    </form>

    <!-- Button row -->
    <section class="mt-3" *ngIf="user.permissionsInCompany.user_registry_request">
      <div class="d-flex flex-column flex-lg-row justify-content-end align-items-lg-baseline">
        <button mat-flat-button color="warn" class="m-0 m-lg-3 mb-3"
          (click)="rejectConfirmation()">{{ 'Rechazar solicitud' | translate }}</button>
        <button mat-flat-button color="primary" type="submit"
          (click)="acceptConfirmation()">{{ 'Aceptar solicitud' | translate }}</button>
      </div>
    </section>
  </mat-card>

</div>
