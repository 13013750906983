export interface TableCardItem {
  itemReference(): string;
}

export class TableCardSort {
  key: string;
  direction: null | 'asc' | 'desc' = null;

}
export class TableCardPagination {
  page: number = 0;
  pageSize: number = 10;
  totalItems: number = 0;
  sort: TableCardSort = null;

  currentItemReference?: string = null;

}

export class TableCardItemField {
  key: string;
  name: string;
  type?: 'text' | 'number' | 'date' | 'icon' = 'text';
  align?: 'left' | 'center' | 'right' = 'center';
  sortable?: boolean = true;
  sortDirection?: 'asc' | 'desc' = 'asc';
}

export class TableCardConfiguration {
  pagination?: TableCardPagination;
  tableFields?: TableCardItemField[];
  cardFields?: TableCardItemField[];

  pageSizes: number[] = [10, 20, 50];
  pageSizesDefault: number = 10;
}
