import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-mensaje',
  templateUrl: './modal-mensaje.component.html',
  styleUrls: ['./modal-mensaje.component.scss']
})
export class ModalMensajeComponent implements OnInit {
  public title: string;
  public accept: string;
  public cancel: string;
  public message: string;

  @Output() acceptEmit = new EventEmitter<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data) {
      if (this.data.title) {
        this.title = this.data.title;
      }
      if (this.data.mensaje) {
        this.message = this.data.mensaje;
      }

      if (this.data.accept) {
        this.accept = this.data.accept;
      } else {
        this.accept = 'Ok';
      }

      if (this.data.cancel) {
        this.cancel = this.data.cancel;
      } else {
        this.cancel = null;
      }
    }
  }

  public emitAccept(): void {
    this.acceptEmit.emit();
  }

}
