export class Parametros {

  public avisoNumRecPenJuntas: number;
  public regexIban: Array<any>;

  constructor(data?) {
      this.avisoNumRecPenJuntas = data && data.avisoNumRecPenJuntas || 1;
      this.regexIban = data && JSON.parse(data.regexIban) || [];
  }
}
