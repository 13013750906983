import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-account-icon',
  templateUrl: './account-icon.component.html',
})
export class AccountIconComponent implements OnInit {

  @Input() cssClass: string;
  @Input() routerLink: any;

  constructor() { }

  ngOnInit(): void {
  }

}
