import { Router } from '@angular/router';
import { AlertService } from './../../shared/services/alert.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseComunicationService } from './base-comunication.service';
import { Injectable } from '@angular/core';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecoverService extends BaseComunicationService {

  private urlApi = `${environment.apiAuthenticationBaseUrl}`;

  constructor(private http: HttpClient,
    private alertService: AlertService,
    private routerService?: Router) {
    super(http);
  }

  /**
   * Email verification request.
   */
  recoverRequest(data: any): void {
    const url: string = `${this.urlApi}/reset-password/request-reset-password`;
    this.realizarPeticionPost(url, data).subscribe(
      data => {

        if ('INVALID' === data.status) {
          this.alertService.showDetailError(data.status, data.error_message);
        } else {
          this.alertService.showDetailSuccess('Solicitud enviada', 'Revise la bandeja de entrada de su correo electrónico');
          this.routerService.navigateByUrl('/');
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  /**
   * Change password confirmation.
   */
  changePassword(password: string, passwordConfirm: string, resetToken: string): void {
    const url = `${this.urlApi}/reset-password/change-password`;
    let data: any = new Object();
    data.password = password;
    data.confirm_password = passwordConfirm;
    data.reset_password_token = resetToken;

    this.realizarPeticionPost(url, data).subscribe(
      data => {
        if ('INVALID' === data.status) {
          this.alertService.showDetailError(data.status, data.error_message);
        } else {
          this.alertService.showDetailSuccess('Correcto', 'Contraseña modificada');
          this.routerService.navigateByUrl('/');
        }
      },
      err => { this.alertService.showDetailError('Error', err.error_message) }
    );
  }

  /**
   * Validate password quality.
   */
  validatePassword(password: string): Observable<any> {
    const url = `${this.urlApi}/reset-password/validate-password`;
    return this.realizarPeticionPost(url, {password: password});
  }

  /**
   * Validate password and password confirm.
   */
  validatePasswordConfirm(password: string, passwordConfirm: string): Observable<any> {
    const url = `${this.urlApi}/reset-password/validate-password-with-confirm`;
    return this.realizarPeticionPost(url, {password: password, confirm_password: passwordConfirm});
  }

  /**
   * Validate password reset token.
   */
  validateToken(token: string): Observable<any> {
    const url = `${this.urlApi}/reset-password/get-info-reset-password-token`;
    return this.realizarPeticionPost(url, {reset_password_token: token});
  }

  getApiInfo(): Observable<any> {
    const url = `${this.urlApi}`;
    return this.realizarPeticionGet(url);
  }

  getPolicy(): Observable<any> {
    const url = `${this.urlApi}/reset-password/get-password-policy`;
    return this.realizarPeticionGet(url);
  }



}
