import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-mensaje-cookies',
  templateUrl: './modal-mensaje-cookies.component.html',
  styleUrls: ['./modal-mensaje-cookies.component.scss']
})
export class ModalMensajeCookiesComponent implements OnInit {

  public title: string;
  public accept: string;
  public cancel: string;
  public message: string;

  @Output() acceptEmit = new EventEmitter<any>();

  constructor( ) { }

  ngOnInit(): void {
    this.message = 'Te informamos que nuestra página usa cookies estrictamente necesarias, así como cookies de terceros (Google Recaptcha), que nos ofrecen información anonimizada y seguridad para evitar accesos por bots de Internet. Estas cookies se almacenan en tu dispositivo y podemos acceder a las mismas con la finalidad de mejorar nuestra página, su seguridad. Si nos otorgas tu consentimiento a través del botón Aceptar, se instalarán en tu dispositivo. Si continúas navegando y no has aceptado/configurado/rechazado, no se instalarán en tu dispositivo.'
  }

  public emitAccept(): void {
    this.acceptEmit.emit();
  }

  // Descarga del documento de politicas de privacidad
  downloadDocument() {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = 'assets/documentos/Política de Cookies.pdf';
    link.download = 'Política de Cookies.pdf';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
