import { UtilsService } from 'src/app/shared/services/utils.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { map } from 'rxjs/operators';
import { NO, REQUIRED_MESSAGE, SI } from 'src/app/app.constants';
import { InvoiceGet } from 'src/app/core/models/invoices/invoice-get';
import { InvoiceSite } from 'src/app/core/models/invoices/invoice-site.model';
import { InvoiceSubtype } from 'src/app/core/models/invoices/invoice-subtype.model';
import { InvoiceType } from 'src/app/core/models/invoices/invoice-type.model';
import { Worksheets } from 'src/app/core/models/worksheets.model';
import { SearchService } from 'src/app/core/services/search.service';
import { InputField } from 'src/app/shared/shared.module';
import { ThousandsPipe } from 'src/app/shared/pipes/thousands.pipe';
import { CurrencyPipe } from '@angular/common';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { VatType } from 'src/app/core/models/vat-type.model';
import { StoredFile } from 'src/app/core/models/stored-file.model';
import { StoredFilesService } from 'src/app/core/services/stored-files.service';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { CSiteCriteria } from '../../../../models/csite_criteria.model';

@Component({
  selector: 'app-factura-generica',
  templateUrl: './factura-generica.component.html',
  styleUrls: ['./factura-generica.component.scss'],
  providers: [CurrencyPipe, ThousandsPipe],
})
export class FacturaGenericaComponent implements OnInit, OnChanges {
  inputs = new Array<InputField>();
  ubicaciones: InvoiceSite[] = [];
  partesDeTrabajo: Worksheets[] = [];
  ivaExento = 'NO';

  ivaExentoCheck = false;
  mobile: boolean;
  partesCargados = false;
  ubicacionCargada = false;
  defaultUbicacionCargada = false;
  worksheet: Worksheets[] = [];
  partesDeTrabajoFromEdit: Worksheets[] = [];
  obraFromEdit: InvoiceSite;
  IVASPermitidos: VatType[];
  customInputA: string = null;
  inputAmountDefaultMode: 'negative' | 'positive' | 'default';
  inputIrpfAmountDefaultMode: 'negative' | 'positive' | 'default';
  inputBaseImponibleManualAmountDefaultMode:
    | 'negative'
    | 'positive'
    | 'default';

  isLoadingFactura = false;

  ubicacionChange = new BehaviorSubject<any>(null);

  @Input() formGroup: FormGroup;
  @Input() invoiceGet: InvoiceGet;
  @Input() editOn: boolean;
  @Input() companyId: string;
  @Input() destinatarioId: string;
  @Input() tipoFactura: InvoiceType;
  @Input() subTipoFactura: InvoiceSubtype;
  @Input() vatTypes: VatType[];
  @Input() fechaFactura: string;
  @Input() changeFormFirstStep = false;

  @Output() formGroupEmiter = new EventEmitter<Array<FormGroup>>();
  @Output() back = new EventEmitter<boolean>();

  @ViewChild('siteOption', { static: true })
  siteOptionTemplate: TemplateRef<any>;
  @ViewChild('worksheetOption', { static: true })
  worksheetOptionTemplate: TemplateRef<any>;
  @ViewChild('fechaRecepcionObra', { static: true })
  fechaRecepcionObraTemplate: TemplateRef<any>;

  constructor(
    protected fb: FormBuilder,
    protected searchService: SearchService,
    protected currencyPipe: CurrencyPipe,
    protected thousandsPipe: ThousandsPipe,
    protected storedFileService: StoredFilesService,
    public utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes.changeFormFirstStep?.currentValue || (changes.subTipoFactura?.currentValue?.id !== changes.subTipoFactura?.previousValue?.id)) {
      this.ubicacionCargada = false;
      this.setCustomInput();
      this.inputAmountDefaultMode = 'positive';
      this.inputIrpfAmountDefaultMode = 'negative';
      this.inputBaseImponibleManualAmountDefaultMode = 'default';

      this.initForm();
    }

  }

  initForm() {
    this.formGroup = this.generateForm();
    this.generateFgroupIvas();
    this.generateCustomForm();

    this.inputs = new Array<InputField>();
    this.generateInputs().forEach((input) => {
      this.inputs.push(input);
    });

    this.generateIvasInputs();
    this.generateCustomInput();

    if(!this.editOn) {
      this.initializeToggles();
    }

    this.formGroup.setValidators([this.ValidateBaseExentaIVA()]);

    this.changeUbicacion();

    if (this.customInputA) {
      this.formGroup
        .get(this.customInputA)
        .valueChanges.subscribe((partesDeTrabajo: [Worksheets]) => {
          this.changeCustomInputA(partesDeTrabajo);
        });
    }

    this.formGroup.get('importeIrpf').valueChanges.subscribe(() => {
      this.setImporteTotalFactura();
    });

    this.changeBaseImponible();

    this.changeBaseExentaIva();

    this.changeIvasPermitidos();

    this.formGroup.get('importeIVA').valueChanges.subscribe((value) => {
      this.setImporteTotalFactura();
    });

    this.formGroup.get('importeVariosIVA').valueChanges.subscribe(() => {
      this.setImporteTotalFactura();
    });

    this.formGroup.get('adjuntarFactura').valueChanges.subscribe((adjunto) => {
      this.adjuntarArchivo(adjunto, 'adjuntarFacturaId');
    });

    this.formGroup
      .get('adjuntarDocumentacionAdicional')
      .valueChanges.subscribe((adjunto) => {
        this.adjuntarArchivo(adjunto, 'adjuntarDocumentacionAdicionalId');
      });

    if (this.editOn) {
      this.setEditValueToForm(this.invoiceGet);
    }
  }

  submit() {
    this.formGroupEmiter.emit([this.formGroup]);
  }

  ivaExentoToggleChange(event) {
    this.changeToggleText('ivaExentoToggle', event.checked);
    this.displayInput('baseExentaIva', event.checked);
    if (!event.checked) {
      this.formGroup.get('baseExentaIva').setValue(0);
    }
  }

  inversionDeSujetoPasivoChange(event) {
    this.changeToggleText('inversionDeSujetoPasivo', event.checked);
    const ivaToggle =
      this.subTipoFactura?.templateParameters?.vatOptions?.otherVattypesSlider
        ?.show !== false;
    if (event.checked) {
      this.displayInput('ivaToggle', false);
      this.displayInput('importeIVA', false);
      this.clearIVASPermitidosInputs();
      this.ivaToggleChange(new MatSlideToggleChange(null, false), false);
    } else {
      this.displayInput('ivaToggle', ivaToggle);
      this.displayInput('importeIVA', true);
      this.ivaToggleChange(
        new MatSlideToggleChange(null, this.formGroup.get('ivaToggle').value)
      );
    }
  }

  ivaToggleChange(event, importeIVA = true) {
    this.changeToggleText('ivaToggle', event.checked);
    if (event.checked) {
      if (this.getIVASPermitidos()?.length > 0) {
        this.getIVASPermitidos().forEach((vatType) => {
          this.displayInput(vatType.type_key, true);
        });
      }
      this.displayInput('importeVariosIVA', true);
      this.displayInput('importeIVA', false);
      this.formGroup
        .get('importeVariosIVA')
        .setValue(
          this.utilsService.formatAutocalculateAmountInput(this.calcularIVA())
        );
    } else {
      if (this.getIVASPermitidos()?.length > 0) {
        this.getIVASPermitidos().forEach((vatType) => {
          this.displayInput(vatType.type_key, false);
        });
      }
      this.displayInput('importeVariosIVA', false);
      this.displayInput('importeIVA', importeIVA);
      this.formGroup
        .get('importeIVA')
        .setValue(
          this.utilsService.formatAutocalculateAmountInput(this.calcularIVA())
        );
      this.clearIVASPermitidosInputs();
    }
  }

  retencionIrpfToggleChange(event) {
    this.changeToggleText('retencionIrpfToggle', event.checked);
    this.displayInput('importeIrpf', event.checked);
    if (!event.checked) {
      this.formGroup.get('importeIrpf').setValue(0);
    }
  }

  displayInput(key: string, display: boolean) {
    this.changeInputProperty(key, 'display', display);
  }

  changeToggleText(key: string, event: boolean) {
    this.changeInputProperty(key, 'toggleText', event ? SI : NO);
  }

  changeInputProperty(key: string, property: string, value: any) {
    this.inputs.find((c) => c.key === key)[property] = value;
  }

  calcularIVA() {
    let iva = 0;

    if (this.formGroup.get('ivaToggle').value) {
      if (this.getIVASPermitidos()?.length > 0) {
        this.getIVASPermitidos().forEach((vatType) => {
          const ivaPermitido = this.formatAmountInput(vatType.type_key);
          if (!isNaN(ivaPermitido)) {
            iva += ivaPermitido;
          }
        });
      }
    } else {
      const ivaDefecto =
        (this.formatAmountInput('baseImponible') -
          this.formatAmountInput('baseExentaIva')) *
        Number(
          this.getIVAByKey(
            this.subTipoFactura?.templateParameters?.vatOptions
              ?.preferedVattypeUniquekey
          )?.per_one_factor
        );

      if (!isNaN(ivaDefecto)) {
        iva = ivaDefecto;
      }
    }

    return iva;
  }

  setImporteTotalFactura() {
    const importeTotal = this.formGroup.get('inversionDeSujetoPasivo').value
      ? this.formatAmountInput('baseImponible') + this.importeIrpf()
      : this.formatAmountInput('baseImponible') +
        Number(this.calcularIVA()) +
        this.importeIrpf();
    /*console.log("this.formGroup.get('inversionDeSujetoPasivo').value="+this.formGroup.get('inversionDeSujetoPasivo').value)
    console.log("this.formatAmountInput('baseImponible')="+this.formatAmountInput('baseImponible'))
    console.log("this.importeIrpf()="+this.importeIrpf())
    console.log("this.calcularIVA()="+this.calcularIVA())
    console.log("Number(this.calcularIVA())="+Number(this.calcularIVA()))
    console.log('importeTotal='+importeTotal)*/
    if (!isNaN(importeTotal)) {
      this.formGroup
        .get('importeTotalFactura')
        .setValue(
          this.utilsService.formatAutocalculateAmountInput(importeTotal)
        );
    }
  }

  generateForm() {
    const fGroup = this.fb.group({
      ubicacion: [
          null,
        {
          validators: this.subTipoFactura?.templateParameters?.csiteSection
            ?.mandatory
            ? [Validators.required]
            : null,
          updateOn: 'change',
        },
      ],
      baseImponible: [
        {
          value: 0,
          disabled:
            this.subTipoFactura?.templateParameters?.baseAmountReadonly ||
            false,
        },
        Validators.required
      ],
      ivaExentoToggle: [
        this.subTipoFactura?.templateParameters?.vatExentoOptions
          ?.sliderDefault || false,
      ],
      baseExentaIva: [
        0,
        {
          validators: this.subTipoFactura?.templateParameters?.vatExentoOptions
            ?.baseAmountMandatory
            ? [Validators.required]
            : null,
        },
      ],
      inversionDeSujetoPasivo: [
        {
          value:
            this.subTipoFactura?.templateParameters?.vatOptions?.ispSection
              ?.ispSlider?.default || false,
          disabled:
            this.subTipoFactura?.templateParameters?.vatOptions?.ispSection
              ?.ispSlider?.readonly || false,
        },
      ],
      fechaRecepcionObra: [null],
      ivaToggle: [
        {
          value:
            this.subTipoFactura?.templateParameters?.vatOptions
              ?.otherVattypesSlider?.default || false,
          disabled:
            this.subTipoFactura?.templateParameters?.vatOptions
              ?.otherVattypesSlider?.readonly || false,
        },
      ],
      importeIVA: [
        {
          value: 0,
          disabled: true,
        },
      ],
      importeVariosIVA: [
        {
          value: 0,
          disabled: true,
        },
      ],
      retencionIrpfToggle: [
        {
          value:
            this.subTipoFactura?.templateParameters?.irpfSection?.appliesSlider
              ?.default || false,
          disabled:
            this.subTipoFactura?.templateParameters?.irpfSection?.appliesSlider
              ?.readonly || false,
        },
      ],
      importeIrpf: [
        this.subTipoFactura?.templateParameters?.irpfSection?.amount
          ?.default || 0,
        {
          validators: this.subTipoFactura?.templateParameters?.irpfSection
            ?.amount?.mandatory
            ? [Validators.required]
            : null,
        },
      ],
      importeTotalFactura: [
        {
          value: 0,
          disabled: true,
        },
      ],
      adjuntarFactura: [
        {
          value: null,
          disabled:
            this.subTipoFactura?.templateParameters?.invoiceFileInput
              ?.readonly || false,
        },
        {
          validators: this.subTipoFactura?.templateParameters?.invoiceFileInput
            ?.mandatory
            ? [Validators.required]
            : null,
        },
      ],
      adjuntarFacturaId: [
        null,
        {
          validators: this.subTipoFactura?.templateParameters?.invoiceFileInput
            ?.mandatory
            ? [Validators.required]
            : null,
        },
      ],
      adjuntarDocumentacionAdicional: [
        {
          value: null,
          disabled:
            this.subTipoFactura?.templateParameters?.extraFileInput?.readonly ||
            false,
        },
        {
          validators: this.subTipoFactura?.templateParameters?.extraFileInput
            ?.mandatory
            ? [Validators.required]
            : null,
        },
      ],
      adjuntarDocumentacionAdicionalId: [
        null,
        {
          validators: this.subTipoFactura?.templateParameters?.extraFileInput
            ?.mandatory
            ? [Validators.required]
            : null,
        },
      ]
    });

    return fGroup;
  }

  generateInputs(): InputField[] {
    const inputs: InputField[] = [
      {
        key: 'ubicacion',
        type: 'autocomplete-chip',
        label:
          this.subTipoFactura?.templateParameters?.csiteSection?.labelText ||
          'Ubicación',
        controlValue: '',
        layout: 'col-md-12 col-lg-12',
        placeholder: 'Seleccione una opción',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        selectOptionTemplate: this.siteOptionTemplate,
        options: this.ubicaciones,
        readonly: this.subTipoFactura?.templateParameters?.csiteSection?.readonly ||
        false,
        infoIconShow:
          this.subTipoFactura?.templateParameters?.csiteSection?.hintText !==
            null &&
          this.subTipoFactura?.templateParameters?.csiteSection?.hintText !==
            undefined,
        infoIconText:
          this.subTipoFactura?.templateParameters?.csiteSection?.hintText || '',
        display:
          this.subTipoFactura?.templateParameters?.csiteSection?.show || false,
        filteredOptions: (value) => this.ubicacionFilteredOptions(value),
        validatorMessages: this.subTipoFactura?.templateParameters?.csiteSection
          ?.mandatory
          ? [{ key: 'required', value: REQUIRED_MESSAGE }]
          : null,
      },
      {
        key: 'divider1',
        type: 'divider',
        label: 'Base imponible',
        layout: 'col-md-12 col-lg-12 ',
      },
      {
        key: 'baseImponible',
        type: 'amount',
        label: 'Base imponible',
        controlValue: '',
        layout: 'col-lg-6 col-md-12',
        readonly:
          this.subTipoFactura?.templateParameters?.baseAmountReadonly || false,
        amountMode:
          this.subTipoFactura?.templateParameters?.baseAmountCalculationMode !==
            'manual' &&
          this.subTipoFactura?.templateParameters?.baseAmountCalculationMode !==
            null &&
          this.subTipoFactura?.templateParameters?.baseAmountCalculationMode !==
            undefined
            ? this.inputBaseImponibleManualAmountDefaultMode
            : this.inputAmountDefaultMode,
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }]
      },
      {
        key: 'ivaExentoToggle',
        type: 'toggle',
        label: '¿Tiene IVA Exento o IVA no sujeto?',
        controlValue: '',
        layout: 'col-lg-6  col-md-12',
        infoIconText:
          this.subTipoFactura?.templateParameters?.vatExentoOptions
            ?.sliderHintText || '',
        infoIconShow:
          this.subTipoFactura?.templateParameters?.vatExentoOptions
            ?.sliderHintText !== null &&
          this.subTipoFactura?.templateParameters?.vatExentoOptions
            ?.sliderHintText !== undefined,
        display:
          this.subTipoFactura?.templateParameters?.vatExentoOptions?.show ||
          false,
        toggleChange: (event) => {
          this.ivaExentoToggleChange(event);
        },
        toggleText: NO,
      },
      {
        key: 'baseExentaIva',
        type: 'amount',
        label: 'Base imponible exenta de IVA o con IVA no sujeto',
        controlValue: '',
        layout: 'col-lg-6 col-md-12',
        display: false,
        amountMode: this.inputAmountDefaultMode,
        infoIconShow:
          this.subTipoFactura?.templateParameters?.vatExentoOptions
            ?.baseAmountHintText !== null &&
          this.subTipoFactura?.templateParameters?.vatExentoOptions
            ?.baseAmountHintText !== undefined,
        infoIconText:
          this.subTipoFactura?.templateParameters?.vatExentoOptions
            ?.baseAmountHintText || '',
        validatorMessages: this.subTipoFactura?.templateParameters
          ?.vatExentoOptions?.baseAmountMandatory
          ? [
              { key: 'required', value: REQUIRED_MESSAGE },
              {
                key: 'baseExentaInvalid',
                value:
                  'La base imponible exenta no puede ser mayor a la base imponible',
              },
            ]
          : [
              {
                key: 'baseExentaInvalid',
                value:
                  'La base imponible exenta no puede ser mayor a la base imponible',
              },
            ],
      },
      {
        key: 'divider2',
        type: 'divider',
        label: 'IVA',
        display:
          this.subTipoFactura?.templateParameters?.vatOptions?.show || false,
        layout: 'col-md-12 col-lg-12 ',
      },
      {
        key: 'inversionDeSujetoPasivo',
        type: 'toggle',
        label: 'Inversión sujeto pasivo',
        controlValue: '',
        layout: 'col-lg-6 col-md-12',
        infoIconShow:
          this.subTipoFactura?.templateParameters?.vatOptions?.ispSection
            ?.ispSlider?.hintText !== null &&
          this.subTipoFactura?.templateParameters?.vatOptions?.ispSection
            ?.ispSlider?.hintText !== undefined,
        infoIconText:
          this.subTipoFactura?.templateParameters?.vatOptions?.ispSection
            ?.ispSlider?.hintText || '',
        display:
          (this.subTipoFactura?.templateParameters?.vatOptions?.ispSection
            ?.ispSlider?.show &&
            this.subTipoFactura?.templateParameters?.vatOptions?.show) ||
          false,
        toggleChange: (event) => {
          this.inversionDeSujetoPasivoChange(event);
        },
        toggleText: NO,
      },
      {
        key: 'fechaRecepcionObra',
        type: 'inputTemplate',
        label: 'Fecha de recepción obra:',
        controlValue: '',
        layout: 'col-lg-6 col-md-12 display-inline-flex',
        inputTemplate: this.fechaRecepcionObraTemplate,
        infoIconShow:
          this.subTipoFactura?.templateParameters?.vatOptions?.ispSection
            ?.receptionDateInfo?.hintText !== null &&
          this.subTipoFactura?.templateParameters?.vatOptions?.ispSection
            ?.receptionDateInfo?.hintText !== undefined,
        infoIconText:
          this.subTipoFactura?.templateParameters?.vatOptions?.ispSection
            ?.receptionDateInfo?.hintText || '',
        display: false,
        defaultValue: null,
      },
      {
        key: 'ivaToggle',
        type: 'toggle',
        label: '¿Tiene otros tipos de IVA?',
        controlValue: '',
        layout: 'col-lg-6 col-md-12',
        infoIconShow:
          this.subTipoFactura?.templateParameters?.vatOptions
            ?.otherVattypesSlider?.hintText !== null &&
          this.subTipoFactura?.templateParameters?.vatOptions
            ?.otherVattypesSlider?.hintText !== undefined,
        infoIconText:
          this.subTipoFactura?.templateParameters?.vatOptions
            ?.otherVattypesSlider?.hintText || '',
        display:
          (this.subTipoFactura?.templateParameters?.vatOptions
            ?.otherVattypesSlider?.show &&
            this.subTipoFactura?.templateParameters?.vatOptions?.show) ||
          false,
        defaultValue: null,
        toggleChange: (event) => {
          this.ivaToggleChange(event);
        },
        toggleText: NO,
      },
      {
        key: 'importeIVA',
        type: 'amount',
        label: `Importe IVA (${
          this.getIVAByKey(
            this.subTipoFactura?.templateParameters?.vatOptions
              ?.preferedVattypeUniquekey
          )?.percent_description
        })`,
        controlValue: '',
        layout: 'col-lg-6 col-md-12',
        readonly: true,
        display:
          this.subTipoFactura?.templateParameters?.vatOptions?.show || false,
      },
      {
        key: 'blank',
        type: 'blank',
        layout: 'col-md-12 col-lg-12',
        display:
          this.subTipoFactura?.templateParameters?.vatOptions?.show || false,
      },
      {
        key: 'blank',
        type: 'blank',
        layout: 'col-md-12 col-lg-12',
        display:
          this.subTipoFactura?.templateParameters?.vatOptions?.show || false,
      },
      {
        key: 'importeVariosIVA',
        type: 'amount',
        label: 'Total IVA',
        controlValue: '',
        display:
          this.subTipoFactura?.templateParameters?.vatOptions?.show || false,
        layout: 'col-lg-6 col-md-12',
        readonly: true,
      },
      {
        key: 'divider3',
        type: 'divider',
        label: 'IRPF',
        display:
          this.subTipoFactura?.templateParameters?.irpfSection?.show || false,
        layout: 'col-md-12 col-lg-12',
      },
      {
        key: 'retencionIrpfToggle',
        type: 'toggle',
        label: '¿Tiene retención de IRPF?',
        controlValue: '',
        layout: 'col-lg-6 col-md-12',
        infoIconShow:
          this.subTipoFactura?.templateParameters?.irpfSection?.appliesSlider
            ?.hintText !== null &&
          this.subTipoFactura?.templateParameters?.irpfSection?.appliesSlider
            ?.hintText !== undefined,
        infoIconText:
          this.subTipoFactura?.templateParameters?.irpfSection?.appliesSlider
            ?.hintText || '',
        display:
          (this.subTipoFactura?.templateParameters?.irpfSection?.appliesSlider
            ?.show &&
            this.subTipoFactura?.templateParameters?.irpfSection?.show) ||
          false,
        toggleChange: (event) => {
          this.retencionIrpfToggleChange(event);
        },
        toggleText: NO,
      },
      {
        key: 'importeIrpf',
        type: 'amount',
        label: 'Importe IRPF',
        controlValue: '',
        layout: 'col-lg-6 col-md-12',
        amountMode: this.inputIrpfAmountDefaultMode,
        readonly: this.subTipoFactura?.templateParameters?.irpfSection?.amount
        ?.readonly || false,
        infoIconShow:
          this.subTipoFactura?.templateParameters?.irpfSection?.amount
            ?.hintText !== null &&
          this.subTipoFactura?.templateParameters?.irpfSection?.amount
            ?.hintText !== undefined,
        infoIconText:
          this.subTipoFactura?.templateParameters?.irpfSection?.amount
            ?.hintText || '',
        display:
          false &&
          (this.subTipoFactura?.templateParameters?.irpfSection?.show || false),
        validatorMessages: this.subTipoFactura?.templateParameters?.irpfSection
          ?.amount?.mandatory
          ? [{ key: 'required', value: REQUIRED_MESSAGE }]
          : null,
      },
      {
        key: 'importeTotalFactura',
        type: 'amount',
        label: 'Importe total factura',
        controlValue: '',
        layout: 'col-lg-6 col-md-12 layout-align-right bold',
        infoIconShow:
          this.subTipoFactura?.templateParameters?.invoiceTotalHintText !==
            null &&
          this.subTipoFactura?.templateParameters?.invoiceTotalHintText !==
            undefined,
        infoIconText:
          this.subTipoFactura?.templateParameters?.invoiceTotalHintText || '',
        readonly: true,
      },
      {
        key: 'divider4',
        type: 'divider',
        label: 'Documentación',
        layout: 'col-md-12 col-lg-12',
        display:
          this.subTipoFactura?.templateParameters?.invoiceFileInput?.show ||
          false ||
          this.subTipoFactura?.templateParameters?.extraFileInput?.show ||
          false,
      },
      {
        key: 'adjuntarFactura',
        type: 'fileDrop',
        label:
          this.subTipoFactura?.templateParameters?.invoiceFileInput
            ?.labelText || '',
        controlValue: '',
        layout: 'col-md-12 col-lg-12',
        fileExtensions: ['pdf'],
        display:
          this.subTipoFactura?.templateParameters?.invoiceFileInput?.show ||
          false,
        infoIconShow:
          this.subTipoFactura?.templateParameters?.invoiceFileInput
            ?.hintText !== null &&
          this.subTipoFactura?.templateParameters?.invoiceFileInput
            ?.hintText !== undefined,
        infoIconText:
          this.subTipoFactura?.templateParameters?.invoiceFileInput?.hintText ||
          '',
        validatorMessages: this.subTipoFactura?.templateParameters
          ?.invoiceFileInput?.mandatory
          ? [{ key: 'required', value: REQUIRED_MESSAGE }]
          : null,
      },
      {
        key: 'adjuntarDocumentacionAdicional',
        type: 'fileDrop',
        label:
          this.subTipoFactura?.templateParameters?.extraFileInput?.labelText ||
          '',
        controlValue: '',
        layout: 'col-md-12 col-lg-12',
        fileExtensions: ['pdf'],
        display:
          this.subTipoFactura?.templateParameters?.extraFileInput?.show ||
          false,
        infoIconShow:
          this.subTipoFactura?.templateParameters?.extraFileInput?.hintText !==
            null &&
          this.subTipoFactura?.templateParameters?.extraFileInput?.hintText !==
            undefined,
        infoIconText:
          this.subTipoFactura?.templateParameters?.extraFileInput?.hintText ||
          '',
        validatorMessages: this.subTipoFactura?.templateParameters
          ?.extraFileInput?.mandatory
          ? [{ key: 'required', value: REQUIRED_MESSAGE }]
          : null,
      },
    ];

    if(!this.subTipoFactura?.templateParameters?.csiteSection?.show) {
      this.setDefaultValueUbicacion()
    }

    return inputs;
  }

  generateCustomInput() {
    if (this.inputs?.length > 0) {
      const INDEX_PARTES_DE_TRABAJO =
        this.inputs.findIndex((input) => {
          return input.key === 'ubicacion';
        }) + 1;
      const inputPartesDeTrabajo: InputField = {
        key: 'partesDeTrabajo',
        type: 'autocomplete-multi-chip',
        label: 'Partes de trabajo',
        controlValue: '',
        layout: 'col-md-12 col-lg-12',
        placeholder: 'Seleccione una opción',
        selectBindLabel: 'selectDescription',
        selectOptionLabel: 'id',
        options: this.partesDeTrabajo,
        readonly: this.subTipoFactura?.templateParameters?.worksheetsSection?.readonly || false,
        infoIconShow:
          this.subTipoFactura?.templateParameters?.worksheetsSection
            ?.hintText !== null &&
          this.subTipoFactura?.templateParameters?.worksheetsSection
            ?.hintText !== undefined,
        infoIconText:
          this.subTipoFactura?.templateParameters?.worksheetsSection
            ?.hintText || '',
        display:
          this.subTipoFactura?.templateParameters?.worksheetsSection?.show ||
          false,
        filteredOptions: (value) => {
          this.getDataSecondSelect(value);
          return this.partesDeTrabajoFilteredOptions();
        },
        selectOptionTemplate: this.worksheetOptionTemplate,
        validatorMessages: this.subTipoFactura?.templateParameters
          ?.worksheetsSection?.mandatory
          ? [{ key: 'required', value: REQUIRED_MESSAGE }]
          : null,
      };

      this.inputs.splice(INDEX_PARTES_DE_TRABAJO, 0, inputPartesDeTrabajo);
    }
  }

  generateCustomForm() {
    if (this.subTipoFactura?.templateParameters?.worksheetsSection) {
      this.formGroup.addControl(
        'partesDeTrabajo',
        new FormControl([])
      );

      this.formGroup
        .get('partesDeTrabajo')
        .setValidators(
          this.subTipoFactura?.templateParameters?.worksheetsSection?.mandatory
            ? Validators.required
            : null
        );
    }
  }

  getIVASPermitidos() {
    const ivasOrdenados: VatType[] = [];

    this.subTipoFactura?.templateParameters?.vatOptions?.allowedVattypeUniquekeys.forEach(
      (ivaKey) => {
        const iva = this.getIVAByKey(ivaKey);
        if(iva) {
          ivasOrdenados.push(iva);
        }
      }
    );

    return ivasOrdenados;
  }

  getIVAByKey(key: string) {
    return this.vatTypes?.find((vatType) => {
      return vatType.type_key === key;
    });
  }

  clearIVASPermitidosInputs() {
    this.getIVASPermitidos().forEach((vatType) => {
      this.formGroup.get(vatType.type_key).setValue(0);
    });
  }

  initializeToggles() {
    this.ivaExentoToggleChange(
      new MatSlideToggleChange(
        null,
        this.formGroup.get('ivaExentoToggle').value
      )
    );

    if (this.subTipoFactura?.templateParameters?.vatOptions?.show) {
      this.inversionDeSujetoPasivoChange(
        new MatSlideToggleChange(
          null,
          this.formGroup.get('inversionDeSujetoPasivo').value
        )
      );
    }

    if (this.subTipoFactura?.templateParameters?.irpfSection?.show) {
      this.retencionIrpfToggleChange(
        new MatSlideToggleChange(
          null,
          this.formGroup.get('retencionIrpfToggle').value
        )
      );
    }
  }

  ValidateBaseExentaIVA() {
    return (group: FormGroup): any => {
      if (
        Math.abs(this.formatAmountInput('baseExentaIva')) >
        Math.abs(this.formatAmountInput('baseImponible'))
      ) {
        group.get('baseExentaIva').setErrors({ baseExentaInvalid: true });
      } else {
        group.get('baseExentaIva').setErrors(null);
      }
    };
  }

  changeUbicacion() {
    this.formGroup
      .get('ubicacion')
      .valueChanges.subscribe((ubicacion: [InvoiceSite]) => {
        let display = false;
        let value = null;
        this.formGroup.get(this.customInputA)?.setValue(null);
        if (ubicacion?.length > 0) {
          if (
            ubicacion[0]?.reception_with_reservations_date &&
            this.subTipoFactura?.templateParameters?.vatOptions?.ispSection
              ?.receptionDateInfo?.show &&
            this.subTipoFactura?.templateParameters?.vatOptions?.show
          ) {
            display = true;
            value = ubicacion[0]?.reception_with_reservations_date;
          }
        }
        this.formGroup.get('fechaRecepcionObra').setValue(value);
        this.changeInputProperty('fechaRecepcionObra', 'defaultValue', value);
        this.displayInput('fechaRecepcionObra', display);

        this.getDataSecondSelect();
      });
  }

  changeCustomInputA(data: any) {
    if (data) {
      const partesDeTrabajo = data as Worksheets[];
      let baseImponible = 0;
      if (
        this.subTipoFactura?.templateParameters?.baseAmountCalculationMode !==
        'manual'
      ) {
        partesDeTrabajo.forEach((parteDeTrabajo) => {
          baseImponible += parteDeTrabajo.total_amount;
        });
        this.formGroup
          .get('baseImponible')
          .setValue(
            this.utilsService.formatAutocalculateAmountInput(baseImponible)
          );
      }
    }
  }

  importeIrpf() {
    let importeRpf = this.formatAmountInput('importeIrpf');

    if (importeRpf > 0) {
      importeRpf = importeRpf * -1;
      this.formGroup.get('importeIrpf').setValue(importeRpf);
    }

    return importeRpf;
  }

  formatAmountInput(formControlKey: string) {
    return this.utilsService.formatAmount(
      this.formGroup.get(formControlKey).value?.toString()
    );
  }

  changeBaseImponible() {
    this.formGroup.get('baseImponible').valueChanges.subscribe(() => {
      this.formGroup
        .get('importeIVA')
        .setValue(
          this.utilsService.formatAutocalculateAmountInput(this.calcularIVA())
        );
    });
  }

  changeBaseExentaIva() {
    this.formGroup.get('baseExentaIva').valueChanges.subscribe(() => {
      this.formGroup
        .get('importeIVA')
        .setValue(
          this.utilsService.formatAutocalculateAmountInput(this.calcularIVA())
        );
    });
  }

  changeIvasPermitidos() {
    if (this.getIVASPermitidos()?.length > 0) {
      this.getIVASPermitidos().forEach((vatType) => {
        this.formGroup.get(vatType.type_key).valueChanges.subscribe((data) => {
          this.formGroup
            .get('importeVariosIVA')
            .setValue(
              this.utilsService.formatAutocalculateAmountInput(
                this.calcularIVA()
              )
            );
        });
      });
    }
  }

  setEditValueToForm(invoice: InvoiceGet) {
    this.setBaseExenta(invoice);
    this.setInversionDeSujetoPasivo(invoice);
    this.setRetencionIRPF(invoice);
    this.setBaseImponible(invoice);
    this.setIVAS(invoice);
    this.setAdjuntarFactura(invoice);
    this.setAdjuntarDocumentacionAdicional(invoice);

    this.initializeToggles();
  }

  setBaseExenta(invoice: InvoiceGet) {
    if (this.haveBaseExenta(invoice) !== undefined && this.haveBaseExenta(invoice) !== null) {
      this.formGroup.get('ivaExentoToggle').setValue(true);
      this.formGroup
        .get('baseExentaIva')
        .setValue(
          this.utilsService.formatAutocalculateAmountInput(
            this.haveBaseExenta(invoice).baseAmount
          )
        );
    }
  }

  setInversionDeSujetoPasivo(invoice: InvoiceGet) {
    if (
      invoice.reverseVatLiability !== undefined &&
      invoice.reverseVatLiability !== null
    ) {
      this.formGroup
        .get('inversionDeSujetoPasivo')
        .setValue(invoice.reverseVatLiability);
    }
  }

  setRetencionIRPF(invoice: InvoiceGet) {
    if (
      invoice.irpfValue !== undefined &&
      invoice.irpfValue !== null
    ) {
      const irpfValue = this.inputIrpfAmountDefaultMode === 'positive' ? Math.abs(Number(invoice.irpfValue)) : invoice.irpfValue;
      this.formGroup.get('retencionIrpfToggle').setValue(true);
      this.formGroup
        .get('importeIrpf')
        .setValue(
          this.utilsService.formatAutocalculateAmountInput(
            irpfValue
          )
        );
    }
  }

  setBaseImponible(invoice: InvoiceGet) {
    if (
      (this.subTipoFactura.templateParameters.baseAmountCalculationMode ===
        'manual' &&
        invoice.baseAmount !== undefined &&
        invoice.baseAmount !== null) ||
      (this.subTipoFactura.templateParameters.baseAmountCalculationMode !==
        'manual' &&
        invoice.baseAmount !== undefined &&
        invoice.baseAmount !== null &&
        invoice.linkedWorksheets.length === 0)
    ) {
      this.formGroup
        .get('baseImponible')
        .setValue(
          this.utilsService.formatAutocalculateAmountInput(
            invoice.baseAmount
          )
        );
    }
  }

  setIVAS(invoice: InvoiceGet) {
    if (this.vatLinesWhithoutBaseExenta(invoice)?.length === 1) {
      if (
        this.vatLinesWhithoutBaseExenta(invoice)[0].vatTypeTypeKey !==
          this.subTipoFactura.templateParameters.vatOptions
            .preferedVattypeUniquekey ||
        (this.vatLinesWhithoutBaseExenta(invoice)[0].vatTypeTypeKey ===
          this.subTipoFactura.templateParameters.vatOptions
            .preferedVattypeUniquekey &&
          this.vatLinesWhithoutBaseExenta(invoice)[0].amount !==
            this.formatAmountInput('importeIVA'))
      ) {
        this.formGroup
          .get(this.vatLinesWhithoutBaseExenta(invoice)[0].vatTypeTypeKey)
          .setValue(
            this.utilsService.formatAutocalculateAmountInput(
              this.vatLinesWhithoutBaseExenta(invoice)[0]?.amount
            )
          );
      }
    }

    if (this.vatLinesWhithoutBaseExenta(invoice)?.length > 1) {
      this.formGroup.get('ivaToggle').setValue(true);
      this.vatLinesWhithoutBaseExenta(invoice).forEach((vatLine) => {
        this.formGroup
          .get(vatLine.vatTypeTypeKey)
          ?.setValue(
            this.utilsService.formatAutocalculateAmountInput(vatLine.amount)
          );
      });
    }
  }

  setAdjuntarFactura(invoice: InvoiceGet) {
    if (
      invoice.invoiceFileUuid !== '' &&
      invoice.invoiceFileUuid !== null
    ) {
      this.formGroup
        .get('adjuntarFacturaId')
        .setValue(invoice.invoiceFileUuid);
      this.storedFileService
        .getFileInfo(invoice.invoiceFileUuid)
        .subscribe((resp) => {
          if (resp?.count > 0) {
            this.formGroup.get('adjuntarFactura').setErrors(null);
            this.formGroup
              .get('adjuntarFactura')
              .setValue(resp.values[0].filename, { emitEvent: false });
            this.inputs.find((i) => i.key === 'adjuntarFactura').controlValue =
              resp.values[0].filename;
          }
        });
    }
  }

  setAdjuntarDocumentacionAdicional(invoice: InvoiceGet) {
    if (
      invoice.invoiceAttachmentFileUuid !== '' &&
      invoice.invoiceAttachmentFileUuid !== null
    ) {
      this.formGroup
        .get('adjuntarDocumentacionAdicionalId')
        .setValue(invoice.invoiceAttachmentFileUuid);
      this.storedFileService
        .getFileInfo(invoice.invoiceAttachmentFileUuid)
        .subscribe((resp) => {
          if (resp?.count > 0) {
            this.formGroup
              .get('adjuntarDocumentacionAdicional')
              .setErrors(null);
            this.formGroup
              .get('adjuntarDocumentacionAdicional')
              .setValue(resp.values[0].filename, { emitEvent: false });
            this.inputs.find(
              (i) => i.key === 'adjuntarDocumentacionAdicional'
            ).controlValue = resp.values[0].filename;
          }
        });
    }
  }

  haveBaseExenta(invoice: InvoiceGet) {
    return invoice.vatLines?.find((vatline) => {
      return (
        vatline.vatTypeTypeKey ===
        this.subTipoFactura?.templateParameters?.vatExentoOptions
          ?.vattypeUniquekey
      );
    });
  }

  vatLinesWhithoutBaseExenta(invoice: InvoiceGet) {
    return invoice.vatLines?.filter((vatline) => {
      return (
        vatline.vatTypeTypeKey !==
        this.subTipoFactura?.templateParameters?.vatExentoOptions
          ?.vattypeUniquekey
      );
    });
  }

  getPartesDeTrabajoById(partesDeTrabajo: Worksheets[], idUbicacion: number) {
    const peticiones = [];

    this.invoiceGet.linkedWorksheets.forEach((parteDeTrabajo) => {
      peticiones.push(this.searchService.getWorksheetById(parteDeTrabajo.id));
    });

    if (peticiones.length > 0) {
      forkJoin(peticiones).subscribe((resp: any) => {
        if (resp?.length > 0) {
          resp?.forEach((res) => {
            if (idUbicacion === Number(res.values[0].csite_id)) {
              partesDeTrabajo.push(res.values[0]);
            }
          });
        }

        this.ubicacionChange.next(partesDeTrabajo);
      });
    } else {
      this.ubicacionChange.next(partesDeTrabajo);
    }
  }

  getIdPartesDeTrabajoEdit() {
    const idsPartesTrabajo = [];
    this.invoiceGet.linkedWorksheets.forEach((parteDeTrabajo) => {
      idsPartesTrabajo.push(parteDeTrabajo.id);
    });

    return idsPartesTrabajo;
  }

  setDefaultValueUbicacion() {

    if (
      this.subTipoFactura?.templateParameters?.csiteSection?.default &&
      this.subTipoFactura?.templateParameters?.csiteSection?.default !==
        undefined &&
      !this.defaultUbicacionCargada
    ) {
      const criteria = this.getCsiteCriteria();
      criteria.uuid = this.subTipoFactura?.templateParameters?.csiteSection?.default,
      this.searchService
        .getConstructionSite(criteria)
        .subscribe((resp) => {
          if (resp.count === 1) {
            this.formGroup.get('ubicacion').setValue([resp.values[0]]);
          }
          this.defaultUbicacionCargada = true;
        });
    } else {
      this.ubicacionChange.next(null);
    }
  }

  getDataSecondSelect(value: string = '') {
    const idUbicacion =
      this.formGroup.get('ubicacion')?.value?.length > 0
        ? this.formGroup.get('ubicacion')?.value[0]?.id
        : null;

    const criteria =
      this.subTipoFactura?.templateParameters?.worksheetsSection
        ?.fixedCriteria || null;
    if (
      this.subTipoFactura?.templateParameters?.worksheetsSection
        ?.invoiceDateCriteriaFieldname
    ) {
      criteria[
        this.subTipoFactura?.templateParameters?.worksheetsSection?.invoiceDateCriteriaFieldname
      ] = this.fechaFactura;
    }

    if (idUbicacion && this.tipoFactura && this.subTipoFactura && criteria) {
      criteria.company_id = this.companyId;
      criteria.csite_id = idUbicacion;
      criteria.smart_text = value;

      this.searchService.getWorksheets(criteria).subscribe((res) => {
        this.ubicacionChange.next(res);
        if (this.editOn) {
          this.getPartesDeTrabajoById(res?.values, Number(idUbicacion));
        } else {
          this.ubicacionChange.next(res?.values);
        }
      });
    } else {
      this.ubicacionChange.next(null);
    }
  }

  generateFgroupIvas() {
    if (this.getIVASPermitidos()?.length > 0) {
      this.getIVASPermitidos().forEach((vatType) => {
        this.formGroup.addControl(vatType?.type_key, new FormControl(0));
      });
    }
  }

  generateIvasInputs(display = false) {
    if (this.getIVASPermitidos()?.length > 0) {
      const INDEX =
        this.inputs.findIndex((input) => {
          return input.key === 'importeIVA';
        }) + 2;

      this.getIVASPermitidos().forEach((vatType, index) => {
        const iva: InputField = {
          key: vatType.type_key,
          type: 'amount',
          label: `Importe IVA del ${vatType.percent_description}`,
          controlValue: '',
          display,
          layout: 'col-lg-4 col-md-12',
          amountMode: this.inputAmountDefaultMode,
        };
        this.inputs.splice(INDEX + index, 0, iva);
      });
    }
  }

  setCustomInput() {
    this.customInputA = null;
    if (this.subTipoFactura?.templateParameters?.worksheetsSection) {
      this.customInputA = 'partesDeTrabajo';
    }
  }


  adjuntarArchivo(adjunto, formControl) {
    if (adjunto?.name) {
      const file = new StoredFile();
      const reader = new FileReader();
      reader.readAsDataURL(adjunto);
      reader.onload = () => {
        this.isLoadingFactura = true;
        file.base64 = reader.result.toString().split(',')[1];
        file.filename = adjunto.name;
        file.mimetype = adjunto.type;
        const fileName = adjunto.name.toString().split('.');
        file.file_extension = fileName[fileName.length - 1];
        this.storedFileService.uploadFile(file).subscribe(
          (resp) => {
            if (resp) {
              this.formGroup.get(formControl).setValue(resp.stored_file_uuid);
            } else {
              this.formGroup.get(formControl).setValue(null);
            }
            this.isLoadingFactura = false;
          },
          () => (this.isLoadingFactura = false)
        );
      };
    } else {
      if (this.formGroup.get(formControl).value) {
        this.isLoadingFactura = true;
        this.storedFileService
          .unlikFile(this.formGroup.get(formControl).value)
          .subscribe(
            () => {
              this.formGroup.get(formControl).setValue(null);
              this.isLoadingFactura = false;
            },
            () => (this.isLoadingFactura = false)
          );
      }
    }
  }

  ubicacionFilteredOptions(value: string) {
    if (this.tipoFactura && this.subTipoFactura) {
      const cSiteCriteria = this.getCsiteCriteria();
      cSiteCriteria.uuid = null;
      cSiteCriteria.smart_text = value;

      if (!this.editOn && cSiteCriteria) {
        return this.searchService.getConstructionSite(cSiteCriteria).pipe(
          map((res) => {
            this.ubicaciones = this.formatUbicaciones(res.values as Array<any>);

            if (res.count === 1) {
              this.formGroup.get('ubicacion').setValue([this.ubicaciones[0]]);
            } else {
              this.setDefaultValueUbicacion();
            }

            return this.ubicaciones;
          })
        );
      }

      if (this.editOn && cSiteCriteria) {
        const constructionSiteSubscribes = [
          this.searchService.getConstructionSite(cSiteCriteria),
        ];

        if (this.invoiceGet.csiteId?.toString()) {
          constructionSiteSubscribes.push(
            this.searchService.getConstructionSiteById(
              this.invoiceGet.csiteId?.toString()
            )
          );
        }

        return forkJoin(constructionSiteSubscribes).pipe(
          map((resp) => {
            this.ubicaciones = [];
            if (resp.length > 0) {
              if (resp[0].count > 0) {
                this.formatUbicaciones(resp[0].values).forEach((invoice) => {
                  this.ubicaciones.push(invoice);
                });
              }

              if (resp.length === 2) {
                if (resp[1].count > 0) {
                  this.formatUbicaciones(resp[1].values).forEach((invoice) => {
                    if (
                      (resp[0].values as any[]).find(
                        (res) => res.id === invoice.id
                      ) === undefined
                    ) {
                      this.ubicaciones.push(invoice);
                    }
                  });
                }
              }

              if (this.ubicaciones.length === 1) {
                this.formGroup.get('ubicacion').setValue([this.ubicaciones[0]]);
              } else {
                const csF = this.ubicaciones.find(
                  (cst) => Number(cst.id) === Number(this.invoiceGet?.csiteId)
                );

                if (!this.ubicacionCargada) {
                  if (csF) {
                    this.formGroup.get('ubicacion').setValue([csF]);
                  } else {
                    this.setDefaultValueUbicacion();
                  }

                  this.ubicacionCargada = true;
                }
              }
            }

            return this.ubicaciones;
          })
        );
      }
    }
  }

  getCsiteCriteria() {
    const cSiteCriteria =
      this.subTipoFactura?.templateParameters?.csiteSection?.fixedCriteria ||
      {};
    if (
      this.subTipoFactura?.templateParameters?.csiteSection
        ?.extraEmiterCriteriaFieldname
    ) {
      cSiteCriteria[
        this.subTipoFactura?.templateParameters?.csiteSection?.extraEmiterCriteriaFieldname
      ] = this.companyId;
    }

    if (
      this.subTipoFactura?.templateParameters?.csiteSection
        ?.invoiceDateCriteriaFieldname
    ) {
      cSiteCriteria[
        this.subTipoFactura?.templateParameters?.csiteSection?.invoiceDateCriteriaFieldname
      ] = this.fechaFactura;
    }

    if (cSiteCriteria) {
      cSiteCriteria.parent_company_id = Number(this.destinatarioId);
    }

    return cSiteCriteria;
  }

  formatUbicaciones(constructionSite: any[]) {
    return constructionSite.map((emp) => {
      return new InvoiceSite({
        id: emp.id,
        internalNumber: emp.internal_number,
        description:
          emp.internal_number === undefined
            ? emp.description
            : `${emp.internal_number} - ${emp.description}`,
        address: emp.address,
        order: emp.order,
        reception_with_reservations_date: emp.reception_with_reservations_date,
        reception_without_reservations_date:
          emp.reception_without_reservations_date,
        uuid: emp.uuid,
      });
    });
  }

  partesDeTrabajoFilteredOptions() {
    return this.ubicacionChange.asObservable().pipe(
      map((res: any) => {
        this.partesDeTrabajo = [];

        if (res?.length > 0) {
          (res as Array<any>)?.map((w) => {
            w.selectDescription =
              'Parte ' +
              w.external_uniquekey +
              ' (' +
              this.thousandsPipe.transform(
                this.currencyPipe.transform(w.total_amount)
              ) +
              ')';
          });
          this.partesDeTrabajo = res;

          let parteDeTrabajoEdit = [];
          if (this.editOn && !this.partesCargados) {
            parteDeTrabajoEdit = this.partesDeTrabajo.filter((parteDeTrabajo) =>
              this.getIdPartesDeTrabajoEdit().includes(parteDeTrabajo.id)
            );
          }

          if (parteDeTrabajoEdit.length > 0) {
            if (!this.partesCargados) {
              this.formGroup
                .get('partesDeTrabajo')
                .setValue(parteDeTrabajoEdit);
              this.partesCargados = true;
            } else {
              if (this.partesDeTrabajo.length === 1) {
                this.formGroup
                  .get('partesDeTrabajo')
                  .setValue([this.partesDeTrabajo[0]]);
              }
            }
          } else {
            if (this.partesDeTrabajo.length === 1) {
              this.formGroup
                .get('partesDeTrabajo')
                .setValue([this.partesDeTrabajo[0]]);
            }
          }
        }
        return this.partesDeTrabajo;
      })
    );
  }

  backStepOne() {
    this.back.emit(false);
  }
}
