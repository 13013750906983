export class TableConfiguration {
  /** Specifies if the table has details either column or buttons */
  details?: boolean;
  /** Contains every column keys that needs to launch some detail event */
  detailColumnKeys?: Array<string>;
  sorting?: boolean;
  /** Specifies if the user can delete any item inline */
  delete?: boolean;
  hideExport?: boolean;
  pageable?: boolean;
  pagination?: boolean;
  pageSizes?: Array<number>;
  defaultPageSize?: number;
  hideHeaders?: boolean;
  noResult?: boolean;
  detailsRoute?: string;
  disablePersistence?: boolean;
  validations?: Array<any>;
  hidenCardDetailsFields?: Array<string>;
}

export const DefaultConfig: TableConfiguration = {
  details: true,
  detailColumnKeys: new Array<string>(),
  pagination: true,
  pageSizes: [25, 50, 100],
  defaultPageSize: 25,
  sorting: true,
  delete: false,
  hideExport: true,
  pageable: false,
  hideHeaders: false,
  noResult: true,
  detailsRoute: null,
  disablePersistence: false,
  validations: [],
  hidenCardDetailsFields: [],
};
