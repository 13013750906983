 <!-- BARRA DE ACCIONES -->
 <div class="row justify-content-center pt-3" [class.arpada-main-actions-mobile]="mobile">
  <div class="col-12" align="center">
    <button *ngIf="user.permissionsInCompany.user_registry_request" (click)="newRegistryRequest()"
      mat-stroked-button color="primary">
      <mat-icon class="material-icons material-icons-outlined">person_add_alt</mat-icon>
      {{ 'Alta de usuario' | translate }}
    </button>
    <button *ngIf="user.permissionsInCompany.user_registry_request" [disabled]="!pendingRequest" mat-stroked-button (click)="seeRegistryRequests()"
      color="accent" style="margin-left: 15px" >
      <mat-icon class="material-icons material-icons-outlined">supervisor_account</mat-icon>
      {{ 'Solicitudes' | translate }}
    </button>
  </div>
</div>


<mat-card class="mt-3" [class.arpada-mobile]="mobile">

  <!-- SECCIÓN DE BÚSQUEDA  -->
  <section class="filter-box">

    <!-- CAJA DE BÚSQUEDA DIRECTA -->
    <div class="row header">
      <div class="col-4 col-lg-4 arpada-smart-search-box align-icon-text" [formGroup]="fGroupSmart">
        <mat-form-field class="col-12" appearance="legacy">
          <input matInput type="text" name="smartSearch"
          formControlName="smartSearch" />
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
      </div>

      <ng-container *ngIf="!mobile">
        <label class="col-5 pl-3 pt-0 pointer toggle-filter my-auto py-auto arpada-strict-search-toggle align-icon-text" (click)="showFilters = !showFilters">
          <mat-icon class="material-icons">filter_alt</mat-icon>
          <i [ngClass]="(showFilters)?'fa fa-angle-up':'fa fa-angle-down'" aria-hidden="true"></i>
          <small>Filtrar resultados</small>
        </label>
      </ng-container>

      <ng-container *ngIf="mobile">
        <div class="col-8" align="end">
          <button mat-button (click)="showFilterSelection = true; showFilter()" class="col-auto align-icon-text"><mat-icon class="material-icons material-icons-outlined">filter_alt</mat-icon>{{ 'Filtrar' | translate }}</button>
          <button mat-button (click)="sort()" class="col-auto align-icon-text"><mat-icon>import_export</mat-icon>{{ 'Ordenar' | translate }}</button>
        </div>
      </ng-container>

    </div>

    <!-- CAJA DE FILTROS VERSIÓN ESCRITORIO -->
    <mat-card class="arpada-card-filter mat-elevation-z" *ngIf="showFilters && !mobile">
      <form [formGroup]="fGroup" (ngSubmit)="filterSubmit()">
        <app-inputs-generator [fGroup]="fGroup" [inputFields]="inputs"></app-inputs-generator>
      </form>
    </mat-card>

    <!-- CAJA DE FILTROS VERSIÓN MÓVIL -->
    <div *ngIf="showFilterSelection" class="arpada-mobile-fullscreen-modal">
      <div class="arpada-fullscreen-modal-toolbar">
        <div class="arpada-fs-modal-toolbar-text">
          <a class="primary" (click)="showFilterSelection = false">
            <h1 class="align-icon-text">
              <mat-icon class="material-icons material-icons-outlined">arrow_back_ios_new</mat-icon>
              {{ 'Filtrar' | translate }}
            </h1>
          </a>
        </div>
        <div class="arpada-fs-modal-toolbar-actions" style="visibility: hidden;">
          <button mat-stroked-button color="primary" >Aplicar</button>
        </div>
      </div>
      <div class="arpada-fs-modal-body">
        <div class="body-title">

        </div>
        <div class="body-content">
          <mat-card>
            <div class="snack-bar-info simple-inline-snack-bar align-icon-text mb-2">
              <mat-icon class="material-icons material-icons-outlined">info</mat-icon>
              <b style="margin-right: 4px">{{ customPagination.totalItems }}</b> {{ 'Usuarios encontrados' | translate }}
            </div>
            <form [formGroup]="fGroup" (ngSubmit)="filterSubmit()">
              <app-inputs-generator [fGroup]="fGroup" [inputFields]="inputs"></app-inputs-generator>
            </form>
          </mat-card>
        </div>
      </div>
    </div>

  </section>

  <!-- SECCIÓN DE RESULTADOS DE BÚSQUEDA -->
  <section class="mb-3">
    <app-custom-table
      [configuration]="tableConfiguration"
      [pagination]="customPagination"
      [columns]="columnDefs"
      [columnsCard]="columnDefsCards"
      [columnsListadoCards]="columnDefsListadoCards"
      [columnsExpandedCard]="columnDefsListadoExpandedCards"
      [fullCardTemplate]="cardTemplate"
      [items]="rowData"
      [allowExpand]="true"
      [keyCard]="'F.Factura'"
      (paginationEmit)="pagination($event)"></app-custom-table>
  </section>

</mat-card>


<!----------------------------------------------------------->
<!-------- TEMPLATE BOTÓN LIMPIAR FILTROS DE BUSQUEDA ------->
<!----------------------------------------------------------->
<ng-template #clearFilters let-input>
  <a class="primary align-icon-text arpada-bold-1" (click)="input.function()"><mat-icon>delete_sweep</mat-icon>{{ input.label }}</a>
</ng-template>


<!----------------------------------------------------------------------------->
<!-------------- TEMPLATE PARA ACCIONES SOBRE USUARIO EN TABLA  --------------->
<!----------------------------------------------------------------------------->
<ng-template #actions let-item>
  <button mat-icon-button color="primary" (click)="seeDetails(item); $event.stopPropagation()" matTooltip="{{ 'Ver en detalle' | translate }}"><mat-icon>person_search</mat-icon></button>
  <button mat-icon-button color="primary" (click)="disableUser(item)" *ngIf="item.status === 'active' && user.permissionsInCompany.users_disable" matTooltip="{{'Desactivar'|translate}}">
    <mat-icon class="material-icons">person_off</mat-icon></button>
</ng-template>


<!----------------------------------------------------------------------------->
<!-------------- TEMPLATE PARA VISTA TARJETA DE CADA FILA --------------------->
<!----------------------------------------------------------------------------->
<ng-template #cardTemplate let-item>

  <div class="col-6 arpada-light-card-text text-left">
    <span class="align-icon-text" *ngIf="item.contact_phone">
      <mat-icon inline class="material-icons-outlined arpada-light-icon" style="font-size: 20px">phone</mat-icon>
      {{ item.contact_phone }}
    </span>
  </div>
  <div class="col-6">
  </div>

  <div (click)="seeDetails(item); $event.stopPropagation()">

  <div class="col-12 mb-2" style="text-align: center;">

    <span class="d-block mt-3"><b>{{item.fullname}}</b></span>
    <div class="col-12" >

      <span class="align-icon-text arpada-light-card-text justify-content-center" >
        <mat-icon inline class="material-icons-outlined arpada-light-icon" style="font-size: 20px">email</mat-icon>
        {{ item.contact_email }}
      </span>
    </div>
  </div>


  <ng-container>
    <div class="col-12 mx-auto my-1" style="border-top: 1px solid #EEE"></div>
      <div class="col-12">
        <div style="width: 100%; text-align: center;">
          <button mat-icon-button (click)="seeDetails(item);$event.stopPropagation()" matTooltip="{{ 'Ver en detalle' | translate }}">
            <mat-icon class="material-icons material-icons-outlined">person_search</mat-icon></button>
          <button mat-icon-button *ngIf="item.status === 'active' && user.permissionsInCompany.users_disable" matTooltip="{{'Desactivar'|translate}}">
            <mat-icon class="material-icons material-icons-outlined" >person_off</mat-icon></button>
        </div>
      </div>
  </ng-container>
  </div>
</ng-template>
