import { VatType } from './../vat-type.model';
import { ConstructionSite } from '../construction-sites.model';
import { Proforma } from '../proformas.model';
import { InvoiceGet, Worksheet } from './invoice-get';
import { VatLine } from './invoice-new';
import { InvoiceSubtype } from './invoice-subtype.model';
import { InvoiceType } from './invoice-type.model';

export class InvoiceDto {
  emisor: any;
  destinatario: any;
  CIF: string;
  numeroFactura: string;
  fechaFactura: Date;
  tiposFactura: InvoiceType;
  subTipoFactura: InvoiceSubtype;
  ubicacion: ConstructionSite;
  proforma: Proforma;
  baseImponible: number;
  retencion: number;
  porcentajeRetencion: number;
  formaPago: number;
  prontoPago: number;
  totalIVA: number;
  totalFactura: number;
  nombreFacturaAdjunta: string;
  idFacturaAdjunta: string;
  numeroFacturaAbono: string;
  fechaFacturaAbono: string;
  baseImponibleAbono: number;
  totalIVAAbono: number;
  totalFacturaAbono: number;
  nombreFacturaAdjuntaAbono: string;
  idFacturaAdjuntaAbono: string;
  tieneProntoPago: boolean;
  prontoPagoIncluido: boolean;
  inversionSujetoPasivo: boolean;
  baseExentaIva: number;
  irpf: number;

  // Abonos de factura
  tiposDeIva: Array<VatLine>;
  nombreFacturaAdjuntaAdicional: string;
  idFacturaAdjuntaAdicional: string;
  causaDeAbono: string;

  //Pedidos/Suministros de obra
  nombreAlbaranes: string;
  idAlbaranes: string;

  // Otras facturas y gastos generales
  nombreDocumentacionAdicional: string;
  idDocumentacionAdicional: string;

  // Postventa
  tipoDeFactura: string;
  partesDeTrabajo: Array<Worksheet>;

  ivas: Iva[];

  facturaAbono: InvoiceGet[];
}

export class Iva {
  value: number;
  tipoDeIva: VatType;
}
