export class InvoiceState {
  id: string;
  description: string;
  order: string;
  include: Array<any>;
  stateForSearchUniquekey: string;

  constructor(data?) {
    this.id = data && data.id || '';
    this.description = data && data.description || '';
    this.order = data && data.order || '';
    this.include = data && data.include || [];
    this.stateForSearchUniquekey = data && data.stateForSearchUniquekey || '';
  }
}
