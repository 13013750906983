<div *ngIf="inputFields">
  <form [formGroup]="contentForm" (ngSubmit)="onSubmit(contentForm.value)" class="w-100">
    <section>
      <app-inputs-generator [fGroup]="contentForm" [inputFields]="inputFields" [layout]="layout"
        [dateToFromLayout]="dateToFromLayout" [checkboxLayout]="checkboxLayout"
        (selectedEmit)="selectElement($event)" ></app-inputs-generator>
    </section>
    <section class="cstm-captcha" *ngIf="captcha && captcha.habilitado">
      <!-- <re-captcha
      formControlName="recaptchaReactive"
      [siteKey]="captcha.siteKey" ></re-captcha> --> captcha comentado
    </section>
    <!-- Button row -->
    <section class="row footer mt-3">
      <div class="col ml-auto text-right">
        <ng-container *ngIf="showDeleteButton">
          <button [disabled]="!enabledDeleteButton" (click)="delete($event);" mat-raised-button color="warn"
            type="button" class="btn-remove float-left" [matTooltip]="'Botón Eliminar'" aria-label="Botón Eliminar">
            <i class="fa fa-trash align-text-top" aria-hidden="true"></i> {{'Eliminar' | translate}}
          </button>
        </ng-container>

        <ng-container *ngIf="showAcceptButton">
          <button [disabled]="!enabledDeleteButton" (click)="delete($event);"
          mat-raised-button color="primary" [disabled]="checkValid()"
          aria-label="Botón Aceptar"
          type="submit" class="ml-2 btn-save float-left"> {{'Aceptar' | translate}}
          </button>
        </ng-container>

        <button mat-raised-button class="ml-2 btn-cancel float-left" *ngIf="!hideCancelButton" type="button"
          aria-label="Botón Cancelar "
          (click)="cancel()">{{'Cancelar' | translate}}</button>

        <button mat-raised-button *ngIf="!hideSaveButton" type="submit" class="ml-2 btn-save" color="primary"
          aria-label="Botón Guardar"
          [disabled]="!contentForm.valid || saveDisabled">{{'Guardar' | translate}}</button>
      </div>
    </section>
  </form>
</div>
