import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from 'ngx-file-drop';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'attach-input-drop',
  templateUrl: './attach-input-drop.component.html',
  styleUrls: ['./attach-input-drop.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AttachInputDropComponent,
      multi: true,
    },
  ],
})
export class AttachInputDropComponent implements OnInit {
  @Input() label: string;
  @Input() extensions: Array<string>;
  @Input() placeholder: string;
  @Input() id: string;
  @Input() htmlFileName: string = '';
  @Input() display = true;
  htmlExtensions: string;
  file: File;

  @Output() fileEmitter: EventEmitter<File> = new EventEmitter<File>();

  @ViewChild('attachInput', { static: true }) attachInput: ElementRef;

  propagateChange = (_: any) => {};

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.htmlExtensions = '';
    this.extensions.forEach((ext, index) => {
      this.extensions.length - 1 === index
        ? (this.htmlExtensions += '.' + ext)
        : (this.htmlExtensions += '.' + ext + ',');
    });
  }
  public files: NgxFileDropEntry[] = [];

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;

    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          this.file = file;
          this.onChange();
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        this.file = null;
      }
    }
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  writeValue(value: null) {
    // clear file input
    this.htmlFileName = '';
    this.file = null;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {}

  onChange() {
    if (!this.checkExtensions(this.file.name)) {
      this.removeFileFromInput();
      this.alertService.showWarning(
        'La extensión del archivo debe ser alguna de las siguientes: ' +
          this.extensions.join(', ')
      );
    } else {
      if (!this.checkSize(this.file)) {
        this.removeFileFromInput();
        this.alertService.showWarning(
          'El tamaño del archivo no puede superar los 10 MB.'
        );
      } else {
        this.htmlFileName = this.file.name;
        this.fileEmitter.emit(this.file);
        this.propagateChange(this.file);
      }
    }
  }

  removeFileFromInput(): void {
    this.file = null;
    this.htmlFileName = '';
    this.propagateChange(this.file);
  }

  checkExtensions(fileName: string): boolean {
    let isCorrect = false;
    this.extensions.forEach((ext) => {
      if (fileName.split('.').some((value) => value.toLowerCase() === ext)) {
        isCorrect = true;
      }
    });

    return isCorrect;
  }

  checkSize(file: File) {
    return file && file.size < 10 * 1024 * 1024;
  }
}
