export class InvoiceSite {
  id: string;
  internalNumber: string;
  description: string;
  address: string;
  order: string;
  reception_with_reservations_date: string;
  reception_without_reservations_date: string;
  uuid: string;

  constructor(data?) {
    this.id = data && data.id || '';
    this.internalNumber = data && data.internalNumber || '';
    this.description = data && data.description || '';
    this.address = data && data.address || '';
    this.order = data && data.order || '';
    this.reception_with_reservations_date = data && data.reception_with_reservations_date || '';
    this.reception_without_reservations_date = data && data.reception_without_reservations_date || '';
    this.uuid = data && data.uuid || '';

  }
}
