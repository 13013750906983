<!-- <div id="custom-wrapper" class="row align-items-center" *ngIf="this.spinnerService.showSpinner">
  <div class="col">
    <div id="global-spinner" class="spinner-wrapper">
      <div class="spinner-inner"> -->
        <!-- <mat-progress-spinner diameter="20" aria-label="Cargando información" color="primary" mode="indeterminate">
        </mat-progress-spinner> -->
        <!-- <ng-container > -->
          <div class="custom-wrapper" *ngIf="this.spinnerService.showSpinner">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        <!-- </ng-container> -->

      <!-- </div>
    </div>
  </div>
</div> -->
