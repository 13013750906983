
<h1 matDialogTitle>Seleccione una empresa</h1>

<form [formGroup]="fGroup" (ngSubmit)="onSubmit()">

  <mat-dialog-content>
    <div class="row p-auto my-3 justify-content-center">
      <app-inputs-generator [fGroup]="fGroup" [inputFields]="inputs"></app-inputs-generator>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-flat-button color="primary" type="submit" [mat-dialog-close]="true" [disabled]="!fGroup.valid">Seleccionar</button>
  </mat-dialog-actions>
</form>
