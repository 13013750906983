import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Autoform } from '../autoform';

@Component({
  selector: 'app-inline-form',
  templateUrl: './inline-form.component.html',
  styleUrls: ['./inline-form.component.css']
})
export class InlineFormComponent extends Autoform implements OnInit {

  constructor() {
    super();
  }

  @Input() layout = 'col-md-4 col-lg-3';
  @Input() dateToFromLayout = 'col-lg-6 col-md-8 col-sm-10';
  @Input() checkboxLayout = 'col-md-4 col-lg-3';

  ngOnInit(): void {
  }

  submitInlineForm(inlineFormData: any, formDirective: FormGroupDirective): void {
    formDirective.resetForm();
    this.contentForm.reset(this.initFormValues);
    this.onSubmit(inlineFormData);
  }

}
