export class InvoiceNewDto {
  invoice_uuid: string;
  invoice: InvoiceNew;
  refund_prompt_payment_invoice_uuid: string;
  refund_prompt_payment_invoice: InvoiceNew;

  constructor(data?: any) {
    this.invoice_uuid = (data && data.invoice_uuid) || '';
    this.invoice = (data && data.invoice) || '';
    this.refund_prompt_payment_invoice_uuid =
      (data && data.refund_prompt_payment_invoice_uuid) || '';
    this.refund_prompt_payment_invoice =
      (data && data.refund_prompt_payment_invoice) || '';
  }
}

export class InvoiceNew {
  invoice_number: string;
  invoice_date: string;
  base_amount: number;
  invoice_total: number;
  withholding_amount: number;
  withholding_perone: number;
  has_prompt_payment: boolean;
  prompt_payment_amount: number;
  invoice_file_uuid: string;
  invoice_attachment_file_uuid: string;
  invoice_type_id: string;
  invoice_subtype_id: string;
  reverse_vat_liability: boolean;
  vat_lines: Array<VatLine>;
  company_id: number;
  csite_id: number;
  linked_worksheets: Array<string>;
  linked_proforma: string;
  irpf_perone: number;
  irpf_value: number;
  company_which_is_invoiced_id: number;
  refunded_invoice_id?: number;

  constructor(data?: any) {
    this.invoice_number = (data && data.invoice_number) || '';
    this.invoice_date = (data && data.invoice_date) || '';
    this.base_amount = (data && data.base_amount) || null;
    this.invoice_total = (data && data.invoice_total) || null;
    this.invoice_file_uuid = (data && data.invoice_file_uuid) || null;
    this.invoice_attachment_file_uuid =
      (data && data.invoice_attachment_file_uuid) || null;
    this.invoice_type_id = (data && data.invoice_type_id) || null;
    this.vat_lines = (data && data.vat_lines) || null;
    this.irpf_perone = (data && data.irpf_perone) || 0;
    this.irpf_value = (data && data.irpf_value) || 0;
    this.company_which_is_invoiced_id =
      (data && data.company_which_is_invoiced_id) || 0;
    this.refunded_invoice_id = (data && data.refunded_invoice_id) || null;
  }
}

export class VatLine {
  amount: number;
  vat_type_id: string;
  percent_description: string;
  base_amount?: number;

  constructor(data?: any) {
    this.amount = (data && data.amount) || null;
    this.vat_type_id = (data && data.vat_type_id) || '';
    this.percent_description = (data && data.percent_description) || '';
    this.base_amount = (data && data.base_amount) || null;
  }

  static buildFromDto(data?): VatLine {
    const vatLine = new VatLine();
    vatLine.amount = (data && data.amount) || null;
    vatLine.vat_type_id = (data && data.vat_type_id) || null;
    return vatLine;
  }
}
