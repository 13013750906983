import {
  Notifications,
  NotificationService,
} from './../../services/notification.service';
import { MensajeService } from './../../../shared/services/mensaje.service';
import {
  ACEPTAR,
  CANCELAR,
  NOMBRE_SITIO,
  COPYRIGHT,
} from 'src/app/app.constants';
import {
  version
} from '../../../../environments/version';
import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  HostListener,
  ChangeDetectorRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, ActivatedRoute } from '@angular/router';

import { APP_VERSION } from '../../../app.constants';
import { LoginService } from '../../services/login.service';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { Subscription, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { InputField } from 'src/app/shared/shared.module';
import { FilterDataService } from 'src/app/shared/services/filter-data.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserRelCompany } from 'src/app/shared/models/company.model';
import { InvoicingUserService } from '../../services/invoicing-user.service';

const facturacion = 'FACTURACIÓN';
const gestionUsuarios = 'GESTIÓN DE USUARIOS';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  headerTittle: string;

  isAuth: boolean;
  principal: any;
  userDetails: any;
  userBase: any;
  isAdmin: boolean;
  userId: number;
  unreadWarnings: number;
  isCollapsed = false;
  screenWidth: number;
  nombreSitio = NOMBRE_SITIO;
  appVersion = APP_VERSION;
  apiVersion = '';
  utilidad = '';
  copyright: string = COPYRIGHT;
  versionString: string = version.versionString;

  funcionalidades = null;

  functionSearch = false;
  functionCreate = false;
  functionUserAdmin = false;

  mobile: boolean = false;
  usuario: Usuario;
  dataToolbar: any;

  notifications$: Observable<any>;
  registryRequestNotification: boolean = false;

  companies: Array<any>;

  fGroup: FormGroup;
  public inputs = new Array<InputField>();

  private suscripciones: Subscription = new Subscription();

  @ViewChild(MatSidenav, { static: true }) sidenav: MatSidenav;

  constructor(
    private loginService: LoginService,
    public dialog: MatDialog,
    private notificationsService: NotificationService,
    private router: Router,
    private utilsService: UtilsService,
    private mensajeService: MensajeService,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private fb: FormBuilder,
    private invoicingUserService: InvoicingUserService,
    private filterDataService: FilterDataService
  ) {
    this.fGroup = this.fb.group({
      company: [''],
    });

    this.screenWidth = window.innerWidth;
    this.mobileDisplay(this.screenWidth);
  }

  ngOnInit(): void {
    this.headerTittle = facturacion;
    this.isAuth = this.loginService.isLogged();
    this.usuario = this.loginService.getUser();
    this.getUserInfo();

    const formatUrl = this.router.url.split('/');

    this.chooseHeaderTitle(formatUrl[formatUrl.length - 1]);

    // Accesos
    this.functionSearch =
      this.usuario.permissionsInCompany?.invoices_view ||
      this.usuario.permissionsInCompany?.invoices_edit;

    this.functionCreate = this.usuario.permissionsInCompany?.invoices_create;

    this.functionUserAdmin =
      this.usuario.permissionsInCompany?.user_registry_request ||
      this.usuario.permissionsInCompany?.users_view ||
      this.usuario.permissionsInCompany?.users_disable;

    this.userId = 0;
    this.principal = { userId: 0 };
    if (window.innerWidth < 770) {
      this.sidenav.close();
    }

    // Carga inicial de notificaciones de la aplicación.
    this.notificationsService.searchAllNotifications();
    this.notificationsListener();
    this.titleListener();
  }

  ngOnDestroy(): void {
    this.suscripciones.unsubscribe();
  }

  public collapseNavbar(): void {
    this.isCollapsed = true;
  }

  public logout(): void {
    this.mensajeService.mostrarMensaje(
      '400px',
      'Cerrar sesión',
      ACEPTAR,
      CANCELAR,
      '¿Seguro que quiere cerrar sesión?',
      'logout'
    );

    const subsAccept = this.mensajeService.logoutEmit.subscribe(() => {
      this.filterDataService.setData(null);
      this.loginService.logout();
    });

    this.suscripciones.add(subsAccept);
  }

  public decideToogle(title = facturacion): void {
    this.headerTittle = title;
    if (this.sidenav.opened && window.innerWidth < 770) {
      this.sidenav.toggle();
    }
  }

  /**
   * La API decide qué roles tienen acceso a las utilidades.
   */
  public returnAcces(pag: string): boolean {
    const userRol = this.loginService.getUser().roles[0];

    const func = this.funcionalidades.filter((f) => pag === f.nombre)[0];
    if (!func || !func.perfiles) {
      return false;
    } else {
      if (func.perfiles.indexOf(userRol) > -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event): void {
    this.screenWidth = window.innerWidth;
    this.mobileDisplay(this.screenWidth);
  }

  private mobileDisplay(width: number): void {
    this.mobile = width <= 1020 ? true : false;
    if (!this.mobile) {
      if (this.sidenav) {
        this.sidenav.close();
      }
    }
  }

  private notificationsListener() {
    this.notifications$ = this.notificationsService.onNotification();

    const sus = this.notifications$.subscribe((notifications) => {
      const nn = notifications.filter(
        (n) => n.key === Notifications.USER_REGISTRY_REQUEST
      );
      if (nn && nn.length > 0) {
        this.registryRequestNotification = true;
      } else {
        this.registryRequestNotification = false;
      }
    });
    this.suscripciones.add(sus);
  }

  private titleListener() {
    const title = this.utilsService.toolbarTitle.subscribe((data) => {
      this.dataToolbar = data;
      this.cdr.detectChanges();
    });
    this.suscripciones.add(title);
  }

  back() {
    if (this.dataToolbar.event) {
      this.utilsService.backFromFilter.emit('filter');
    } else {
      if (this.dataToolbar?.url) {
        this.router.navigateByUrl(this.dataToolbar?.url);
      } else {
        if (this.dataToolbar?.back) {
          this.location.back();
        }
      }
    }
  }

  navega() {
    let permises = this.loginService.getUser().permissionsInCompany;

    if (permises.invoices_view || permises.invoices_edit) {
      this.redirectTo('home/invoices');
    } else if (
      permises.users_view ||
      permises.user_registry_request ||
      permises.users_disable
    ) {
      this.headerTittle = gestionUsuarios;
      this.redirectTo('home/users');
    } else if (permises.invoices_create) {
      this.redirectTo('home/invoices/new');
    } else {
      this.redirectTo('home');
    }
  }

  getUserInfo() {
    this.invoicingUserService.getUserInfo().subscribe((userInfo) => {
      this.companies = userInfo.user_rel_companies;
      this.configurarInputs();
      this.cargarComboCompany();
    });
  }

  private cargarComboCompany() {
    this.inputs.find((i) => i.key === 'company').options = this.companies;
  }

  private configurarInputs(): void {
    this.inputs.push({
      key: 'company',
      type: 'menu',
      controlValue: '',
      placeholder: this.usuario.company.name,
      layout: 'col-12 col-lg-8',
      selectBindLabel: 'company_name',
      options:
        this.companies && this.companies.length
          ? this.companies.map(
              (c) => new UserRelCompany({ id: c.id, name: c.name })
            )
          : new Array<any>(),
    });
  }

  changeCompany(event) {
    if (event[1]) {
      this.loginService.assignCompany(this.companies, event[1].company_id);
      this.filterDataService.setData(null);
      this.navega();
    }
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  goToSettings() {
    this.headerTittle = facturacion;
  }

  chooseHeaderTitle(url) {
    if (url) {
      if (url === 'users') {
        this.headerTittle = gestionUsuarios;
      } else {
        this.headerTittle = facturacion;
      }
    }
  }
}
