import { UtilsService } from 'src/app/shared/services/utils.service';
import { Notification, Notifications, NotificationService } from './../../../services/notification.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { InvoicingUserService } from 'src/app/core/services/invoicing-user.service';
import { LoginService } from 'src/app/core/services/login.service';

@Component({
  selector: 'app-users-registry-requests-container',
  templateUrl: './users-registry-requests-container.component.html',
  styleUrls: ['./users-registry-requests-container.component.scss']
})
export class UsersRegistryRequestsContainerComponent implements OnInit {

  userType: string;

  constructor(public loginService: LoginService,
    private router: Router,
    private notificationService: NotificationService,
    private invoicingUserService: InvoicingUserService
    ) {

    }

  ngOnInit(): void {
    this.userType = this.loginService.getUser().userType;

    // Redirige a listado de usuarios si no hay solicitudes pendientes
    // Refresca notificaciones de la aplicación.
    this.invoicingUserService.getUserRegistryRequestCount().subscribe(count => {
      if (!count || count < 1) {
        this.notificationService.removeNotificationByKey(Notifications.USER_REGISTRY_REQUEST);
        this.router.navigateByUrl('/home/users');
      } else {
        if (count) {
          this.notificationService.addNotificationByKey(Notifications.USER_REGISTRY_REQUEST);
        }
      }
    })

  }

}
