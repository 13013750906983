<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content class="mat-typography">
  <p>{{message}}</p>
  <p>Dispones de más información en nuestra <a class="link" (click)="downloadDocument()">Política de Cookies</a>.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="primary"
  aria-label="Botón Mas Información" (click)="downloadDocument()">Mas Información</button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial (click)="emitAccept()"
  aria-label="Botón aceptar">Aceptar</button>
</mat-dialog-actions>
