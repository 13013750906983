export class CustomPagination {
    pageSize: number;
    page: number;
    sort: string;
    serverSort?: string;
    order: string;
    totalItems?: number;
    totalItemsLoaded?: number;
    parent?: string;
    scrollY?: number;
}
