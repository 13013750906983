import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setItem(key: string, data: string): void {
    localStorage.setItem(key, btoa(data));
  }

  getItem(key: string): string {
    const data: string = localStorage.getItem(key);
    let result: string = null;
    if (data !== undefined && data !== null) {
      result = atob(data);
    }
    return result;
  }

  getItemJson(key: string): any {
    return JSON.parse(this.getItem(key));
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    var cookies = localStorage.getItem('accept-cookies');
    localStorage.clear();
    localStorage.setItem('accept-cookies', cookies);
  }
}
