<div class="row arpada-snack-bar">

  <!-- Icon -->
  <div class="col-1 h-100 m-auto text-left">
    <mat-icon class="arpada-snack-bar-icon" fontSet="material-icons-outlined">{{data.icon}}</mat-icon>
    <!-- <i [ngClass]="data.icon + ' arpada-snack-bar-icon'"></i> -->
  </div>

  <!-- Title and description -->
  <div [ngClass]="data.class !== 'snack-bar-success' ? 'col-9' : 'col-11'">
    <h3 class="w-100 mb-0" style="font-weight: 700;">{{data.title | translate}}</h3>
    <h4 class="w-100">{{data.message | translate }}</h4>
  </div>

  <!-- Close button -->
  <div *ngIf="data.class !== 'snack-bar-success'" class="col-2" align="end">
      <button mat-icon-button class="" (click)="snackBarRef.dismiss()">
        <mat-icon>close</mat-icon>
      </button>
  </div>

</div>
