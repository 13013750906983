<mat-toolbar *ngIf="!mobile" class="toolbar sub-toolbar" [class.arpada-mobile-subtoolbar]="mobile">
  <a [routerLink]="" (click)="back()" style="text-decoration: none;" class="align-icon-text">
    <mat-icon *ngIf="mobile" style="font-size: 20px;" inline>arrow_back_ios</mat-icon>
    <h1>
      {{'Configuración de usuario' | translate}}
    </h1>
  </a>
  <i *ngIf="!mobile" class="material-icons material-icons-outlined invoice-add-icon"
    aria-hidden="true">settings</i>
</mat-toolbar>

<a [routerLink]="" (click)="back()" *ngIf="!mobile" class="arpada-bold-2 arpada-back-button align-icon-text">
  <mat-icon inline>close</mat-icon>Cerrar
</a>

<app-user-settings></app-user-settings>
