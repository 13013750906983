import { InputField } from 'src/app/shared/components/autoform/input-fields/input-fields';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { Autoform } from '../autoform';
import { Router } from '@angular/router';
import { FilterDataService } from 'src/app/shared/services/filter-data.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent extends Autoform implements OnInit, AfterViewInit, OnDestroy {

  showFilters = true;
  storedFilter: any;
  routePath: string;
  filterDataSub: Subscription;
  filtrosAplicados = 0;

  @Input() resetEmit = new EventEmitter<any>();
  private suscripciones: Subscription = new Subscription();

  @Output() selectedEmit = new EventEmitter<any>();
  @Output() clearEmit = new EventEmitter();
  private readonlySelects: Array<Array<[string, any]>> = [];

  constructor(
    private router: Router,
    private filterDataService: FilterDataService,
    private cdr: ChangeDetectorRef) {
    super();
  }

  @Input() public layout = 'col-lg-3 col-md-4 col-sm-6';
  @Input() dateFromToLayout = 'col-lg-6 col-md-8 col-sm-12';

  ngOnInit(): void {
    this.routePath = this.router.url;

    this.inputFields.forEach( element => {
      if (element.readonly && element.type === 'select') {
        this.readonlySelects.push([element.key, element.controlValue]);

      }
    });

    // Filter persistence
    this.filterDataSub = this.filterDataService.currentData.subscribe(data => {
      this.storedFilter = data;
    });
    // suscripción para resetear los valores del filtro
    const sus = this.resetEmit.subscribe( () => {
      this.resetFilter();
    });
    this.suscripciones.add(sus);

  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.filterDataSub.unsubscribe();
    this.suscripciones.unsubscribe();
  }

  /* If there is any storedFilter it will filter by it, if not, it will do a "resetFilter"
  ** This method is mean to be used from a parent in ngAfterViewInit or after all the filters are set.*/
  doFilter(): void {
    if (this.filterDataService.getParentUrl() === this.routePath.split('/')[2] && this.storedFilter) {
      this.filterDataService.setParentUrl(null);
      this.filterSubmit(this.storedFilter);
      this.setFormValues(this.storedFilter);
    } else {
      if (this.filterDataService.getParentUrl() !== this.routePath.split('/')[2]) {
        this.filterDataService.setParentUrl(null);
      }

      if (this.contentForm.valid) {
        this.filterSubmit(this.contentForm);
      }
    }
  }

  setFormValues(filterFormData: any): void {
    Object.keys(filterFormData).forEach(key => {
      this.setFormValue(key, filterFormData[key]);
    });
    // If filters are modified programmatically, show filter
    this.showFilters = true;
    this.cdr.detectChanges();
  }

  resetFilter(): void {
    /* keys */
    const values = Object.keys(this.initFormValues);

    let valuesNotToReset: Array<InputField>;

    if (this.initFormValues) {

      valuesNotToReset = this.inputFields.filter( input => input.readonly === true);

      valuesNotToReset.forEach(element => {
        /* if ( this.readonlySelects.includes([element.key])) */

        if ( typeof element.controlValue === 'object' && element.controlValue.value !== undefined) {
          this.initFormValues[element.key] = element.controlValue.value;
        } else {
          this.initFormValues[element.key] = element.controlValue;
        }
      });
    }

    this.contentForm.reset(this.initFormValues);

    if (this.contentForm.valid) {
      this.filterSubmit(this.contentForm);
      this.filtrosAplicados = 0;
    }

    this.clearEmit.emit();
  }

  filterSubmit(form: FormGroup): void {
    const filterFormData = form.getRawValue();

    this.inputFields.forEach( element => {
      if (element.readonly) {
        if ( typeof element.controlValue === 'object' && element.controlValue.value !== undefined) {
          filterFormData[element.key] = element.controlValue.value;
        } else {
          filterFormData[element.key] = element.controlValue;
        }
      }
    });

    this.filtrosAplicados = 0;
    for (const key in filterFormData) {
      if (filterFormData[key] && filterFormData[key] !== '') {
        this.filtrosAplicados++;
      }

    }
    // tslint:disable-next-line:no-string-literal
    filterFormData['hasFilters'] = this.filtrosAplicados > 0;
    this.storeFilters(filterFormData);
    this.onSubmit(filterFormData);
  }

  storeFilters(filterFormData: any): void {
    this.filterDataService.setData(filterFormData);
  }

  openFilters(): void {
    this.showFilters = true;
  }

  hideFilters(): void {
    this.showFilters = false;
  }

  public selectElement(e: any): void {
    this.correlatedReset(e[0]);
    this.selectedEmit.emit(e);
  }

  /**
   * Cada vez que un input es modificado se busca su input asociado para
   * resetear su selección.
   * @param e Input modificado.
   */
  public correlatedReset(e: any) {
    this.inputFields.forEach(i => {
      if (i.key=== e && i.correlated) {
        i.correlated.forEach(corr => {
          this.contentForm.get(corr).setValue(null);
        });
      }
    })

  }

}
