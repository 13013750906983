import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CustomPagination } from '../../components/table/table-configuration/custom-pagination';
import { GetPropertyFromString } from '../../pipes/get-property-from-string';
import { Columns } from '../../shared.module';


declare var jsPDF: any;

@Component({
  selector: 'app-export-pdf',
  templateUrl: './export-pdf.component.html',
  styleUrls: ['./export-pdf.component.css']
})
export class ExportPdfComponent implements OnInit {
  pdfDoc: any;
  pdfHeader: any[];
  pdfName: string;


  @Input() standalone = false;
  @Input() items: any[];
  @Input() columns: Columns[];
  @Input() pagination: CustomPagination;

  @Output() exportPdf = new EventEmitter<any>();

  constructor(private propertyFromString: GetPropertyFromString, private datePipe: DatePipe, private route: Router) {
  }

  ngOnInit(): void {
    this.pdfName = '';
    this.route.url.split('/').forEach((str, index) => {
      if (index === 1) {
        this.pdfName = this.pdfName.concat(str);
      } else if (index > 1) {
        this.pdfName = this.pdfName.concat('_').concat(str);
      }
    });
  }

  doExport(): void {
    if (this.standalone) {
      this.exportPdf.emit(true);
    } else {
      this.pdf();
    }
  }

  pdf(): void {
    this.pdfHeader = [];
    this.pdfDoc = new jsPDF();
    const pdfArray: any[] = [];

    this.columns.forEach(col => {
      if (col.title) {
        this.pdfHeader.push({ content: col.title, styles: { fillColor: [41, 64, 114], textColor: [255, 255, 255] } });
      }
    });

    if (this.pagination) {
      if (this.pagination.sort) {
        if (this.pagination.order === 'asc') {
          this.items.sort((a, b) => {
            if (typeof a[this.pagination.sort] === 'string') {
              return a[this.pagination.sort].toLowerCase() > b[this.pagination.sort].toLowerCase() ? 1 : -1;
            } else {
              return a[this.pagination.sort] > b[this.pagination.sort] ? 1 : -1;
            }
          });
        } else if (this.pagination.order === 'desc') {
          this.items.sort((a, b) => {
            if (typeof a[this.pagination.sort] === 'string') {
              return a[this.pagination.sort].toLowerCase() > b[this.pagination.sort].toLowerCase() ? -1 : 1;
            } else {
              return a[this.pagination.sort] > b[this.pagination.sort] ? -1 : 1;
            }
          });
        }
      }
    }

    this.items.forEach(item => {
      item = this.enumTranslation(item);

      const tempArray: any[] = [];
      this.columns.forEach(col => {
        if (col.title) {
          let itemProperty = this.propertyFromString.transform(item, col.key);
          if (col.isDate) {
            itemProperty = this.datePipe.transform(itemProperty, 'dd/MM/yyyy');
          }
          if (col.isDateTime) {
            itemProperty = this.datePipe.transform(itemProperty, 'dd/MM/yyyy HH:mm:ss');
          }
          if (col.isBoolean) {
            itemProperty = itemProperty ? 'Si' : 'No';
          }
          tempArray.push(itemProperty);
        }
      });

      pdfArray.push(tempArray);
    });

    this.pdfDoc.autoTable({
      head: [this.pdfHeader],
      body: pdfArray
    });
    this.pdfDoc.save(this.pdfName);
  }

  enumTranslation(item: any): any {
    const resItem = Object.assign({}, item);
    if (resItem.active != null) {
      if (resItem.active === true) {
        resItem.active = 'Sí';
      } else if (resItem.active === false) {
        resItem.active = 'No';
      }
    }

    if (resItem.available != null) {
      if (resItem.available === true) {
        resItem.available = 'Sí';
      } else if (resItem.available === false) {
        resItem.available = 'No';
      }
    }

    if (resItem.enabled != null) {
      if (resItem.enabled === true) {
        resItem.enabled = 'Sí';
      } else if (resItem.enabled === false) {
        resItem.enabled = 'No';
      }
    }


    return resItem;
  }

}
