import { SpinnerService } from '../../services/spinner.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-http-spinner',
  templateUrl: './http-spinner.component.html',
  styleUrls: ['./http-spinner.component.scss']
})
export class HttpSpinnerComponent implements OnInit {

  constructor(public spinnerService: SpinnerService) { }

  ngOnInit(): void {
  }

}
