<div class="row justify-content-center">

    <form [formGroup]="fGroup" (ngSubmit)="submit()" class="col-12 mt-3">

      <div class="row justify-content-center">

      <!-- Generación de imputs -->
      <app-inputs-generator [fGroup]="fGroup" [inputFields]="inputs"></app-inputs-generator>

      <!-- Captcha -->
      <div class="ctm-captcha col-12 col-lg-7">
        <re-captcha formControlName="captcha"
        (resolved)="resolved($event)"
        [siteKey]="captchaKey"></re-captcha>
      </div>

      <!-- Submit button wrapper -->
      <div class="col-12 col-lg-5 mt-3">
        <button mat-flat-button color="primary"
        [disabled]="!fGroup.valid"
        aria-label="Botón solicitar registro"
        type="submit" class="col-12 arpada-button-large"
        >{{ 'Registro' | translate }}</button>
      </div>

      <!-- Politicas check -->
      <div class="row mt-3">
        <mat-checkbox color="primary" formControlName="politicas" class="col-1">
        </mat-checkbox>
        <h4 class="secondary my-auto col-11">Acepto las <a class="link" (click)="downloadDocument('Política de Privacidad.pdf')">Políticas de Privacidad</a>, <a class="link" (click)="downloadDocument('Política de Cookies.pdf')">Políticas de Cookies</a> y <a class="link" (click)="downloadDocument('Condiciones de Uso.pdf')">Condiciones de Uso</a> de este portal.</h4>
      </div>

    </div>

  </form>
</div>
