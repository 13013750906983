import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceTypeToIcon'
})
export class InvoiceTypeToIconPipe implements PipeTransform {

  transform(invoiceType: string): string {
    let icon: string;
    switch (invoiceType) {
      case 'construction_site_proforma':  icon = 'house_siding'; break;
      case 'construction_site_orders':    icon = 'handyman'; break;
      case 'postsale':                    icon = 'support_agent'; break;
      case 'general':                     icon = 'engineering'; break;
      case 'refund':                       icon = 'credit_score'; break;
      default:                            icon = 'info';break;
    }
    return icon;
  }

}
