import { Component, OnInit, Input, ViewChild, SimpleChange, SimpleChanges, AfterViewInit, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ValidatorMessages } from '../../autoform/input-fields/input-fields';

@Component({
  selector: 'app-selectv2',
  templateUrl: './selectv2.component.html',
  styleUrls: ['./selectv2.component.css']
})
export class Selectv2Component implements OnInit, AfterViewInit, OnChanges {
  @Input() formGroup: FormGroup;
  @Input() label: string;
  @Input() bindLabel: string;
  @Input() items: Array<any> = [];
  @Input() layout = 'w-100';
  @Input() selectControl: FormControl;
  @Input() selectControlName: string;
  @Input() validatorMessages: Array<ValidatorMessages>;

  @ViewChild(MatAutocompleteTrigger, { static: true }) trigger: MatAutocompleteTrigger;

  filteredOptions: Observable<any[]>;
  selectedOption: any;

  constructor() {

  }

  ngOnInit(): void {
    if (!this.selectControl) {
      this.selectControl = this.formGroup.get(this.selectControlName) as FormControl;
    }

    this.filteredOptions = this.selectControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  ngAfterViewInit(): void {
    this.trigger.panelClosingActions.subscribe(e => {
      if (!(e && e.source) && !this.selectedOption) {
        this.trigger.closePanel();
        this.clearFilter();
      }

      if (e && e.source) {
        this.selectedOption = e.source.value;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const items: SimpleChange = changes.items;
    const selectControlName: SimpleChange = changes.selectControlName;

    if (selectControlName && selectControlName.currentValue) {
      this.selectControl = this.formGroup.get(this.selectControlName) as FormControl;
    }

    if (items && items.currentValue) {
      if (!this.selectControl.value) { this.selectControl.setValue(null); }
    }
  }

  private _filter(value: string): string[] {

    this.selectedOption = null;

    const filterValue = (value && value[this.bindLabel] && (value[this.bindLabel] as string).toLowerCase())
      || value && value.toLowerCase() || '';

    if (value === '' || !value) {
      return this.items;
    } else {
      return this.items.filter(option => {
        const optionString = option[this.bindLabel] || option;
        return optionString.toLowerCase().includes(filterValue);
      });
    }
  }

  clearFilter(): void {
    if (this.selectControl && this.selectControl instanceof String) {
      this.selectControl.setValue('');
    } else {
      this.selectControl.setValue(null);
    }

  }

  //  returns the arrow function that resolves the display element of the selected object
  showDisplay = (option) => option && this.bindLabel && option[this.bindLabel] || option || '';

}
