import { KeyValue } from './../../shared/models/key-value.model';
import { map } from 'rxjs/operators';
import { Pageable } from './../models/pageable.model';
import { AlertService } from './../../shared/services/alert.service';
import { UserDismissConfirmModalComponent } from '../components/users/user-dismiss-confirm-modal/user-dismiss-confirm-modal.component';
import { UserRelCompanyGet } from './../../shared/models/company.model';
import { environment } from './../../../environments/environment';
import { BaseComunicationService } from './base-comunication.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { UserPageable } from '../models/pageable.model';
import { MatDialog } from '@angular/material/dialog';
import { InvoicingUserNew } from '../models/users-new.model';
import { InvoicingUserUpdate } from '../models/users-update.model';

@Injectable({
  providedIn: 'root'
})
export class InvoicingUserService extends BaseComunicationService{

  urlApiQueries: string = environment.apiInvoicingUsersQueryBaseUrl;
  urlApiCommands: string = environment.apiInvoicingUsersCommandBaseUrl;

  constructor(public http: HttpClient,
    private dialog: MatDialog,
    private alertService: AlertService) {
    super(http);
  }

  public getUserRelCompanySearchPageable(pageable: UserPageable): Observable<any> {
    const url = `${this.urlApiQueries}/user-rel-company-search`;
    if (pageable.order[0].name === 'register_date') {
      pageable.order[0].name = 'fullname'
    }
    return this.realizarPeticionPost(url, pageable.toService());
  }

  public getUserRelCompany(id: number): Observable<any> {
    const url = `${this.urlApiQueries}/user-rel-company-search`;
    let pageable = new UserPageable({limit: 1, id});
    return this.realizarPeticionPost(url, pageable.toService());
  }

  getUserInfo(): Observable<any> {
    const url = `${this.urlApiQueries}/get-user-info`;
    return this.realizarPeticionGet(url);
  }

  public getUserInfoSearchPageable(pageable: UserPageable): Observable<any> {
    const url = `${this.urlApiQueries}/user-info-search`;
    return this.realizarPeticionPost(url, pageable.toService());
  }
  public getUserRegistryRequestCount(): Observable<any> {
    const url = `${this.urlApiQueries}/user-registry-requests-search`;
    const pageable = new Pageable({ limit: 1 });
    return this.realizarPeticionPost(url, pageable).pipe(
      map( res => res.count , err => of(null))
    );
  }

  public getUserRegistryRequest(id: string): Observable<any> {
    const url = `${this.urlApiQueries}/user-registry-requests-search`;
    const params = new Array<KeyValue>();
    params.push(new KeyValue('id', id));
    return this.realizarPeticionGetConFiltros(url, params);
  }

  public getUserRegistryRequestPageable(pageable: Pageable): Observable<any> {
    const url = `${this.urlApiQueries}/user-registry-requests-search`;
    return this.realizarPeticionPost(url, pageable);
  }

  public getUserPermissions(): Observable<any> {
    const url = `${this.urlApiQueries}/get-permissions`;
    return this.realizarPeticionGet(url)
  }

  public getUserTypes(): Observable<any> {
    const url = `${this.urlApiQueries}/get-user-types`;
    return this.realizarPeticionGet(url)
  }

  /**
   * Diálogo de confirmación de baja de usuario.
   */
  public dismissUserConfirmation(user: UserRelCompanyGet): any {
    // Configura ventana modal
    const dial = this.dialog.open(UserDismissConfirmModalComponent, {
      data: {user: user},
      width: '600px'
    });

    // Formulario emergente de confirmación.
    return dial.componentInstance.emitValues;
  }

  /**
   * Petición baja de usuario.
   *
   */
  dismissUser(userId: number): Observable<any> {
    const url = `${this.urlApiCommands}/user-company/dismiss-user-in-company`;
    return this.realizarPeticionPost(url, {id: userId});
  }

  public createUser(newUser: InvoicingUserNew, userregistryrequestId?: string) {

    let params = '';

    if(userregistryrequestId) {
      params = `?from_userregistryrequest_id=${userregistryrequestId}`
    }

    const url = `${this.urlApiCommands}/user-company/create-user-in-company${params}`;
    return this.realizarPeticionPost(url, newUser);
  }

  public updateUser(updateUser: InvoicingUserUpdate) {
    const url = `${this.urlApiCommands}/user-company/update-user-in-company`;
    return this.realizarPeticionPost(url, updateUser);
  }

  acceptUserRegistryRequest(userId: string): Observable<any> {
    return this.updateUserRegistry(userId, 'accept_user_registry');
  }

  rejectUserRegistryRequest(userId: string): Observable<any> {
    return this.updateUserRegistry(userId, 'reject_user_registry');
  }

  /**
   * Actualiza solicitud de alta de usuario.
   */
  private updateUserRegistry(userId: string, action: string): Observable<any> {
    const url = `${this.urlApiCommands}/user-registration-request/update-user-registry`;
    return this.realizarPeticionPost(url, {id: userId, update_action: action});
  }

}
