<div class="row mt-5 justify-content-center">

  <mat-card class="col-12 col-lg-7 col-xl-6 m-3">

    <mat-tab-group mat-align-tabs="center" dynamicHeight>

      <mat-tab>
        <ng-template mat-tab-label><mat-icon class="material-icons-outlined">contact_page</mat-icon>{{ 'Datos personales' | translate}}</ng-template>
        <ng-template matTabContent>
          <ng-template [ngTemplateOutlet]="userInfoTemplate"></ng-template>
        </ng-template>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label><mat-icon class="material-icons-outlined">business</mat-icon>{{ 'Datos de empresa' | translate}}</ng-template>
        <ng-template matTabContent>
          <ng-template [ngTemplateOutlet]="companyInfoTemplate"></ng-template>
        </ng-template>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label><mat-icon class="material-icons-outlined">password</mat-icon>{{ 'Contraseña' | translate}}</ng-template>
        <ng-template matTabContent>
          <ng-template [ngTemplateOutlet]="changePasswordTemplate"></ng-template>
        </ng-template>
      </mat-tab>

    </mat-tab-group>
  </mat-card>
</div>



<!------------------------------->
<!-- TEMPLATE DATOS DE USAURIO -->
<!------------------------------->
<ng-template #userInfoTemplate>
  <div class="mt-3">
    <app-inputs-generator [fGroup]="fGroupUser" [inputFields]="inputsUser"></app-inputs-generator>
    <div class="d-flex flex-column flex-lg-row justify-content-end">
      <button mat-flat-button color="primary" type="submit"
        (click)="submitUserSettings()"
        [disabled]="!(fGroupUser.valid && fGroupUser.dirty)">Actualizar</button>
    </div>
  </div>
</ng-template>

<!------------------------------->
<!-- TEMPLATE DATOS DE EMPRESA -->
<!------------------------------->
<ng-template #companyInfoTemplate>
  <!-- {{ companyInfo.company_name }} -->
  <div class="mt-3">
    <div class="m-5">
      <span class="d-block"><b>{{ 'Nombre de empresa' | translate}}</b></span>
      <span>{{ companyInfo.company_name }}</span>
    </div>
    <div class="m-5">
      <span class="d-block"><b>{{ 'Razón social' | translate}}</b></span>
      <span>{{ companyInfo.company_registered_name }}</span>
    </div>
    <div class="m-5">
      <span class="d-block"><b>{{ 'CIF' | translate}}</b></span>
      <span>{{ companyInfo.company_vat_number }}</span>
    </div>
    <div class="m-5">
      <span class="d-block"><b>{{ 'Correo electrónico' | translate}}</b></span>
      <span>{{ companyInfo.company_email }}</span>
    </div>
    <div class="m-5">
      <span class="d-block"><b>{{ 'Teléfono' | translate}}</b></span>
      <span>{{ companyInfo.company_phone }}</span>
    </div>
  </div>
</ng-template>

<!-------------------------------->
<!-- TEMPLATE CAMBIO CONTRASEÑA -->
<!-------------------------------->
<ng-template #changePasswordTemplate>
  <div class="mt-3">
    <app-inputs-generator [fGroup]="fGroupPassword" [inputFields]="inputsPassword"></app-inputs-generator>
    <!-- Errores de formulario general -->
    <mat-error *ngIf="fGroupPassword.errors?.passwordConfirmInvalid">
      {{ passwordValidationErrorMessage | translate }}
    </mat-error>

    <div class="col-12 mt-3 mb-3">
      <app-custom-inline-snackbar [data]="{ title: '', message: apiMessage}"></app-custom-inline-snackbar>
    </div>

    <div class="d-flex flex-column flex-lg-row justify-content-end">
      <button mat-flat-button color="primary" type="submit"
        (click)="submitPassword()"
        [disabled]="!(fGroupPassword.valid && fGroupPassword.dirty)">Actualizar</button>
    </div>
  </div>
</ng-template>

<!-- {{ passwordValidationMessage }} -->

<!-- {{ user | json }} -->


