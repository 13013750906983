import { MatTableDataSource } from '@angular/material/table';
import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, AfterViewChecked, Output, EventEmitter, ViewChild } from '@angular/core';
import { TableCardConfiguration, TableCardItem, TableCardPagination } from './configuration/table-card-configuration';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-table-card',
  templateUrl: './table-card.component.html',
  styleUrls: ['./table-card.component.scss']
})
export class TableCardComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {

  @Input() items: any[] = [];
  @Input() pagination: TableCardPagination = new TableCardPagination();
  _configuration: TableCardConfiguration;
  @Input() set configuration(configuration: TableCardConfiguration) {
    this._configuration = Object.assign({}, this._configuration, configuration);
  }

  @Output() refresh = new EventEmitter<TableCardPagination>();

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  displayedColumns: String[] = [];

  desktop: boolean = true;

  constructor() { }

  ngOnInit(): void {

    this._configuration.tableFields.forEach(f => {
      this.displayedColumns.push(f.key);
    })

    // Control cambios de paginación
    this.paginator.page.subscribe(pageEvent => {
      this.pagination.page = pageEvent.pageIndex;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isLoading, items, columns, pagination } = changes;

    if (items) this.dataSource.data = items.currentValue;

    if (pagination) {
      this.refresh.emit(pagination.currentValue);
    }
  }



  ngAfterViewChecked(): void {

  }

  ngOnDestroy(): void {
    // Copia de estado?
  }
}
