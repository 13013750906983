import { Columns } from './../table/table-configuration/columns';
import { LocaleService } from './../../services/locale.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, TemplateRef } from '@angular/core';
import { DetailsItem } from '../table/table-configuration/details-item';

@Component({
  selector: 'details-content',
  templateUrl: './details-content.component.html',
  styleUrls: ['./details-content.component.scss']
})
export class DetailsContentComponent implements OnInit {

  @Input() hidenFields: Array<string> = new Array<string>();
  @Input() labels: Array<Columns | any>;
  @Input() item: any;
  @Input() numRows: number;
  @Input() discriminator = '';
  @Input() fullCardTemplate: TemplateRef<any>;

  @Input() selection = new SelectionModel<any>(true, []);
  @Output() selectedEmit = new EventEmitter<any>();
  @Output() detailsEmit = new EventEmitter<DetailsItem>();

  constructor(private localeService: LocaleService) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    return numSelected === this.numRows;
  }

  public masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.selection.select(this.item);

    this.selectedEmit.emit(this.selection.selected);

  }

  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  public changeCheckbox(e: any, row: any): void {
    this.selection.toggle(row);
    this.selectedEmit.emit(this.selection.selected);

  }
  public getNumberFormatted(value: any) {
    return this.localeService.getNumberFormatted(value);
  }

  columnDetail(item: any, key: string): void {
    const itemEmitted: DetailsItem = { item, key };

    this.detailsEmit.emit(itemEmitted);
  }

  /**
   * Control de check habilitado según estado de la fila.
   * @param row Elemento de la fila
   * @returns true si debe deshabilitarse
   */
  public enableCheckbox(row: any): boolean {

    return false;
  }
}
