export class UserRelCompany {
  id: number;
  userCompanyId: string;
  name: string;
  userRoles: string[];
  userPermissions: any;
  userType: string;

  company_vat_number: string;
  company_registered_name: string;
  company_email: string;
  company_phone: string;


  constructor(data?) {
    this.id = data && data.id || null;
    this.userCompanyId = data && data.userCompanyId || null;
    this.name = data && data.name || '';
    this.userRoles = data && data.userRoles || [];
    this.userPermissions = data && data.userPermissions || {};
    this.userType = data && data.userType || '';

    this.company_vat_number = data && data.company_vat_number || null;
    this.company_registered_name = data && data.company_registered_name || null;
    this.company_email = data && data.company_email || null;
    this.company_phone = data && data.company_phone || null;
  }
}

export class UserRelCompanyGet {
  id: number;
  contact_email: string;
  company_id: number;
  company_name: string;
  company_vat_number: string;
  firstname: string;
  lastname: string;
  lastname2: string;
  fullname: string;
  contact_phone: string;
  job_position_in_company: string;
  user_roles: string[];
  user_permissions: any;
  user_type: 'internal' | 'external' | null;
  status: string;


  constructor(data?: any) {
    this.id = data && data.id;
    this.contact_email = data && data.user_email;
    this.company_id = data && data.company_id;
    this.company_name = data && data.company_name;
    this.company_vat_number = data && data.company_vat_number;
    this.firstname = data && data.firstname;
    this.lastname = data && data.lastname;
    this.lastname2 = data && data.lastname2;
    this.fullname = data && data.fullname;
    this.contact_phone = data && data.contact_phone;
    this.job_position_in_company = data && data.job_position_in_company;
    this.user_roles = data && data.user_roles;
    this.user_permissions = data && data.user_permissions;
    this.user_type = data && data.user_type;
    this.status = data && data.status;
  }
}
