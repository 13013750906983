export class ApiErrorDetail {
  loc: Array<string>;
  msg: string;
  type: string;
}

export class ApiError {

detail: Array<ApiErrorDetail>;

  constructor(data?: any) {
    this.detail = new Array<ApiErrorDetail>();

    if (data) {
      data.array.forEach(err => {
        const error = new ApiErrorDetail();
        error.loc = err.loc;
        error.msg = err.msg;
        error.type = err.type;
        this.detail.push(error);
      });
    }
  }
}
