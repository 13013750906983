<div class="center mt-1" *ngIf="display">
    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" [multiple]="false" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">

        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <ng-container *ngIf="file !== null || htmlFileName !== ''; then thenTemplate; else elseTemplate"></ng-container>
            <ng-template #thenTemplate>
                <span class="m-2">{{htmlFileName}}</span>
                <i class="material-icons material-icons-outlined pointer" matTooltip="{{'Eliminar Adjunto' | translate}}" matTooltipPosition="right" aria-hidden="true" (click)="removeFileFromInput()">cancel</i>
            </ng-template>
            <ng-template #elseTemplate>
                <p>
                    <i class="material-icons material-icons-outlined pointer attach-icon" matTooltip="{{'Añadir adjunto' | translate}}" matTooltipPosition="right" aria-hidden="true" (click)="openFileSelector()">attachment</i>
                    <span class="pointer m-2" (click)="openFileSelector()">Arrastre un archivo o pulse aquí.</span>
                </p>

            </ng-template>


        </ng-template>
    </ngx-file-drop>
</div>
<div class="row mt-2 mb-2">
    <span class="blue-text"> SOLO FORMATO PDF. El archivo no debe exceder los 10 MB</span>
</div>