import { filter } from 'rxjs/operators';
import { ButtonTableConfig } from './../../../core/models/button-table.model';
import { DetailsItem } from './table-configuration/details-item';
import { Component, OnInit, Input, SimpleChanges, SimpleChange,
  TemplateRef, ViewChild, Output, EventEmitter, ChangeDetectorRef, HostListener, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FilterDataService } from 'src/app/shared/services/filter-data.service';
import { CardViewComponent } from '../card-view/card-view.component';
import { CustomPagination } from './table-configuration/custom-pagination';
import { TablePaginationService } from 'src/app/shared/services/table-pagination.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortable, SortDirection } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { TableConfiguration, DefaultConfig } from './table-configuration/table-configuration';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Columns } from './table-configuration/columns';
import { DeleteItem } from './table-configuration/delete-item';
import { SelectionModel } from '@angular/cdk/collections';
import { Column } from 'ag-grid-community';
import { formatNumber, getLocaleNumberFormat, NumberFormatStyle } from '@angular/common';
import { LocaleService } from '../../services/locale.service';

@Component({
  selector: 'app-custom-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @ViewChild('activeIcon', { static: true }) activeIcon: TemplateRef<any>;
  @ViewChild('enabledIcon', { static: true }) enabledIcon: TemplateRef<any>;
  @ViewChild('availableIcon', { static: true }) availableIcon: TemplateRef<any>;
  @ViewChild('booleanIcon', { static: true }) booleanIcon: TemplateRef<any>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('dateTimeTemplate', { static: true }) dateTimeTemplate: TemplateRef<any>;
  @ViewChild('timeTemplate', { static: true }) timeTemplate: TemplateRef<any>;
  @ViewChild('cardView', { static: true }) cardView: CardViewComponent;

  @ViewChild(MatTable, { static: true }) mattable: MatTable<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;


  @Input() isLoading: boolean;
  @Input() items: any;
  @Input() columns: Columns[];
  @Input() columnsCard: Columns[];
  @Input() columnsExpandedCard: Columns[];
  @Input() columnsListadoCards: Columns[];
  @Input() pagination: CustomPagination;
  @Input() hideDelete: boolean;
  @Input() clickRow = false;
  @Input() keyCard = '';
  @Input() allowExpand = false;
  @Input() rowExpandTemplateRef: TemplateRef<any>;
  @Input() discriminator = '';
  @Input() fullCardTemplate: TemplateRef<any>;

  @Input() set configuration(configuration: TableConfiguration) {
    this._configuration = Object.assign({}, this._configuration, configuration);
  }



  @Output() deleteEmit = new EventEmitter<DeleteItem>();
  @Output() detailsEmit = new EventEmitter<DetailsItem>();
  @Output() paginationEmit = new EventEmitter<CustomPagination>();
  @Output() selectedEmit = new EventEmitter<any>();
  @Output() clickEmit = new EventEmitter<any>();
  @Output() mobile = new EventEmitter<boolean>();

  mobileVersion: boolean = false;

  routePath: string;
  storedPagination: CustomPagination;

  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  _configuration: TableConfiguration;
  _configurationExpandable: TableConfiguration;

  windowsWidth: number = window.innerWidth;

  onChanges = new BehaviorSubject<SimpleChange>(undefined);
  changes = this.onChanges.asObservable();

  paginationSub: Subscription;

  selection = new SelectionModel<any>(true, []);

  expandedElement: any | null;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.windowsWidth = event.target.innerWidth;
    this.userAgent(this.windowsWidth);
  }

  constructor(
    private router: Router,
    private filterDataService: FilterDataService,
    private tablePaginationService: TablePaginationService,
    private cdr: ChangeDetectorRef,
    private localeService: LocaleService) {

    this.routePath = this.router.url;

    this.pagination = {
      pageSize: 25,
      page: 1,
      sort: '',
      order: '',
      totalItems: 0
    };

    this._configuration = DefaultConfig;
    this._configurationExpandable = DefaultConfig;
    this._configurationExpandable.sorting = false;
  }

  ngOnInit(): void {

    this.userAgent(this.windowsWidth);

    this.routePath = this.router.url;

    if (this.columnsCard === undefined) {
      this.columnsCard = this.columns;
    }
    if (this.columnsListadoCards === undefined) {
      this.columnsListadoCards = this.columns;
    }
    // Initialize dataSource
    this.dataSource.sortingDataAccessor = (obj, property) => {
      let result = property.split('.').reduce((o, p) => o && o[p], obj);

      if (typeof result === 'string') {
        result = result.toLowerCase();
      }
      return result;

    };

    this.paginationSub = this.tablePaginationService.currentData.subscribe(data => {
      this.storedPagination = Object.assign({}, data);
    });
  }

  ngAfterViewInit(): void {
    // Sorting & Paginator config
    if (this._configuration.sorting) {
      this.sort.sortChange.subscribe(data => {
        this.updatePagination(data);
      });
    }

    if (this._configuration.pagination) {
      // Traduccion paginator
      this.paginator._intl.itemsPerPageLabel = 'Registros por página:';
      this.paginator._intl.nextPageLabel = 'Siguiente página';
      this.paginator._intl.previousPageLabel = 'Página anterior';
      this.paginator._intl.lastPageLabel = 'Última página';
      this.paginator._intl.firstPageLabel = 'Primera página';
      this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        return (length === 0 ? 0 : (page * pageSize) + 1) + ' - ' + (length > ((page * pageSize) + pageSize) ? ((page * pageSize) + pageSize) : length) + ' de ' + length;
      };

      this.paginator.page.subscribe(data => {
        this.updatePagination(data);
      });
    }

    // Bind sorting & paginator to dataSource
    if (!this._configuration.pageable) {
      if (this._configuration.sorting) { this.dataSource.sort = this.sort; }
      if (this._configuration.pagination) { this.dataSource.paginator = this.paginator; }
    }

    this.changes.subscribe(data => {
      if (data && data.currentValue && data.currentValue.length > 0 && this._configuration.pagination) {
        if (this.tablePaginationService.getParentUrl() === this.routePath.split('/')[2] && this.storedPagination) {
          this.tablePaginationService.setParentUrl(null);
          if (!this._configuration.pageable) {
            this.pagination = this.storedPagination;
            setTimeout(() => this.nextPage(), 1);
            setTimeout(() => this.sortColumn(), 1);
          }
        } else {
          if (this.tablePaginationService.getParentUrl() !== this.routePath.split('/')[2] && !this._configuration.disablePersistence) {
            this.tablePaginationService.setParentUrl(null);
          }

          if (!this._configuration.pageable) {
            this.pagination.order = 'none'; // Default order got from back-end
            this.pagination.sort = this.columns[0].key;
            setTimeout(() => this.sortColumn(), 1);
          }
        }
      }

      if (this._configuration.pageable) {
        this.paginator.pageIndex = this.pagination.page - 1;
        this.sort.direction = this.pagination.order as SortDirection;
        this.sort.active = this.pagination.sort;
      }
    });

    // To avoid Expression has changed after it was checked error
    this.cdr.detectChanges();
  }

  sortColumn(): void {
    if (this.pagination.order !== 'none') {
      const matSortable: MatSortable = {
        id: this.pagination.sort,
        start: this.pagination.order === 'desc' ? 'desc' : 'asc',
        disableClear: true,
      };
      this.sort.sort(matSortable);
    }
  }

  nextPage(): void {
    const page = this.pagination.page;
    for (let i = 1; i < page; i++) {
      this.paginator.nextPage();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    const { isLoading, items, columns, pagination } = changes;

    if (items && items.currentValue) {
      this.onChanges.next(items);
      this.items = items.currentValue;
      this.dataSource.data = items.currentValue;
    }

    if (pagination) {
      if (pagination.currentValue) {
        this.pagination = pagination.currentValue;
      } else {
        this.pagination = {
          pageSize: 25,
          page: 1,
          sort: '',
          order: '',
          totalItems: 0
        };
      }
    }

    if (columns && columns.currentValue) {
      this.columns = columns.currentValue;
      this.setCellTemplates();
      columns.currentValue.forEach(column => {
        this.displayedColumns.push(column.key);
      });
    }

    if (isLoading) {
      if (isLoading.currentValue) {
        this.showIsLoading();
        if (this.columns) {
          this.columns.forEach ( column => {
            if (column.key === 'checkbox' ) {
              this.selection.clear();
            }
          });
        }
      } else {
        this.removeIsLoading();
      }
    }

  }

  ngOnDestroy(): void {
    this.onChanges.complete();
    this.paginationSub.unsubscribe();
  }

  updatePagination(data: any): void {
    this.pagination.pageSize = data.pageSize != null ? data.pageSize : this.pagination.pageSize;
    this.pagination.page = data.pageIndex != null ? (data.pageIndex + 1) : this.pagination.page;
    this.pagination.sort = !data.active ? this.pagination.sort : data.active;
    this.pagination.serverSort = !data.active ? this.pagination.sort : data.active.includes('.') ? data.active.split('.')[0] : data.active;
    this.pagination.order = !!data.direction ? data.direction : this.pagination.order;
    this.pagination = { ...this.pagination };

    if (!this._configuration.disablePersistence) {
      if (this.pagination.pageSize !== this._configuration.defaultPageSize || this.pagination.sort || this.pagination.order || this.pagination.page > 1) {
        this.tablePaginationService.setData(this.pagination);
        this.tablePaginationService.setParentUrl(null);
      }
      this.paginationEmit.emit(this.pagination);
    }
  }

  setCellTemplates(): void {
    this.columns.forEach(col => {
      switch (col.key) {
        case 'available': {
          col.cellTemplate = this.availableIcon;
          break;
        }
        case 'active': {
          col.cellTemplate = this.activeIcon;
          break;
        }
        case 'enabled': {
          col.cellTemplate = this.enabledIcon;
          break;
        }
      }




      if (col.isDate) {
        col.cellTemplate = this.dateTemplate;
      }

      if (col.isDateTime) {
        col.cellTemplate = this.dateTimeTemplate;
      }

      if (col.isTime) {
        col.cellTemplate = this.timeTemplate;
      }

      if (col.isBoolean) {
        col.cellTemplate = this.booleanIcon;
      }
    });
  }

  showIsLoading(): void {
    this.isLoading = true;
  }

  removeIsLoading(): void {
    this.isLoading = false;
  }

  details(item: any, index?: number): void {
    const itemEmitted: DetailsItem = { item, index };
    this.detailsEmit.emit(itemEmitted);
  }
  public emitirDetalle(e: any): void {
    this.detailsEmit.emit(e);

  }
  columnDetail(item: any, key: string): void {
    const itemEmitted: DetailsItem = { item, key };
    this.detailsEmit.emit(itemEmitted);
  }
  remove(item: any, index: number): void {
    const itemEmitted: DeleteItem = { item, index };
    this.deleteEmit.emit(itemEmitted);
  }

  public checkItem(e: any): void {

  }

  /**
   * Counts if all tablerows enabled are selected.
   * @returns True if all selected
   */
  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.items.filter(it => !this.disableCheckbox(it)).length;

    return numSelected === numRows;
  }

  public masterToggle(): void {
    if(this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.items.forEach(row => {
        if (!this.disableCheckbox(row)) {
          this.selection.select(row);
        }
      })
    }

    this.selectedEmit.emit(this.selection.selected);

  }

  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  public changeCheckbox(e: any, row: any): void {
    this.selection.toggle(row);
    this.selectedEmit.emit(this.selection.selected);

  }

    /**
     * Control de check habilitado según estado de la fila.
     * @param row Elemento de la fila
     * @returns true si debe deshabilitarse
     */
    public disableCheckbox(row: any): boolean {


    return false;
  }

  public validateColumn(item: any, column: Columns): string {
    if (item !== undefined && item !== null
      && item[column.key] !== undefined
      && item[column.key] !== null
      && column.validationFunc !== undefined
      && column.validationFunc !== null) {
      var result = column.validationFunc(item[column.key]);
      return result ? '' : column.validationErrorCssClass;
    }

    return '';

  }

  public clickedItem(e: any): void {
    if (this.clickRow) {
      this.clickEmit.emit(e);
    }
  }

  public capturarClick(): void {

  }

  public getNumberFormatted(column: Columns, value: any) {
    return this.localeService.getNumberFormatted(value);
  }

  /**
   * Control de excepciones a la hora de mostrar botones de acción para un registro.
   */
  public decideShowButton(funcion: ButtonTableConfig, item: any): boolean {

    switch(true) {
      // Si el botón no es ningun caso controlado se muestra por defecto
      default : {
        return true;
      }
    }
  }

  public userAgent(screenWidth: number) {
    let mobile = false;

    // Emite onChanges de pagination para reiniciar paginación en CardView.
    this.items = [];
    if (screenWidth <= 1020) {
      mobile = true;
    }

    if (mobile != this.mobileVersion) {
      const paginationTemp = Object.assign({}, this.pagination);
      paginationTemp.page = 1;
      this.pagination = paginationTemp;
      this.paginationEmit.emit(this.pagination);
    }

    this.mobileVersion = mobile;
    this.mobile.emit(mobile);
  }

  public isNumber(number: any) {
    return !isNaN(number);
  }

}
