import { InvoicingUserService } from 'src/app/core/services/invoicing-user.service';
import { LoginService } from 'src/app/core/services/login.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

export class Notifications {
  static USER_REGISTRY_REQUEST = 'user_registry_request';
}

export class Notification {
  key: string;
  category: string;
  total: string;
}

/**
 * Centralización de notificaciones en la aplicación.
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public notifications = new BehaviorSubject<Array<Notification>>([]);
  private _notifications = new Array<Notification>();

  constructor(
    private loginService: LoginService,
    private invoicingUserService: InvoicingUserService) {}


  addNotification(notification: Notification): void {
    // Elimina si exsite
    this._notifications = this._notifications.filter(n => n.key !== notification.key);
    this._notifications.push(notification);
    this.notifications.next(this._notifications);
  }

  addNotificationByKey(key: string) {
    const notification: Notification = new Notification();
    notification.key = key;
    this.addNotification(notification);
  }

  removeNotificationByKey(key: string): void {
    this._notifications = this._notifications.filter(n => n.key !== key);
    this.notifications.next(this._notifications);
  }

  onNotification(): Observable<Notification[]> {
    return this.notifications.asObservable();
  }

  /**
   *
   */
  searchAllNotifications(): void {
    // 1 - Registry Request notifications
    if (this.loginService.getUser().permissionsInCompany.user_registry_request) {
      this.invoicingUserService.getUserRegistryRequestCount().subscribe(res => {
        res ? this.addNotificationByKey(Notifications.USER_REGISTRY_REQUEST) :
              this.removeNotificationByKey(Notifications.USER_REGISTRY_REQUEST);
      });
    }

  }
}
