
<h1 matDialogTitle>{{ '¿Quiere cancelar la factura?'}}</h1>

<form [formGroup]="fGroup" (ngSubmit)="onSubmit()">

  <mat-dialog-content>
    <div class="row">

      <div class="my-3">
        <span>Va a cancelar la factura <b>{{ invoice.invoice_number }}</b> con un importe total de <b>{{ invoice.total_amount | currency }}</b></span>
      </div>

      <div class="p-auto my-3 justify-content-center">
        <app-inputs-generator [fGroup]="fGroup" [inputFields]="inputs"></app-inputs-generator>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" [mat-dialog-close]="true">{{ 'No' | translate}}</button>
    <button mat-flat-button color="primary" type="submit" [mat-dialog-close]="true" [disabled]="!fGroup.valid">{{ 'Sí' | translate}}</button>
  </mat-dialog-actions>
</form>
