import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterDataService {

  private parentUrl;

  private dataSource = new BehaviorSubject('default data');
  currentData: Observable<any> = this.dataSource.asObservable();

  constructor() {
    this.setData(null);
  }

  setData(data: any) {
    let flag = false;
    if (data) {
      Object.values(data).forEach(value => {
        if (value) {
          flag = true;
        }
      });
    }

    flag ? this.dataSource.next(data) : this.dataSource.next(null);
  }

  setParentUrl(parentUrl: string) {
    this.parentUrl = parentUrl;
  }

  getParentUrl() {
    return this.parentUrl;
  }
}
