import { UtilsService } from 'src/app/shared/services/utils.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { LoginService } from 'src/app/core/services/login.service';

@Component({
  selector: 'app-users-manage-container',
  templateUrl: './users-manage-container.component.html',
  styleUrls: ['./users-manage-container.component.scss']
})
export class UsersManageContainerComponent implements OnInit {

  mobile = false;
  userType: string;

  constructor(
              public loginService: LoginService,
              private utilsService: UtilsService
              ) { }

  ngOnInit(): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;

    this.userType = this.loginService.getUser().userType;

    this.utilsService.toolbarTitle.emit({title: 'Gestión de usuarios', icon: 'manage_accounts'})
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;
  }


}
