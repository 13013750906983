import { environment } from '../environments/environment';

export const APP_VERSION = 'v0.0.0';

export const INITIAL_MESSAGE = '';

export const API_BASE_URL = environment.apiBaseUrl ? environment.apiBaseUrl : 'http://localhost:8080';

export const WARNING_REQUEST_SET_INTERVAL = environment.warningRequestSetInteval ? environment.warningRequestSetInteval : 3000;

export const WARNING_INTERVAL_ENABLED = !environment.warningIntervalEnabled ? environment.warningIntervalEnabled : true;

export const COORD_REGEX = '^(-?[0-9]+(\\.?[0-9]+)?)\\s*?,\\s*?(-?[0-9]+(\\.?[0-9]+$)?)';
export const POSITIVE_INT_REGEX = '^[0-9]*$';
export const POSITIVE_NON_ZERO_INT_REGEX = '^[1-9][0-9]*$';
export const POSITIVE_FLOAT_REGEX = '(^[0-9]+(\\.?[0-9]+)?$)';
export const MOBILE_REGEX = '^([+]|[0-9])[0-9]*$';
export const EMAIL_REGEX = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$';
export const TIME = '^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$';

/* Códigos roles */
export const INTERNAL = 'internal';
export const EXTERNAL = 'external';

/* Control accesos */
export const PANTALLA_LOGIN = 'PL';
export const PANTALLA_HOME = 'PH';

export const PANTALLA_DETALLE_FACTURA = 'PDF';

/** User Messages */
export const OK_MESSAGE = 'Registro actualizado correctamente.';
export const GENERAL_ALERT = 'Hubo un error con el servidor, por favor, intentelo de nuevo mas tarde.';
export const UPDATE_ALERT = 'Hubo un error al actualizar el registro, por favor, intentelo de nuevo mas tarde.';

/** Validation Messages */
export const POSITIVE_INT_REGEX_MESSAGE = 'Los números deben de ser enteros y mayor a 0';
export const REQUIRED_MESSAGE = 'Campo obligatorio';
export const COORD_REGEX_MESSAGE = 'Los números deben cumplir formato "41.12, 58.74"';
export const POSITIVE_FLOAT_REGEX_MESSAGE = 'Los números deben de ser mayor a 0';
export const DATE_VALIDATION_MESSAGE = 'La fecha debe de ser menor o igual que la fecha actual';
export const INVOICE_NUMBER_ALREADY_EXIST_MESSAGE = 'Este número de factura ya existe para el año ';
export const USER_MAXLENGTH_MESSAGE = 'La longitud máxima es de 200 caracteres';
export const USER_EMAIL_MESSAGE = 'El email no es valido';
export const USER_PHONE_MESSAGE = 'El telefono no es valido';

/* Modal Generica */
export const SI = 'Sí';
export const NO = 'No';
export const ACEPTAR = 'Aceptar';
export const CANCELAR = 'Cancelar';

/* Nombre de utilidades */
export const NOMBRE_SITIO = 'Portal de Facturación';
export const NOMBRE_SITIO_REDUCIDO = 'PF';

/* Pie de página */
export const COPYRIGHT = '© Grupo Arpada - Área Virtual';

/* Icono de utilidades (Solo configurado para font awesome) */
export const ICONO_FACTURAS = 'fa fa-envelope';

/* Descripción de utilidades */
export const MENU_FACTURAS = 'Gestión de Facturas';

/* Literales de modal de información */
export const MODAL_INFO_TITULO = 'Información';
export const INFO_PROFORMA = 'En caso de que la factura que vas a registrar ya incluya el descuento por pronto pago, selecciona en este campo el valor \"Sí\". En caso de que vayas a presentar una factura de abono aparte para el pronto pago, selecciona \"No\"'
