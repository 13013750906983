<!-- SECCIÓN DE BÚSQUEDA  -->
<section class="filter-box">

    <!-- CAJA DE BÚSQUEDA DIRECTA -->
    <div class="row header">
        <div [ngClass]="mobile ? 'col-12 col-lg-12' : 'col-4 col-lg-4'" class="arpada-smart-search-box align-icon-text" [formGroup]="fGroupSmart">
            <mat-form-field class="col-12" appearance="legacy">
                <input matInput type="text" name="smartSearch" formControlName="smartSearch" />
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
        </div>

        <ng-container *ngIf="!mobile">
            <label class="col-5 pl-3 pt-0 pointer toggle-filter my-auto py-auto arpada-strict-search-toggle align-icon-text" (click)="showFilters = !showFilters">
                <mat-icon class="material-icons">filter_alt</mat-icon>
                <i [ngClass]="(showFilters)?'fa fa-angle-up':'fa fa-angle-down'" aria-hidden="true"></i>
                <small>Filtrar resultados</small>
            </label>
        </ng-container>

        <div class="col-3" align="end" *ngIf="!mobile">
            <button *ngIf="canDownloadExcel()" mat-stroked-button color="primary" (click)="downloadInvoicesAsExcel()" [ngStyle]="{'padding': isDownloadingExcel ? ' 8px 9px 11px 9px' : '0px 7px 0px 5px' }" style="min-width: 0px;" [disabled]="isDownloadingExcel">
                <img *ngIf="!isDownloadingExcel" class="primaty-color-filter"
                    src="../../../../../assets/svg/Icon-excel.svg" style="margin-top: -2px">
                <mat-progress-spinner matSuffix [diameter]="18" *ngIf="isDownloadingExcel"
                    aria-label="Cargando información" color="primary" mode="indeterminate"
                    style="float: right; margin-bottom: -4px;">
                </mat-progress-spinner>
            </button>
            <button *ngIf="canDownloadZip()" mat-stroked-button color="primary" (click)="downloadInvoicesZip()" style="margin-left: 8px; padding: 0px 9px 0px 5px; font-weight: bold;" [disabled]="disableZipDownload">
                <img class="primaty-color-filter" src="../../../../../assets/svg/Icon-pdf.svg" style="margin-top: -2px">
                <span>Descarga facturas</span>
            </button>
        </div>

        <ng-container *ngIf="mobile">
            <div class="col-8" style="margin: .5rem 0rem;">
                <button mat-button (click)="showFilterSelection = true; showFilter()" class="col-auto align-icon-text">
                    <mat-icon class="material-icons material-icons-outlined">filter_alt</mat-icon>
                    {{ 'Filtrar' | translate }}
                </button>
                <button mat-button (click)="sort()" class="col-auto align-icon-text">
                    <mat-icon>import_export</mat-icon>{{ 'Ordenar' | translate }}
                </button>
            </div>
            <div class="col-4" style="margin: .5rem 0rem;" align="end">
                <button *ngIf="canDownloadExcel()" mat-stroked-button color="primary" (click)="downloadInvoicesAsExcel()" style="min-width: 0px;" [disabled]="isDownloadingExcel" [ngStyle]="{'padding': isDownloadingExcel ? ' 8px 9px 11px 9px' : '0px 7px 0px 5px' }">
                    <img *ngIf="!isDownloadingExcel" class="primaty-color-filter"
                        src="../../../../../assets/svg/Icon-excel.svg" style="margin-top: -2px">
                    <mat-progress-spinner matSuffix [diameter]="18" *ngIf="isDownloadingExcel"
                        aria-label="Cargando información" color="primary" mode="indeterminate"
                        style="float: right; margin-bottom: -4px;">
                    </mat-progress-spinner>
                </button>
                <button *ngIf="canDownloadZip()" mat-stroked-button color="primary" (click)="downloadInvoicesZip()" style="min-width: 0px; margin-left: 8px; padding: 0px 7px 0px 5px; font-weight: bold;" [disabled]="disableZipDownload">
                    <img class="primaty-color-filter" src="../../../../../assets/svg/Icon-pdf.svg"
                        style="margin-top: -2px">
                </button>
            </div>
        </ng-container>

    </div>

    <!-- CAJA DE FILTROS VERSIÓN ESCRITORIO -->
    <mat-card class="arpada-card-filter mat-elevation-z" *ngIf="showFilters && !mobile">
        <form [formGroup]="fGroup" (ngSubmit)="filterSubmit()">
            <app-inputs-generator [fGroup]="fGroup" [inputFields]="inputs"></app-inputs-generator>
        </form>
    </mat-card>

    <!-- CAJA DE FILTROS VERSIÓN MÓVIL -->
    <div *ngIf="showFilterSelection" class="arpada-mobile-fullscreen-modal">
        <div class="arpada-fullscreen-modal-toolbar">
            <div class="arpada-fs-modal-toolbar-text">
                <a class="primary" (click)="showFilterSelection = false">
                    <h1 class="align-icon-text">
                        <mat-icon class="material-icons material-icons-outlined">arrow_back_ios_new</mat-icon>
                        {{ 'Filtrar' | translate }}
                    </h1>
                </a>
            </div>
            <div class="arpada-fs-modal-toolbar-actions" style="visibility: hidden;">
                <button mat-stroked-button color="primary">Aplicar</button>
            </div>
        </div>
        <div class="arpada-fs-modal-body">
            <div class="body-title">

            </div>
            <div class="body-content">
                <mat-card>
                    <div class="snack-bar-info simple-inline-snack-bar align-icon-text mb-2">
                        <mat-icon class="material-icons material-icons-outlined">info</mat-icon>
                        <b style="margin-right: 4px">{{ customPagination.totalItems }}</b> {{ 'facturas encontradas' | translate }}
                    </div>
                    <form [formGroup]="fGroup" (ngSubmit)="filterSubmit()">
                        <app-inputs-generator [fGroup]="fGroup" [inputFields]="inputs"></app-inputs-generator>
                    </form>
                </mat-card>
            </div>
        </div>
    </div>

</section>

<!-- SECCIÓN DE RESULTADOS DE BÚSQUEDA -->
<section class="mb-3">
    <app-custom-table [configuration]="tableConfiguration" [pagination]="customPagination" [columns]="columnDefs" [columnsCard]="columnDefsCards" [columnsListadoCards]="columnDefsListadoCards" [columnsExpandedCard]="columnDefsListadoExpandedCards" [fullCardTemplate]="cardInvoice"
        [items]="rowData" [allowExpand]="true" [rowExpandTemplateRef]="invoiceExpanded" [keyCard]="'F.Factura'" (paginationEmit)="pagination($event)"></app-custom-table>
</section>

<!-- <br>
FORMULARIO: {{ fGroup.value | json }}
<br>
SMART: {{ fGroupSmart.value | json }} -->
<!-- <br>

FILAS: {{ rowData | json }} -->


<!------------------------------------------------------------>
<!-------- TEMPLATE PARA ESTADOS DE FACTURA EN SELECT -------->
<!------------------------------------------------------------>
<ng-template #stateOption let-item>
    <div class="align-icon-text">
        <mat-icon *ngIf="item.stateForSearchUniquekey !== 'refund_pending' && item.stateForSearchUniquekey !== 'withheld_payment' && item.stateForSearchUniquekey !== 'rejected_payment'; else svgIcon" style="font-size: 18px;" class="material-icons material-icons-outlined arpada-state-icon-{{ item.stateForSearchUniquekey }}">
            {{ item.stateForSearchUniquekey | invoiceStateToIcon }}
        </mat-icon>
        <ng-template #svgIcon>
            <img class="material-icons material-icons-outlined arpada-state-icon-{{ item.stateForSearchUniquekey }}" style="width: 18px; margin-right: 11px" src="{{'../../../../../../../assets/svg/' + item.stateForSearchUniquekey + '.svg'}}">
        </ng-template>
        {{ item.description }}
    </div>
</ng-template>

<!----------------------------------------------------------->
<!-------- TEMPLATE PARA OBRA EN SELECT --------------------->
<!----------------------------------------------------------->
<ng-template #siteOption let-item>
    <span style="display: block">{{ item.description }}</span>
    <small>{{ item?.address }}</small>
</ng-template>

<!----------------------------------------------------------->
<!-------- TEMPLATE PARA TIPO DE FACTURA EN SELECT ---------->
<!----------------------------------------------------------->
<ng-template #invoiceTypeOption let-item>
    <div class="align-icon-text">
        <mat-icon *ngIf="utils.chooseDisplayIcon(item.icon_code); else customIcon" style="font-size: 18px;" class="material-icons material-icons-outlined">{{ item.icon_code }}
        </mat-icon>
        <ng-template #customIcon>
            <img style="width: 18px; margin-right: 11px" src="{{'../../../../../assets/icons/' + utils.getCustomIcon(item.icon_code)}}" alt="">
        </ng-template>
        {{ item.description }}
    </div>
</ng-template>

<!----------------------------------------------------------->
<!-------- TEMPLATE BOTÓN LIMPIAR FILTROS DE BUSQUEDA ------->
<!----------------------------------------------------------->
<ng-template #clearFilters let-input>
    <a class="primary align-icon-text arpada-bold-1" (click)="input.function()">
        <mat-icon>delete_sweep</mat-icon>{{ input.label }}
    </a>
</ng-template>


<!----------------------------------------------------------->
<!-------- TEMPLATE PARA ESTADOS DE FACTURA EN TABLA -------->
<!----------------------------------------------------------->
<ng-template #stateChip let-item let-column="column">
    <div [ngClass]="!mobile ? 'arpada-chip arpada-chip-' + item.stateId + ' align-icon-text' : 'arpada-chip arpada-chip-' + item.stateId + ' align-icon-text arpada-card-chip'">
        <mat-icon *ngIf="item.stateId !== 'refund_pending' && item.stateId !== 'withheld_payment' && item.stateId !== 'rejected_payment'; else svgIcon" class="material-icons material-icons-outlined">{{item.stateId | invoiceStateToIcon}}</mat-icon>
        <ng-template #svgIcon>
            <img class="material-icons material-icons-outlined arpada-state-icon-{{ item.stateId }}" style="width: 18px; margin-right: 11px" src="{{'../../../../../../../assets/svg/' + item.stateId + '.svg'}}">
        </ng-template>
        {{ item.stateDescription }}
    </div>
</ng-template>

<!----------------------------------------------------------->
<!-------- TEMPLATE PARA EMPRESA DE FACTURA EN TABLA -------->
<!----------------------------------------------------------->
<ng-template #company let-item let-column="column">
    <span>
        {{item.companyDescription}} | {{item.companyVatNumber}}
    </span>
</ng-template>

<!----------------------------------------------------------->
<!---------- TEMPLATE PARA OBRA DE FACTURA EN TABLA --------->
<!----------------------------------------------------------->
<ng-template #csite let-item let-column="column">
    <ng-container *ngIf="item.csiteInternalNumber !== 'NA'; else noUbicacion">
        <small style="display: block">{{item.csiteInternalNumber}} - {{item.csiteDescription}}</small>
        <small>{{item.csiteAddress}}</small>
    </ng-container>
    <ng-template #noUbicacion>
        <small>-</small>
    </ng-template>
</ng-template>


<!-------------------------------------------------------------------------------->
<!-------- TEMPLATE PARA DETALLE DE FACTURA EN TABLA (Despliegue de fila) -------->
<!-------------------------------------------------------------------------------->
<ng-template #invoiceExpanded let-item>
    <div class="align-icon-text w-100">
        <table class="arpada-invoice-row-expanded">
            <tr>
                <th *ngIf="item.stateDescription">{{'Factura en estado' | translate}}:</th>
                <th *ngIf="item.invoiceTypeId">{{'Tipo de factura' | translate}}:</th>
                <th *ngIf="item.invoiceInternalId">{{'ID Arpada' | translate}}:</th>
                <th *ngIf="item.baseAmount">{{'Base imponible' | translate}}:</th>
                <th *ngIf="item.vatLines">{{'IVA' | translate}}:</th>
                <th *ngIf="item.withholdingAmount">{{'Retención' | translate}}:</th>
                <th *ngIf="item.irpfValue">{{'IRPF' | translate}}:</th>
            </tr>
            <tr>
                <td *ngIf="item.stateDescription" class="arpada-state-text-{{item.stateId}}">
                    <b>
                        <span *ngIf="displayStateSteps(item)">{{item.stateDetailsProgressText}}</span>
                        {{item.stateDescription}}
                    </b>
                    <span *ngIf="item.stateDetailsText" class="d-block">{{ item.stateDetailsText }}</span>
                    <span *ngIf="item.stateDetailsTlf" class="d-block">{{ item.stateDetailsTlf }}</span>
                    <span *ngIf="item.stateDetailsEmail" class="d-block">{{ item.stateDetailsEmail }}</span>
                </td>
                <td *ngIf="item.invoiceTypeId">
                    <div class="align-icon-text">
                        <mat-icon *ngIf="utils.chooseDisplayIcon(getIconInvoiceType(item.invoiceTypeId)); else customIcon" class="material-icons material-icons-outlined">{{getIconInvoiceType(item.invoiceTypeId)}}
                        </mat-icon>
                        <ng-template #customIcon>
                            <img class="custom-icon-filter" style="width: 24px; margin-right: 11px" src="{{'../../../../../assets/icons/' + utils.getCustomIcon(getIconInvoiceType(item.invoiceTypeId))}}" alt="">
                        </ng-template>
                        {{item.invoiceTypeDescription}}
                    </div>
                </td>
                <td *ngIf="item.invoiceInternalId">{{item.invoiceInternalId}}</td>
                <td *ngIf="item.baseAmount">{{item.baseAmount | currency }}</td>
                <td *ngIf="item.vatLines">
                    <ng-template #conIVA>
                        <span *ngFor="let vatLine of item.vatLines">
                            <span *ngIf="vatLine.amount !== 0 && !item.reverseVatLiability">
                                <small>({{vatLine.vatPerone | percent }}) </small> {{ vatLine.amount | currency }}
                                <br>
                                <!-- <small>hello</small> -->
                            </span>
                        </span>
                    </ng-template>
                    <span *ngIf="displayIVA(item.vatLines, item.reverseVatLiability); else conIVA">{{item.reverseVatLiability
                        ? 'ISP' : 0 | currency }}</span>
                </td>
                <td *ngIf="item.withholdingAmount">{{item.withholdingAmount || 0 | currency }}</td>
                <td *ngIf="item.irpfValue">{{item.irpfValue || 0 | currency }}</td>
            </tr>
        </table>
    </div>
</ng-template>

<!----------------------------------------------------------------------------->
<!-------- TEMPLATE PARA TIPO DE FACTURA EN TABLA (Despliegue de fila) -------->
<!----------------------------------------------------------------------------->
<ng-template #siteIcon let-item>
    <mat-icon *ngIf="utils.chooseDisplayIcon(getIconInvoiceType(item.invoiceTypeId)); else customIcon" class="material-icons material-icons-outlined arpada-icon" [matTooltip]="item.invoiceTypeDescription" [matTooltipPosition]="'right'">
        {{getIconInvoiceType(item.invoiceTypeId)}}</mat-icon>
    <ng-template #customIcon>
        <img class="custom-icon-filter" [matTooltip]="item.invoiceTypeDescription" [matTooltipPosition]="'right'" style="width: 24px; margin-right: 11px" src="{{'../../../../../assets/icons/' + utils.getCustomIcon(getIconInvoiceType(item.invoiceTypeId))}}" alt="">
    </ng-template>
</ng-template>

<!----------------------------------------------------------------------------->
<!-------------- TEMPLATE PARA ACCIONES SOBRE FACTURA EN TABLA  --------------->
<!----------------------------------------------------------------------------->
<ng-template #invoiceActions let-item>
    <mat-menu #invoiceActionsMenu="matMenu">
        <button *ngIf="item.stateId === 'sent' && user.permissionsInCompany.invoices_edit && item.invoicePermissions.invoiceEditable" class="text-primary-light" (click)="editInvoice(item)" mat-menu-item>
            <mat-icon>edit</mat-icon>{{'Editar'|translate}}
        </button>
        <!-- <button (click)="editInvoice(item)" mat-menu-item><mat-icon>edit</mat-icon>{{'Editar'|translate}}</button> -->
        <button *ngIf="item.invoicePermissions.invoiceCancelable" (click)="cancelInvoice(item)" class="text-primary-light" mat-menu-item>
            <mat-icon>delete</mat-icon>{{'Cancelar'|translate}}
        </button>
        <button *ngIf="item.invoiceAttachmentFileUuid" class="text-primary-light" (click)="downloadFile(item.invoiceAttachmentFileUuid);$event.stopPropagation()" mat-menu-item>
            <img class="primaty-color-filter" src="../../../../../assets/svg/Icon-download.svg" style="margin-right: 15px; margin-left: 1px;">
            {{'Doc. Adicional'|translate}}
        </button>
    </mat-menu>

    <div style="min-width: 100px; margin-left: 5px;" class="col-12" align="start">
        <button mat-icon-button color="primary" (click)="seeDetails(item);$event.stopPropagation()" class="text-primary-light" matTooltip="{{ 'Ver en detalle' | translate }}" style="margin-right: -13px;">
            <mat-icon>preview</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="downloadFile(item.invoiceFileUuid);$event.stopPropagation()" class="text-primary-light" matTooltip="{{ 'Descargar pdf' | translate }}" style="margin-right: -13px;">
            <img class="primaty-color-filter" src="../../../../../assets/svg/Icon-download.svg">
        </button>
        <!-- Si el usuario tiene permisos de edición o borrado y la factura lo permite -->
        <button *ngIf="item.invoicePermissions.invoiceEditable || item.invoicePermissions.invoiceCancelable" mat-icon-button color="primary" (click)="$event.stopPropagation()" [matMenuTriggerFor]="invoiceActionsMenu">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
</ng-template>

<!----------------------------------------------------------------------------->
<!-------------- TEMPLATE PARA VISTA TARJETA DE CADA FILA --------------------->
<!----------------------------------------------------------------------------->
<ng-template #cardInvoice let-item>

    <mat-menu #invoiceActionsMenu="matMenu">
        <button *ngIf="item.stateId === 'sent' && user.permissionsInCompany.invoices_edit && item.invoicePermissions.invoiceEditable" class="text-primary-light" (click)="editInvoice(item); $event.stopPropagation()" mat-menu-item>
            <mat-icon>edit</mat-icon>{{'Editar'|translate}}
        </button>
        <button *ngIf="item.stateId === 'sent' && item.invoicePermissions.invoiceCancelable" class="text-primary-light" (click)="cancelInvoice(item); $event.stopPropagation()" mat-menu-item>
            <mat-icon>delete</mat-icon>{{'Cancelar'|translate}}
        </button>
        <button class="text-primary-light" (click)="downloadFile(item.invoiceAttachmentFileUuid)" mat-menu-item>
            <img class="primaty-color-filter" style="margin-right: 15px; margin-left: 1px;"
                src="../../../../../assets/svg/Icon-download.svg">{{'Doc. Adicional'|translate}}
        </button>
    </mat-menu>

    <div class="col-6 text-primary-light" style="font-size: 16px; font-weight: bold;">{{item.invoice_number}}</div>
    <div class="col-6" style="font-size: 16px; color: #3F6F55" align="end">{{ item.invoiceDate | date: 'dd/MM/yyyy' }}
    </div>
    <div (click)="$event.stopPropagation(); saveLastPage()" [routerLink]="'detail/' + item.id">

        <h2 class="col-12 mt-3 align-icon-text" style="font-size: 13px;">
            <mat-icon *ngIf="utils.chooseDisplayIcon(getIconInvoiceType(item.invoiceTypeId)); else customIcon" class="material-icons material-icons-outlined">{{getIconInvoiceType(item.invoiceTypeId)}}
            </mat-icon>
            <ng-template #customIcon>
                <img class="custom-icon-filter" style="width: 24px; margin-right: 5px" src="{{'../../../../../assets/icons/' + utils.getCustomIcon(getIconInvoiceType(item.invoiceTypeId))}}" alt="">
            </ng-template>
            {{ item.invoiceTypeDescription}}
        </h2>

        <div class="col-12">
            <div style="max-width: 290px;">
                <small>{{item.companyDescription }} | {{item.companyVatNumber}}</small>
                <small *ngIf="item.csiteInternalNumber !== 'NA'" style="display: block; padding-top: 10px;">
                    {{item.csiteInternalNumber}}
                    {{item.csiteInternalNumber && item.csiteDescription ? '-' : ''}}
                    {{item.csiteDescription}}
                </small>
                <small *ngIf="item.csiteAddress && item.csiteInternalNumber !== 'NA'" style="display: block; width: 85%;">{{item.csiteAddress}}</small>
            </div>
            <div *ngIf="displayCardActions(item, user)" style="margin-top: -39px;" align="end">
                <button class="card-action-buttons" mat-icon-button color="primary" (click)="$event.stopPropagation(); downloadFile(item.invoiceFileUuid)">
                    <img class="primaty-color-filter" src="../../../../../assets/svg/Icon-download.svg">
                </button>
                <button class="card-action-buttons" mat-icon-button color="primary" (click)="$event.stopPropagation()" [matMenuTriggerFor]="invoiceActionsMenu">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </div>
            <div *ngIf="!displayCardActions(item, user)" style="margin-top: -39px;" align="end">
                <button class="card-action-buttons" mat-icon-button color="primary" (click)="$event.stopPropagation() ; downloadFile(item.invoiceFileUuid)">
                    <img class="primaty-color-filter" src="../../../../../assets/svg/Icon-download.svg">
                </button>
            </div>
        </div>

        <div class="col-12 mx-auto my-3" style="border-top: 1px solid #EEE"></div>

        <div class="col-12" style="display: flex; " [ngStyle]="{'margin-bottom': (item.stateDetailsText || item.stateDetailsTlf || item.stateDetailsEmail) ? '1rem' : '0rem'}">
            <div [ngClass]="(item.invoicePermissions.invoiceEditable || item.invoicePermissions.invoiceCancelable) ? 'col-6' : 'col-9'" style="float: left; width: 60%;">
                <ng-container [ngTemplateOutlet]="stateChip" [ngTemplateOutletContext]="{$implicit: item}">
                </ng-container>
                <div class="arpada-state-text-{{item.stateId}}" style="margin-left: 2px; font-size: 13px; margin-bottom: 18px;">
                    <span *ngIf="item.stateDetailsText" class="d-block">{{ item.stateDetailsText }}</span>
                    <span *ngIf="item.stateDetailsTlf" class="d-block">{{ item.stateDetailsTlf }}</span>
                    <span *ngIf="item.stateDetailsEmail" class="d-block">{{ item.stateDetailsEmail }}</span>
                </div>
            </div>

            <div class="card-total-amount" class="col-3" align="end" style="width: 40%;">
                <small style="font-size: 10px; color: #212121">IMPORTE (IVA INC.)</small>
                <h2 style="font-size: 16px; font-weight: bold; color: #212121">{{ item.total_amount | currency }}
                </h2>
            </div>

        </div>

    </div>
</ng-template>

<!-- {{ customPagination | json }} -->
