import { Order } from './order.model';

export class ProformaCriteria {
  order: Order[];
  offset: number;
  limit: number;
  id: string;
  ids: string[];
  uuid: string;
  uuids: string[];
  csite_id: string;
  csite_ids: string[];
  company_id: string;
  company_ids: string[];
  smart_text: string;
  b_only_invoiceables = true;

  constructor(data?: any) {
    this.order = (data && data.order) || [];
    this.offset = (data && data.offset) || 0;
    this.limit = (data && data.limit) || 0;
    this.id = (data && data.id) || 0;
    this.ids = (data && data.ids) || [];
    this.uuid = (data && data.uuid) || '';
    this.uuids = (data && data.uuids) || [];
    this.csite_id = (data && data.csite_id) || '';
    this.csite_ids = (data && data.csite_ids) || [];
    this.company_id = (data && data.company_id) || '';
    this.company_ids = (data && data.company_ids) || [];
    this.b_only_invoiceables = (data && data.b_only_invoiceables) || true;
  }
}
