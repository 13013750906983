<mat-toolbar class="toolbar sub-toolbar" *ngIf="!mobile" [class.arpada-mobile-subtoolbar]="mobile">
    <a (click)="back()" style="text-decoration: none;" class="align-icon-text">
        <mat-icon *ngIf="mobile" style="font-size: 20px;" inline>arrow_back_ios</mat-icon>
        <h1>
            {{'Detalle de factura' | translate}}
        </h1>
    </a>
    <i *ngIf="!mobile" class="material-icons material-icons-outlined invoice-add-icon" aria-hidden="true">preview</i>
</mat-toolbar>

<ng-container *ngIf="display()">

    <a (click)="back()" *ngIf="!mobile" class="arpada-bold-2 arpada-back-button align-icon-text">
        <mat-icon inline>arrow_back_ios</mat-icon>Volver
    </a>

    <!-- BARRA DE ACCIONES -->
    <div class="row justify-content-center pt-3" [class.arpada-main-actions-mobile]="mobile">
        <div class="col-12 col-lg-10 col-xl-8" [align]="mobile ? 'center' : 'end' " style="padding-right: 0">
            <button *ngIf="user.permissionsInCompany.invoices_edit && invoice.stateId === 'sent' && invoice.invoicePermissions.invoiceEditable" (click)="editInvoice(invoice)" mat-flat-button color="primary">
        <mat-icon class="material-icons material-icons-outlined">edit</mat-icon>
        Editar factura
      </button>
            <button *ngIf="invoice.invoicePermissions.invoiceCancelable && invoice.stateId === 'sent'" mat-flat-button (click)="cancelInvoice(invoice)" color="warn" style="margin-left: 15px">
        <mat-icon class="material-icons material-icons-outlined">delete</mat-icon>
        Cancelar factura
      </button>
        </div>
    </div>


    <div class="row mt-4 justify-content-center">
        <mat-card class="col-12 col-lg-10 col-xl-8 mt-3 detail-container">

            <!-- CABECERA  -->
            <div class="row">
                <div class="col-12 col-lg-8 order-2 order-lg-1">
                    <h1 class="align-icon-text d-inline-flex">
                        <i *ngIf="utilsService.chooseDisplayIcon(getIconInvoiceType(invoice.invoiceTypeId)); else customIcon" class="material-icons material-icons-outlined" style="font-size: 40px" aria-hidden="true">{{getIconInvoiceType(invoice.invoiceTypeId)}}</i>
                        <ng-template #customIcon>
                            <img class="color-filter-black-green" style="width: 40px; height: 47px" src="{{'../../../../../../assets/icons/' + utilsService.getCustomIcon(getIconInvoiceType(invoice.invoiceTypeId))}}" alt="">
                        </ng-template>
                        {{ invoice.invoiceTypeDescription }}
                    </h1>
                </div>

                <div class="col-12 col-lg-4 order-1 order-lg-2 arpada-state-card">
                    <span class="d-none d-lg-inline-block"><b>{{ 'Nº de factura'}}:</b> {{ invoice.invoice_number }}</span>

                    <div class="arpada-state-chip">
                        <div [ngClass]="'d-inline-flex arpada-chip arpada-chip-' + invoice.stateId + ' align-icon-text'">
                            <mat-icon *ngIf="invoice.stateId !== 'refund_pending' && invoice.stateId !== 'withheld_payment' && invoice.stateId !== 'rejected_payment'; else svgIcon" class="material-icons material-icons-outlined">{{invoice.stateId | invoiceStateToIcon}}</mat-icon>
                            <ng-template #svgIcon>
                                <img class="material-icons material-icons-outlined arpada-state-icon-{{ invoice.stateId }}" style="width: 18px; margin-right: 11px" src="{{'../../../../../../../assets/svg/' + invoice.stateId + '.svg'}}">
                            </ng-template>
                            {{ invoice.stateDescription }}
                        </div>
                    </div>
                    <div class="arpada-state-text-{{invoice.stateId}}">
                        <span *ngIf="invoice.stateDetailsText" class="d-block"><b *ngIf="invoice.stateDetailsProgressText">{{invoice.stateDetailsProgressText}} </b>{{ invoice.stateDetailsText }}</span>
                        <span *ngIf="invoice.stateDetailsTlf" class="d-block">{{ invoice.stateDetailsTlf }}</span>
                        <span *ngIf="invoice.stateDetailsEmail" class="d-block">{{ invoice.stateDetailsEmail }}</span>
                    </div>
                </div>
            </div>
            <!-- SECCIONES DESPLEGABLES  -->
            <mat-accordion class="arpada-accordion" multi="true">

                <!-- INFORMACIÓN DE PAGOS (Solo para facturas con pago emitido) -->
                <mat-expansion-panel *ngIf="invoice.stateId === 'payment_sent'" expanded="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="align-icon-text">
                            <mat-icon>euro</mat-icon>{{'Información de pagos' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <!-- TEMPLATE POR CADA LÍNEA DE PAGO -->
                    <ng-container *ngFor="let paymentsLine of invoice.paymentsLines" [ngTemplateOutlet]="paymentsLineTemplate" [ngTemplateOutletContext]="{$implicit: paymentsLine}">
                    </ng-container>

                </mat-expansion-panel>


                <!-- FACTURA -->
                <mat-expansion-panel expanded="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="align-icon-text">
                            <mat-icon>preview</mat-icon>{{'Factura' | translate}}
                        </mat-panel-title>
                        <!-- <mat-panel-description></mat-panel-description> -->
                    </mat-expansion-panel-header>

                    <ng-container [ngTemplateOutlet]="invoiceTemplate" [ngTemplateOutletContext]="{$implicit: invoice}"></ng-container>

                </mat-expansion-panel>

                <!-- HISTORIAL DE ACTIVIDAD -->
                <mat-expansion-panel expanded="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="align-icon-text">
                            <mat-icon class="material-icons material-icons-outlined">fact_check</mat-icon>{{'Historial de actividad' | translate}}
                        </mat-panel-title>
                        <!-- <mat-panel-description></mat-panel-description> -->
                    </mat-expansion-panel-header>

                    <ng-container [ngTemplateOutlet]="invoiceEventsTemplate" [ngTemplateOutletContext]="{$implicit: invoice.events}"></ng-container>
                </mat-expansion-panel>

            </mat-accordion>
        </mat-card>
    </div>
</ng-container>

<!------------------------------------------------>
<!------ TEMPLATE PARA LINEA DE PAGOS ------------>
<!------------------------------------------------>
<ng-template #paymentsLineTemplate let-paymentsLine>

    <!-- VERSIÓN ESCRITORIO -->
    <div class="arpada-paymentline-row mb-1 d-none d-lg-flex">
        <table style="width: 100%;">
            <tr>
                <th style="width: 10%; padding-left: 10px">{{ 'ID' | translate }}</th>
                <th style="width: 10%">{{ 'Emisión' | translate }}</th>
                <th style="width: 20%">{{ 'Entidad' | translate }}</th>
                <th style="width: 25%">{{ 'Concepto' | translate }}</th>
                <th style="width: 20%"></th>
                <th style="width: 15%; padding-right: 10px" class="arpada-paymentline-dark amount-right">{{ 'Importe' | translate }}</th>
            </tr>
            <tr>
                <td style="padding-left: 10px">{{ paymentsLine.id }}</td>
                <td>{{ paymentsLine.paymentDate | date: 'dd/MM/yyyy'}}</td>
                <td>{{ paymentsLine.bankingEntity }}</td>
                <td>{{ paymentsLine.concept }}</td>
                <td>
                    <a href="tel:{{ paymentsLine.confirmingPhone | noWhiteSpaces }}" class="primary align-icon-text">
                        <mat-icon class="material-icons material-icons-outlined">phone</mat-icon>{{ paymentsLine.confirmingPhone }}
                    </a>
                </td>
                <td class="arpada-paymentline-dark amount-right" style="padding-right: 10px">{{ paymentsLine.amount | currency }}</td>
            </tr>
        </table>
    </div>

    <!-- VERSION MÓVIL -->
    <div class="row arpada-paymentline-card mb-1 pt-1 d-flex d-lg-none">
        <div class="col-6 mt-1 arpada-bold-2">{{ paymentsLine.bankingEntity }}</div>
        <div class="col-6">
            <a href="tel:{{ paymentsLine.confirmingPhone | noWhiteSpaces }}" class="primary float-end align-icon-text">
                <mat-icon class="material-icons material-icons-outlined">phone</mat-icon><span style="text-decoration: underline;">{{ paymentsLine.confirmingPhone }}</span>
            </a>
        </div>
        <div class="col-12 text-center py-4">{{ paymentsLine.concept }}</div>

        <div class="arpada-paymentline-dark py-1 col-12">
            <div class="row">
                <div class="col-5">{{ paymentsLine.paymentDate | date: 'dd/MM/yyyy'}}</div>
                <div class="col-7 amount-right">
                    <span class="col-auto" style="text-transform: uppercase; font-size: 11px; margin-right: 5px">{{ 'Importe' | translate}}</span>
                    <span class="col-auto arpada-bold-1">{{ paymentsLine.amount | currency }}</span>
                </div>
            </div>
        </div>
    </div>

</ng-template>

<!------------------------------------------------>
<!----------- TEMPLATE PARA FACTURA -------------->
<!------------------------------------------------>
<ng-template #invoiceTemplate let-invoice>

    <div class="row mb-3 arpada-invoice-detail-section">
        <span class="arpada-title-3">{{ 'Identificación del ' + getIdentificationTitle() | translate }}</span>
        <span class="col-5 col-lg-3 arpada-bold-2">{{ getEmisorOrDestinatarioLabel() | translate }}</span>
        <span class="col-7 col-lg-9">{{ getEmisorOrDestinatario() }}</span>

        <span class="col-5 col-lg-3 arpada-bold-2">{{ 'CIF' | translate }}</span>
        <span class="col-7 col-lg-9">{{ getCifEmisorOrDestinatario() }}</span>
    </div>

    <div class="row mb-3 pt-3 arpada-section-border-top arpada-invoice-detail-section">
        <span class="arpada-title-3">{{ 'Identificación de factura' | translate }}</span>
        <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Nº de factura' | translate }}</span>
        <span class="col-7 col-lg-9">{{ invoice.invoice_number }}</span>

        <ng-container *ngIf="displayIdArpada()">
            <span class="col-5 col-lg-3 arpada-bold-2">{{ 'ID Arpada' | translate }}</span>
            <span class="col-7 col-lg-9">{{ invoice.invoiceInternalId }}</span>
        </ng-container>

        <ng-container *ngIf="invoice.stateDescription">
            <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Estado factura' | translate }}</span>
            <span class="col-7 col-lg-9">{{ invoice.stateDescription }}</span>
        </ng-container>

        <ng-container *ngIf="invoice.invoiceTypeDescription">
            <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Tipo de factura' | translate }}</span>
            <span class="col-7 col-lg-9">{{ invoice.invoiceTypeDescription }}</span>
        </ng-container>

        <ng-container *ngIf="invoice.invoiceSubtypeDescription">
            <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Subtipo de factura' | translate }}</span>
            <span class="col-7 col-lg-9">{{ invoice.invoiceSubtypeDescription}}</span>
        </ng-container>

        <ng-container *ngIf="invoice.invoiceDate">
            <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Fecha emisión' | translate }}</span>
            <span class="col-7 col-lg-9">{{ invoice.invoiceDate | date: 'dd/MM/yyyy' }}</span>
        </ng-container>

        <ng-container *ngIf="invoice.invoiceRegisterDate">
            <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Fecha registro' | translate }}</span>
            <span class="col-7 col-lg-9">{{ invoice.invoiceRegisterDate | date: 'dd/MM/yyyy' }}</span>
        </ng-container>
    </div>

    <div class="row mb-3 pt-3 arpada-section-border-top arpada-invoice-detail-section">
        <ng-container *ngIf="invoice.csiteDescription">
            <span class="arpada-title-3">{{ 'Detalle de factura' | translate }}</span>
            <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Ubicación' | translate }}</span>
            <span class="col-7 col-lg-9">{{ invoice.csiteDescription }}</span>
        </ng-container>

        <ng-container *ngIf="invoice.linkedWorksheets.length > 0">
            <span class="col-5 col-lg-3 arpada-bold-2">{{'Partes de trabajo' | translate}}</span>
            <span class="col-7 col-lg-9"><span *ngFor="let linkedWorksheets of invoice.linkedWorksheets; let i = index">{{linkedWorksheets?.externalUniquekey}} ({{linkedWorksheets.totalAmount | currency}})<span *ngIf="invoice.linkedWorksheets.length !== i + 1"> | </span></span>
            </span>
        </ng-container>

        <ng-container *ngIf="invoice.linkedProforma">
            <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Proforma asociada' | translate }}</span>
            <span class="col-7 col-lg-9">{{ invoice.linkedProforma.description }}</span>
        </ng-container>

        <ng-container *ngIf="invoice.baseAmount">
            <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Base imponible' | translate }}</span>
            <span class="col-7 col-lg-9">{{ invoice.baseAmount | currency }}</span>
        </ng-container>

        <ng-container *ngIf="getBaseExentaIVA() !== 0">
            <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Base imponible exenta de IVA o con IVA no sujeto' | translate }}</span>
            <span class="col-7 col-lg-9">{{ getBaseExentaIVA() | currency }}</span>
        </ng-container>

        <ng-container *ngIf="invoice.withholdingPerone">
            <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Retención' | translate }} ({{ invoice.withholdingPerone | percent }})</span>
            <span class="col-7 col-lg-9">{{ invoice.withholdingAmount | currency }}</span>
        </ng-container>

        <ng-container *ngIf="invoice.linkedProforma?.paymentMethod">
            <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Forma pago' | translate }}</span>
            <span class="col-7 col-lg-9">{{ invoice.linkedProforma.paymentMethod || '--' }}</span>
        </ng-container>

        <!-- <ng-container *ngIf="invoice.refundsInvoiceUuid || invoice.relatedToRefundUuid">
          <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Facturas asociadas' | translate }}</span>
          <span *ngIf="invoice.refundsInvoiceUuid || invoice.relatedToRefundUuid" class="col-7 col-lg-9" (click)="seeFacturaProntoPago()"><span class="ver_factura">Ver Factura</span></span>
      </ng-container> -->

        <ng-container *ngIf="invoice.promptPaymentAmount || invoice.relatedToRefundUuid">
            <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Abono pronto pago' | translate }}</span>
            <span class="col-7 col-lg-9" *ngIf="invoice.promptPaymentAmount; else detalle">{{ invoice.promptPaymentAmount | currency }}</span>
            <ng-template #detalle>
                <span *ngIf="invoice.relatedToRefundUuid" class="col-7 col-lg-9" (click)="seeFacturaProntoPago(this.linkedInvoice.id)"><span><img style="width: 18px; height: 18px; margin-right: 3px; margin-bottom: 3px;" src="../../../../../assets//svg/Icon-doc.svg" alt=""></span>
                <span class="ver_factura">{{this.linkedInvoice?.invoice_number}}</span>
                </span>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="(invoice.refundsInvoiceUuid && linkedInvoice) || invoice.refundsThatRefundMeUuids.length > 0">
            <span class="col-5 col-lg-3 arpada-bold-2">{{ getFacturasAsociadasLabel(invoice) | translate }}</span>
            <ng-container *ngIf="linkedRefundsInvoice?.length > 0; else uniqueInvoice">
                <span class="col-7 col-lg-9">
                      <span *ngFor="let item of linkedRefundsInvoice; let i = index" (click)="seeFacturaProntoPago(item.id)">
                        <span>
                        <img style="width: 18px; height: 18px; margin-right: 3px; margin-bottom: 3px;" src="../../../../../assets//svg/Icon-doc.svg" alt="">
                      </span>
                <span class="ver_factura">
                      {{item.invoice_number}}
                    </span><span *ngIf="linkedRefundsInvoice?.length !== i + 1" style="margin-left: 5px;"> | </span>
                </span>

                </span>
            </ng-container>
            <ng-template #uniqueInvoice>
                <span *ngIf="invoice.refundsInvoiceUuid && linkedInvoice" class="col-7 col-lg-9" (click)="seeFacturaProntoPago(this.linkedInvoice.id)"><span><img style="width: 18px; height: 18px; margin-right: 3px; margin-bottom: 3px;" src="../../../../../assets//svg/Icon-doc.svg" alt=""></span>
                <span class="ver_factura">{{this.linkedInvoice?.invoice_number}}</span>
                </span>
            </ng-template>
        </ng-container>

        <!-- <ng-container *ngIf="invoice.reverseVatLiability"> -->
        <span class="col-5 col-lg-3 arpada-bold-2">{{ 'Inversión sujeto pasivo' | translate }}</span>
        <span class="col-7 col-lg-9">{{ invoice.reverseVatLiability ? 'Sí' : 'No' | translate }}</span>
        <!-- </ng-container> -->


        <span *ngIf="!invoice.reverseVatLiability" class="col-5 col-lg-3 arpada-bold-2">
          {{ 'Total IVA' | translate }}
          <ng-container *ngIf="!multipleVatLines && !invoice.reverseVatLiability">
            ({{ totalPerc }})
          </ng-container>
        </span>
        <span *ngIf="!invoice.reverseVatLiability" class="col-7 col-lg-9">{{ totalIva || 0 | currency }}</span>
        <ng-container *ngIf="multipleVatLines">
            <ng-container *ngFor="let vatLine of invoice.vatLines">
                <ng-container *ngIf="displayIva(vatLine)">
                    <small class="col-5 col-lg-3" style="text-align: right;">{{ vatLine.vatTypeDescription }} {{ vatLine.vatPcentDescription }}</small>
                    <span class="col-7 col-lg-9">{{ vatLine.amount | currency }}</span>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="invoice.irpfValue && invoice.irpfValue !== 0">
          <span class="col-5 col-lg-3 arpada-bold-2">{{ 'IRPF' | translate }}</span>
          <span class="col-7 col-lg-9">{{ invoice.irpfValue | currency }}</span>
        </ng-container>


        <span class="col-12 arpada-bold-2 align-right">{{ 'Total factura' | translate }}</span>
        <span class="col-12 align-right arpada-total-amount-orange">{{ invoice.total_amount | currency }}</span>
    </div>

    <div *ngIf="invoice.invoiceFileUuid || invoice.invoiceAttachmentFileUuid" class="row pt-3 arpada-section-border-top">
        <button *ngIf="invoice.invoiceFileUuid" mat-stroked-button color="primary" class="col-11 col-sm-auto m-3 mb-1" style="text-align: left" (click)="downloadFile(invoice.invoiceFileUuid)">
      <mat-icon inline class="material-icons material-icons-outlined">cloud_download</mat-icon>
      Descargar documento</button>
        <button *ngIf="invoice.invoiceAttachmentFileUuid" mat-stroked-button color="primary" class="col-11 col-sm-auto m-3 mb-1" style="text-align: left" (click)="downloadFile(invoice.invoiceAttachmentFileUuid)">
      <mat-icon inline class="material-icons material-icons-outlined">cloud_download</mat-icon>
      Descargar documento adicional</button>
    </div>

</ng-template>


<!------------------------------------------------>
<!----- TEMPLATE PARA EVENTOS DE FACTURA --------->
<!------------------------------------------------>
<ng-template #invoiceEventsTemplate let-events>

    <div class="arpada-stepper-vertical">
        <ng-container *ngFor="let event of events">
            <div class="arpada-step-vertical">
                <div class="row">
                    <div class="arpada-step-title">{{ event.eventName }}</div>
                    <div *ngIf="event.eventDatetime" class="arpada-step-col row col-12 col-sm-3 d-flex d-sm-inline-block">
                        <span class="arpada-table-header-1 col-5 col-sm-auto d-flex d-sm-block">{{ 'Fecha' | translate}}</span>
                        <span class="col-7 col-sm-12">{{ event.eventDatetime | date: 'dd/MM/yyyy'}}</span>
                    </div>
                    <div *ngIf="event.actor" class="arpada-step-col row col-12 col-sm-4 d-flex d-sm-inline-block">
                        <span class="arpada-table-header-1 col-5 col-sm-auto d-flex d-sm-block">{{ 'Usuario' | translate}}</span>
                        <span class="col-7 col-sm-12">{{ event.actor }}</span>
                    </div>
                    <div *ngIf="event.comments" class="arpada-step-col row col-12 col-sm-5">
                        <span class="arpada-table-header-1 col-5 col-sm-auto">{{ 'Comentarios' | translate}}</span>
                        <span class="col-7 col-sm-12">{{ event.comments }}</span>
                    </div>
                </div>
            </div>

        </ng-container>
    </div>

</ng-template>
