import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'getPropertyFromString'
})
export class GetPropertyFromString implements PipeTransform {


  transform(item: any, key: string): any {
    let res;
    if (key) {
      const split = key.split('.');

      if (typeof item === 'string') {
        res = item;

      } else {
        res = split.reduce((result, currentValue) => {
          if (result && typeof result[currentValue] !== 'undefined') {
            const value = result[currentValue];
            const date = moment.isDate(value);
            if (date) {
              //Is a Date

              return moment(value).format('DD/MM/yyyy');
            }
            else {
              // Any other type
              return value;
            }

          } else {
            return null;
          }

        }, item);

      }

    }
    return res !== null ? res : '--';
  }
}
