
import { Order } from './order.model';

export class CSiteCriteria {
  order: Order[];
  offset: number;
  limit: number;
  id: number;
  ids: number[];
  uuid: string;
  uuids: string[];
  only_csites_with_supplies_contract_on_company_id: number;
  only_csites_with_pending_proformas_on_company_id: number;
  only_csites_with_pending_postsale_worksheets_on_company_id: number;
  only_csites_where_postsale_supplies_applies_on_company_id: number;
  only_csites_where_proforma_or_supplies_refunds_applies_on_company_id: number;
  only_csites_where_postsale_refunds_applies_on_company_id: number;
  only_csites_with_invoices_on_company_id: number;
  only_csites_with_invoices_for_company_which_is_invoiced_id: number;
  smart_text: string;
  parent_company_id: number;
  by_facturable_a_fecha: string;
  csite_type: string;

  constructor(data?: any) {
    this.order = (data && data.order) || [];
    this.offset = (data && data.offset) || 0;
    this.limit = (data && data.limit) || 0;
    this.id = (data && data.id) || 0;
    this.ids = (data && data.ids) || [];
    this.uuid = (data && data.uuid) || '';
    this.uuids = (data && data.uuids) || [];
    this.only_csites_with_supplies_contract_on_company_id =
      (data && data.only_csites_with_supplies_contract_on_company_id) || null;
    this.only_csites_with_pending_proformas_on_company_id =
      (data && data.only_csites_with_pending_proformas_on_company_id) || null;
    this.only_csites_with_pending_postsale_worksheets_on_company_id =
      (data &&
        data.only_csites_with_pending_postsale_worksheets_on_company_id) ||
      null;
    this.only_csites_where_postsale_supplies_applies_on_company_id =
      (data &&
        data.only_csites_where_postsale_supplies_applies_on_company_id) ||
      null;
    this.only_csites_where_proforma_or_supplies_refunds_applies_on_company_id =
      (data &&
        data.only_csites_where_proforma_or_supplies_refunds_applies_on_company_id) ||
      null;
    this.only_csites_where_postsale_refunds_applies_on_company_id =
      (data && data.only_csites_where_postsale_refunds_applies_on_company_id) ||
      null;
    this.only_csites_with_invoices_on_company_id =
      (data && data.only_csites_with_invoices_on_company_id) || null;
    this.only_csites_with_invoices_for_company_which_is_invoiced_id =
      (data && data.only_csites_with_invoices_for_company_which_is_invoiced_id) || null;
    this.smart_text = (data && data.smart_text) || null;
    this.parent_company_id = (data && data.parent_company_id) || null;
    this.by_facturable_a_fecha = (data && data.by_facturable_a_fecha) || null;
    this.csite_type = (data && data.csite_type) || null;
  }
}
