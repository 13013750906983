<mat-form-field class="w-100">
  <input matInput readonly class="pointer" (click)="attachInput.click()" [value]="htmlFileName" type="text"
      [placeholder]="placeholder || 'Upload file'" maxlength="255" />
  <button *ngIf="file && file.name" type="button" mat-icon-button matSuffix (click)="removeFileFromInput()"
      class="pointer">
      <mat-icon alt="Eliminar Archivo adjunto">clear</mat-icon>
  </button>
  <label matSuffix class="pointer" for="{{id}}">
      <mat-icon alt="Adjuntar archivo" (click)="attachInput.click()">attach_file</mat-icon>
  </label>
</mat-form-field>
<input #attachInput id="{{id}}" hidden type="file" class="w-100 custom-file-input" id="customFile"
  (change)="onChange($event)" [placeholder]="placeholder || 'Upload file'" [accept]="htmlExtensions"/>
