import { Pageable } from '../../../models/pageable.model';
import { InvoicingUserService } from '../../../services/invoicing-user.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { Usuario } from '../../../../shared/models/usuario.model';
import {
  Component,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { Columns, TableConfiguration } from 'src/app/shared/shared.module';
import { CustomPagination } from 'src/app/shared/components/table/table-configuration/custom-pagination';
import { UserRelCompanyGet } from 'src/app/shared/models/company.model';
import { UserRequestGet } from 'src/app/core/models/users.model';
import { Order } from 'src/app/core/models/order.model';

@Component({
  selector: 'app-users-registry-requests-list-internal',
  templateUrl: './users-registry-requests-list-internal.component.html',
  styleUrls: ['./users-registry-requests-list-internal.component.scss'],
})
export class UsersRegistryRequestsListInternalComponent implements OnInit {
  user: Usuario;
  mobile: boolean = false;
  loading: boolean = true;

  // CONFIGURACIÓN GENERAL
  DEFAULT_PAGE_SIZE = 10;
  DEFAULT_ORDER_DIRECTION = 'desc';
  DEFAULT_ORDER_NAME = 'id';

  // Configuración para la Tabla de resultados de búsqueda
  public tableConfiguration = new TableConfiguration();
  public columnDefs: Columns[] = [];
  public columnDefsCards: Columns[] = [];
  public columnDefsListadoCards: Columns[] = [];
  public columnDefsListadoExpandedCards: Columns[] = [];
  public rowData = Array<UserRequestGet>(); // SOLO DESARROLLO

  customPagination: CustomPagination = {} as CustomPagination;

  // Templates para tabla
  @ViewChild('company', { static: true }) companyTemplate: TemplateRef<any>;
  @ViewChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;

  // Templates para tarjetas
  @ViewChild('cardTemplate', { static: true }) cardTemplate: TemplateRef<any>;

  @HostListener('window:resize', ['$event'])
  private onResize(event): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;

  }

  constructor(
    private loginService: LoginService,
    private router: Router,
    private utils: UtilsService,
    private invoicingUserService: InvoicingUserService,
    private location: Location,
    private utilsService: UtilsService
  ) {
    this.mobile = window.innerWidth <= 1020 ? true : false;
    this.user = this.loginService.getUser();
  }

  ngOnInit(): void {
    this.configureTable();

    this.searchUsers();

    this.utilsService.toolbarTitle.emit({
      title: 'Solicitudes de alta',
      icon: null,
      back: true,
    });

  }

  seeDetails(e) {
    this.router.navigateByUrl('/home/users/new/' + e.id_original);
  }

  back() {
    this.location.back();
  }

  /* Método de configuración de la tabla */
  private configureTable(): void {
    this.initializePagination();

    this.tableConfiguration.details = true;
    this.tableConfiguration.sorting = true;
    this.tableConfiguration.delete = true;
    this.tableConfiguration.hideExport = true;
    this.tableConfiguration.pageSizes = [this.DEFAULT_PAGE_SIZE, 25, 50];
    this.tableConfiguration.pageable = true;
    this.tableConfiguration.pagination = true;
    this.tableConfiguration.defaultPageSize = this.customPagination.pageSize;

    this.tableConfiguration.hidenCardDetailsFields = [
      'request_datetime',
      'fullname',
      'contact_phone',
      'email',
      'Acciones',
    ];

    this.initializeColumnDefs();
    this.initializeColumnDefsListadoCards();
  }

  /* Método de inicialización de columnas tabla normal */
  private initializeColumnDefs(): void {
    this.columnDefs = new Array<Columns>();

    this.columnDefs.push({
      title: 'Icon',
      key: 'icon',
      orderDisabled: true,
      isCentered: true,
    });
    this.columnDefs.push({
      title: 'Empresa',
      key: 'company_vat_number',
      cellTemplate: this.companyTemplate,
    });
    this.columnDefs.push({ title: 'Fecha solicitud', key: 'id', isDate: true });
    this.columnDefs.push({
      title: 'Nombre Completo',
      key: 'firstname',
      isCentered: true,
    });
    this.columnDefs.push({ title: 'Email', key: 'email', isCentered: true });
    this.columnDefs.push({
      title: 'Teléfono',
      key: 'contact_phone',
      isCentered: true,
    });
    this.columnDefs.push({
      title: 'Acciones',
      key: 'userPermissions',
      cellTemplate: this.actionsTemplate,
      orderDisabled: true,
      isRight: true,
    });
  }

  private initializeColumnDefsListadoCards(): void {
    this.columnDefsListadoCards = new Array<Columns>();
    this.columnDefsListadoCards.push({
      title: 'Empresa',
      key: 'company_vat_number',
    });
    this.columnDefsListadoCards.push({
      title: 'Fecha solicitud',
      key: 'id',
      isDate: true,
    });
    this.columnDefsListadoCards.push({ title: 'Nombre', key: 'firstname' });
    this.columnDefsListadoCards.push({ title: 'Email', key: 'email' });
    this.columnDefsListadoCards.push({
      title: 'Teléfono',
      key: 'contact_phone',
    });
  }

  private initializePagination(persistOrder?: boolean): void {
    // Restaura paginación en tabla
    this.customPagination.sort = this.DEFAULT_ORDER_NAME;
    this.customPagination.order = this.DEFAULT_ORDER_DIRECTION;
    this.customPagination.totalItems = 0;
    this.customPagination.page = 1;
    this.customPagination.pageSize = 10;
    this.customPagination = Object.assign({}, this.customPagination);
  }

  /**
   * Control evento de paginación de tabla
   *
   * @param {*} e Nuevo estado de paginación
   */
  pagination(updatePagination) {
    this.customPagination = updatePagination;
    this.searchUsers();
  }

  sort() {
    document.getElementById('cardSortButton').click();
  }

  /**
   * Compone solicitud de facturas paginadas, ordenadas y filtradas.
   *
   */
  private searchUsers() {
    const pageable = new Pageable();

    pageable.offset =
      this.customPagination.pageSize * (this.customPagination.page - 1);
    pageable.limit = this.customPagination.pageSize;
    pageable.order = [
      new Order({
        direction: this.customPagination.order,
        name: this.utils.camelToSnake(this.customPagination.sort),
      }),
    ];


    this.invoicingUserService
      .getUserRegistryRequestPageable(pageable)
      .subscribe(
        (data) => {
          const list: UserRequestGet[] = [];
          if (data.values || []) {
            (data.values as []).forEach((userRequest: any) => {
              const invoice: UserRequestGet = new UserRequestGet(userRequest);

              // Hay que hacer esto ya que hay que utilizar el ordenar por id en el backend
              invoice.id = userRequest.request_datetime;
              invoice.firstname =
                (userRequest.lastname || '') +
                (userRequest.lastname2 ? ' ' + userRequest.lastname2 : '') +
                ', ' +
                userRequest.firstname;

              list.push(invoice);
            });

            if (list && list.length === 0) {
              // No hay solicitudes, se redirige a pantalla de usuarios

              this.router.navigateByUrl('/home/users');
            }

            this.rowData = list;

            // Refresca pie de tabla
            this.customPagination.totalItems = data.count;
          }
          this.loading = false;
        },
        (err) => (this.loading = false)
      );
  }

}
