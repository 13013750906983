<mat-toolbar class="toolbar sub-toolbar">
    <i *ngIf="utilsService.chooseDisplayIcon(subTipoFactura?.templateParameters?.step2IconCode || tipoFactura?.icon_code); else customIcon" class="material-icons material-icons-outlined invoice-icon" aria-hidden="true">{{subTipoFactura?.templateParameters?.step2IconCode || tipoFactura?.icon_code }}</i>
    <ng-template #customIcon>
        <img class="invoice-icon color-filter-black-green" style="width: 37px; height: 37px" src="{{'../../../../../../assets/icons/' + utilsService.getCustomIcon(subTipoFactura?.templateParameters?.step2IconCode || tipoFactura?.icon_code)}}" alt="">
    </ng-template>
    <h1 style="overflow: hidden; text-overflow: ellipsis;">{{subTipoFactura?.templateParameters?.step2HeaderText || tipoFactura?.description | translate}}</h1>
</mat-toolbar>

<section>
    <div class="row">
        <div class="col-md-12">
            <h5><b>{{'IDENTIFICACIÓN DE ' + getIdentificationTitle() | translate}}</b></h5>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-md-2">
            <span><b>{{getEmisorOrDestinatarioLabel() | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{getEmisorOrDestinatario()}}</span>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-md-2">
            <span><b>{{'CIF' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{getCifEmisorOrDestinatario()}}</span>
        </div>
    </div>

    <div class="row mt-4 mb-4">
        <div class="col-md-12">
            <mat-divider [inset]="true"></mat-divider>
        </div>
    </div>
</section>

<section>
    <div class="row">
        <div class="col-md-12">
            <h5><b>{{'IDENTIFICACIÓN DE FACTURA' | translate}}</b></h5>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-md-2">
            <span><b>{{'Nº de factura' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{invoiceDTO?.numeroFactura}}</span>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-md-2">
            <span><b>{{'Fecha factura' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{invoiceDTO?.fechaFactura | date: 'dd/MM/yyyy'}}</span>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-md-2">
            <span><b>{{'Tipo de factura' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{invoiceDTO?.tiposFactura?.description}}</span>
        </div>
    </div>

    <!-- *ngIf="invoiceDTO?.tiposFactura?.subtypes.length > 1" -->
    <div class="row mt-2">
        <div class="col-md-2">
            <span><b>{{'Subtipo' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{invoiceDTO?.subTipoFactura?.description}}</span>
        </div>
    </div>

    <div class="row mt-4 mb-4">
        <div class="col-md-12">
            <mat-divider [inset]="true"></mat-divider>
        </div>
    </div>
</section>

<section>
    <div class="row">
        <div class="col-md-12">
            <h5><b>{{'DETALLE DE FACTURA' | translate}}</b></h5>
        </div>
    </div>
    <!-- <div class="row mt-2" *ngIf="invoiceDTO?.causaDeAbono">
      <div class="col-md-2">
          <span><b>{{'Causa del abono' | translate}}</b></span>
      </div>
      <div class="col-md-10">
          <span>{{InvoiceSubtypes[invoiceDTO?.causaDeAbono]}}</span>
      </div>
  </div>
  <div class="row mt-2" *ngIf="invoiceDTO?.tipoDeFactura">
      <div class="col-md-2">
          <span><b>{{'Tipo de factura' | translate}}</b></span>
      </div>
      <div class="col-md-10">
          <span>{{InvoiceSubtypes[invoiceDTO?.tipoDeFactura]}}</span>
      </div>
  </div> -->
    <!-- <div class="row mt-2" *ngIf="invoiceDTO?.tiposFactura">
        <div class="col-md-2">
            <span><b>{{'Tipo de factura' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{invoiceDTO?.tiposFactura?.description}}</span><span *ngIf="invoiceDTO?.tiposFactura?.subtypes.length > 1">. {{invoiceDTO?.subTipoFactura?.description}}</span>
        </div>
    </div> -->

    <div class="row mt-2" *ngIf="invoiceDTO?.ubicacion">
        <div class="col-md-2">
            <span><b>{{'Ubicación' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{invoiceDTO?.ubicacion?.description}} ({{invoiceDTO?.ubicacion?.address}})</span>
        </div>
    </div>

    <div class="row mt-2" *ngIf="invoiceDTO?.partesDeTrabajo?.length > 0">
        <div class="col-md-2">
            <span><b>{{'Partes de trabajo' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span *ngFor="let parteDeTrabajo of invoiceDTO?.partesDeTrabajo; let i = index"> {{parteDeTrabajo?.external_uniquekey}} ({{parteDeTrabajo?.total_amount | currency}})<span *ngIf="invoiceDTO?.partesDeTrabajo.length !== i + 1"> | </span></span>
        </div>
    </div>

    <div class="row mt-2" *ngIf="invoiceDTO?.facturaAbono?.length > 0">
        <div class="col-md-2">
            <span><b>{{'Factura' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{invoiceDTO?.facturaAbono[0].invoice_number}}</span>
        </div>
    </div>

    <div class="row mt-2" *ngIf="invoiceDTO?.proforma?.description">
        <div class="col-md-2">
            <span><b>{{'Proforma asignada' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{invoiceDTO?.proforma?.description}}</span>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-md-2">
            <span><b>{{'Base imponible' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{invoiceDTO?.baseImponible | currency }}</span>
        </div>
    </div>

    <div class="row mt-2" *ngIf="getBaseExentaIVA() !== 0">
        <div class="col-md-2">
            <span><b>{{'Base imponible exenta de IVA o con IVA no sujeto' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{getBaseExentaIVA() | currency }}</span>
        </div>
    </div>

    <div class="row mt-2" *ngIf="invoiceDTO?.retencion">
        <div class="col-md-2">
            <span><b>{{'Retención' | translate }} {{invoiceDTO?.porcentajeRetencion / 100 | percent}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{invoiceDTO?.retencion | currency }}</span>
        </div>
    </div>

    <div class="row mt-2" *ngIf="invoiceDTO?.formaPago">
        <div class="col-md-2">
            <span><b>{{'Forma pago' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{invoiceDTO?.formaPago}}</span>
        </div>
    </div>

    <div class="row mt-2" *ngIf="invoiceDTO?.prontoPagoIncluido && invoiceDTO?.prontoPago">
        <div class="col-md-2">
            <span><b>{{'Pronto pago' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{invoiceDTO?.prontoPago | currency }}</span>
        </div>
    </div>

    <section *ngIf="invoiceDTO?.ivas && (invoiceDTO?.inversionSujetoPasivo === false || invoiceDTO?.inversionSujetoPasivo === undefined || invoiceDTO?.inversionSujetoPasivo === null)">
        <div class="row mt-2" *ngFor="let tipoIVA of invoiceDTO?.ivas">
            <ng-container *ngIf="tipoIVA?.value && tipoIVA?.value !== 0 && tipoIVA.per_one_factor !== 0">
                <div class="col-md-2">
                    <span><b>{{'Importe IVA' | translate}} {{tipoIVA.tipoDeIva.percent_description}}</b></span>
                </div>
                <div class="col-md-10">
                    <span>{{tipoIVA?.value | currency }}</span>
                </div>
            </ng-container>
        </div>
    </section>

    <div class="row mt-2" *ngIf="invoiceDTO?.inversionSujetoPasivo === true">
        <div class="col-md-2">
            <span><b>{{'Inversión sujeto pasivo' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{invoiceDTO?.inversionSujetoPasivo ? 'Sí' : 'No' }}</span>
        </div>
    </div>

    <div class="row mt-2" *ngIf="invoiceDTO?.totalIVA && (invoiceDTO?.inversionSujetoPasivo === false || invoiceDTO?.inversionSujetoPasivo === undefined || invoiceDTO?.inversionSujetoPasivo === null)">
        <div class="col-md-2">
            <span><b>{{'Total IVA' | translate}}</b></span>
        </div>
        <div class="col-md-10">
            <span>{{invoiceDTO?.totalIVA | currency }}</span>
        </div>
    </div>

    <div class="row mt-2" *ngIf="invoiceDTO?.irpf && invoiceDTO?.irpf !== 0">
      <div class="col-md-2">
          <span><b>{{'IRPF' | translate}}</b></span>
      </div>
      <div class="col-md-10">
          <span>{{invoiceDTO?.irpf | currency }}</span>
      </div>
  </div>

    <div class="row mt-2 totalFactura">
        <div class="col-md-12 ">
            <span><b>{{'Total factura' | translate}}</b></span>
        </div>
        <div class="col-md-12">
            <span class="arpada-total-amount-orange">{{invoiceDTO?.totalFactura | currency }}</span>
        </div>
    </div>

    <div class="row mt-4 mb-4">
        <div class="col-md-12">
            <mat-divider [inset]="true"></mat-divider>
        </div>
    </div>

</section>

<section *ngIf="invoiceDTO?.nombreFacturaAdjunta">
    <div class="row mt-2">
        <div class="col-md-12">
            <span><b>{{'Factura adjunta:' | translate}}</b></span>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12">
            <span class="pointer downloadDoc" (click)="downloadFile(invoiceDTO?.idFacturaAdjunta)">{{invoiceDTO?.nombreFacturaAdjunta}}
      <i class="material-icons  download-icon"
      matTooltip="{{'Descargar factura adjunta' | translate}}"
      matTooltipPosition="left"
      aria-hidden="true">cloud_download</i>
    </span>
        </div>
    </div>

</section>

<section *ngIf="invoiceDTO?.nombreFacturaAdjuntaAdicional">
    <div class="row mt-2">
        <div class="col-md-12">
            <span><b>{{'Factura adjunta adicional:' | translate}}</b></span>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12">
            <span class="pointer downloadDoc" (click)="downloadFile(invoiceDTO?.idFacturaAdjuntaAdicional)">{{invoiceDTO?.nombreFacturaAdjuntaAdicional}}
      <i class="material-icons  download-icon"
      matTooltip="{{'Descargar factura adjunta adicional' | translate}}"
      matTooltipPosition="left"
      aria-hidden="true">cloud_download</i>
    </span>
        </div>
    </div>
</section>

<!-- Adjuntos adicionales para pedidos/suministros de obra -->
<!-- <section *ngIf="invoiceDTO?.nombreAlbaranes">
  <div class="row mt-2">
      <div class="col-md-2">
          <span><b>{{'Albaranes:' | translate}}</b></span>
      </div>
  </div>
  <div class="row mt-2">
      <div class="col-md-12">
          <span class="pointer downloadDoc" (click)="downloadFile(invoiceDTO?.idAlbaranes)">{{invoiceDTO?.nombreAlbaranes}}
      <i class="material-icons  download-icon"
      matTooltip="{{'Descargar albaranes' | translate}}"
      matTooltipPosition="left"
      aria-hidden="true">cloud_download</i>
    </span>
      </div>
  </div>
</section> -->

<!-- Adjuntos adicionales para otras facturas y gastos adicionales -->
<!-- <section *ngIf="invoiceDTO?.nombreDocumentacionAdicional">
  <div class="row mt-2">
      <div class="col-md-10">
          <span><b>{{'Documentación adicional:' | translate}}</b></span>
      </div>
  </div>
  <div class="row mt-2">
      <div class="col-md-12">
          <span class="pointer downloadDoc" (click)="downloadFile(invoiceDTO?.idDocumentacionAdicional)">{{invoiceDTO?.nombreDocumentacionAdicional}}
      <i class="material-icons  download-icon"
      matTooltip="{{'Descargar documentación adicional' | translate}}"
      matTooltipPosition="left"
      aria-hidden="true">cloud_download</i></span>
      </div>
  </div>
</section> -->

<section>
    <div class="row mt-4 mb-4">
        <div class="col-md-12">
            <mat-divider [inset]="true" class="separador"></mat-divider>
        </div>
    </div>
</section>

<!-- FACTURA DE ABONO PRONTO PAGO PARA PROFORMA -->
<section *ngIf="invoiceDTO?.prontoPagoIncluido === false && invoiceDTO?.tieneProntoPago">
    <mat-toolbar class="toolbar sub-toolbar">
        <i class="material-icons material-icons-outlined proforma-icon" matTooltip="{{'Factura de abono por pronto pago' | translate}}" matTooltipPosition="left" aria-hidden="true">credit_score</i>
        <h1>{{'Factura de abono por pronto pago' | translate}}</h1>

    </mat-toolbar>


    <section>
        <div class="row mt-2">
            <div class="col-md-2">
                <span><b>{{'Nº de factura abono' | translate}}</b></span>
            </div>
            <div class="col-md-10">
                <span>{{invoiceDTO?.numeroFacturaAbono}}</span>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-2">
                <span><b>{{'Fecha factura abono' | translate}}</b></span>
            </div>
            <div class="col-md-10">
                <span>{{invoiceDTO?.fechaFacturaAbono | date: 'dd/MM/yyyy'}}</span>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-2">
                <span><b>{{'Base imponible' | translate}}</b></span>
            </div>
            <div class="col-md-10">
                <span> {{invoiceDTO?.baseImponibleAbono | currency }}</span>
            </div>
        </div>
        <div class="row mt-2" *ngIf="invoiceDTO?.inversionSujetoPasivo === false">
            <div class="col-md-2">
                <span><b>{{'Total IVA' | translate}}</b></span>
            </div>
            <div class="col-md-10">
                <span> {{invoiceDTO?.totalIVAAbono | currency }}</span>
            </div>
        </div>
        <div class="row mt-2 totalFactura">
            <div class="col-md-12">
                <span><b>{{'Total factura' | translate}}</b></span>
            </div>
            <div class="col-md-12">
                <span class="arpada-total-amount-orange"> {{invoiceDTO?.totalFacturaAbono | currency }}</span>
            </div>
        </div>
        <div class="row mt-4 mb-4">
            <div class="col-md-12">
                <mat-divider [inset]="true"></mat-divider>
            </div>
        </div>
    </section>

    <section>
        <div class="row mt-2">
            <div class="col-md-12">
                <span><b>{{'Factura de abono:' | translate}}</b></span>
            </div>
        </div>
        <div class="row mt-12">
            <div class="col-md-12">
                <span class="pointer downloadDoc" (click)="downloadFile(invoiceDTO?.idFacturaAdjuntaAbono)">{{invoiceDTO?.nombreFacturaAdjuntaAbono}}
        <i class="material-icons  download-icon"
        matTooltip="{{'Descargar factura abono' | translate}}"
        matTooltipPosition="left"
        aria-hidden="true">cloud_download</i>
      </span>
            </div>
        </div>
        <div class="row mt-4 mb-4">
            <div class="col-md-12">
                <b><mat-divider [inset]="true"></mat-divider></b>
            </div>
        </div>
    </section>
</section>


<!-- Button row -->
<section class="row footer">
    <div class="col ml-auto">
        <button mat-flat-button type="submit" class="btn-apply btn-next" (click)="confirm()">{{ 'Confirmar' | translate }}</button>
        <button mat-stroked-button matStepperPrevious color="primary" class="btn-apply btn-back mr-4">{{ 'Anterior' | translate }}</button>
    </div>
</section>
