import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ConfigService } from './../../shared/services/config.service';
import { Usuario } from './../../shared/models/usuario.model';
import { LoginService } from './../services/login.service';
import { PANTALLA_LOGIN } from 'src/app/app.constants';

@Injectable()
export class LoginRouteGuard implements CanActivate {
  funcionalidades = null;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private configService: ConfigService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let site = '';
    if (route.data) {
      site = route.data.site;
    }

    if (
      this.loginService.isLogged() &&
      this.loginService.getUser().rolesInCompany?.length > 0
    ) {
      return true;

      // Comprobación asíncrona:
      // return this.loadAccess(site, this.loginService.getUser());
    } else {
      this.goTo('login');
      return false;
    }
  }

  private goTo(rute: string): void {
    this.router.navigate([rute], { skipLocationChange: false });
  }

  /**
   * Get user access permissions.
   */
  public loadAccess(pag: string, user: Usuario): Observable<boolean> {
    return this.configService.obtenerFuncionalidades().pipe(
      map((res) => {
        this.funcionalidades = res;
        return this.returnAccess(pag, user);
      })
    );
  }

  private returnAccess(site: string, user: Usuario): boolean {
    return true;
  }

  private hasAccess(pag: string, rol: string): boolean {
    const datos = this.funcionalidades.filter((f) => pag === f.nombre)[0];
    if (!datos || !datos.perfiles) {
      return false;
    } else {
      if (datos.perfiles.indexOf(rol) > -1) {
        return true;
      } else {
        return false;
      }
    }
  }
}
