import { Captcha } from './../models/captcha.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatpchaService {

    // Ruta base.
    public baseURL: string = environment.apiBaseUrl;
    // Ruta específica.
    private urlApi = `/api/v1/captcha`;


    constructor(private http: HttpClient) { }

    /**
     * Carga los avisos de usuario para la utilidad especificada
     * por parámetro.
     */
    public obtenerConfig(): Observable<any> {
      const url = `${this.urlApi}`;

      return this.realizarPeticionGet(url);
    }

    /**
     * Realiza petición get.
     */
    public realizarPeticionGet(url: string): any {
      const urlFinal = `${this.baseURL}${url}`;

      const hs = new HttpHeaders().set('Content-Type', 'application/json' );

      return this.http.get(urlFinal, { headers: hs, withCredentials: false});
    }
}
