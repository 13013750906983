import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'icon'
})
export class ToIconPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) { }

  transform(value: boolean | string, args?: any): SafeHtml {
    const icon = value ? '<i class=\'fa fa-check-square-o\'></i>' : '<i class=\'fa fa-times\'></i>';

    return this.domSanitizer.bypassSecurityTrustHtml(icon);
  }
}
