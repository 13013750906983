import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-generic-icon',
  templateUrl: './generic-icon.component.html',
  styleUrls: ['./generic-icon.component.scss']
})
export class GenericIconComponent implements OnInit {

  @Input() cssClass: string;
  @Input() routerLink: any;
  @Input() tooltip: string;
  @Input() icon: string;

  @Output() actionPress = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public pressEvent(e: any): void {


    if (e) {
      if (e.code === 'Space' || e.code === 'Enter') {

      }
    }
  }

}
