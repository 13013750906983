<div class="background" (click)="close()"></div>
<div class="position-relative">
  <div class="dropdown-menu search-results">
    <ul class="list-group">
      <li class="list-group-item border-0" *ngFor="let item of data; let i = index" [attr.data-index]="i"
        [class.hover]="i == arrowKeyPosition" (click)="onClick(item)" (mouseover)="setArrowKeyPosition(i)">{{item.name}}
      </li>
    </ul>
  </div>
</div>
