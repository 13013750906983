import { InvoiceSearchCriteria } from './invoice_search_criteria.model';

export class InvoicesZip {
  invoice_criteria: InvoiceSearchCriteria
  folder_structure: string[];

  constructor(data?: any) {
    this.invoice_criteria = (data && data.invoiceCriteria) || null;
    this.folder_structure = ["construction_site","supplier"];
  }
}