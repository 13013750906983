import {
  NotificationService,
} from './../../../services/notification.service';
import { InvoicingUserService } from './../../../services/invoicing-user.service';
import { LocalStorageService } from './../../../../shared/services/local-storage.service';
import { Router } from '@angular/router';
import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  Columns,
  InputField,
  TableConfiguration,
} from 'src/app/shared/shared.module';
import {
  InvoiceEvent,
  InvoiceGet,
} from 'src/app/core/models/invoices/invoice-get';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { InvoiceCommandsService } from 'src/app/core/services/invoice-commands.service';
import {
  UserState,
  UserType,
  Usuario,
} from './../../../../shared/models/usuario.model';
import { LoginService } from './../../../services/login.service';
import { CustomPagination } from './../../../../shared/components/table/table-configuration/custom-pagination';
import { UserPageable, Pageable } from './../../../models/pageable.model';
import { InputFieldArray } from './../../../../shared/components/autoform/input-fields/input-fields';
import { AlertService } from './../../../../shared/services/alert.service';
import { SearchService } from './../../../services/search.service';
import { UserRelCompanyGet } from 'src/app/shared/models/company.model';
import { Notifications } from 'src/app/core/services/notification.service';
import { TablePaginationService } from 'src/app/shared/services/table-pagination.service';
import { FilterDataService } from 'src/app/shared/services/filter-data.service';
import { CustomFilters } from 'src/app/shared/components/table/table-configuration/custom-filters';
import { Order } from 'src/app/core/models/order.model';

@Component({
  selector: 'app-users-manage-filter-internal',
  templateUrl: './users-manage-filter-internal.component.html',
  styleUrls: ['./users-manage-filter-internal.component.scss'],
})
export class UsersManageFilterInternalComponent implements OnInit, OnDestroy {
  user: Usuario;

  // CONFIGURACIÓN GENERAL
  DEFAULT_PAGE_SIZE = 10;
  DEFAULT_ORDER_DIRECTION = 'desc';
  DEFAULT_ORDER_NAME = 'fullname';

  // Configuración de formulario de búsqueda
  fGroup: FormGroup;
  fGroupSmart: FormGroup; // Form individual de búsquedas por texto.
  inputs = new Array<InputField>();
  inputFieldsArray: InputFieldArray = new InputFieldArray();

  companies: { id; name: string; uuid: string }[] = [];
  userTypes: UserType[] = [];
  userRelCompanyList: any[];
  userStates: any[] = [];

  autoFilter: boolean = true;
  showFilters: boolean = false;
  showFilterSelection: boolean = false;
  filterMode = 1;
  mobile: boolean = false;
  positionRestored = false;
  previousPosition: number;
  loading = true;
  maxItems = false;
  private autoCompleteSub: Subscription = new Subscription();

  pendingRequest: boolean = false;

  // Templates para filtros
  @ViewChild('stateOption', { static: true })
  stateOptionTemplate: TemplateRef<any>;
  @ViewChild('clearFilters', { static: true })
  clearFiltersTemplate: TemplateRef<any>;

  // Configuración para la Tabla de resultados de búsqueda
  public tableConfiguration = new TableConfiguration();
  public columnDefs: Columns[] = [];
  public columnDefsCards: Columns[] = [];
  public columnDefsListadoCards: Columns[] = [];
  public columnDefsListadoExpandedCards: Columns[] = [];
  public rowData = Array<UserRelCompanyGet>();
  seeDetail: EventEmitter<any> = new EventEmitter();
  customPagination: CustomPagination = {} as CustomPagination;
  storedPagination: CustomPagination;
  customFilters: CustomFilters = {} as CustomFilters;
  storedFilters: CustomFilters;
  primeraEntrada = true;
  primeraEntradaAssetsCount = 0;

  // Templates para tabla
  @ViewChild('company', { static: true }) companyTemplate: TemplateRef<any>;
  @ViewChild('status', { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;

  // Templates para tarjetas
  @ViewChild('cardTemplate', { static: true }) cardTemplate: TemplateRef<any>;

  paginationSub: Subscription;
  filterDataSub: Subscription;
  private backFromFilterSub: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private searchService: SearchService,
    private invoicingUserService: InvoicingUserService,
    private notificationService: NotificationService,
    private alertService: AlertService,
    private loginService: LoginService,
    private invoiceCommandsService: InvoiceCommandsService,
    private utils: UtilsService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private tablePaginationService: TablePaginationService,
    private filterDataService: FilterDataService
  ) {
    this.mobile = window.innerWidth <= 1020 ? true : false;

    this.user = this.loginService.getUser();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;
  }

  @HostListener('window:scroll', ['$event'])
  private onScrollCard(event): void {
    this.customPagination.scrollY = window.scrollY;
    if (
      this.mobile &&
      window.innerHeight + window.scrollY === document.body.scrollHeight &&
      this.customPagination.scrollY !== 0 &&
      this.customPagination.totalItemsLoaded < this.customPagination.totalItems
    ) {
      this.pagination(this.customPagination);
    }
  }

  ngOnInit(): void {
    // Seteamos el storedPagination con el valor del currentData del servicio
    this.paginationSub = this.tablePaginationService.currentData.subscribe(
      (data) => {
        this.storedPagination = data;
      }
    );

    this.filterDataSub = this.filterDataService.currentData.subscribe(
      (data) => {
        this.storedFilters = data;
      }
    );

    this.backFromFilterSub = this.utils.backFromFilter.subscribe((data) => {
      if (data === 'filter') {
        this.showFilterSelection = false;
      }
      this.utils.toolbarTitle.emit({
        title: 'Gestión de Usuarios',
        icon: 'manage_accounts',
      });
    });

    this.autoCompleteSub = this.utils.autoComplateChange.subscribe((data) => {
      if (this.autoFilter) {
        if (data.value) {
          this.fGroup.controls[data.key].setValue(
            this.fGroup.controls[data.key].value.filter(
              (item) => item.id !== data.value?.id
            )
          );
        } else {
          this.fGroup.controls[data.key].setValue([]);
        }
        this.filterSubmit();
      }
    });

    // Configuración de formulario de búsqueda estricta
    this.setForm();
    // Configuración de formulario de búsqueda inteligente
    this.setFormSmart();

    this.configureTable();

    // Consultar solicitudes pendientes
    this.pendingRequestSearch();

    // Carga de assets
    this.loadUserStates();
    this.loadUserTypes();
    this.loadCompanies();

  }

  ngOnDestroy(): void {
    this.paginationSub.unsubscribe();
    this.autoCompleteSub.unsubscribe();
    this.backFromFilterSub.unsubscribe();
  }

  /**
   * Actualiza modo de filtrado a Estricto,
   * inicializa la paginación y solicita registros.
   *
   */
  filterSubmit() {
    this.customPagination.totalItemsLoaded = 0;
    this.maxItems = false;
    this.filterMode = 1;
    this.initializePagination();
    if (this.primeraEntrada) {
      if (this.mobile) {
        if (this.primeraEntradaAssetsCount === 3) {
          let lastPage = 0;
          if (this.mobile) {
            lastPage = this.searchService.lastPageUser
              ? this.searchService.lastPageUser
              : this.customPagination.page === 0
              ? 1
              : this.customPagination.page;
          } else {
            lastPage =
              this.customPagination.page === 0 ? 1 : this.customPagination.page;
          }
          for (let index = 0; index < lastPage; index++) {
            // get items
            this.searchService.lastPageUser = index;
            this.customPagination.page = index;
            this.searchUsers();
          }
          // En el listado infito al cargar todos los datos se va a la posición del usuario previamente seleccionado
          let scrollY = this.customPagination.scrollY;
          setTimeout(function () {
            window.scrollTo(window.scrollX, scrollY);
          }, 1500);
        }
      } else {
        this.searchUsers();
      }
    } else {
      this.customPagination.page = 1;
      this.searchUsers();
    }
  }

  /**
   * Actualiza modo de filtrado a Inteligente,
   * inicializa la paginación y solicita registros.
   *
   */
  smartFilterSubmit() {
    this.filterMode = 2;
    this.initializePagination();
    this.searchUsers();
  }

  /**
   * Inicializa campos de filtrado y solicita registros.
   *
   */
  filterReset() {
    this.filterMode = 1;
    this.fGroup.reset();

    if (!this.autoFilter) {
      this.initializePagination();
      this.filterSubmit();
    }
  }

  /**
   * Compone solicitud de facturas paginadas, ordenadas y filtradas.
   *
   */
  private searchUsers() {
    let offset = 0;

    if (this.mobile) {
      offset =
        this.customPagination.pageSize *
        (this.customPagination.page === 0
          ? this.customPagination.page
          : this.searchService.lastPageUser
          ? this.customPagination.page
          : this.customPagination.page - 1);
    } else {
      offset =
        this.customPagination.pageSize *
        (this.customPagination.page === 0
          ? this.customPagination.page
          : this.customPagination.page - 1);
    }

    if (this.primeraEntradaAssetsCount === 3) {
      const pageable = new UserPageable();

      pageable.smartText = this.fGroupSmart.controls.smartSearch.value;

      pageable.offset = offset;
      pageable.limit = this.customPagination.pageSize;
      pageable.smartFirstnameOrSurnames = this.fGroup.controls.fullname.value;
      pageable.contactEmail = this.fGroup.controls.userEmail.value;
      pageable.order = [
        new Order({
          direction: this.customPagination.order,
          name: this.utils.camelToSnake(this.customPagination.sort),
        }),
      ];

      // Agrega filtro inteligente o estricto según modo
      this.filterMode === 2
        ? this.assignSmartFormValues(pageable)
        : this.assignStrictFormValues(pageable);
      this.invoicingUserService
        .getUserRelCompanySearchPageable(pageable)
        .subscribe(
          (data) => {
            const list: UserRelCompanyGet[] = [];

            if (data.values || []) {
              (data.values as []).forEach((userRelCompany: any) => {
                const invoice: UserRelCompanyGet = new UserRelCompanyGet(
                  userRelCompany
                );

                list.push(invoice);
              });

              this.rowData = list;

              // Refresca pie de tabla
              // Añadimos los datos para guardar la paginacion
              if (this.customPagination.totalItemsLoaded === undefined) {
                this.customPagination.totalItemsLoaded = 0;
              }
              this.customPagination.totalItemsLoaded += this.rowData.length;
              this.customPagination.totalItems = data.count;
              this.maxItems =
                this.customPagination.totalItems <=
                this.customPagination.totalItemsLoaded;
              this.customPagination.parent = this.router.url.split('/')[2];
              this.tablePaginationService.setData(this.customPagination);
              // Añadimos los datos para guardar los filtros
              this.customFilters.parent = this.router.url.split('/')[2];
              this.filterDataService.setData(this.customFilters);
              this.primeraEntrada = false;
            }
            this.loading = false;
          },
          (err) => (this.loading = false)
        );
    }
  }

  assignStrictFormValues(pageable: UserPageable): void {

    if (this.customFilters?.filters === undefined) {
      this.customFilters.filters = new Map<string, string>();
    }

    // Empresa
    const companiesValue = this.fGroup.get('companies')?.value;
    const companies: Array<any> =
      companiesValue && companiesValue.length > 0 ? companiesValue : null;
    if (companies) {
      pageable.companyuuids = companies.map((s) => s.uuid);
      // Añadimos el filtro seleccionado a la lista de filtros para guardarla en el servicio para poder recuperarla luego
      // Guardamos el key del filtro con el mismo nombre del campo del formulario
      this.customFilters?.filters.set(
        'companies',
        companies.map((s) => s?.uuid).toString()
      );
    } else {
      this.customFilters?.filters.delete('companies');
    }

    // Estados de usuario
    const statesValue = this.fGroup.get('userStates')?.value;
    const states: UserState[] =
      statesValue && statesValue.length > 0 ? statesValue : null;
    if (states) {
      pageable.statusKeys = states.map((s) => s?.user_state_key);
      // Añadimos el filtro seleccionado a la lista de filtros para guardarla en el servicio para poder recuperarla luego
      // Guardamos el key del filtro con el mismo nombre del campo del formulario
      this.customFilters?.filters.set(
        'userStates',
        states.map((s) => s?.user_state_key).toString()
      );
    } else {
      this.customFilters?.filters.delete('userStates');
    }

    // Tipo de usuario
    const userTypeValue = this.fGroup.get('userType')?.value;
    const userType: Array<UserType> =
      userTypeValue && userTypeValue.length > 0 ? userTypeValue : null;
    if (userType) {
      pageable.userTypeKeys = userType.map((s) => s?.user_type_key);
      // Añadimos el filtro seleccionado a la lista de filtros para guardarla en el servicio para poder recuperarla luego
      // Guardamos el key del filtro con el mismo nombre del campo del formulario
      this.customFilters?.filters.set(
        'userType',
        userType.map((s) => s?.user_type_key).toString()
      );
    } else {
      this.customFilters?.filters.delete('userType');
    }

    // Nombre o apellidos
    const fullname = this.fGroup.get('fullname')?.value;
    if (fullname) {
      this.customFilters?.filters.set('fullname', fullname);
    } else {
      this.customFilters?.filters.delete('fullname');
    }

    // Correo Electronico
    const userEmail = this.fGroup.get('userEmail')?.value;
    if (userEmail) {
      this.customFilters?.filters.set('userEmail', userEmail);
    } else {
      this.customFilters?.filters.delete('userEmail');
    }
    // SMART SEARCH
    const smartSearchValue = this.fGroupSmart.get('smartSearch')?.value;
    if (smartSearchValue) {
      this.customFilters?.filters.set('smartSearch', smartSearchValue);
    } else {
      this.customFilters?.filters.delete('smartSearch');
    }
  }

  assignSmartFormValues(pageable: UserPageable): void {
    const smartSearchValue = this.fGroupSmart.get('smartSearch')?.value;
    pageable.smartText = smartSearchValue;
    if (smartSearchValue) {
      this.customFilters?.filters.set('smartSearch', smartSearchValue);
    } else {
      this.customFilters?.filters.delete('smartSearch');
    }
  }

  /**
   * Configuración de formulario de búsqueda inteligente al escribir.
   */
  private setFormSmart(): void {
    this.fGroupSmart = this.fb.group({
      smartSearch: '',
    });

    // Control de cambios en búsqueda
    this.fGroupSmart.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {

        this.filterMode = 2;
        this.customFilters.filters = new Map<string, string>();
        this.fGroup.reset(); // Limpia filtros generales
      });
    this.setSmartFilterValue('smartSearch');
  }

  /**
   * Configuración de formulario.
   */
  private setForm(): void {
    this.fGroup = this.fb.group(
      {
        companies: [[]],
        userStates: [],
        userType: [''],
        fullname: [''],
        userEmail: [''],
      },
      { updateOn: 'blur' }
    );

    // Control de eventos de formulario para búsqueda automática on blur
    // (Solo si la búsqueda automática está activa)
    this.fGroup.valueChanges.subscribe(() => {
      if (this.autoFilter) {
        this.tablePaginationService.resetStore.emit(true);
        this.filterSubmit();
      }
    });

    // Configuración de inputfields
    this.inputs.push(
      {
        key: 'companies',
        type: 'autocomplete-multi-chip',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        placeholder: 'Todas las empresas',
        controlValue: '',
        layout: 'col-12 col-sm-4 arpada-input-small',
        filteredOptions: (value) => of(this.companies),
      },
      {
        key: 'userStates',
        type: 'autocomplete-chip',
        label: 'Estado de factura',
        placeholder: 'Todos los estados',
        selectBindLabel: 'user_state_description',
        selectOptionLabel: 'description',
        controlValue: '',
        layout: 'col-12 col-sm-4 arpada-input-small',
        options: this.userStates,
        filteredOptions: (value) => of(this.userStates),
        selectOptionTemplate: this.stateOptionTemplate,
      },
      {
        key: 'userType',
        type: 'autocomplete-chip',
        label: 'Tipo de factura',
        placeholder: 'Todos los tipos',
        selectBindLabel: 'user_type_description',
        selectOptionLabel: 'user_type_description',
        controlValue: '',
        layout: 'col-12 col-sm-4 arpada-input-small',
        options: this.userTypes,
        filteredOptions: (value) => of(this.userTypes),
      },
      {
        key: 'fullname',
        type: 'text',
        label: 'Nombre de usuario',
        placeholder: 'Nombre o apellidos',
        controlValue: '',
        layout: 'col-12 col-sm-4 mt-3 arpada-input-small',
      },
      {
        key: 'userEmail',
        type: 'text',
        label: 'Correo electrónico',
        placeholder: 'Correo electrónico',
        controlValue: '',
        layout: 'col-12 col-sm-4 mt-3 arpada-input-small',
      },
      this.decideSearchButton(),
      {
        key: 'Limpiar',
        type: 'inputTemplate',
        label: 'Eliminar filtros',
        layout: 'col-12 col-sm-2 mt-4',
        icon: 'filter',
        inputTemplate: this.clearFiltersTemplate,
        function: () => this.filterReset(),
      }
    );

    this.setFiltersValues('');
  }

  private decideSearchButton(): InputField {
    let input: InputField;
    if (this.autoFilter) {
      input = {
        key: 'Buscar',
        type: 'blank',
        layout: 'col-12 col-sm-2 mt-3',
        icon: 'search',
      };
    } else {
      input = {
        key: 'Buscar',
        type: 'button',
        label: 'Buscar',
        layout: 'col-12 col-sm-2 mt-3',
        icon: 'search',
        function: () => this.filterSubmit(),
      };
    }
    return input;
  }

  private loadUserStates() {
    this.userStates = new Array<UserState>();
    this.userStates.push(
      new UserState({
        user_state_key: 'active',
        user_state_description: 'Activo',
      })
    );
    this.userStates.push(
      new UserState({
        user_state_key: 'inactive',
        user_state_description: 'Inactivo',
      })
    );
    this.setFiltersValues('userStates');
    this.primeraEntradaAssetsCount++;
    this.filterSubmit();
  }

  private loadUserTypes() {
    this.invoicingUserService.getUserTypes().subscribe(
      (res) => {
        const temp: UserType[] = [];
        (res.user_types || []).forEach((type) => temp.push(new UserType(type)));

        this.userTypes = [].concat(temp);
        this.setFiltersValues('userType');
        this.primeraEntradaAssetsCount++;
        this.filterSubmit();
      },
      (err) => console.log('Error loadInvoiceTypes')
    );
  }

  private loadCompanies() {
    this.searchService.getCompanies('').subscribe(
      (res) => {
        const temp: any[] = [];
        (res.values || []).forEach((emp) => {
          temp.push({
            id: emp.id,
            name: `${emp.description}`,
            uuid: emp.uuid,
            description: `${emp.vat_number} | ${emp.description}`,
          });
        });

        this.companies = [].concat(temp);
        this.setFiltersValues('companies');
        this.primeraEntradaAssetsCount++;
        this.filterSubmit();
      },
      (err) => console.log('Error loadCompanies')
    );
  }

  /* Método de configuración de la tabla */
  private configureTable(): void {
    this.initializePagination();

    this.tableConfiguration.details = true;
    this.tableConfiguration.sorting = true;
    this.tableConfiguration.delete = true;
    this.tableConfiguration.hideExport = true;
    this.tableConfiguration.pageSizes = [this.DEFAULT_PAGE_SIZE, 25, 50];
    this.tableConfiguration.pageable = true;
    this.tableConfiguration.pagination = true;
    this.tableConfiguration.defaultPageSize = this.customPagination.pageSize;

    this.tableConfiguration.hidenCardDetailsFields = [
      'status',
      'fullname',
      'contact_phone',
      'user_email',
      'Acciones',
    ];

    this.initializeColumnDefs();
    this.initializeColumnDefsListadoCards();
  }

  private initializePagination(persistOrder?: boolean): void {
    if (
      this.storedPagination?.parent === this.router.url.split('/')[2] &&
      this.storedPagination
    ) {
      this.customPagination = this.storedPagination;
    } else {
      this.customPagination.sort = this.DEFAULT_ORDER_NAME;
      this.customPagination.order = this.DEFAULT_ORDER_DIRECTION;
      this.customPagination.totalItems = 0;
      this.customPagination.page = 1;
      this.customPagination.pageSize = 10;
      this.customPagination = Object.assign({}, this.customPagination);
    }
  }

  private initializePagination2(persistOrder?: boolean): void {
    // versión móvil
    if (this.mobile) {
      this.backup();
    } else {
      this.restoreStatus();
    }
  }

  /* Método de inicialización de columnas tabla normal */
  private initializeColumnDefs(): void {
    this.columnDefs = new Array<Columns>();

    this.columnDefs.push({
      title: 'Icon',
      key: 'icon',
      orderDisabled: true,
      isCentered: true,
    });
    this.columnDefs.push({
      title: 'Empresa',
      key: 'company',
      cellTemplate: this.companyTemplate,
    });
    this.columnDefs.push({
      title: 'Estado',
      key: 'status',
      cellTemplate: this.statusTemplate,
    });
    this.columnDefs.push({
      title: 'Nombre Completo',
      key: 'fullname',
      isCentered: true,
    });
    this.columnDefs.push({
      title: 'Email',
      key: 'contact_email',
      isCentered: true,
    });
    this.columnDefs.push({
      title: 'Teléfono',
      key: 'contact_phone',
      isCentered: true,
    });
    this.columnDefs.push({
      title: 'Acciones',
      key: 'userPermissions',
      cellTemplate: this.actionsTemplate,
      orderDisabled: true,
      isRight: true,
    });
  }

  private initializeColumnDefsListadoCards(): void {
    this.columnDefsListadoCards = new Array<Columns>();
    this.columnDefsListadoCards.push({ title: 'Empresa', key: 'company' });
    this.columnDefsListadoCards.push({ title: 'Estado', key: 'status' });
    this.columnDefsListadoCards.push({ title: 'Nombre', key: 'fullname' });
    this.columnDefsListadoCards.push({ title: 'Email', key: 'contact_email' });
    this.columnDefsListadoCards.push({
      title: 'Teléfono',
      key: 'contact_phone',
    });
  }

  /**
   * Control evento de paginación de tabla
   *
   * @param {*} e Nuevo estado de paginación
   */
  pagination(updatePagination) {
    this.customPagination = updatePagination;
    this.searchUsers();
  }

  pendingRequestSearch() {
    this.invoicingUserService.getUserRegistryRequestCount().subscribe((res) => {
      if (res) {
        this.pendingRequest = true;
        this.notificationService.addNotificationByKey(
          Notifications.USER_REGISTRY_REQUEST
        );
        this.alertService.showDetailWarning(
          'Solicitudes de alta pendientes',
          'Tiene solicitudes pendientes de atender, diríjase a Solicitudes.'
        );
      } else {
        this.notificationService.removeNotificationByKey(
          Notifications.USER_REGISTRY_REQUEST
        );
      }
    });
  }

  seeDetails(e) {
    this.searchService.lastPageUser = this.customPagination.page;
    this.tablePaginationService.resetStore.emit(true);
    this.router.navigateByUrl('/home/users/detail/' + e.id);
  }

  disableUser(user: UserRelCompanyGet) {
    const emit = this.invoicingUserService
      .dismissUserConfirmation(user)
      .subscribe((res) => {
        this.invoicingUserService.dismissUser(user.id).subscribe(
          (data) => {
            if (data.status.startsWith('INVALID')) {
              if (data.status === 'INVALID') {
                this.alertService.showDetailError(
                  data.status,
                  data.error_message
                );
              } else {
                this.alertService.showDetailWarning(
                  data.status,
                  data.error_message
                );
              }
            } else {
              this.alertService.showDetailInfo(
                'Usuario desactivado',
                'Ha dado de baja el usuario satisfactoriamente. Si desea activarlo de nuevo, puede hacerlo accediendo al detalle del usuario y haciendo clic en el botón correspondiente.'
              );
              this.searchUsers();
            }
            emit.unsubscribe();
          },
          (err) =>
            this.alertService.showDetailError(
              'Error',
              'No se pudo realizar la solicitud'
            )
        );
      });
  }

  newRegistryRequest() {
    this.router.navigateByUrl('/home/users/new');
  }

  seeRegistryRequests() {
    this.router.navigateByUrl('/home/users/requests/pending');
  }

  getInvoiceLatestEvent(events: InvoiceEvent[]): InvoiceEvent[] {
    if (!events) return null;
    return [events[events.length - 1]];
  }

  cancelInvoice(invoice: InvoiceGet) {
    this.invoiceCommandsService.cancelInvoiceConfirmation(invoice);
  }

  sort() {
    document.getElementById('cardSortButton').click();
  }


  backup() {
    let lastPageSize = 10;
    let lastPage = 3;

    if (this.mobile) {
      // Restaura paginación cardview
      this.customPagination.sort = this.DEFAULT_ORDER_NAME;
      this.customPagination.order = this.DEFAULT_ORDER_DIRECTION;
      this.customPagination.totalItems = 0;
      this.customPagination.page = lastPage;
      this.customPagination.pageSize = 200;
      this.customPagination = Object.assign({}, this.customPagination);

      for (let index = 0; index < lastPage; index++) {
        // get items
        this.customPagination.page++;
      }
    } else {
      // Restaura paginación en tabla
      this.customPagination.sort = this.DEFAULT_ORDER_NAME;
      this.customPagination.order = this.DEFAULT_ORDER_DIRECTION;
      this.customPagination.totalItems = 0;
      this.customPagination.page = lastPage;
      this.customPagination.pageSize = lastPageSize;
      this.customPagination = Object.assign({}, this.customPagination);
    }
  }


  saveStatus(id: any) {
    // Guardar estado
    let status: any = new Object();
    status.form = this.fGroup.value;
    status.pagination = this.customPagination;
    status.detail = id;
    this.localStorageService.setItem('filter', JSON.stringify(status));
  }

  restoreStatus() {
    let status: any = this.localStorageService.getItemJson('filter');
    if (status) {
      Object.keys(status.form).map((k) =>
        this.fGroup.get(k).setValue(status.form[k])
      );
      this.customPagination = Object.assign({}, status.pagination);
      this.previousPosition = status.id;
    }
    this.localStorageService.removeItem('filter');
  }

  windowsChange(mobile: boolean) {
    this.mobile = mobile;
  }
  setSmartFilterValue(key: string) {
    if (
      this.storedFilters?.parent === this.router.url.split('/')[2] &&
      this.storedFilters
    ) {
      this.storedFilters.filters.forEach((value: string, key: string) => {
        // SMART SEARCH
        if (key === 'smartSearch') {
          this.fGroupSmart.get(key).setValue(value);
        }
      });
    }
  }

  private setFiltersValues(filter: string) {
    if (
      this.storedFilters?.parent === this.router.url.split('/')[2] &&
      this.storedFilters
    ) {
      this.showFilters = this.storedFilters.filters.size > 0 ? true : false;

      this.storedFilters.filters.forEach((value: string, key: string) => {
        // Empresa
        if (key === 'companies' && filter === 'companies') {
          if (value.split(',').length > 1) {
            let companies = value.split(',');
            let companieList = new Array<any>();
            companies.forEach((company) => {
              companieList.push(
                this.companies.find((is) => is.uuid.toString() === company)
              );
            });
            this.fGroup.get(key).setValue(companieList);
          } else {
            this.fGroup
              .get(key)
              .setValue([
                this.companies.find((s) => s.uuid.toString() === value),
              ]);
          }
        }

        // Estados de usuario
        if (key === 'userStates' && filter === 'userStates') {
          this.fGroup
            .get(key)
            .setValue([
              this.userStates.find(
                (s) => s.user_state_key.toString() === value
              ),
            ]);
        }

        // Tipo de usuario
        if (key === 'userType' && filter === 'userType') {
          this.fGroup
            .get(key)
            .setValue([
              this.userTypes.find((s) => s.user_type_key.toString() === value),
            ]);
        }

        // Nombre o apellidos
        if (key === 'fullname' && filter === '') {
          this.fGroup.get(key).setValue(value);
        }

        // Correo Electronico
        if (key === 'userEmail' && filter === '') {
          this.fGroup.get(key).setValue(value);
        }
      });
    }
  }

  showFilter() {
    this.utils.toolbarTitle.emit({
      title: 'Filtrar',
      icon: null,
      back: true,
      event: true,
    });
  }
}
