<div class="row m-3 justify-content-center">
  <mat-card class="col-12 col-md-6 col-lg-4 m-3 pt-0" >
    <div class="row justify-content-center">

      <ng-container *ngIf="loading; else verificado">
        <div class="row my-3">
          <h1 class="text-center">Verificando...</h1>
        </div>
      </ng-container>

    </div>
  </mat-card>
</div>

<ng-template #verificado>
  <div class="row my-3">
    <h2 class="text-center">Su email ha sido verificado correctamente</h2>
    <h4 class="text-center">Su solicitud está siendo revisada por el equipo responsable. Cuando se acepte su solicitud, recibirá un email con instrucciones sobre cómo acceder al Área Virtual.</h4>
    <!-- Submit button -->
    <button mat-flat-button color="primary"
    aria-label="Botón Iniciar Sesión"
    [routerLink]="'/'"
    class="col-12 arpada-button-large">{{ 'Ir al inicio' | translate }}</button>

  </div>
</ng-template>




