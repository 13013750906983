// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  apiBaseUrl: '',

  apiAuthenticationBaseUrl: 'https://integration-main-auth-service.dev-aks.grupoarpada.com',

  apiInvoicingBaseUrl: 'https://integration-main-invoicing-commands-service.dev-aks.grupoarpada.com/invoicing-user/commands',

  apiInvoicingUsersCommandBaseUrl: 'https://integration-main-invoicing-commands-service.dev-aks.grupoarpada.com/invoicing-user/commands',
  apiInvoicingCommandsBaseUrl: 'https://integration-main-invoicing-commands-service.dev-aks.grupoarpada.com/invoices/commands',
  apiStoredFilesCommandsBaseUrl: 'https://integration-main-invoicing-commands-service.dev-aks.grupoarpada.com/storedfiles/commands',
  
  apiInvoicingUsersQueryBaseUrl: 'https://integration-main-invoicing-query-service.dev-aks.grupoarpada.com/invoicing-users/queries',
  apiInvoicingQueryBaseUrl: 'https://integration-main-invoicing-query-service.dev-aks.grupoarpada.com/invoices/queries',
  apiSearchExternalBaseUrl: 'https://integration-main-invoicing-query-service.dev-aks.grupoarpada.com/invoices/external-entities',
  apiStoredFilesQueryBaseUrl: 'https://integration-main-invoicing-query-service.dev-aks.grupoarpada.com/storedfiles/queries',
  apiExcelGenerator: 'https://integration-main-invoicing-query-service.dev-aks.grupoarpada.com/invoices/excel-generators',

// loginWithCaptcha: true,

  warningRequestSetInteval: 30000,
  warningIntervalEnabled: true,

  app_key: '1c0a1701d81b4a00974c77e8f4b2e117',

  // captcha data
  site_key: '6LeVsGMbAAAAACchaARBS5-jOMU2mU6aR83p6qUi',

  captcha_key: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',


  msal_client_id: '5d7fe238-c11b-4935-9357-8be93b7600ff',
  msal_authority_url: 'https://login.microsoftonline.com/common/',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
