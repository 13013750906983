import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { CustomPagination } from '../table/table-configuration/custom-pagination';
import { AgTableColumnDefinition } from './column-definition.model';
import { Columns } from '../table/table-configuration/columns';

@Component({
  selector: 'app-ag-table',
  templateUrl: './ag-table.component.html',
  styleUrls: ['./ag-table.component.scss']
})
export class AgTableComponent implements OnInit {
  @Input() pagination: CustomPagination = new CustomPagination();
  @Input() isLoading: boolean;
  @Input() configuration: any;
  @Input() columnDefs: Array<AgTableColumnDefinition>;
  @Input() items: [];
  @Input() showCardView = false;
  @Input() frameworkComponents: any;
  @Input() gridOptions: any;
  @Output() rowDoubleClicked = new EventEmitter();
  @Output() deleteEmit = new EventEmitter<any>();
  @Output() paginationEmit = new EventEmitter<CustomPagination>();
  windowsWidth: number = window.innerWidth;

  private gridApi;
  private gridColumnApi;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.windowsWidth = event.target.innerWidth;
  }

  constructor() { }

  ngOnInit(): void {

    if (!this.configuration) {

      this.pagination.totalItems = this.items?.length || 0;
      this.configuration = { pagination: true, pageSizes: [20, 40, 60], defaultPageSize: 20 };
    }
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
    window.addEventListener('resize', function(): void {
      setTimeout(function(): void {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
  }

  exportCSV(): void {
    this.gridApi.exportDataAsCsv();
  }

  remove(it: any, i: number): void {
    const itemEmitted = { item: it, index: i };
    this.deleteEmit.emit(itemEmitted);
  }

  updatePagination(data: any): void {
    this.pagination.pageSize = data.pageSize != null ? data.pageSize : this.pagination.pageSize;
    this.pagination.page = data.pageIndex != null ? (data.pageIndex + 1) : this.pagination.page;
    this.pagination.sort = !data.active ? this.pagination.sort : data.active;
    this.pagination.serverSort = !data.active ? this.pagination.sort : data.active.includes('.') ? data.active.split('.')[0] : data.active;
    this.pagination.order = !!data.direction ? data.direction : this.pagination.order;
    this.pagination = { ...this.pagination };

    if (!this.configuration.disablePersistence) {
      this.paginationEmit.emit(this.pagination);
    }
  }
}
