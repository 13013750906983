<div style="z-index: 99; position: fixed; bottom: 100px; left: 0; background-color: beige; width: 100%; padding-left: 100px">
  CONFIGURATION: {{ _configuration  | json }} <BR>
  PAGINATION: {{ pagination | json }} <BR>
  INFO: {{ items.length }} <BR>
</div>

<!---------------->
<!-- TABLE VIEW -->
<!---------------->
<ng-container *ngIf="desktop">
  Vista tabla
  <table class="table" mat-table matSort [dataSource]="dataSource" matSortDisableClear class="w-100"
    multiTemplateDataRows>

    <ng-container *ngFor="let column of _configuration.tableFields">
      <ng-container *ngIf="column.key === 'text'">
        <ng-container matColumnDef="{{ column.key }}">

          <!-- Cabecera -->
          <th scope="col" class="py-2" mat-header-cell *matHeaderCellDef mat-sort-header disableClear
              aria-label="columna ordenación">
              <strong *ngIf="column.key !== 'icon'" >{{ column.title }}</strong>
          </th>

          <!-- Detalle -->
          <td *matCellDef="let item; let i = index" mat-cell class="no-height">
            <div *ngIf="column.key !== 'icon'" class="revert">
              <!-- adds button in case of details column -->
              <ng-container *ngIf="false else simpleColumn">
              </ng-container>
            </div>

            <ng-template #simpleColumn>
              <ng-container *ngIf="column.type === 'text'">
                {{ item | getPropertyFromString: column.key }}
              </ng-container>
            </ng-template>
          </td>


        </ng-container>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="h-40"></tr>

    <!-- ---- No Result ------>
    <!--
    <tr mat-footer-row *matFooterRowDef="['noResults']"
      [style.display]="dataSource.data.length === 0 ? 'table-row' : 'none'"></tr>

    <ng-container matColumnDef="noResults">
      <td [attr.colSpan]="displayedColumns.length" class="text-center" mat-footer-cell *matFooterCellDef>
        <div class="no-results text-center py-2" *ngIf="_configuration.noResult">
          <span *ngIf="!isLoading"
            class="font-weight-normal mb-0">{{'Sin elementos' | translate}}</span>
          <span *ngIf="isLoading" id="table-padding"></span>
        </div>
        <div class="text-center py-2" *ngIf="!_configuration.noResult">
          <h2 id="py-4"></h2>
        </div>
      </td>
    </ng-container> -->
  </table>

  <!----- Paginator ----->
  <mat-paginator *ngIf="_configuration.pagination" [hidePageSize]="false" [pageSize]="_configuration.pageSizesDefault"
    [pageSizeOptions]="_configuration.pageSizes || [25]" [length]="pagination.totalItems || (items && items.length)" showFirstLastButtons>
  </mat-paginator>

</ng-container>


<!---------------->
<!-- CARDS VIEW -->
<!---------------->
<ng-container *ngIf="!desktop">
  Vista tarjeta 222
</ng-container>

