<div class="row m-3 justify-content-center">
  <mat-card class="col-12 col-md-6 col-lg-4 m-3 pt-0" >
    <div class="row justify-content-center">

      <ng-container *ngIf="loading; else changePassword">
        <div class="row my-3">
          <h1 style="text-align: center;">Verificando...</h1>
        </div>
      </ng-container>

    </div>
  </mat-card>
</div>

<ng-template #changePassword>
  <div class="mt-3 col-12">
    <h2 style="text-align: center;">{{ tokenInfo.resetEmail }}</h2>

    <ng-container *ngIf="tokenInfo.isWelcomeMessage">
      <h3>{{tokenInfo.welcomeMessage}}</h3>
    </ng-container>


    <form [formGroup]="fGroup" (ngSubmit)="submit()" class="col-12">

      <!-- Generación de inputs -->
      <app-inputs-generator [fGroup]="fGroup" [inputFields]="inputs"></app-inputs-generator>

      <!-- Errores de formulario general -->
      <mat-error *ngIf="fGroup.errors?.passwordConfirmInvalid">
        {{ passwordValidationErrorMessage | translate }}
      </mat-error>

      <div class="col-12 mt-3 mb-3">
        <app-custom-inline-snackbar [data]="{ title: '', message: apiMessage}"></app-custom-inline-snackbar>
      </div>

      <!-- Submit button -->
      <button mat-flat-button color="primary"
      [disabled]="!fGroup.valid && tokenValid"
      aria-label="Botón actualizar contraseña"
      type="submit"
      class="col-12 arpada-button-large">{{ 'Update' | translate }}</button>
    </form>
  </div>
</ng-template>



