import { InputField } from 'src/app/shared/shared.module';
import { Validators, FormBuilder, FormGroup, AsyncValidatorFn, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { AlertService } from './../../../../shared/services/alert.service';
import { RecoverService } from './../../../services/recover.service';
import { Subscription, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { REQUIRED_MESSAGE } from 'src/app/app.constants';

export interface TokenInfo {
  resetToken: string;
  resetEmail: string;
  portalRedirectUrl: string;
  isWelcomeMessage: boolean;
  welcomeMessage: string;
}

@Component({
  selector: 'app-recover-reset',
  templateUrl: './recover-reset.component.html',
  styleUrls: ['./recover-reset.component.scss']
})
export class RecoverResetComponent implements OnInit, OnDestroy {

  fGroup: FormGroup;
  inputs = new Array<InputField>();
  private token: string;
  tokenValid: boolean = true;
  tokenInfo: TokenInfo = {} as TokenInfo;

  loading: boolean = true;

  apiMessage: string = '';
  passwordValidationErrorMessage: string;
  private sub: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private recoverService: RecoverService,
    private alertService: AlertService) {

    this.fGroup = fb.group({
      password: ['', Validators.required, this.validatePasswordAsync()],
      passwordCheck: ['', Validators.required]
    }, {
      asyncValidators: this.validatePasswordConfirmAsync('password', 'passwordCheck')
    });

    this.configurarInputs();
  }

  ngOnInit(): void {
    // Catch reset password token from URL.
    const sub = this.route.params.subscribe(
      params => {
        this.token = params['token'];
        this.validateToken();
        this.getPolicy();
      },
      err => console.log(err)
    );
    this.sub.add(sub);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private validateToken(): void {
    const sub = this.recoverService.validateToken(this.token).subscribe(
      res => {

        if (res.status === 'INVALID' && res.token_info !== undefined && res.token_info !== null) {
          this.alertService.showDetailError('Error', 'El enlace para actualizar su contraseña no es válido');
          this.tokenValid = false;
          this.router.navigateByUrl('/');
        } else {
          this.tokenInfo = {} as TokenInfo;
          this.tokenInfo.resetEmail = res.token_info.reset_email;
          this.tokenInfo.resetToken = res.token_info.reset_token;
          this.tokenInfo.portalRedirectUrl = res.token_info.portal_redirect_url;
          this.tokenInfo.isWelcomeMessage = res.token_info.is_welcome_message;
          this.tokenInfo.welcomeMessage = res.token_info.welcome_message;
          this.tokenValid = true;
        }
        this.loading = false;
      },
      err => {
        console.log(err);
        this.tokenValid = false;
        this.loading = false;
        this.router.navigateByUrl('/');
      }
    );
    this.sub.add(sub);
  }

  validatePasswordAsync() {
    return (input: FormControl) => {
      return timer(1000).pipe(
        switchMap(() => this.recoverService.validatePassword(input.value)),
        map(res => {
          return res.status !== 'INVALID' ? null : { passwordInvalid: true };
        })
      );
    };
  }

  validatePasswordConfirmAsync(password: string, passwordConfirm: string) {
    return (input: AbstractControl) => {

      const p1 = input.get(password).value;
      const p2 = input.get(passwordConfirm).value;

      // Optimización de validaciones contra el servidor
      if (p1 === '' || p2 === '') {
        return { passwordConfirmInvalid: true };
      }

      return timer(1000).pipe(
        switchMap(() => this.recoverService.validatePasswordConfirm(p1 ,p2)),
        map(res => {
          this.passwordValidationErrorMessage = res.validation_message;
          return res.status !== 'INVALID' ? null : { passwordConfirmInvalid: true };
        },
        err => {
          this.passwordValidationErrorMessage = null
          return { passwordConfirmInvalid: true }
        })
      );
    };
  }

  private getPolicy(): void {
    const sub = this.recoverService.getPolicy().subscribe(
      res => {
        this.apiMessage = res.password_policy_message;
        this.loading = false;
      },
      err => {
        console.log(err);
        this.alertService.showError('Ocurrió un error. Inténtelo de nuevo más tarde.');
        this.router.navigateByUrl('/');
        this.loading = false;
      }
    );
    this.sub.add(sub);
  }

  submit(): void {
    this.recoverService.changePassword(
      this.fGroup.get('password').value,
      this.fGroup.get('passwordCheck').value, this.tokenInfo.resetToken);
  }

  private configurarInputs(): void {
    /** Configuración de formulario */
    this.inputs.push(
      {
        key: 'password',
        type: 'password',
        label: 'Contraseña',
        controlValue: '',
        layout: 'col-12',
        hideRequired: true,
        validatorMessages: [
          {key: 'required', value: REQUIRED_MESSAGE},
          {key: 'passwordInvalid', value: 'Contraseña inválida'}],
        associatedButton: {
          icon: 'visibility',
          arialabel: 'Mostrar/ocultar',
          function: () => {
            this.inputs[0].type = this.inputs[0].type === 'text' ? 'password' : 'text';
            this.inputs[0].associatedButton.icon = this.inputs[0].associatedButton.icon === 'visibility' ? 'visibility_off': 'visibility';
          }
        }
      },
      {
        key: 'passwordCheck',
        type: 'password',
        label: 'Confirmación de contraseña',
        controlValue: '',
        layout: 'col-12',
        hideRequired: true,
        validatorMessages: [
          {key: 'required', value: REQUIRED_MESSAGE}],
        associatedButton: {
          icon: 'visibility',
          arialabel: 'Mostrar/ocultar',
          function: () => {
            this.inputs[1].type = this.inputs[1].type === 'text' ? 'password' : 'text';
            this.inputs[1].associatedButton.icon = this.inputs[1].associatedButton.icon === 'visibility' ? 'visibility_off': 'visibility';
          }
        }
      }
    );
  }

}
