<div clas="row align-items-center" *ngIf="showSpinner">
  <div class="col">
    <div id="global-spinner" class="spinner-wrapper">
      <div class="spinner-inner">
        <mat-progress-spinner aria-label="Cargando información" color="primary" mode="indeterminate">
        </mat-progress-spinner>

      </div>
    </div>
  </div>
</div>
