import { Component, Input, OnInit } from '@angular/core';

export interface InlineSnackbarData {
  title: string;
  message: string;
  class: string;
}

@Component({
  selector: 'app-custom-inline-snackbar',
  templateUrl: './custom-inline-snackbar.component.html',
  styleUrls: ['./custom-inline-snackbar.component.scss']
})
export class CustomInlineSnackbarComponent implements OnInit {

  @Input() data: InlineSnackbarData;

  constructor() { }

  ngOnInit(): void {
  }

}
