<mat-toolbar class="toolbar sub-toolbar" *ngIf="!mobile" [class.arpada-mobile-subtoolbar]="mobile">
  <a [routerLink]="" (click)="back()" style="text-decoration: none;" class="align-icon-text">
    <mat-icon *ngIf="mobile" style="font-size: 20px;" inline>arrow_back_ios</mat-icon>
    <h1>
      {{'Solicitudes de alta' | translate}}
    </h1>
  </a>
  <i *ngIf="!mobile" class="material-icons material-icons-outlined invoice-add-icon"
    aria-hidden="true">supervisor_account</i>
</mat-toolbar>

<ng-container *ngIf="!loading">

  <a [routerLink]="" (click)="back()" *ngIf="!mobile" class="arpada-bold-2 arpada-back-button align-icon-text">
    <mat-icon inline>arrow_back_ios</mat-icon>Volver
  </a>

  <div class="row justify-content-center"></div>

  <div class="row mt-0 mt-lg-4 justify-content-center">
  <mat-card class="mt-4 col-12 col-lg-10 col-xl-8" [class.arpada-mobile-version]="mobile">

      <ng-container *ngIf="mobile">
        <div align="end" class="filter-box">
          <button mat-button (click)="sort()" class="col-auto mt-3 align-icon-text"><mat-icon>import_export</mat-icon>{{ 'Ordenar' | translate }}</button>
        </div>
      </ng-container>

      <section class="mb-3">
        <app-custom-table
          [configuration]="tableConfiguration"
          [pagination]="customPagination"
          [columns]="columnDefs"
          [columnsCard]="columnDefsCards"
          [columnsListadoCards]="columnDefsListadoCards"
          [columnsExpandedCard]="columnDefsListadoExpandedCards"
          [fullCardTemplate]="cardTemplate"
          [items]="rowData"
          [allowExpand]="true"
          [keyCard]="'F.Factura'"
          (paginationEmit)="pagination($event)"></app-custom-table>
      </section>

    <!-- </div> -->
  </mat-card>
</div>
</ng-container>


<!----------------------------------------------------------->
<!-------- TEMPLATE PARA EMPRESA DE FACTURA EN TABLA -------->
<!----------------------------------------------------------->
<ng-template #company let-item let-column="column">
  <span class="d-block">{{item.foundbyvat_company_name}}</span>
  <small>{{item.company_vatnumber}}</small>
</ng-template>

<!----------------------------------------------------------------------------->
<!-------------- TEMPLATE PARA ACCIONES SOBRE USUARIO EN TABLA  --------------->
<!----------------------------------------------------------------------------->
<ng-template #actions let-item>
  <button mat-icon-button color="primary" (click)="seeDetails(item); $event.stopPropagation()" matTooltip="{{ 'Ver en detalle' | translate }}"><mat-icon>person_search</mat-icon></button>
  <!-- <button mat-icon-button color="primary" (click)="disableUser(item)" *ngIf="item.user_status === 'disabled' && user.permissionsInCompany.users_disable" matTooltip="{{'Desactivar'|translate}}">
    <mat-icon class="material-icons">person_off</mat-icon></button> -->
</ng-template>

<!----------------------------------------------------------------------------->
<!-------------- TEMPLATE PARA VISTA TARJETA DE CADA FILA --------------------->
<!----------------------------------------------------------------------------->
<ng-template #cardTemplate let-item>

  <div class="col-6 arpada-light-card-text text-left">
    <span class="align-icon-text" *ngIf="item.contact_phone">
      <mat-icon inline class="material-icons-outlined arpada-light-icon" style="font-size: 20px">phone</mat-icon>
      {{ item.contact_phone }}
    </span>
  </div>
  <div class="col-6" style="font-size: 14px; font-weight: bold; color: #3F6F55" align="end">
    <span>{{ item.request_datetime | date: 'dd/MM/yyyy'}}</span>
    <!-- <mat-icon matTooltip="{{ item.user_status === 'enabled' ? 'Activo' : 'Inactivo' | translate}}"
    class="material-icons material-icons-outlined arpada-user-status-icon-{{item.user_status | lowercase}}">
    account_circle</mat-icon> -->
  </div>

  <div (click)="seeDetails(item); $event.stopPropagation()">

  <div class="col-12 mb-2" style="text-align: center;">
    <!-- <span class="d-block">{{ item.foundbyvat_company_name }}</span>
    <span class="d-block">{{ item.company_vatnumber }}</span> -->

    <span class="d-block mt-3"><b>{{item.fullname}}</b></span>
    <div class="col-12" >

      <span class="align-icon-text arpada-light-card-text justify-content-center" >
        <mat-icon inline class="material-icons-outlined arpada-light-icon" style="font-size: 20px">email</mat-icon>
        {{ item.email }}
      </span>
    </div>
  </div>


  <ng-container>
    <div class="col-12 mx-auto my-1" style="border-top: 1px solid #EEE"></div>
      <div class="col-12">
        <div style="width: 100%; text-align: center;">
          <button mat-icon-button (click)="seeDetails(item);$event.stopPropagation()" matTooltip="{{ 'Ver solicitud' | translate }}">
            <mat-icon class="material-icons material-icons-outlined">person_search</mat-icon></button>
        </div>
      </div>
  </ng-container>
  </div>
</ng-template>
