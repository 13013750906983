<mat-toolbar class="toolbar sub-toolbar">
    <i *ngIf="utilsService.chooseDisplayIcon(subTipoFactura?.templateParameters?.step2IconCode || tipoFactura?.icon_code); else customIcon" class="material-icons material-icons-outlined invoice-icon" aria-hidden="true">{{subTipoFactura?.templateParameters?.step2IconCode || tipoFactura?.icon_code }}</i>
    <ng-template #customIcon>
        <img class="invoice-icon color-filter-black-green" style="width: 37px; height: 37px" src="{{'../../../../../../assets/icons/' + utilsService.getCustomIcon(subTipoFactura?.templateParameters?.step2IconCode || tipoFactura?.icon_code)}}" alt="">
    </ng-template>
    <h1 style="overflow: hidden; text-overflow: ellipsis;">{{subTipoFactura?.templateParameters?.step2HeaderText || tipoFactura?.description | translate}}</h1>
</mat-toolbar>

<app-inputs-generator [fGroup]="formGroup" [inputFields]="inputs"></app-inputs-generator>

<!-- Button row -->
<section class="row footer">
    <div class="col ml-auto">
        <button mat-flat-button matStepperNext type="submit" color="primary" class="btn-apply btn-next" [disabled]="!formGroup.valid || isLoadingFactura" (click)="submit()">{{ 'Siguiente' | translate }}</button>
        <button mat-stroked-button matStepperPrevious color="primary" class="btn-apply btn-back mr-4" (click)="backStepOne()">{{ 'Anterior' | translate }}</button>
    </div>
</section>

<!-- Divider Section -->
<ng-template #dividerSection let-row>
    <span style="font-weight: bold; color: #3F6F55 !important;">{{row.label}}</span>
    <mat-divider style="margin-top: 0.4rem;" [inset]="true"></mat-divider>
</ng-template>

<!----------------------------------------------------------->
<!-------- TEMPLATE PARA OBRA EN SELECT --------------------->
<!----------------------------------------------------------->
<ng-template #siteOption let-item>
    <span style="display: block">{{ item?.description }}</span>
    <small>{{ item?.address }}</small>
</ng-template>

<!----------------------------------------------------------->
<!-------- TEMPLATE PARA FACTURAS EN SELECT --------------------->
<!----------------------------------------------------------->
<ng-template #invoiceOption let-item>
    <span style="display: block">{{ item?.invoice_number}} ({{ item?.base_amount }} €)</span>
</ng-template>

<!----------------------------------------------------------->
<!-------- TEMPLATE PARA PARTES DE TRABAJO EN SELECT --------------------->
<!----------------------------------------------------------->
<ng-template #worksheetOption let-item>
    <span style="display: block">Parte {{ item?.external_uniquekey }} ({{ item?.total_amount | currency | thousandsPipe }} )</span>
    <small> Fecha alta: {{ item?.worksheet_date }}</small>
</ng-template>

<ng-template #fechaRecepcionObra let-row>
    <div class="col-md-12 retencion">
        <span *ngIf="fechaRecepcionObra !== null && fechaRecepcionObra !== undefined" class="retencionSpan">{{row.label}} {{row.defaultValue | date: 'dd/MM/yyyy'}}</span>
        <i *ngIf="row.infoIconShow" class="material-icons material-icons-outlined info-icon" matTooltip="{{row.infoIconText | translate}}" matTooltipPosition="below" aria-hidden="true">info
      </i>
    </div>
</ng-template>
