<mat-toolbar class="toolbar sub-toolbar" *ngIf="!mobile" [class.arpada-mobile-subtoolbar]="mobile">
  <a [routerLink]="" (click)="back()" style="text-decoration: none;" class="align-icon-text">
    <mat-icon *ngIf="mobile" style="font-size: 20px;" inline>arrow_back_ios</mat-icon>
    <h1>
      {{title | translate}}
    </h1>
  </a>
  <i *ngIf="!mobile" class="material-icons material-icons-outlined invoice-add-icon"
    aria-hidden="true">manage_accounts</i>
</mat-toolbar>


<a [routerLink]="" (click)="back()" *ngIf="!mobile" class="arpada-bold-2 arpada-back-button align-icon-text">
  <mat-icon inline>arrow_back_ios</mat-icon>Volver
</a>


<ng-container *ngIf="userType === 'internal'">
  <app-user-registry-detail-internal [mobile]="mobile"></app-user-registry-detail-internal>
</ng-container>

<ng-container *ngIf="userType === 'external'">
  <app-user-registry-detail-external [mobile]="mobile"></app-user-registry-detail-external>
</ng-container>
