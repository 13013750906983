import { Observable } from 'rxjs';
import { ValidatorFn } from '@angular/forms';
import { TemplateRef } from '@angular/core';

/**
 * InputField object to define an input and its configuration.
 */
export class InputField {
  /**
   * Key of the inputField, it will set the formControlName with this key.
   */
  key: string;

  keySelect?: string;

  /*
   * Label that will be showed in the form, only needed for select, Material
   * uses placeholder instead.
   */
  label?: string;

  /*
   * Type of the input.
   */
  type:
    | 'text'
    | 'textarea'
    | 'number'
    | 'selectV2'
    | 'select'
    | 'checkbox'
    | 'date'
    | 'dateFromTo'
    | 'dateRange'
    | 'streetFinder'
    | 'multiselect'
    | 'multiselectV2'
    | 'submit'
    | 'file'
    | 'fileDrop'
    | 'inputTemplate'
    | 'none'
    | 'noneArea'
    | 'blank'
    | 'time'
    | 'button'
    | 'password'
    | 'select-chip'
    | 'multiselect-chip'
    | 'autocomplete-chip'
    | 'autocomplete-multi-chip'
    | 'amount'
    | 'menu'
    | 'toggle'
    | 'divider';

  /*
   * Max length for inputs type text and number
   */
  maxLength?: number;

  /*
   * Extensions of the attach input
   */
  fileExtensions?: Array<string>;

  /*
   * Value to determinate autocomplete behaviou
   */
  autocomplete?: string;

  /*
   * Placeholder for the input (Used as a label in Material).
   */
  placeholder?: string;

  /*
   * Control value for the input, it can be a value or a
   * ControlValue object.
   */
  controlValue?: any | ControlValue;

  /*
  * Date filter is a function to filter the days the datepicker will show as enabled.
  This function is (date: Date): boolean => { return boolean }
  * if true it will show the day, if false, it will "disable" the day
  */
  dateFilter?: (date: Date) => boolean;

  /*
   * Value for the dateFrom input.
   */
  dateFromValue?: string | ControlValue;

  /*
   * Validators function for the dateFrom input.
   */
  dateFromValidators?: ValidatorFn | Array<ValidatorFn>;

  /*
  * Date filter is a function to filter the days the datepicker will show as enabled.
   This function is (date: Date): boolean => { return boolean }
  * if true it will show the day, if false, it will "disable" the day
  */
  dateFromFilter?: (date: Date) => boolean;

  /*
   * Value for the dateTo input.
   */
  dateToValue?: string | ControlValue;

  /*
   * Validators function for the dateTo input.
   */
  dateToValidators?: ValidatorFn | Array<ValidatorFn>;

  /*
  * Date filter is a function to filter the days the datepicker will show as enabled.
  This function is (date: Date): boolean => { return boolean }
  * if true it will show the day, if false, it will "disable" the day
  */
  dateToFilter?: (date: Date) => boolean;

  /*
   * Validators function for the input.
   */
  validators?: ValidatorFn | Array<ValidatorFn>;

  /** Array of posible messages for every validator added */
  validatorMessages?: Array<ValidatorMessages>;

  /*
   * Array of values to populate the select.
   */
  options?: Array<any>;

  filteredOptions?: (value: string) => Observable<any>;

  /*
   * Hide the first dummy option of the select.
   */
  hideFirstOption?: boolean;

  /*
   * Array of values to populate the select.
   */
  selectValues?: Array<any>;

  /*
   * Bind label for object arrays values to display a certain property
   * of the object in the input.
   */
  selectBindLabel?: string;

  /**
   * Bind label for option list of the object in the input.
   */
  selectOptionLabel?: string;

  /*
   * CSS Class will be applied to the input.
   */
  layout?: string;

  /**
   * template for input
   */
  inputTemplate?: TemplateRef<any>;

  /**
   * Params to pass to the template
   */
  templateParams?: any;

  /**
   * Flag for accesible components
   */
  accesibility? = false;

  /**
   * Button associated to a input
   */

  associatedButton?: any;

  /**
   * Button associated to a input
   */

  ariaLabel?: string;

  /**
   * Function associated to a button
   */

  function?: any;

  /**
   * Icon associated to a button
   */

  icon?: string;

  /**
   * Readonly attribute
   */

  readonly?: boolean;

  /**
   * Function that compares two diferents objects by value, not by reference
   *  Example:
   *  compareOrganismos = (obj1, obj2) => {
   *   return obj1 && obj2 && obj1.id === obj2.id;
   *  }
   * @type {*}
   * @memberof InputField
   */
  compareFunc?: any;

  /**
   * Associate inputfield to refress when this change.
   */
  correlated?: Array<string>;

  /**
   * Force text transform to uppercase typing.
   */
  forceUppercase?: boolean;

  loading?: boolean;

  hideRequired?: boolean;

  remove?: (e: Event) => void;

  selectOptionTemplate?: TemplateRef<any>;

  infoIconText?: string;

  infoIconShow?: boolean;

  focusOut?: Function = null;

  toggleChange?: (e: Event) => void;

  toggleText?: string;

  defaultValue?: any;

  display?: boolean = true;

  amountMode?: 'negative' | 'positive' | 'default';

  constructor(data?: Partial<InputField>) {
    this.key = (data && data.key) || null;
    this.label = (data && data.label) || null;
    this.type = (data && data.type) || 'textarea';
    this.maxLength = (data && data.maxLength) || null;
    this.fileExtensions = (data && data.fileExtensions) || null;
    this.placeholder = (data && data.placeholder) || null;
    this.controlValue = (data && data.controlValue) || null;
    this.dateFilter = (data && data.dateFilter) || null;
    this.dateFromValue = (data && data.dateFromValue) || null;
    this.dateFromValidators = (data && data.dateFromValidators) || null;
    this.dateFromFilter = (data && data.dateFromFilter) || null;
    this.dateToValue = (data && data.dateToValue) || null;
    this.dateToValidators = (data && data.dateToValidators) || null;
    this.dateToFilter = (data && data.dateToFilter) || null;
    this.validators = (data && data.validators) || null;
    this.validatorMessages = (data && data.validatorMessages) || null;
    this.options = (data && data.options) || null;
    this.hideFirstOption = (data && data.hideFirstOption) || null;
    this.selectValues = (data && data.selectValues) || null;
    this.selectBindLabel = (data && data.selectBindLabel) || null;
    this.layout = (data && data.layout) || null;
    this.inputTemplate = (data && data.inputTemplate) || null;
    this.templateParams = (data && data.templateParams) || null;
    this.accesibility = (data && data.accesibility) || null;
    this.associatedButton = (data && data.associatedButton) || null;
    this.ariaLabel = (data && data.ariaLabel) || null;
    this.function = (data && data.function) || null;
    this.icon = (data && data.icon) || null;
    this.readonly = (data && data.readonly) || null;

    this.correlated = (data && data.correlated) || null;
    this.forceUppercase = (data && data.forceUppercase) || false;
    this.loading = (data && data.loading) || false;
    this.hideRequired = (data && data.hideRequired) || false;
    this.infoIconText = (data && data.infoIconText) || null;
    this.infoIconShow = (data && data.infoIconShow) || false;
    this.focusOut = (data && data?.focusOut) || null;
    this.toggleChange = (data && data.toggleChange) || null;
    this.toggleText = (data && data.toggleText) || null;
    this.defaultValue = (data && data.defaultValue) || null;
    this.display = (data && data.display) || true;
    this.amountMode = (data && data.amountMode) || 'default';
  }
}
/** Type for validation messages */
export declare interface ValidatorMessages {
  key: string;
  value: string;
}

/**
 * Control value object which mimic the object used in FormControls constructor
 */
export class ControlValue {
  value: any;
  disabled: boolean;
}

export class InputFieldArray {
  inputFields: Array<InputField>;

  constructor() {
    this.inputFields = new Array<InputField>();
  }

  // Updates the SelectValues reference to a new object
  updateSelectValuesReference(key: string, values: any[]): void {
    const filteredObjects = this.inputFields.filter((item) => item.key === key);
    if (filteredObjects && filteredObjects.length > 0) {
      filteredObjects[0].selectValues = [].concat(values);
    }
  }
  updateOptionsValueReference(key: string, values: any[]): void {
    const filteredObjects = this.inputFields.filter((item) => item.key === key);
    if (filteredObjects && filteredObjects.length > 0) {
      filteredObjects[0].options = [].concat(values);
    }
  }
  updateControlValueReference(key: string, value: ControlValue | any): void {
    const filteredObjects = this.inputFields.filter((item) => item.key === key);
    if (filteredObjects && filteredObjects.length > 0) {
      filteredObjects[0].controlValue = {};
      filteredObjects[0].controlValue =
        value.value !== undefined ? value.value : value;
    }
  }

  getControlValue(key: string): any {
    const filteredObjects = this.inputFields.filter((item) => item.key === key);
    let value = null;
    if (filteredObjects && filteredObjects.length > 0) {
      value = filteredObjects[0].controlValue;
    }
    return value;
  }

  /**
   * Actualiza el estado readonly del componente
   */
  updateControlValueReadonly(key: string, value: boolean): void {
    const filteredObjects = this.inputFields.filter((item) => item.key === key);
    filteredObjects[0].readonly = value;
  }
}
