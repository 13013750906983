import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'delete-icon',
  templateUrl: './delete-icon.component.html',
  styleUrls: ['./delete-icon.component.css']
})
export class DeleteIconComponent implements OnInit {

  @Input() cssClass: string;
  @Input() routerLink: any;

  constructor() { }

  ngOnInit() {
  }

}
