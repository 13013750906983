export class UserRelCompanyGet {
  id: number;
  contact_email: string;
  company: number;
  company_name: string;
  company_vat_number: string;
  firstname: string;
  lastname: string;
  lastname2: string;
  fullname: string;
  contact_phone: string;
  job_position_in_company: string;
  user_roles: string[];
  user_permissions: any;
  user_type: 'internal' | 'external' | null;
  user_status: string;


  constructor(data?: any) {
    this.id = data && data.id;
    this.contact_email = data && data.contact_email;
    this.company = data && data.company;
    this.company_name = data && data.company_name;
    this.company_vat_number = data && data.company_vat_number;
    this.firstname = data && data.firstname;
    this.lastname = data && data.lastname;
    this.lastname2 = data && data.lastname2;
    this.fullname = data && data.fullname;
    this.contact_phone = data && data.contact_phone;
    this.job_position_in_company = data && data.job_position_in_company;
    this.user_roles = data && data.user_roles;
    this.user_permissions = data && data.user_permissions;
    this.user_type = data && data.user_type;
    this.user_status = data && data.user_status;
  }
}

export class UserRequestGet {
  id: number;
  id_original: number;
  firstname: string;
  lastname: string;
  lastname2: string;
  fullname: string;
  email: string;
  company_vatnumber: string;
  contact_phone: string;
  job_position_in_company: string;
  foundbyvat_company_id: string;
  foundbyvat_company_name: string;
  request_datetime: string;

  constructor(data?: any) {
    this.id = data && data.id;
    this.id_original = data && data.id;
    this.firstname = data && data.firstname;
    this.lastname = data && data.lastname;
    this.lastname2 = data && data.lastname2;
    this.email = data && data.email;
    this.contact_phone = data && data.contact_phone;
    this.job_position_in_company = data && data.job_position_in_company;
    this.company_vatnumber = data && data.company_vatnumber;
    this.foundbyvat_company_id = data && data.foundbyvat_company_id;
    this.foundbyvat_company_name = data && data.foundbyvat_company_name;
    this.request_datetime = data && data.request_datetime;

    this.getFullname();
  }

  getFullname() {
    this.fullname =  (this.lastname || '') + (this.lastname2 ? ' ' + this.lastname2 : '')
      + ', ' + this.firstname;
    return this.fullname;
  }
}
