import { Component, OnInit } from '@angular/core';
import { AccessibilityService } from '../../services/accessibility.service';

@Component({
  selector: 'app-accesibility',
  templateUrl: './accesibility.component.html',
  styleUrls: ['./accesibility.component.scss']
})
export class AccesibilityComponent implements OnInit {
  // Por defecto usamos la configuración no optimizada
  public accessible = false;

  constructor(private accService: AccessibilityService) { }

  ngOnInit(): void {
    this.accessible = this.accService.getAccessibillityConfig();
  }

  change(event): void {
    this.accessible = event.checked;
    this.accService.setAccessibillityConfig(event.checked);
    location.reload();
  }
}
