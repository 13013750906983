import { Component, OnInit, Input, ViewChild, EventEmitter, Output, SimpleChanges, SimpleChange, AfterViewInit, OnChanges } from '@angular/core';
import { FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { startWith, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-selectv2-solo',
  templateUrl: './selectv2-solo.component.html',
  styleUrls: ['./selectv2-solo.component.css']
})
export class Selectv2SoloComponent implements OnInit, AfterViewInit, OnChanges {
  form: FormGroup;
  selectControl: AbstractControl;

  @Input() label: string;
  @Input() bindLabel: string;
  @Input() items: Array<any> = [];
  @Input() layout = 'w-100';
  @Input() submitButtonText = 'Añadir';
  @Input() showButton = false;
  @Input() disabled = false;

  @Output() value: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatAutocompleteTrigger, { static: true }) trigger: MatAutocompleteTrigger;

  filteredOptions: Observable<any[]>;
  selectedOption: any;


  constructor() {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      inputValue: new FormControl(),
    });

    this.selectControl = this.form.controls['inputValue'];

    this.disabled ? this.selectControl.disable() : this.selectControl.enable();

    this.filteredOptions = this.selectControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

  }

  ngAfterViewInit(): void {
    this.trigger.panelClosingActions.subscribe(e => {
      if (!(e && e.source) && !this.selectedOption) {
        this.trigger.closePanel();
        this.clearFilter();
      }

      if (e && e.source) {
        this.selectedOption = e.source.value;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const disabled: SimpleChange = changes.disabled;

    if (this.selectControl && disabled && typeof disabled.currentValue === 'boolean') {
      disabled.currentValue ? this.selectControl.disable() : this.selectControl.enable();
    }
  }

  private _filter(value: string): string[] {

    this.selectedOption = null;

    const filterValue = (value && value[this.bindLabel] && (value[this.bindLabel] as string).toLowerCase())
      || value && value.toLowerCase() || '';

    if (value === '') {
      return this.items;
    } else {
      return this.items.filter(option => {
        const optionString = option[this.bindLabel] || option;
        return optionString.toLowerCase().includes(filterValue);
      });
    }
  }

  clearFilter(): void {
    if (this.selectControl && this.selectControl instanceof String) {
      this.selectControl.setValue('');
    } else {
      this.selectControl.setValue(null);
    }
  }

  //  returns the arrow function that resolves the display element of the selected object
  showDisplay = (option) => option && this.bindLabel && option[this.bindLabel] || option || '';

  optionSelected(formValue: any): void {
    if (!this.showButton) {
      this.value.emit(formValue.inputValue);
    }
  }

  submit(formValue: any): void {
    this.clearFilter();
    this.value.emit(formValue.inputValue);
  }

}
