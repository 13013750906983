import { Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

const NEGATIVE = 'negative';
const POSITIVE = 'positive';
const DEFAULT = 'default';

@Directive({
  selector: '[appAmount]',
})
export class AmountDirective {
  constructor(
    public ngControl: NgControl,
    @Inject(ElementRef) private readonly elementRef: ElementRef<HTMLInputElement>
    ) {}

  val: string;
  defaultRegex = /^-?(\d|\.)*(\,[0-9]{0,2})?$/;
  @Input() mode: 'negative' | 'positive' | 'default' = DEFAULT;

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    if (typeof event === 'string') {
      let text = event;

      if (text.charAt(text.length - 1) === '.') {
        const inputString = text.split('.');
        text = '';
        inputString.forEach((e, index) => {
          if (index === inputString.length - 2) {
            text += e + ',';
            return;
          } else {
            if (e !== '') {
              text += e + '.';
            }
          }
        });
      }

      this.onInputChange(text);
    }
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event) {
    const allowedChars = ['-', ',', '.', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if(!allowedChars.includes((event.key))) {
      event.preventDefault();
    }
    if(
      !(this.elementRef.nativeElement.selectionStart === 0 && this.elementRef.nativeElement.selectionEnd === event.currentTarget.value.length)
      ) {
      if (
        !new RegExp(this.defaultRegex).test(event.currentTarget.value + event.key)
      ) {
        event.preventDefault();
      }
    }

  }

  @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  onInputChange(event) {
    const regex =
      this.mode === POSITIVE ? /^(\d|\.)*(\,[0-9]{0,2})?$/ : this.defaultRegex;

    let inputString = event as string;

    if (this.mode === NEGATIVE) {
      if (
        inputString.charAt(0) !== '-' &&
        inputString.length > 0 &&
        inputString !== '0' &&
        inputString !== '00'
      ) {
        inputString = `-${inputString}`;
      }
    }

    this.val = inputString;

    if (this.val === ',') {
      this.val = '';
    }

    if (this.val === '-,') {
      this.val = '-';
    }

    if (this.val.length === 2) {
      if (this.val.charAt(0) === '0' && this.val.charAt(1) !== ',') {
        this.val = this.val.charAt(1);
      }
    }

    if (this.val.length === 3) {
      if (
        this.val.charAt(0) === '-' &&
        this.val.charAt(1) === '0' &&
        this.val.charAt(2) !== ','
      ) {
        this.val = `-${this.val.charAt(2)}`;
      }
    }

    if (this.val?.length > 0) {
      const amount = this.val.split(',');

      let decimals = '';
      let integers = '';

      if (amount.length === 2) {
        decimals = amount[1].slice(0, 2);
        integers = amount[0] + ',';
      } else {
        integers = amount[0];
      }

      if (integers.length > 3) {
        const digits = integers.replace(/(\.|\,)/g, '').split('');
        const resto = digits.length % 3;

        let restoIndex = 0;

        switch (resto) {
          case 1:
            restoIndex = 0;
            break;
          case 2:
            restoIndex = 1;
            break;
          case 0:
            restoIndex = 2;
            break;
        }

        integers = '';

        digits.forEach((c, index) => {
          if (
            index % 3 === restoIndex &&
            index !== digits.length - 1 &&
            c !== '-'
          ) {
            integers += c + '.';
          } else {
            integers += c;
          }
        });
      }

      if (amount.length === 2) {
        this.val = `${integers},${decimals}`;
      } else {
        this.val = integers;
      }
    }

    this.ngControl.valueAccessor.writeValue(this.val.replace(',,', ','));
  }
}
