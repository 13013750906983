import { FormControl, ValidationErrors } from '@angular/forms';

export class CustomValidators {

    public static noWhitespaceValidator(control: FormControl): ValidationErrors | null {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace || !control.value;
        return isValid ? null : { whitespace: true };
    }
}
