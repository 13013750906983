import { AlertService } from './../../../../shared/services/alert.service';
import { InvoicingUserService } from './../../../services/invoicing-user.service';
import {
  REQUIRED_MESSAGE,
  USER_EMAIL_MESSAGE,
  USER_MAXLENGTH_MESSAGE,
  USER_PHONE_MESSAGE,
  ACEPTAR,
  CANCELAR,
} from './../../../../app.constants';
import { SearchService } from './../../../services/search.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { of, Subscription } from 'rxjs';
import { InputField } from 'src/app/shared/shared.module';
import { Location } from '@angular/common';
import { debounceTime, map } from 'rxjs/operators';
import { UserNewGet } from 'src/app/core/models/users-new-get.model';
import { UserPageable } from 'src/app/core/models/pageable.model';
import { InvoicingUserNew, UserNew } from 'src/app/core/models/users-new.model';
import {
  InvoicingUserUpdate,
  UserUpdate,
} from 'src/app/core/models/users-update.model';
import { MensajeService } from './../../../../shared/services/mensaje.service';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { LoginService } from './../../../services/login.service';

@Component({
  selector: 'app-user-registry-detail-internal',
  templateUrl: './user-registry-detail-internal.component.html',
  styleUrls: ['./user-registry-detail-internal.component.scss'],
})
export class UserRegistryDetailInternalComponent implements OnInit {
  @Input() mobile: boolean = false;

  userId: string = null;
  user: UserNewGet;
  currentRoute: string;
  loginUser: Usuario;

  fGroup: FormGroup;
  private sub: Subscription = new Subscription();
  inputs = new Array<InputField>();

  companies: {
    id;
    name: string;
    description: string;
    vat_number: string;
  }[] = [];
  userTypes: Array<any>;
  invoices_permission: string;
  users_permission: string;
  onlyRead: boolean = false;
  userStatus: string;
  btnText: string;

  @ViewChild('invoicesPermissionSection', { static: true })
  invoicesPermissionSection: TemplateRef<any>;
  @ViewChild('usersPermissionSection', { static: true })
  usersPermissionSection: TemplateRef<any>;
  @ViewChild('userStatusSection', { static: true })
  userStatusSection: TemplateRef<any>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private location: Location,
    private alertService: AlertService,
    private searchService: SearchService,
    private mensajeService: MensajeService,
    private invoicingUserService: InvoicingUserService,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.fGroup = new FormGroup({});
    this.invoices_permission = '';
    this.users_permission = '';

    this.loginUser = this.loginService.getUser();

    // Recuperar id de factura desde path
    this.route.params.subscribe((params) => {
      this.userId = params.id;

      this.currentRoute = this.router.url.split('/')[3];

      if (this.userId && this.currentRoute === 'detail') {

        this.invoicingUserService
          .getUserRelCompany(Number(this.userId))
          .subscribe((resp) => {
            if (resp) {
              this.user = new UserNewGet(resp.values[0]);
              this.btnText =
                this.user.status === 'active'
                  ? 'Actualizar'
                  : 'Activar y actualizar';
            }
            this.setForm();
            this.setUserDataForm();

            if (!this.user?.actions_permission.user_editable) {
              this.setReadOnly();
            }
          });
      } else if (this.userId && this.currentRoute === 'new') {
        this.btnText = 'Guardar';
        this.invoicingUserService
          .getUserRegistryRequest(this.userId)
          .subscribe((resp) => {
            if (resp) {
              this.user = new UserNewGet(resp.values[0]);
            }
            this.setForm();
            this.setUserDataForm();
          });
      } else {
        this.btnText = 'Guardar';
        this.setForm();
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  setUserDataForm() {
    this.fGroup.get('firstname').setValue(this.user?.firstname);
    this.fGroup.get('lastname').setValue(this.user?.lastname);
    this.fGroup.get('lastname2').setValue(this.user?.lastname2);
    this.fGroup.get('user_email').setValue(this.user?.user_email);
    this.fGroup.get('contact_phone').setValue(this.user?.contact_phone);
    this.fGroup
      .get('job_position_in_company')
      .setValue(this.user?.job_position_in_company);
    this.fGroup.get('user_status').setValue(this.user?.status);
    this.inputs.find((i) => i.key === 'user_email').readonly = true;

    this.userStatus = this.user?.status;

    if (this.currentRoute !== 'new') {
      if (
        this.user?.user_permissions.invoices_create &&
        this.user?.user_permissions.invoices_edit &&
        this.user?.user_permissions.invoices_view
      ) {
        this.invoices_permission = 'gestionar';
      }
      if (
        !this.user?.user_permissions.invoices_create &&
        !this.user?.user_permissions.invoices_edit &&
        this.user?.user_permissions.invoices_view
      ) {
        this.invoices_permission = 'ver';
      }
      if (
        !this.user?.user_permissions.invoices_create &&
        !this.user?.user_permissions.invoices_edit &&
        !this.user?.user_permissions.invoices_view
      ) {
        this.invoices_permission = 'sinPermiso';
      }

      if (
        this.user?.user_permissions.user_registry_request &&
        this.user?.user_permissions.users_disable &&
        this.user?.user_permissions.users_view
      ) {
        this.users_permission = 'gestionar';
        this.inputs.find((i) => i.key === 'company').readonly = false;
      }
      if (
        !this.user?.user_permissions.user_registry_request &&
        !this.user?.user_permissions.users_disable &&
        this.user?.user_permissions.users_view
      ) {
        this.users_permission = 'ver';
      }
      if (
        !this.user?.user_permissions.user_registry_request &&
        !this.user?.user_permissions.users_disable &&
        !this.user?.user_permissions.users_view
      ) {
        this.users_permission = 'sinPermiso';
      }
    }
  }

  setReadOnly() {
    this.onlyRead = true;

    this.inputs.find((i) => i.key === 'user_type').readonly = true;
    this.inputs.find((i) => i.key === 'firstname').readonly = true;
    this.inputs.find((i) => i.key === 'lastname').readonly = true;
    this.inputs.find((i) => i.key === 'lastname2').readonly = true;
    this.inputs.find((i) => i.key === 'user_email').readonly = true;
    this.inputs.find((i) => i.key === 'contact_phone').readonly = true;
    this.inputs.find((i) => i.key === 'job_position_in_company').readonly =
      true;
    this.inputs.find((i) => i.key === 'company').readonly = true;
    this.inputs.find((i) => i.key === 'invoices_permission').readonly = true;
    this.inputs.find((i) => i.key === 'users_permission').readonly = true;
  }

  /**
   * Configuración de formulario.
   */
  private setForm(): void {
    this.fGroup = this.fb.group({
      user_type: ['', [Validators.required]],
      firstname: ['', [Validators.required, Validators.maxLength(200)]],
      lastname: ['', [Validators.required, Validators.maxLength(200)]],
      lastname2: ['', [Validators.maxLength(200)]],
      user_email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$'),
        ],
      ],
      contact_phone: [
        '',
        [Validators.required, Validators.pattern('([0-9][ -]*){9}')],
      ],
      company: ['', [Validators.required]],
      job_position_in_company: [
        '',
        [Validators.required, Validators.maxLength(200)],
      ],
      invoices_permission: ['sinPermiso', Validators.required],
      users_permission: ['sinPermiso', Validators.required],
      user_status: [''],
    });

    // Configuración de inputfields
    this.inputs.push(
      {
        key: 'user_status',
        type: 'inputTemplate',
        layout: 'col-12',
        icon: 'filter',
        inputTemplate: this.userStatusSection,
      },
      {
        key: 'user_type',
        type: 'autocomplete-chip',
        label: 'Tipo de usuario',
        controlValue: '',
        layout: 'col-md-12 col-lg-6',
        readonly: false,
        selectBindLabel: 'user_type_description',
        selectOptionLabel: 'user_type_description',
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
        filteredOptions: (value: string) => {
          return this.invoicingUserService.getUserTypes().pipe(
            map((resp) => {
              if (resp) {
                this.userTypes = resp.user_types;
              }
              if (this.userId && this.currentRoute !== 'new') {
                this.fGroup
                  .get('user_type')
                  .setValue([
                    this.userTypes.find(
                      (ut) => ut.user_type_key === this.user?.user_type
                    ),
                  ]);
              }
              return this.userTypes;
            })
          );
        },
      },
      {
        key: 'firstname',
        type: 'text',
        label: 'Nombre',
        controlValue: '',
        layout: 'col-md-12 col-lg-6',
        readonly: false,
        validatorMessages: [
          { key: 'required', value: REQUIRED_MESSAGE },
          { key: 'maxlength', value: USER_MAXLENGTH_MESSAGE },
        ],
      },
      {
        key: 'lastname',
        type: 'text',
        label: 'Primer apellido',
        controlValue: '',
        layout: 'col-md-12 col-lg-6',
        readonly: false,
        validatorMessages: [
          { key: 'required', value: REQUIRED_MESSAGE },
          { key: 'maxlength', value: USER_MAXLENGTH_MESSAGE },
        ],
      },
      {
        key: 'lastname2',
        type: 'text',
        label: 'Segundo apellido',
        controlValue: '',
        layout: 'col-md-12 col-lg-6',
        readonly: false,
        validatorMessages: [
          { key: 'maxlength', value: USER_MAXLENGTH_MESSAGE },
        ],
      },
      {
        key: 'user_email',
        type: 'text',
        label: 'Correo electrónico',
        controlValue: '',
        layout: 'col-md-12 col-lg-6',
        readonly: false,
        validatorMessages: [
          { key: 'required', value: REQUIRED_MESSAGE },
          { key: 'pattern', value: USER_EMAIL_MESSAGE },
        ],
      },
      {
        key: 'contact_phone',
        type: 'text',
        label: 'Teléfono',
        controlValue: '',
        layout: 'col-md-12 col-lg-6',
        readonly: false,
        validatorMessages: [
          { key: 'required', value: REQUIRED_MESSAGE },
          { key: 'pattern', value: USER_PHONE_MESSAGE },
        ],
      },
      {
        key: 'company',
        type: 'autocomplete-chip',
        label: 'CIF',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        placeholder: 'Todas las empresas',
        controlValue: '',
        readonly: false,
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
        layout: 'col-md-12 col-lg-12',
        filteredOptions: (value: string) => {
          return this.searchService.getCompanies(value).pipe(
            debounceTime(2000),
            map((res) => {
              let empresas = res.values as Array<any>;
              this.companies = empresas.map(function (emp) {
                return {
                  id: emp.id,
                  name: `${emp.description}`,
                  description: `${emp.description} | ${emp.vat_number}`,
                  vat_number: `${emp.vat_number}`,
                };
              });
              if (this.userId) {
                const company = this.companies.find(
                  (c) => c.vat_number == this.user?.company_vat_number
                );
                if (company) {
                  this.fGroup.get('company').setValue([company]);
                }
              }
              return this.companies;
            })
          );
        },
      },
      {
        key: 'job_position_in_company',
        type: 'text',
        label: 'Puesto en su empresa',
        placeholder: '',
        controlValue: '',
        readonly: false,
        layout: 'col-md-12 col-lg-12',
        validatorMessages: [
          { key: 'required', value: REQUIRED_MESSAGE },
          { key: 'maxlength', value: USER_MAXLENGTH_MESSAGE },
        ],
      },
      {
        key: 'invoices_permission',
        type: 'inputTemplate',
        controlValue: '',
        layout: 'col-12',
        inputTemplate: this.invoicesPermissionSection,
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
      },
      {
        key: 'users_permission',
        type: 'inputTemplate',
        controlValue: '',
        layout: 'col-12',
        inputTemplate: this.usersPermissionSection,
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
      }
    );
  }

  back() {
    this.location.back();
  }

  submit() {
    if (this.userId && this.currentRoute === 'detail') {
      this.updateUser();
    } else {
      this.saveNewUser();
    }
  }

  updateUser() {
    let updateUser = new UserUpdate();
    updateUser.id = this.userId;
    updateUser.firstname = this.fGroup.get('firstname').value;
    updateUser.lastname = this.fGroup.get('lastname').value;
    updateUser.lastname2 = this.fGroup.get('lastname2').value;
    updateUser.fullname =
      updateUser.firstname +
      ' ' +
      updateUser.lastname +
      ' ' +
      updateUser.lastname2;
    updateUser.user_email = this.fGroup.get('user_email').value;
    updateUser.contact_phone = this.fGroup.get('contact_phone').value;
    updateUser.user_type = this.fGroup.get('user_type').value[0].user_type_key;
    updateUser.status = 'active';
    updateUser.company_id = this.fGroup.get('company').value[0].id;
    updateUser.company_vat_number =
      this.fGroup.get('company').value[0].vat_number;
    updateUser.job_position_in_company = this.fGroup.get(
      'job_position_in_company'
    ).value;

    let userPermissions = new Array<string>();
    switch (this.invoices_permission) {
      case 'ver':
        userPermissions.push('invoicing_invoices_readonly');
        break;
      case 'gestionar':
        userPermissions.push('invoicing_invoices_full');
        break;
    }

    switch (this.users_permission) {
      case 'ver':
        userPermissions.push('invoicing_users_readonly');
        break;
      case 'gestionar':
        userPermissions.push('invoicing_users_full');
        break;
    }
    updateUser.user_roles = userPermissions;

    let invoicingUserUpdate = new InvoicingUserUpdate();
    invoicingUserUpdate.invoicing_user = updateUser;

    this.invoicingUserService
      .updateUser(invoicingUserUpdate)
      .subscribe((resp) => {
        if (resp.status === 'OK') {
          this.alertService.showDetailSuccess(
            '¡Éxito!',
            'Usuario actualizado con exito.'
          );
          this.router.navigateByUrl('/home/users');
        } else {
          this.alertService.showDetailError('Error!', resp.error_message);
        }
      });
  }

  saveNewUser() {
    let newUser = new UserNew();

    newUser.firstname = this.fGroup.get('firstname').value;
    newUser.lastname = this.fGroup.get('lastname').value;
    newUser.lastname2 = this.fGroup.get('lastname2').value;
    newUser.fullname =
      newUser.firstname + ' ' + newUser.lastname + ' ' + newUser.lastname2;
    newUser.user_email = this.fGroup.get('user_email').value;
    newUser.contact_phone = this.fGroup.get('contact_phone').value;
    newUser.user_type = this.fGroup.get('user_type').value[0].user_type_key;
    newUser.status = 'active';
    newUser.company_id = this.fGroup.get('company').value[0].id;
    newUser.company_vat_number = this.fGroup.get('company').value[0].vat_number;
    newUser.job_position_in_company = this.fGroup.get(
      'job_position_in_company'
    ).value;

    let userPermissions = new Array<string>();
    switch (this.invoices_permission) {
      case 'ver':
        userPermissions.push('invoicing_invoices_readonly');
        break;
      case 'gestionar':
        userPermissions.push('invoicing_invoices_full');
        break;
    }

    switch (this.users_permission) {
      case 'ver':
        userPermissions.push('invoicing_users_readonly');
        break;
      case 'gestionar':
        userPermissions.push('invoicing_users_full');
        break;
    }
    newUser.user_roles = userPermissions;

    let invoicingUserNew = new InvoicingUserNew();
    invoicingUserNew.invoicing_user = newUser;

    this.invoicingUserService
      .createUser(invoicingUserNew, this.userId)
      .subscribe((resp) => {
        if (resp.status === 'OK') {
          this.alertService.showDetailSuccess(
            '¡Éxito!',
            'Usuario creado con exito.'
          );
          this.router.navigateByUrl('/home/users');
        } else {
          this.alertService.showDetailError('Error!', resp.error_message);
        }
      });
  }

  rejectConfirmation(): void {
    const messageTitle = `¿Seguro que desea rechazar la solicitud?`;
    const messageBody =
      'Se va a proceder al rechazo de la solicitud de alta del usuario ' +
      (this.user.firstname || '') +
      ' ' +
      (this.user.lastname || '') +
      ' ' +
      (this.user.lastname2 || '');
    this.mensajeService.mostrarMensaje(
      null,
      messageTitle,
      ACEPTAR,
      CANCELAR,
      messageBody
    );

    const confirm = this.mensajeService.acceptEmit.subscribe((res) => {
      this.reject();
    });
    this.sub.add(confirm);
  }

  private reject(): void {
    const sub = this.invoicingUserService
      .rejectUserRegistryRequest(this.userId.toString())
      .subscribe((res) => {
        if (res.status && res.status === 'OK') {
          this.alertService.showDetailWarning(
            'Solicitud denegada',
            res.error_message
          );
          this.router.navigateByUrl('/home/users');
        } else {
          this.alertService.showDetailError('Error', res.error_message);
        }
      });
    this.sub.add(sub);
  }

  acceptConfirmation(): void {
    const messageTitle = `¿Seguro que desea aceptar la solicitud?`;
    let username: string = '';
    if (this.user.firstname) username += ' ' + this.user.firstname;
    if (this.user.lastname) username += ' ' + this.user.lastname;
    if (this.user.lastname2) username += ' ' + this.user.lastname2;

    const messageBody = `Se va a proceder al alta del usuario ${username}.`;

    this.mensajeService.mostrarMensaje(
      null,
      messageTitle,
      ACEPTAR,
      CANCELAR,
      messageBody
    );

    const confirm = this.mensajeService.acceptEmit.subscribe((res) => {
      this.saveNewUser();
    });
    this.sub.add(confirm);
  }
}
