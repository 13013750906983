import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { EventEmitter } from 'events';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { KeyValue } from '../../shared/models/key-value.model';


export class AreaVirtualEncoder extends HttpUrlEncodingCodec {
  encodeKey(key: string): string {
      return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
      return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
      return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
      return decodeURIComponent(value);
  }
}

@Injectable()
export class BaseComunicationService {
  constructor(public httpClient: HttpClient, private router?: Router
  ) { }

  // Base de ruta
  public baseURL: string = environment.apiBaseUrl;

  /*Gestion de errores*/

  public actCorrecto = true;

  // * Evento genérico de propósito general, se suscribe a través de .addlistener("nombreEvento") */
  public eventEmitter: EventEmitter = new EventEmitter();

  /* Variable para almacenar cualquier tipo de dato que queramos conservar aunque cambiemos de router link */
  public variablesPersistentes = {};
  // ****************************************************************************************************************
  // ****************************************************************************************************************
  // ****************************************************************************************************************
  // CRUD GENERICO  DE TABLAS
  public obtenerListaReg(url: string, argumentos): Observable<any> {
    const URL = `${this.baseURL}${url}`;
    return this.realizarPeticionPost(URL, argumentos);
  }

  public obtenerListaRegGET(url: string): Observable<any> {
    const URL = `${this.baseURL}${url}`;
    return this.realizarPeticionGet(URL);
  }

  public realizarPeticionGetWithText(url: string): Observable<any> {
    const hs = new HttpHeaders().set('Content-Type', 'application/json');

    return this.httpClient.get(url, { headers: hs, withCredentials: false, responseType: 'text' });
  }

  // Busqueda para filtros
  public obtenerListaRegConCriterio(url: string, argumentos): Observable<any> {
    const URL = `${this.baseURL}${url}`;
    return this.realizarPeticionPost(URL, argumentos);
  }

  /*** Crea un nuevo registro.
    * @param dato el registro a crear
    */
  public nuevoReg(dato: any, url: string): Observable<any> {
    const URL = `${this.baseURL}${url}`;
    return this.realizarPeticionPost(URL, dato);
  }

  /**
   * Actualiza un registro ya existente.
   * @param dato el registro a actualizar
   */
  public actualizarReg(dato: any, url: string): Observable<any> {
    const URL = `${this.baseURL}${url}`;
    return this.realizarPeticionPost(URL, dato);
  }


  /**
   * Elimina un registro.
   * @param id el id del registro a eliminar
   */
  public eliminarReg(id, url: string): Observable<any> {
    const URL = `${this.baseURL}${url}?id=${id}`;
    return this.realizarPeticionGet(URL);
  }

  /*** Obtiene el detalle de un registro.
  * @param id el id del registro a obtener
  */
  obtenerReg(id, url: string): Observable<any> {

    const URL = `${this.baseURL}${url}/${id}`;
    return this.realizarPeticionGet(URL);
  }



  // Obtiene el nombre de la propiedad que ocupa la posicion index del objeto obj
  public getCurrentKey(obj, index): string {
    return Object.getOwnPropertyNames(obj)[index];
  }

  // ****************************************************************************************************************
  // ****************************************************************************************************************
  // ****************************************************************************************************************
  // PETICIONES HTTP.


  public realizarPeticionGet(url: string, requestHeaders?: HttpHeaders): Observable<any> {
    const URL = `${this.baseURL}${url}`;
    let hs: HttpHeaders = null;
    if(requestHeaders){
      hs = requestHeaders
      hs = hs.set('Content-Type', 'application/json')
    }else{
      hs = new HttpHeaders().set('Content-Type', 'application/json');
    }
    return this.httpClient.get(URL, { headers: hs, withCredentials: false });
  }

  public realizarPeticionGetWithBaseURL(url: string): Observable<any> {
    const URL = `${this.baseURL}${url}`;
    const hs = new HttpHeaders().set('Content-Type', 'application/json');

    return this.httpClient.get(URL, { headers: hs, withCredentials: false });
  }

  public realizarPeticionGetWithBlob(url: string): Observable<any> {
    const URL = `${this.baseURL}${url}`;

    return this.httpClient.get(URL, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', timeout: `${300000}` }),
      responseType: 'blob', withCredentials: false
    });
  }

  /**
   * realizarPeticionGetConArgumentos
   * Concatena una lista de claves y valores a la url
   */
  public realizarPeticionGetConFiltros(url: string, args: Array<KeyValue>): any {
    let urlFinal = `${this.baseURL}${url}`;
    let params: HttpParams = new HttpParams({encoder: new AreaVirtualEncoder()});
    if (args && args.length > 0) {
      args.forEach(element => {
        params = params.set(element.key, element.value);
      });
    }
    const hs = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.get(urlFinal, { headers: hs, withCredentials: false, params: params});
  }

  public realizarPeticionPost(url: string, params?: any, requestHeaders?: HttpHeaders): Observable<any> {
    const urlFinal = `${this.baseURL}${url}`;
    return this.httpClient.post(urlFinal, params, { headers: requestHeaders });
  }

  public realizarPeticionPostNoJson(url: string, params?: any, requestHeaders?: HttpHeaders): Observable<any> {
    const urlFinal = `${this.baseURL}${url}`;
    return this.httpClient.post(urlFinal, params, { responseType: 'blob', headers: requestHeaders});
  }

  public realizarPeticionPostBlobResponse(url: string, params?: any): Observable<any> {
    params = JSON.stringify(params);

    const urlFinal = `${this.baseURL}${url}`;

    const hs = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(urlFinal, params, { headers: hs, withCredentials: false, responseType: 'blob' });
  }

  public realizarPeticionPostWithText(url: string, params: any): Observable<any> {
    params = JSON.stringify(params);
    const hs = new HttpHeaders().set('Content-Type', 'application/json');

    return this.httpClient.post(url, params, { headers: hs, withCredentials: true, responseType: 'text' });
  }

  public realizarPeticionPostWithBlob(url: string, params?: any): Observable<any> {
    params = JSON.stringify(params);
    // Establecemos cabeceras
    const hs = new HttpHeaders().set('Content-Type', 'application/json');

    return this.httpClient.post(url, params, { headers: hs, responseType: 'blob', withCredentials: true });
  }

  public realizarPeticionPostConArrayBuffer(url: string, params?: any): Observable<any> {
    params = JSON.stringify(params);
    // Establecemos cabeceras
    const hs = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, params, { headers: hs, withCredentials: true, responseType: 'arraybuffer' });
  }

  public realizarPeticionPut(url: string, params?: any): Observable<any> {
    params = JSON.stringify(params);
    // Establecemos cabeceras
    const urlFinal = `${this.baseURL}${url}`;

    const hs = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(urlFinal, params, { headers: hs, withCredentials: false });
  }



  public realizarPeticionDelete(url: string): Observable<any> {
    const hs = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.delete(url, { headers: hs, withCredentials: true });
  }

  /*** Realiza una peticion HTTP.                                                                                  TODO
  * @param url direccion del servicio a consumir
  * @param params el params de la peticion. Solo se indica para peticiones distintas de GET
  */
  public realizarPeticionTypeFormulario(url: string, params?: any): Observable<any> {
    // Establecemos cabeceras
    const hs = new HttpHeaders();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    hs.append('Content-Type', 'multipart/form-data');
    hs.append('Accept', 'application/json');
    return this.httpClient.post(url, params, { headers: hs, withCredentials: true });
  }

  public realizarPeticionAutenticacionConToken(url, loginData: FormData, clientId, clientSecret): Observable<any> {
    const urlFinal = `${this.baseURL}${url}`;
    loginData.append('grant_type', 'password');
    loginData.append('client_id', clientId);
    const authData = 'Basic ' + btoa(clientId + ':' + clientSecret);
    const headers = new HttpHeaders().set('Authorization', authData);

    return this.httpClient.post(urlFinal, loginData, { headers });
  }


  // ****************************************************************************************************************
  // ****************************************************************************************************************
  // ****************************************************************************************************************
  // ROUTE.

  public routeNavigate(url: string): void {
    this.router.navigate([`/${url}`], { skipLocationChange: false });
  }

  public routeNavigateParam(url: string, parametro: string): void {
    this.router.navigate([`/${url}`, parametro], { skipLocationChange: false });
  }



}
