import { Component, OnInit } from '@angular/core';
import { COPYRIGHT } from 'src/app/app.constants';
import {
  version
} from '../../../../environments/version';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  copyright: string = COPYRIGHT;
  versionString: string = version.versionString;

  constructor() { }

  ngOnInit(): void {
  }

}
