import { InputField } from 'src/app/shared/shared.module';
import { Captcha } from './../../models/captcha.model';
import { EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges, Directive, OnChanges } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Directive()
export class Autoform implements OnInit, OnChanges {
  contentForm: FormGroup;

  @Output() formEmit = new EventEmitter();
  @Input() inputFields: Array<InputField>;
  @Input() formValidators: Array<ValidatorFn>;
  @Output() ready = new EventEmitter<any>();

  initFormValues = new Object();

  toDates: Array<any> = []; // this variable will store toDates so we can add 23:59 to time for better filtering

  onChanges = new BehaviorSubject<SimpleChange>(null);
  currentAssignedCenters = this.onChanges.asObservable();

  constructor() { }

  ngOnInit(): void {
    this.toDates = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    const inputFields: SimpleChange = changes.inputFields;
    if (inputFields && inputFields.currentValue) {
      this.inputFields = inputFields.currentValue;
      this.initForms();
    }
  }


  initForms(captcha?: Captcha): void {
    this.contentForm = new FormGroup({});
    this.inputFields.forEach(input => {
      if (input.type === 'dateFromTo') {
        this.contentForm.addControl('from' + input.key, new FormControl(input.dateFromValue, input.dateFromValidators));
        this.contentForm.addControl('to' + input.key, new FormControl(input.dateToValue, input.dateToValidators));
        this.initFormValues['from' + input.key] = input.controlValue == null ?
          null : input.controlValue.value != null ? input.controlValue.value : input.controlValue;
        this.initFormValues['to' + input.key] = input.controlValue == null ?
          null : input.controlValue.value != null ? input.controlValue.value : input.controlValue;
        this.toDates['to' + input.key] = 'to' + input.key;
      } else {
        this.contentForm.addControl(input.key, new FormControl(input.controlValue, input.validators));
        this.initFormValues[input.key] = input.controlValue == null ?
          null : input.controlValue.value != null ? input.controlValue.value : input.controlValue;
      }
    });
    if ( captcha && captcha.habilitado ) {
      this.contentForm.addControl('recaptchaReactive', new FormControl(null, Validators.required));
      this.initFormValues['recaptchaReactive'] = null;
    }
    if (this.formValidators) {
      this.contentForm.setValidators(this.formValidators);
    }

    this.ready.emit(true);
  }

  public disableField(key: string): void {
    this.contentForm.controls[key].disable();
  }

  public enableField(key: string): void {
    this.contentForm.controls[key].enable();
  }

  setFormValue(key: string, value: any, init?: boolean): void {
    this.contentForm.controls[key].setValue(value);

    if (init) {
      this.initFormValues[key] = value;
    }
  }

  onSubmit(filterFormData: any): void {

    const formData = Object.assign({}, filterFormData);
    Object.keys(formData).forEach(key => {
      if (typeof formData[key] === 'string') {
        filterFormData[key] = formData[key].trim();
        formData[key] = formData[key].trim();

      }

      if (!!formData[this.toDates[key]]) {
        formData[key] = new Date(formData[key]._d);
        formData[key].setUTCHours(23, 59);
      }
    });

    this.formEmit.emit(formData);
  }
}
