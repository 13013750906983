<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content class="mat-typography">
  <p>{{message}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="primary" mat-dialog-close *ngIf="cancel"
  aria-label="Botón cancelar">{{cancel}}</button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial (click)="emitAccept()"
  aria-label="Botón aceptar">{{accept}}</button>
</mat-dialog-actions>
