import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { CustomPagination } from '../../components/table/table-configuration/custom-pagination';
import { Columns } from '../../shared.module';

import { ExportExcelComponent } from '../export-excel/export-excel.component';
import { ExportPdfComponent } from '../export-pdf/export-pdf.component';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnInit, OnChanges {

  @Input() columns: Columns[];
  @Input() items: any[] = [];
  @Input() pagination: CustomPagination;
  @Input() standalone = false;

  @Output() exportPdf = new EventEmitter<any>();
  @Output() exportExcel = new EventEmitter<any>();

  @ViewChild(ExportExcelComponent, { static: false }) exportExcelComponent: ExportExcelComponent;
  @ViewChild(ExportPdfComponent, { static: false }) exportPdfComponent: ExportPdfComponent;


  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const items: SimpleChange = changes.items;

    if (items && items.currentValue) {
      this.items = items.currentValue.slice();
    }
  }

  exportPdfListener(): void {
    this.exportPdf.emit();
  }

  exportExcelListener(): void {
    this.exportExcel.emit();
  }

  doExportExcel(items: Array<any>): void {
    this.exportExcelComponent.items = items;
    this.exportExcelComponent.xlsx();
  }

  doExportPdf(items: Array<any>): void {
    this.exportPdfComponent.items = items;
    this.exportPdfComponent.pdf();
  }

}
