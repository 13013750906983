/* Node modules */
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  HostListener,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomErrorStateMatcher } from 'src/app/shared/services/error-state-matcher';

/* Modelos */
import { InputField } from '../input-fields/input-fields';

@Component({
  selector: 'app-inputs-generator',
  templateUrl: './inputs-generator.component.html',
  styleUrls: ['./inputs-generator.component.scss'],
})
export class InputsGeneratorComponent implements OnInit {
  mobile = false;

  @Input() fGroup: FormGroup;
  @Input() inputFields: Array<InputField>;
  @Input() layout = 'col-md-4 col-lg-3';
  @Input() dateToFromLayout = 'col-md-8 col-lg-6';
  @Input() checkboxLayout = 'col-lg-12';

  @Output() selectedEmit = new EventEmitter<any>();

  matcher = new CustomErrorStateMatcher();

  @HostListener('window:resize', ['$event'])
  private onResize(event): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;
  }

  constructor() {
    this.mobile = window.innerWidth <= 1020 ? true : false;
  }

  ngOnInit(): void {}

  public getReadOnlyClass(readonly: boolean): string {
    let roclass = '';
    if (readonly) {
      roclass = ' mat-form-field-readonly ';
    }
    return roclass;
  }

  public selectElement(key: string, e: any): void {
    this.selectedEmit.emit([key, e]);
  }

  public returnValue(element: any): any {
    if (element.nombre) {
      return element.nombre;
    } else {
      return element.value;
    }
  }

  focusOut(controlName, data) {
    if (data.focusOut) {
      data.focusOut(this.fGroup.controls[controlName].value);
    }
  }
}
