import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceStateToIcon'
})
export class InvoiceStateToIconPipe implements PipeTransform {

  transform(invoiceStateId: string): string {
    let icon: string;
    switch (invoiceStateId) {
      case 'sent':            icon = 'send'; break;
      case 'canceled':        icon = 'delete_outline'; break;
      case 'rejected':        icon = 'cancel'; break;
      case 'payment_done':
      case 'payment_sent_or_settled':
      case 'payment_sent':    icon = 'check_circle_outline'; break;
      case 'pending_payment': icon = 'hourglass_top'; break;
      case 'pending_approve': icon = 'plagiarism'; break;
      default: icon = 'info';break;
    }
    return icon;
  }

}
