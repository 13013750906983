<div class="row justify-content-center">
  <div class="mt-3 col-sm-12 col-lg-6">

    <form [formGroup]="fGroup" (ngSubmit)="submit()" class="col-12">

      <!-- Campos del formulario -->
      <app-inputs-generator [fGroup]="fGroup" [inputFields]="inputs"></app-inputs-generator>

      <!-- Submit button -->
      <button mat-flat-button color="primary"
      *ngIf="!isLoadingMsAuth" 
      [disabled]="!fGroup.valid"
      aria-label="Botón Iniciar Sesión"
      type="submit"
      class="col-12 arpada-button-large">{{ 'Login' | translate }}</button>

      <button mat-flat-button color="primary"
      *ngIf="isLoadingMsAuth" 
      [disabled]="true"
      aria-label="Botón Iniciar Sesión"
      class="col-12 arpada-button-large buttonSpinner"></button>

      <div class="col-12 mt-3 text-center">
        <a routerLink="/login/recover" class="primary">{{ 'forgotPassword' | translate }}</a>
      </div>

      <div class="col-12 mt-3 text-center marginTopAccent marginBottomNormal" style="display: none !important;"> <!-- Uncomment when available external login-->
        <a *ngIf="!isLoadingMsAuth" (click)="msalLoginPopup()" class="primary"><mat-icon inline=true>lock</mat-icon><span class="marginLeftNormal">{{ 'enterEmployeeArea' | translate }}</span></a>
        <a *ngIf="isLoadingMsAuth" class="disabledLink"><mat-icon inline=true>lock</mat-icon><span class="marginLeftNormal">{{ 'enterEmployeeArea' | translate }}</span></a>
      </div>

      <div *ngIf="showUnexpectedErrorView">
        <p class="arpadaError text-center">{{ 'oauthUnexpectedError' | translate }}</p>
      </div>
      <div *ngIf="showCanNotOpenPopupErrorView">
        <p class="arpadaError text-center">{{ 'oauthCanNotOpenPopupError' | translate }}</p>
      </div>
      <div *ngIf="showForbiddenAccessErrorView">
        <p class="arpadaError text-center">{{ 'oauthForbiddenAccessError' | translate }}</p>
      </div>

      
    </form>

    <div>
    </div>
  </div>
</div>

