<mat-toolbar *ngIf="!mobile" class="toolbar sub-toolbar" [class.arpada-mobile-subtoolbar]="mobile">
  <h1>{{'Listado de facturas' | translate}}</h1>
  <i class="material-icons material-icons-outlined invoice-add-icon"
    aria-hidden="true">format_list_bulleted</i>
</mat-toolbar>

<ng-container *ngIf="userType === 'internal'">
  <mat-card class="mt-3" [class.arpada-mobile]="mobile">
    <app-invoices-filter-internal [mobile]="mobile"></app-invoices-filter-internal>
  </mat-card>
</ng-container>

<ng-container *ngIf="userType === 'external'">
  <mat-card class="mt-3" [class.arpada-mobile]="mobile">
    <app-invoices-filter-external [mobile]="mobile"></app-invoices-filter-external>
  </mat-card>
</ng-container>







