export class UserNew {
  user_email: string;
  user_type: 'internal' | 'external' | null;
  status: string;
  company_id: number;
  company_vat_number: number;
  firstname: string;
  lastname: string;
  lastname2: string;
  fullname: string;
  contact_phone: string;
  user_roles: string[];
  job_position_in_company: string;

  constructor(data?: any) {
    this.firstname = data && data.firstname;
    this.lastname = data && data.lastname;
    this.lastname2 = data && data.lastname2;
    this.contact_phone = data && data.contact_phone;
    this.user_roles = data && data.user_roles;
    this.user_type = data && data.user_type;
    this.user_email = data && data.user_email;
    this.company_id = data && data.company_id;
    this.company_vat_number = data && data.company_vat_number;
    this.fullname = data && data.fullname;
    this.job_position_in_company = data && data.job_position_in_company;
    this.status = data && data.status;
  }
}

export class InvoicingUserNew {
  invoicing_user: UserNew;

  constructor(data?: any) {
    this.invoicing_user = data && data.invoicing_user;
  }
}
