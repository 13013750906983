<div class="row justify-content-center">

  <mat-card class="col-11 col-lg-8 col-xl-7 mt-4">
    <form [formGroup]="fGroup">
      <app-inputs-generator [fGroup]="fGroup" [inputFields]="inputs"></app-inputs-generator>

      <!-- Estado de usuario Section -->
      <ng-template #userStatusSection let-item>
        <ng-container  *ngIf="userId !== undefined">
          <mat-icon matTooltip="{{ userStatus === 'active' ? 'Activo' : 'Inactivo' | translate}}"
            class="material-icons material-icons-outlined arpada-user-status arpada-user-status-icon-{{userStatus | lowercase}}">
            account_circle</mat-icon>
          <span class="arpada-user-status-label arpada-user-status arpada-user-status-icon-{{userStatus | lowercase}}"> {{ userStatus === 'active' ? 'Usuario activo' : ((userId && currentRoute === 'new') ? 'Usuario no registrado' : 'Usuario inactivo') | translate}}</span>
        </ng-container>
      </ng-template>

      <!-- Permisos de factura Section -->
      <ng-template #invoicesPermissionSection let-row>
        <div class="col-12 mb-2">
          <span><b>{{'Permisos de Facturas:' | translate }}</b></span>
        </div>
        <div class="col-12">
          <mat-radio-group [(ngModel)]="invoices_permission" formControlName="invoices_permission" disabled="{{onlyRead}}">
            <mat-radio-button class="m-2" [value]="'sinPermiso'">Sin permiso</mat-radio-button>
            <mat-radio-button class="m-2" [value]="'ver'">Ver</mat-radio-button>
            <mat-radio-button class="m-2" [value]="'gestionar'">Gestionar</mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-template>

      <!-- Permisos de usuario Section -->
      <ng-template #usersPermissionSection let-row>
        <div class="col-12 mb-2">
          <span><b>{{'Permisos de Usuarios:' | translate }}</b></span>
        </div>
        <div class="col-12">
          <mat-radio-group [(ngModel)]="users_permission" formControlName="users_permission" disabled="{{onlyRead}}">
            <mat-radio-button class="m-2" [value]="'sinPermiso'">Sin permiso</mat-radio-button>
            <mat-radio-button class="m-2" [value]="'ver'">Ver</mat-radio-button>
            <mat-radio-button class="m-2" [value]="'gestionar'">Gestionar</mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-template>
      <!-- Button row -->
      <section class="row footer" *ngIf="!onlyRead && (!userId || currentRoute !== 'new')">
        <div class="col ml-auto">
          <button mat-flat-button type="submit" color="primary" class="btn-apply btn-save" (click)="submit()"
            [disabled]="!fGroup.valid">{{ btnText | translate }}</button>
        </div>
      </section>
      <section class="mt-3" *ngIf="loginUser.permissionsInCompany.user_registry_request && (userId && currentRoute === 'new')">
        <div class="d-flex flex-column flex-lg-row justify-content-end align-items-lg-baseline">
          <button mat-flat-button color="warn" class="m-0 m-lg-3 mb-3"
            (click)="rejectConfirmation()">{{ 'Rechazar solicitud' | translate }}</button>
          <button mat-flat-button color="primary" type="submit" [disabled]="!fGroup.valid"
            (click)="acceptConfirmation()">{{ 'Aceptar solicitud' | translate }}</button>
        </div>
      </section>
    </form>
  </mat-card>

</div>


