import { RecoverService } from './../../../services/recover.service';
import { InputField } from 'src/app/shared/shared.module';
import { SignupService } from './../../../services/signup.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Subscription, timer } from 'rxjs';
import { REQUIRED_MESSAGE } from 'src/app/app.constants';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-signup-confirmation',
  templateUrl: './signup-confirmation.component.html',
  styleUrls: ['./signup-confirmation.component.scss']
})
export class SignupConfirmationComponent implements OnInit, OnDestroy {

  private token: string;
  tokenValid: boolean = true;

  loading: boolean = true;

  private sub: Subscription;

  passwordValidationErrorMessage: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private signupService: SignupService,
    private alertService: AlertService) {

  }

  ngOnInit(): void {
    // Catch reset password token from URL.
    this.sub = this.route.params.subscribe(
      params => {
        this.token = params['token'];
        this.validateToken();
      },
      err => console.log(err)
    );
  }

  submit(): void {
    this.router.navigateByUrl('/');
  }

  private validateToken(): void {
    this.signupService.validateToken(this.token).subscribe(
      res => {
        if (res.status && res.status === 'INVALID') {
          this.alertService.showDetailError(res.status, res.error_message);
          this.router.navigateByUrl('/');
        } else {
          this.loading = false;
        }
      },
      err => {
        console.log(err);
        this.alertService.showDetailError('Error', 'El enlace no es válido');
        this.loading = false;
        this.router.navigateByUrl('/');
      }
    );
  }


  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }


}
