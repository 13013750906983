import { VatLine } from './invoice-new';

export class InvoiceUpdateDto {
  invoice_uuid: string;
  id_to_update: number;
  invoice_update: InvoiceUpdate;
  refund_prompt_payment_invoice_uuid: string;
  refund_prompt_payment_invoice: InvoiceUpdate;

  constructor(data?: any) {
    this.invoice_uuid = (data && data.invoice_uuid) || '';
    this.id_to_update = (data && data.id_to_update) || '';
    this.invoice_update = (data && data.invoice_update) || '';
    this.refund_prompt_payment_invoice_uuid =
      (data && data.refund_prompt_payment_invoice_uuid) || '';
    this.refund_prompt_payment_invoice =
      (data && data.refund_prompt_payment_invoice) || '';
  }
}

export class InvoiceUpdate {
  invoice_number: string;
  invoice_date: string;
  base_amount: number;
  invoice_total: number;
  withholding_amount: number;
  withholding_perone: number;
  has_prompt_payment: boolean;
  prompt_payment_amount: number;
  invoice_file_uuid: string;
  invoice_attachment_file_uuid: string;
  invoice_type_id: string;
  invoice_subtype_id: string;
  reverse_vat_liability: boolean;
  vat_lines: Array<VatLine>;
  company_id: number;
  csite_id: number;
  linked_worksheets: string[];
  linked_proforma: string;
  irpf_value: number;
  company_which_is_invoiced_id: number;
  refunded_invoice_id: number;

  constructor(data?: any) {
    this.invoice_number = (data && data.invoice_number) || '';
    this.invoice_date = (data && data.invoice_date) || '';
    this.base_amount = (data && data.base_amount) || null;
    this.invoice_total = (data && data.invoice_total) || null;
    this.invoice_file_uuid = (data && data.invoice_file_uuid) || null;
    this.invoice_attachment_file_uuid =
      (data && data.invoice_attachment_file_uuid) || null;
    this.invoice_type_id = (data && data.invoice_type_id) || null;
    this.vat_lines = (data && data.vat_lines) || null;
    this.irpf_value = (data && data.irpf_value) || 0;
    this.company_which_is_invoiced_id =
      (data && data.company_which_is_invoiced_id) || 0;
    this.refunded_invoice_id = (data && data.refunded_invoice_id) || null;
  }
}
