import { UtilsService } from 'src/app/shared/services/utils.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { LoginService } from 'src/app/core/services/login.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-registry-detail-container',
  templateUrl: './user-registry-detail-container.component.html',
  styleUrls: ['./user-registry-detail-container.component.scss']
})
export class UserRegistryDetailContainerComponent implements OnInit {


  mobile = false;
  userType: string;
  title: string;

  constructor(
    public loginService: LoginService,
    private location: Location,
    private route: ActivatedRoute,
    private router : Router,
    private utilsService: UtilsService
    ) { }

  ngOnInit(): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;

    this.userType = this.loginService.getUser().userType;

    let route = this.router.url.split('/')[3];

    // Recuperar id de factura desde path
    this.route.params.subscribe( params => {
      this.title = route === 'detail' ? 'Detalle y edición de usuario' : 'Solicitud de alta';
    });

    this.utilsService.toolbarTitle.emit({title: this.title, icon: null, back: true})
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;
  }

  back() {
    this.location.back();
  }

}
