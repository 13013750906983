<app-http-spinner></app-http-spinner>
<app-translation></app-translation>
<div class="container mt-3">

    <div class="row justify-content-center">
        <div class="col-8 col-sm-8 col-md-6 col-lg-4" id="logo">
            <!-- logo -->
            <img src="../../../../assets/svg/arpada-area-virtual.svg">
            <span>Área Virtual</span>
        </div>
    </div>

    <router-outlet></router-outlet>

    <div class="row mt-5">
        <h4 class="col-12 text-center secondary removeMarginBottom">{{ copyright }}</h4>
    </div>
    <div class="row">
        <h5 class="col-12 text-center secondary">{{ 'versionFooter' | translate: {versionString: versionString} }}</h5>
    </div>

</div>