import { Captcha } from './../../../models/captcha.model';
import { CatpchaService } from './../../../services/catpcha.service';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { Autoform } from '../autoform';
import { Router } from '@angular/router';
import { RouterEventsService } from 'src/app/shared/services/router-events.service';
import { Location } from '@angular/common';
import { CustomValidators } from '../validators/custom-validators';

@Component({
  selector: 'app-details-content-form',
  templateUrl: './details-content-form.component.html',
  styleUrls: ['./details-content-form.component.css']
})
export class DetailsContentFormComponent extends Autoform implements OnInit, OnChanges {

  constructor(private router: Router, private routerEventsService: RouterEventsService,
              private location: Location, private captchaService: CatpchaService) {
                super();
  }

  @Input() routeParent: string;
  @Input() layout = 'col-md-4 col-lg-3';
  @Input() dateToFromLayout = 'col-lg-4 col-md-6 col-sm-10';
  @Input() checkboxLayout = 'col-lg-12';
  @Input() showDeleteButton = false;
  @Input() showAcceptButton = false;
  @Input() enabledDeleteButton = true;
  @Input() saveDisabled = false;
  @Input() hideCancelButton = false;
  @Input() hideSaveButton = false;
  @Input() cancelRouterLink: string;
  @Input() captcha: Captcha;
  @Input() atLeastOne = false;

  @Output() deleteEmit = new EventEmitter();
  @Output() selectedEmit = new EventEmitter<any>();

  ngOnInit(): void {

    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    this.routeParent = '/' + this.routerEventsService.previousRoutePath.value || this.router.url.split('/')[1];
  }

  ngOnChanges(changes: SimpleChanges): void {
    const inputFields: SimpleChange = changes.inputFields;
    if (inputFields && inputFields.currentValue) {
      this.inputFields = inputFields.currentValue;
      super.initForms(this.captcha);
      this.addTextValidator();
    }
  }

  addTextValidator(): void {
    this.inputFields.forEach( input => {
      if (input.type === 'text') {
        this.contentForm.get(input.key).setValidators(this.getTextValidators(input.validators));
      }
    });
  }

  getTextValidators(validators): any {
    const textvalidators = [CustomValidators.noWhitespaceValidator];
    if (Array.isArray(validators)) {
      textvalidators.push(...validators);
    } else if (validators) {
      textvalidators.push(validators);
    }
    return textvalidators;
  }

  public delete(params): void {
    this.deleteEmit.emit(params);
  }

  public cancel(): void {
    if (this.cancelRouterLink) {
      this.router.navigateByUrl(this.cancelRouterLink);
    } else {
      this.location.back();
    }
  }

  public resolved(value: any): void {

  }

  public checkValid(): boolean {
    let doesntExist = true;
    if ( this.atLeastOne ) {
      this.inputFields.forEach( input => {
        if (this.contentForm.get(input.key).value !== '' ) {

          doesntExist = false;
        }
      });
      return doesntExist;

    } else {
      return !this.contentForm.valid;
    }
  }

  public selectElement(e: any): void {
    this.correlatedReset(e[0]);
    this.selectedEmit.emit(e);
  }

  /**
   * Cada vez que un input es modificado se busca su input asociado para
   * resetear su selección.
   * @param e Input modificado.
   */
   public correlatedReset(e: any) {
    this.inputFields.forEach(i => {
      if (i.key=== e && i.correlated) {
        i.correlated.forEach(corr => {
          this.contentForm.get(corr).setValue(null);
        });
      }
    })

  }
}




