import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiError, AlertService } from 'src/app/shared/shared.module';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private loginService: LoginService,
    private alertService: AlertService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      switch (err.status) {
        case 401:
          this.router.navigateByUrl('login');
          break;
        case 404:
          this.alertService.showDetailError('Error Interno', 'Servidor no responde. Inténtelo de nuevo más tarde');
          break;
        case 403:
          // TODO - Pendiente desarrollo enpoint, siempre devuelve 403
          this.alertService.showDetailError('OJO, ERROR TEMPORAL', 'Control error falso 403');
          break;
        case 405:
          alert('Error allowed interc');
          break;
        // 500 y 400 pueden ser errores gestionados por el backend
        case 500:
        case 400:
          this.apiErrorHandler(err);
          break;
        case 408: // Request timeout
          this.alertService.showDetailError('Error', 'Tiempo de espera agotado para la petición realizada, por favor intentelo de nuevo');
          break;
        case 409:
          this.alertService.showDetailError('Error', err.error.message);
          break;
        case 422: // Request Validation error
          this.apiErrorHandler(err);
          break;
        case 200: // no entiende la respuesta y piensa que es error
          break;
        default:
          this.alertService.showDetailError('Error', 'Se ha producido un error interno');
          this.router.navigateByUrl('error');
          break;
      }


      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }

  /**
   * Handles specific apiErrors returned from BackEnd
   * returns:
   * true => handled Exception
   * false => unhandled Exception
   */
  apiErrorHandler(err: HttpErrorResponse): void {
    let handled = false;
    if (err) {


      if (err.headers.has('errordetail')) {
        // It has an apiError object inside

        const apiError = new ApiError(JSON.parse(JSON.stringify(err)));

        this.alertService.showDetailError('Error de validación', apiError.detail[0].msg);

      } else {
        // It must be an unhandled exception
        handled = false;
      }
    }

    // In case of a unhandled exception, redirects to error page.
    if (!handled) {
      if (this.router.url !== '/login') {
        this.alertService.showDetailError('Error', 'Se ha producido un error inesperado. Por favor, vuelva a intentarlo en unos minutos.');
      }

      if (!environment.production) {
        console.log(err);
      }
    }

  }
}
