<!-- Table component -->
<section *ngIf="windowsWidth > 770 && showCardView !== true">
  <div class="ag-theme-alpine">
    <button mat-raised-button (click)="exportCSV()"
    aria-label="Exportar a CSV" matTooltip="Exportar a CSV">{{"exportar a CSV" | translate}}</button>
  </div>
  <ag-grid-angular role="table" domLayout='autoHeight' class="ag-theme-alpine" [rowData]="items"
    [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)" (rowDoubleClicked)="rowDoubleClicked.emit($event)">
  </ag-grid-angular>
</section>
<!-- Card View for responsive tables -->
<section id="cardViewSection" *ngIf="windowsWidth <= 770 || showCardView">
  <app-card-view #cardView [items]="items" [columns]="columnDefs" [configuration]="configuration"
    [totalItems]="pagination.totalItems" (paginationEmit)="updatePagination($event)" [pagination]="pagination"
    [isLoading]="isLoading" (deleteEmit)="remove($event.item, $event.index)">
  </app-card-view>
</section>
<section>
  <mat-paginator class="ag-theme-alpine" *ngIf="configuration.pagination" [pageSize]="configuration.defaultPageSize"
    [pageSizeOptions]="configuration.pageSizes" [length]="pagination.totalItems" showFirstLastButtons>
  </mat-paginator>
</section>
