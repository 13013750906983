import { UtilsService } from 'src/app/shared/services/utils.service';
import { CurrencyPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import {
  REQUIRED_MESSAGE,
  INFO_PROFORMA,
  INVOICE_NUMBER_ALREADY_EXIST_MESSAGE,
} from 'src/app/app.constants';
import { ConstructionSite } from 'src/app/core/models/construction-sites.model';
import { CSiteCriteria } from 'src/app/core/models/csite_criteria.model';
import { InvoiceGet } from 'src/app/core/models/invoices/invoice-get';
import { InvoiceSite } from 'src/app/core/models/invoices/invoice-site.model';
import { InvoiceSubtype } from 'src/app/core/models/invoices/invoice-subtype.model';
import { InvoiceType } from 'src/app/core/models/invoices/invoice-type.model';
import { Proforma, VatLines } from 'src/app/core/models/proformas.model';
import { ProformaCriteria } from 'src/app/core/models/proforma_criteria.model';
import { StoredFile } from 'src/app/core/models/stored-file.model';
import { InvoicingQueryService } from 'src/app/core/services/invoicing-query.service';
import { SearchService } from 'src/app/core/services/search.service';
import { StoredFilesService } from 'src/app/core/services/stored-files.service';
import { ThousandsPipe } from 'src/app/shared/pipes/thousands.pipe';
import { AlertService, InputField } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-invoice-proforma-de-obra',
  templateUrl: './invoice-proforma-de-obra.component.html',
  styleUrls: ['./invoice-proforma-de-obra.component.scss'],
  providers: [CurrencyPipe, ThousandsPipe],
})
export class InvoiceProformaDeObraComponent implements OnInit, OnChanges {
  inputs = new Array<InputField>();
  inputsProntoPago = new Array<InputField>();
  obrasList = new Array<ConstructionSite>(); // Modelo Obras
  obras: { id; name: string }[] = [];
  constructionSite: InvoiceSite[] = [];
  proformaList = new Array<Proforma>(); // Modelo Proforma
  proformass: { id; name: string }[] = [];
  porcentajeRentencion: number;
  retencion: number;
  inversionSujetoPasivo: string;
  infoProforma: string = INFO_PROFORMA;
  prontoPagoIncluido = 'SI';
  prontoPagoIncluidoCheck = true;
  facturaAbonoProntoPago: boolean;
  prontoPagoBaseImponible: number;
  prontoPagoIVA: number;
  prontoPagoTotal: number;
  importeProntoPago: number;
  mobile: boolean;
  primeraCargaEdicion: boolean;
  cSiteCriteria: CSiteCriteria;
  proformaCriteria: ProformaCriteria;
  facturaAbonoCargada = false;
  proformaFromEdit: Proforma;
  obraFromEdit: InvoiceSite;
  cancelaSeleccionIndividual: boolean;
  vatType: VatLines = null;

  @Input() formGroup: FormGroup;
  @Input() formGroupProntoPago: FormGroup;
  @Input() companyId: string;
  @Input() invoiceGet: InvoiceGet;
  @Input() editOn: boolean;
  @Input() tipoFactura: InvoiceType;
  @Input() subTipoFactura: InvoiceSubtype;
  @Input() changeFormFirstStep = false;

  @Output() formGroupEmiter = new EventEmitter<Array<FormGroup>>();
  @Output() emitProforma = new EventEmitter();
  @Output() back = new EventEmitter<boolean>();

  @ViewChild('retencionSection', { static: true })
  retencionSection: TemplateRef<any>;
  @ViewChild('prontoPagoIncluidoSection', { static: true })
  prontoPagoIncluidoSection: TemplateRef<any>;
  @ViewChild('inversionSujetoPasivoSection', { static: true })
  inversionSujetoPasivoSection: TemplateRef<any>;
  @ViewChild('dividerSection', { static: true })
  dividerSection: TemplateRef<any>;
  @ViewChild('prontoPagoBaseImponibleSection', { static: true })
  prontoPagoBaseImponibleSection: TemplateRef<any>;
  @ViewChild('prontoPagoImporteIVASection', { static: true })
  prontoPagoImporteIVASection: TemplateRef<any>;
  @ViewChild('prontoPagoImporteTotalSection', { static: true })
  prontoPagoImporteTotalSection: TemplateRef<any>;
  @ViewChild('siteOption', { static: true })
  siteOptionTemplate: TemplateRef<any>;
  @ViewChild('proformaOption', { static: true })
  proformaOptionTemplate: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private invoicingQueryService: InvoicingQueryService,
    private searchService: SearchService,
    private storedFileService: StoredFilesService,
    private alertService: AlertService,
    private currencyPipe: CurrencyPipe,
    private thousandsPipe: ThousandsPipe,
    public utilsService: UtilsService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.changeFormFirstStep?.currentValue || (changes.subTipoFactura?.currentValue?.id !== changes.subTipoFactura?.previousValue?.id)) {
      this.primeraCargaEdicion = this.editOn;
      this.cancelaSeleccionIndividual = this.editOn;
      this.cSiteCriteria = new CSiteCriteria();
      this.proformaCriteria = new ProformaCriteria();
      this.cSiteCriteria.only_csites_with_pending_proformas_on_company_id =
        Number(this.companyId);

      this.init();
    }
  }

  init() {
    this.mobile = window.innerWidth <= 1020 ? true : false;
    this.facturaAbonoProntoPago = false;

    this.initForm();
    this.initFormProntoPago();
  }

  initForm() {
    this.formGroup = this.fb.group({
      ubicacion: ['', [Validators.required]],
      proforma: [{ disabled: true }, [Validators.required]],
      baseImponible: [{ value: '', disabled: true }],
      retencion: [''],
      porcientonRetencion: [''],
      formaDePago: [''],
      importeProntoPago: [{ value: '', disabled: true }],
      importeProntoPagoCheck: [''],
      tieneProntoPago: [''],
      inversionDeSujetoPasivo: [''],
      importeTotalFactura: [{ value: '', disabled: true }],
      importeIVA: [{ value: '', disabled: true }],
      ivaToggle: [false],
      adjuntarFactura: ['', [Validators.required]],
      adjuntarFacturaId: ['', [Validators.required]],
    });

    this.inputs = new Array<InputField>();
    this.inputs.push(
      {
        key: 'ubicacion',
        type: 'autocomplete-chip',
        label: this.subTipoFactura?.templateParameters?.csiteSection?.labelText,
        controlValue: '',
        layout: 'col-md-12 col-sm-6',
        placeholder: 'Seleccione una opción',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        options: this.constructionSite,
        filteredOptions: (value: string) => {
          return this.searchService
            .getConstructionSite(this.cSiteCriteria)
            .pipe(
              map((res) => {
                const constructionSite = res.values as Array<any>;
                if (this.obraFromEdit !== undefined) {
                  constructionSite.push(this.obraFromEdit);
                }
                this.constructionSite = constructionSite.map((emp) => {
                  return new InvoiceSite({
                    id: emp.id,
                    internalNumber: emp.internal_number,
                    description:
                      emp.internal_number === undefined
                        ? emp.description
                        : `${emp.internal_number} - ${emp.description}`,
                    address: emp.address,
                  });
                });
                if (this.editOn) {
                  // Hacemos una llamada para la obra a partir del csiteId y lo añadimos al listado de construnctionSite
                  this.searchService
                    .getConstructionSiteById(this.invoiceGet.csiteId.toString())
                    .subscribe((resp) => {
                      if (resp) {
                        const csite: InvoiceSite = new InvoiceSite({
                          id: resp.values[0].id,
                          internalNumber: resp.values[0].internal_number,
                          description: `${resp.values[0].internal_number} - ${resp.values[0].description}`,
                          address: resp.values[0].address,
                        });
                        const csF = this.constructionSite.find(
                          (cst) => cst.id === csite.id
                        );

                        if (!csF) {
                          this.constructionSite.push(csite);
                          this.obraFromEdit = csite;
                        }

                        this.formGroup.get('ubicacion').setValue([csite]);
                      }
                    });
                }
                if (res.count === 1 && !this.cancelaSeleccionIndividual) {
                  this.formGroup
                    .get('ubicacion')
                    .setValue([this.constructionSite[0]]);
                }
                return this.constructionSite;
              })
            );
        },
        selectOptionTemplate: this.siteOptionTemplate,
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
      },
      {
        key: 'proforma',
        type: 'autocomplete-chip',
        label: 'Proforma',
        controlValue: '',
        layout: 'col-lg-12 col-md-12',
        placeholder: 'Seleccione una opción',
        selectBindLabel: 'selectDescription',
        selectOptionLabel: 'id',
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
        options: this.proformaList,
        filteredOptions: () => {
          return this.searchService.getProformas(this.proformaCriteria).pipe(
            map((res) => {
              const proformaList = res.values;
              if (proformaList.length > 0) {
                this.vatType = proformaList[0].vat_lines[0];
              } else {
                this.vatType = null;
              }

              this.changeInputProperty(
                'importeIVA',
                'label',
                `Importe IVA (${this.vatType?.vat_pcent_description || '21%'})`
              );

              if (
                this.proformaFromEdit !== undefined &&
                this.formGroup.get('ubicacion').value[0]?.id ===
                  this.invoiceGet.csiteId
              ) {
                proformaList.push(this.proformaFromEdit);
              }
              if (proformaList.length > 0) {
                proformaList.map((p) => {
                  p.selectDescription =
                    'Contrato: ' +
                    p.contract_number +
                    ' (' +
                    p.activity +
                    ') Proforma ' +
                    p.proforma_number +
                    ' (Base Imponible ' +
                    this.thousandsPipe.transform(
                      this.currencyPipe.transform(p.base_amount)
                    ) +
                    ')';
                });
              }
              return proformaList;
            })
          );
        },
        selectOptionTemplate: this.proformaOptionTemplate,
      },
      {
        key: 'divider',
        type: 'inputTemplate',
        label: 'Retencion',
        controlValue: '',
        layout: 'col-lg-12 col-md-12 mt-4 mb-4',
        inputTemplate: this.dividerSection,
      },
      {
        key: 'baseImponible',
        type: 'amount',
        label: 'Base imponible',
        controlValue: '',
        layout: 'col-lg-6 col-md-12',
        readonly: true,
      },
      {
        key: 'retencion',
        type: 'inputTemplate',
        label: 'Retencion',
        controlValue: '',
        layout: 'col-lg-6 col-md-12',
        inputTemplate: this.retencionSection,
      },
      {
        key: 'formaDePago',
        type: 'text',
        label: 'Forma de pago',
        controlValue: '',
        layout: 'col-lg-6 col-md-12',
        readonly: true,
      },
      {
        key: 'importeProntoPago',
        type: 'amount',
        label: 'Importe pronto pago',
        controlValue: '',
        layout: 'col-lg-6 col-md-12',
        readonly: true,
      },
      {
        key: 'importeProntoPagoCheck',
        type: 'inputTemplate',
        label: 'Pronto pago incluido en la factura',
        controlValue: '',
        inputTemplate: this.prontoPagoIncluidoSection,
        layout: 'col-md-12 col-12',
      },
      {
        key: 'divider2',
        type: 'inputTemplate',
        label: 'Retencion',
        controlValue: '',
        layout: 'col-md-12 col-lg-12 ',
        inputTemplate: this.dividerSection,
      },
      {
        key: 'inversionDeSujetoPasivo',
        type: 'inputTemplate',
        label: 'Inversión sujeto pasivo',
        controlValue: '',
        inputTemplate: this.inversionSujetoPasivoSection,
        layout: 'col-md-12 col-lg-12',
      },
      {
        key: 'importeIVA',
        type: 'amount',
        label: `Importe IVA (${this.vatType?.vat_pcent_description || '21%'})`,
        controlValue: '',
        layout: 'col-md-12 col-lg-6',
        readonly: true,
        infoIconShow: true,
        infoIconText: 'IVA = (Base imponible – Pronto Pago) x 21%',
      },
      {
        key: 'importeTotalFactura',
        type: 'amount',
        label: 'Importe total factura',
        controlValue: '',
        layout: 'col-md-12 col-lg-6 bold',
        readonly: true,
        infoIconShow: true,
        infoIconText:
          'Importe total factura = Base Imponible - Retención - Pronto Pago + IVA',
      },
      {
        key: 'divider3',
        type: 'inputTemplate',
        label: 'Retencion',
        controlValue: '',
        layout: 'col-md-12 col-lg-12',
        inputTemplate: this.dividerSection,
      },
      {
        key: 'adjuntarFactura',
        type: 'fileDrop',
        label: 'Adjuntar factura',
        placeholder: 'Adjuntar factura',
        controlValue: '',
        layout: 'col-md-12 col-lg-12',
        fileExtensions: ['pdf'],
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
        infoIconShow: true,
        infoIconText:
          'Por favor, adjunte sólo un PDF. No es necesario adjuntar proformas a la factura',
      },
      {
        key: 'divider4',
        type: 'inputTemplate',
        label: 'Retencion',
        controlValue: '',
        layout: 'col-md-12 col-lg-12',
        inputTemplate: this.dividerSection,
      }
    );
    this.formGroup.get('proforma').disable();

    this.formGroup.get('adjuntarFactura').valueChanges.subscribe((adjunto) => {
      if (
        (this.editOn && !this.primeraCargaEdicion) ||
        (!this.editOn && !this.primeraCargaEdicion)
      ) {
        if (adjunto?.name) {
          const file = new StoredFile();
          const reader = new FileReader();
          reader.readAsDataURL(adjunto);
          reader.onload = () => {
            file.base64 = reader.result.toString().split(',')[1];
            file.filename = adjunto.name;
            file.mimetype = adjunto.type;
            const fileName = adjunto.name.toString().split('.');
            file.file_extension = fileName[fileName.length - 1];
            this.storedFileService.uploadFile(file).subscribe((resp) => {
              if (resp) {
                this.formGroup
                  .get('adjuntarFacturaId')
                  .setValue(resp.stored_file_uuid);
              } else {
                this.formGroup.get('adjuntarFacturaId').setValue(1);
              }
            });
          };
        } else {
          if (this.formGroup.get('adjuntarFacturaId').value) {
            this.storedFileService
              .unlikFile(this.formGroup.get('adjuntarFacturaId').value)
              .subscribe(() => {
                this.formGroup.get('adjuntarFacturaId').setValue(null);
              });
          }
        }
      }
    });

    this.formGroup.get('ubicacion').valueChanges.subscribe((obra) => {
      if (
        this.formGroup.get('ubicacion') &&
        this.formGroup.get('ubicacion').value.length !== 0
      ) {
        this.proformaCriteria.company_id = this.companyId;
        this.proformaCriteria.csite_id =
          this.formGroup.get('ubicacion').value[0].id;
        this.searchService
          .getProformas(this.proformaCriteria)
          .subscribe((resp) => {
            if (resp) {
              this.proformaList = resp.values;
              this.proformaList.map((p) => {
                p.selectDescription =
                  'Contrato:' +
                  p.contract_number +
                  ' (' +
                  p.activity +
                  ') Proforma ' +
                  p.proforma_number +
                  ' (Base imponible: ' +
                  this.thousandsPipe.transform(
                    this.currencyPipe.transform(p.base_amount)
                  ) +
                  ')';
              });

              if (this.proformaList.length > 0) {
                this.vatType = this.proformaList[0].vat_lines[0];
              } else {
                this.vatType = null;
              }

              this.changeInputProperty(
                'importeIVA',
                'label',
                `Importe IVA (${this.vatType?.vat_pcent_description || '21%'})`
              );
              this.formGroup.get('proforma').enable();

              if (this.editOn && this.primeraCargaEdicion) {
                this.formGroup
                  .get('adjuntarFacturaId')
                  .setValue(this.invoiceGet.invoiceFileUuid);
                this.storedFileService
                  .getFileInfo(this.invoiceGet.invoiceFileUuid)
                  .subscribe((respFile) => {
                    if (respFile) {
                      this.formGroup.get('adjuntarFactura').setErrors(null);
                      this.formGroup
                        .get('adjuntarFactura')
                        .setValue(respFile.values[0].filename);
                      this.inputs.find(
                        (i) => i.key === 'adjuntarFactura'
                      ).controlValue = respFile.values[0].filename;
                    }
                  });
                if (this.invoiceGet.linkedProforma) {
                  this.searchService
                    .getProformasById(
                      this.invoiceGet.linkedProforma.id.toString()
                    )
                    .subscribe((respProforma) => {
                      if (respProforma) {
                        const proformFromDto: Proforma = respProforma.values[0];
                        proformFromDto.selectDescription =
                          'Contrato: ' +
                          proformFromDto.contract_number +
                          ' (' +
                          proformFromDto.activity +
                          ') Proforma ' +
                          proformFromDto.proforma_number +
                          ' (Base Imponible ' +
                          this.thousandsPipe.transform(
                            this.currencyPipe.transform(
                              proformFromDto.base_amount
                            )
                          ) +
                          ')';
                        this.proformaFromEdit = proformFromDto;

                        if (this.proformaFromEdit) {
                          this.vatType = this.proformaFromEdit.vat_lines[0];
                        } else {
                          this.vatType = null;
                        }

                        this.changeInputProperty(
                          'importeIVA',
                          'label',
                          `Importe IVA (${
                            this.vatType?.vat_pcent_description || '21%'
                          })`
                        );

                        this.formGroup
                          .get('proforma')
                          .setValue([proformFromDto]);
                        this.formGroup
                          .get('baseImponible')
                          .setValue(
                            this.utilsService.formatAutocalculateAmountInput(
                              proformFromDto.base_amount
                            )
                          );
                        this.formGroup
                          .get('formaDePago')
                          .setValue(
                            this.invoiceGet.linkedProforma.paymentMethod
                          );
                        this.formGroup
                          .get('importeProntoPagoCheck')
                          .setValue(this.invoiceGet.hasPromptPayment);
                        this.formGroup
                          .get('tieneProntoPago')
                          .setValue(this.invoiceGet.hasPromptPayment);
                        this.formGroup
                          .get('retencion')
                          .setValue(
                            this.invoiceGet.withholdingAmount !== null
                              ? this.invoiceGet.withholdingAmount
                              : 0
                          );
                        this.formGroup
                          .get('porcientonRetencion')
                          .setValue(
                            (this.invoiceGet.withholdingPerone !== null
                              ? this.invoiceGet.withholdingPerone
                              : 0) * 100
                          );

                        if (
                          this.invoiceGet.promptPaymentAmount !== 0 &&
                          this.is_numeric(
                            this.invoiceGet.promptPaymentAmount
                          ) &&
                          this.invoiceGet.hasPromptPayment
                        ) {
                          this.inputs.find(
                            (c) => c.key === 'importeProntoPago'
                          ).layout = 'col-md-12 col-lg-6';
                          if (!this.editOn) {
                            this.inputs.find(
                              (c) => c.key === 'importeProntoPagoCheck'
                            ).layout = 'col-md-12 col-lg-6';
                          } else {
                            this.inputs.find(
                              (c) => c.key === 'importeProntoPagoCheck'
                            ).layout = 'col-md-12 col-lg-6 invisible';
                          }

                          this.formGroup
                            .get('importeProntoPago')
                            .setValue(
                              this.utilsService.formatAutocalculateAmountInput(
                                this.invoiceGet.promptPaymentAmount
                              )
                            );
                          this.importeProntoPago =
                            this.invoiceGet.promptPaymentAmount;
                          if (this.invoiceGet.hasPromptPayment) {
                            this.formGroup
                              .get('importeIVA')
                              .setValue(
                                this.utilsService.formatAutocalculateAmountInput(
                                  (this.invoiceGet.baseAmount +
                                    this.invoiceGet.promptPaymentAmount) *
                                    Number(this.vatType?.vat_perone)
                                )
                              );
                          } else {
                            this.formGroup
                              .get('importeIVA')
                              .setValue(
                                this.utilsService.formatAutocalculateAmountInput(
                                  this.invoiceGet.baseAmount *
                                    Number(this.vatType?.vat_perone)
                                )
                              );
                          }
                        } else {
                          this.inputs.find(
                            (c) => c.key === 'importeProntoPago'
                          ).layout = 'col-md-12 col-lg-6 invisible';
                          this.inputs.find(
                            (c) => c.key === 'importeProntoPagoCheck'
                          ).layout = 'col-md-12 col-lg-6 invisible';
                          this.formGroup
                            .get('importeIVA')
                            .setValue(
                              this.utilsService.formatAutocalculateAmountInput(
                                this.invoiceGet.baseAmount *
                                  Number(this.vatType?.vat_perone)
                              )
                            );
                        }

                        this.prontoPagoIncluidoCheck = true;
                        this.prontoPagoIncluido = 'SI';

                        this.inversionSujetoPasivo = this.invoiceGet
                          .reverseVatLiability
                          ? 'SI'
                          : 'NO';
                        this.formGroup
                          .get('inversionDeSujetoPasivo')
                          .setValue(proformFromDto.reverse_vat_liability);

                        this.retencion = proformFromDto.withholding_amount
                          ? proformFromDto.withholding_amount
                          : 0;
                        this.porcentajeRentencion =
                          (proformFromDto.withholding_perone
                            ? proformFromDto.withholding_perone
                            : 0) * 100;

                        if (
                          this.formGroup.get('inversionDeSujetoPasivo').value
                        ) {
                          this.inputs.find(
                            (c) => c.key === 'importeIVA'
                          ).layout = 'col-md-12 col-lg-6 invisible';
                          this.inputsProntoPago.find(
                            (c) => c.key === 'importeIVAProntoPago'
                          ).layout = 'col-md-12 col-lg-6 invisible';
                          this.formGroup.get('importeIVA').setValue(0);

                          this.prontoPagoIVA = 0;
                        } else {
                          this.inputs.find(
                            (c) => c.key === 'importeIVA'
                          ).layout = 'col-md-12 col-lg-6';
                          this.inputsProntoPago.find(
                            (c) => c.key === 'importeIVAProntoPago'
                          ).layout = 'col-md-12 col-lg-6';
                        }

                        // Calculo del importe total = Base - Retención - Pronto pago + IVA
                        this.calcularImporteTotal(
                          this.formGroup.get('baseImponible').value,
                          this.formGroup.get('retencion').value,
                          this.formGroup.get('importeProntoPago').value,
                          this.formGroup.get('importeIVA').value
                        );

                        this.primeraCargaEdicion = false;
                        this.editOn = false;
                      } else {
                        this.primeraCargaEdicion = false;
                        this.editOn = false;
                      }
                    });
                } else {
                  this.primeraCargaEdicion = false;
                  this.editOn = false;
                }
              }
              if (resp.count === 1 && !this.cancelaSeleccionIndividual) {
                this.formGroup.get('proforma').setValue([this.proformaList[0]]);
              }
            }
          });
      }

      if (obra[0]) {
        this.formGroup.get('proforma').enable();
      } else {
        this.formGroup.get('proforma').setValue([]);
        this.formGroup.get('proforma').disable();
        this.limpiarFormulario();
      }
    });

    this.formGroup.get('proforma').valueChanges.subscribe((proforma) => {
      this.emitProforma.next(proforma[0]);
      if (proforma?.disabled === undefined) {
        if (
          (this.editOn && !this.primeraCargaEdicion) ||
          (!this.editOn && !this.primeraCargaEdicion)
        ) {
          this.limpiarFormulario();
          if (proforma !== null) {
            if (proforma[0]) {
              const prof: Proforma = proforma[0];

              this.formGroup
                .get('baseImponible')
                .setValue(
                  this.utilsService.formatAutocalculateAmountInput(
                    prof.base_amount
                  )
                );
              this.formGroup.get('formaDePago').setValue(prof.payment_method);
              this.formGroup
                .get('importeProntoPagoCheck')
                .setValue(
                  this.utilsService.formatAutocalculateAmountInput(
                    prof.has_prompt_payment
                  )
                );
              this.formGroup
                .get('tieneProntoPago')
                .setValue(prof.has_prompt_payment);
              this.formGroup.get('retencion').setValue(prof.withholding_amount);
              this.formGroup
                .get('porcientonRetencion')
                .setValue(prof.withholding_perone * 100);

              this.formGroup
                .get('importeProntoPago')
                .setValue(
                  this.utilsService.formatAutocalculateAmountInput(
                    prof.prompt_payment_amount
                  )
                );
              this.importeProntoPago = prof.prompt_payment_amount;

              if (
                prof.prompt_payment_amount !== 0 &&
                this.is_numeric(prof.prompt_payment_amount) &&
                prof.has_prompt_payment
              ) {
                this.inputs.find((c) => c.key === 'importeProntoPago').layout =
                  'col-md-12 col-lg-6';
                if (!this.editOn) {
                  this.inputs.find(
                    (c) => c.key === 'importeProntoPagoCheck'
                  ).layout = 'col-md-12 col-lg-6';
                } else {
                  this.inputs.find(
                    (c) => c.key === 'importeProntoPagoCheck'
                  ).layout = 'col-md-12 col-lg-6 invisible';
                }

                if (prof.has_prompt_payment) {
                  this.formGroup
                    .get('importeIVA')
                    .setValue(
                      this.utilsService.formatAutocalculateAmountInput(
                        (prof.base_amount + prof.prompt_payment_amount) *
                          Number(this.vatType?.vat_perone)
                      )
                    );
                } else {
                  this.formGroup
                    .get('importeIVA')
                    .setValue(
                      this.utilsService.formatAutocalculateAmountInput(
                        prof.base_amount * Number(this.vatType?.vat_perone)
                      )
                    );
                }
              } else {
                this.inputs.find((c) => c.key === 'importeProntoPago').layout =
                  'col-md-12 col-lg-6 invisible';
                this.inputs.find(
                  (c) => c.key === 'importeProntoPagoCheck'
                ).layout = 'col-md-12 col-lg-6 invisible';
                this.formGroup
                  .get('importeIVA')
                  .setValue(
                    this.utilsService.formatAutocalculateAmountInput(
                      prof.base_amount * Number(this.vatType?.vat_perone)
                    )
                  );
              }
              this.prontoPagoIncluidoCheck = prof.has_prompt_payment;
              this.prontoPagoIncluido = prof.has_prompt_payment ? 'SI' : 'NO';

              this.inversionSujetoPasivo = prof.reverse_vat_liability
                ? 'SI'
                : 'NO';
              this.formGroup
                .get('inversionDeSujetoPasivo')
                .setValue(prof.reverse_vat_liability);

              this.retencion = prof.withholding_amount;
              this.porcentajeRentencion = prof.withholding_perone * 100;

              this.prontoPagoBaseImponible = prof.prompt_payment_amount;
              this.prontoPagoIVA =
                prof.prompt_payment_amount * Number(this.vatType?.vat_perone);

              if (this.formGroup.get('inversionDeSujetoPasivo').value) {
                this.inputs.find((c) => c.key === 'importeIVA').layout =
                  'col-md-12 col-lg-6 invisible';
                this.inputsProntoPago.find(
                  (c) => c.key === 'importeIVAProntoPago'
                ).layout = 'col-md-12 col-lg-6 invisible';
                this.formGroup.get('importeIVA').setValue(0);
                this.prontoPagoIVA = 0;
              } else {
                this.inputs.find((c) => c.key === 'importeIVA').layout =
                  'col-md-12 col-lg-6';
                this.inputsProntoPago.find(
                  (c) => c.key === 'importeIVAProntoPago'
                ).layout = 'col-md-12 col-lg-6';
              }

              // Calculo del importe total = Base - Retención - Pronto pago + IVA
              this.calcularImporteTotal(
                this.formGroup.get('baseImponible').value,
                this.formGroup.get('retencion').value,
                this.formGroup.get('importeProntoPago').value,
                this.formGroup.get('importeIVA').value
              );

              // Factura Pronto Pago
              this.prontoPagoTotal =
                this.prontoPagoBaseImponible + this.prontoPagoIVA;

              this.formGroupProntoPago
                .get('baseImponibleProntoPago')
                .setValue(this.prontoPagoBaseImponible);
              this.formGroupProntoPago
                .get('importeIVAProntoPago')
                .setValue(this.prontoPagoIVA);
              this.formGroupProntoPago
                .get('importeTotalFacturaProntoPago')
                .setValue(this.prontoPagoTotal);
            }
          }
        }
        // Obtengo los datos de la factura para la edicion

        if (
          this.editOn &&
          this.primeraCargaEdicion &&
          this.invoiceGet.relatedToRefundUuid !== '' &&
          this.invoiceGet.relatedToRefundUuid !== null &&
          !this.facturaAbonoCargada
        ) {
          this.invoicingQueryService
            .getInvoiceByUuid(this.invoiceGet.relatedToRefundUuid)
            .subscribe((resp) => {
              if (resp.values) {
                this.facturaAbonoCargada = true;
                const facturaAbonoDto: InvoiceGet = InvoiceGet.buildFromDto(
                  resp.values.find(
                    (r) => r.uuid === this.invoiceGet.relatedToRefundUuid
                  )
                );
                const slide = new MatSlideToggleChange(null, false);
                this.prontoPagoEnFactura(slide);
                this.formGroupProntoPago
                  .get('fechaFacturaProntoPago')
                  .setValue(
                    moment
                      .utc(facturaAbonoDto.invoiceDate, ['YYYY/MM/DD'])
                      .format()
                  );
                this.formGroupProntoPago
                  .get('numeroFacturaProntoPago')
                  .setValue(facturaAbonoDto.invoice_number);
                this.prontoPagoBaseImponible = facturaAbonoDto.baseAmount;
                this.prontoPagoIVA =
                  facturaAbonoDto.baseAmount * Number(this.vatType?.vat_perone);
                // Factura Pronto Pago
                if (this.formGroup.get('inversionDeSujetoPasivo').value) {
                  this.prontoPagoTotal = this.prontoPagoBaseImponible;
                } else {
                  this.prontoPagoTotal =
                    this.prontoPagoBaseImponible + this.prontoPagoIVA;
                }

                this.formGroupProntoPago
                  .get('baseImponibleProntoPago')
                  .setValue(this.prontoPagoBaseImponible);
                this.formGroupProntoPago
                  .get('importeIVAProntoPago')
                  .setValue(this.prontoPagoIVA);
                this.formGroupProntoPago
                  .get('importeTotalFacturaProntoPago')
                  .setValue(this.prontoPagoTotal);
                this.formGroupProntoPago
                  .get('PDFFacturaIdProntoPago')
                  .setValue(facturaAbonoDto.invoiceFileUuid);
                this.storedFileService
                  .getFileInfo(facturaAbonoDto.invoiceFileUuid)
                  .subscribe((respFile) => {
                    if (respFile) {
                      this.formGroupProntoPago
                        .get('PDFFacturaProntoPago')
                        .setErrors(null);
                      this.formGroupProntoPago
                        .get('PDFFacturaProntoPago')
                        .setValue(respFile.values[0].filename);
                      this.inputsProntoPago.find(
                        (i) => i.key === 'PDFFacturaProntoPago'
                      ).controlValue = respFile.values[0].filename;
                    }
                  });
              }
            });
        }
      }
    });
  }

  initFormProntoPago() {
    this.formGroupProntoPago = this.fb.group({
      numeroFacturaProntoPago: [
        '',
        { validators: [Validators.required], updateOn: 'blur' },
      ],
      fechaFacturaProntoPago: [
        '',
        { validators: [Validators.required], updateOn: 'blur' },
      ],
      PDFFacturaProntoPago: ['', Validators.required],
      PDFFacturaIdProntoPago: ['', [Validators.required]],
      baseImponibleProntoPago: [''],
      importeTotalFacturaProntoPago: [''],
      importeIVAProntoPago: [''],
    });

    this.inputsProntoPago = new Array<InputField>();
    this.inputsProntoPago.push(
      {
        key: 'fechaFacturaProntoPago',
        type: 'date',
        label: 'Fecha de factura de abono',
        controlValue: '',
        layout: 'col-md-12 col-lg-6',
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
      },
      {
        key: 'numeroFacturaProntoPago',
        type: 'text',
        label: 'Nº de factura de abono',
        controlValue: '',
        layout: 'col-md-12 col-lg-6',
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
      },
      {
        key: 'baseImponibleProntoPago',
        type: 'inputTemplate',
        controlValue: '',
        inputTemplate: this.prontoPagoBaseImponibleSection,
        layout: 'col-md-12 col-lg-12',
      },
      {
        key: 'importeIVAProntoPago',
        type: 'inputTemplate',
        controlValue: '',
        inputTemplate: this.prontoPagoImporteIVASection,
        layout: 'col-md-12 col-lg-12 float-right',
      },
      {
        key: 'importeTotalFacturaProntoPago',
        type: 'inputTemplate',
        label: 'Fecha de factura de abono',
        controlValue: '',
        inputTemplate: this.prontoPagoImporteTotalSection,
        layout: 'col-md-12 col-lg-12',
      },
      {
        key: 'divider5',
        type: 'inputTemplate',
        label: 'Retencion',
        controlValue: '',
        layout: 'col-md-12 col-lg-12',
        inputTemplate: this.dividerSection,
      },
      {
        key: 'PDFFacturaProntoPago',
        type: 'fileDrop',
        label: 'Adjuntar factura de abono',
        controlValue: '',
        layout: 'col-md-12 col-lg-12',
        fileExtensions: ['pdf'],
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
        infoIconShow: true,
        infoIconText:
          'Por favor, adjunte sólo un PDF. No es necesario adjuntar proformas a la factura',
      },
      {
        key: 'divider6',
        type: 'inputTemplate',
        label: 'Retencion',
        controlValue: '',
        layout: 'col-md-12 col-lg-12',
        inputTemplate: this.dividerSection,
      }
    );

    const invoicingNumberValidator = (group: FormGroup): any => {
      if (group) {
        if (
          group &&
          !this.primeraCargaEdicion &&
          (group.get('fechaFacturaProntoPago').touched ||
            group.get('numeroFacturaProntoPago').touched)
        ) {
          if (
            group.get('numeroFacturaProntoPago') &&
            group.get('numeroFacturaProntoPago').value
          ) {
            const date = `${new Date(group.get('fechaFacturaProntoPago').value)
              .getFullYear()
              .toString()}-${(
              new Date(group.get('fechaFacturaProntoPago').value).getMonth() + 1
            ).toString()}-${new Date(group.get('fechaFacturaProntoPago').value)
              .getDate()
              .toString()}`;
            this.invoicingQueryService
              .checkUniqueInvoiceNumber(
                group.get('numeroFacturaProntoPago').value,
                this.companyId,
                date
              )
              .subscribe((resp) => {
                if (resp.is_unique) {
                  group.get('numeroFacturaProntoPago').setErrors(null);
                } else {
                  this.inputsProntoPago.find(
                    (i) => i.key === 'numeroFacturaProntoPago'
                  ).validatorMessages = [
                    { key: 'required', value: REQUIRED_MESSAGE },
                    {
                      key: 'invalidInvoiceNumber',
                      value:
                        INVOICE_NUMBER_ALREADY_EXIST_MESSAGE +
                        new Date(
                          this.formGroupProntoPago.get(
                            'fechaFacturaProntoPago'
                          ).value
                        )
                          .getFullYear()
                          .toString(),
                    },
                  ];
                  group
                    .get('numeroFacturaProntoPago')
                    .setErrors({ invalidInvoiceNumber: true });
                }
              });
          }
        }
      }
    };

    this.formGroupProntoPago.setValidators([invoicingNumberValidator]);

    this.formGroupProntoPago
      .get('PDFFacturaProntoPago')
      .valueChanges.subscribe((adjunto) => {
        if (adjunto?.name !== undefined) {
          const file = new StoredFile();
          const reader = new FileReader();
          reader.readAsDataURL(adjunto);
          reader.onload = () => {
            file.base64 = reader.result.toString().split(',')[1];
            file.filename = adjunto.name;
            file.mimetype = adjunto.type;
            const fileName = adjunto.name.toString().split('.');
            file.file_extension = fileName[fileName.length - 1];
            this.storedFileService.uploadFile(file).subscribe((resp) => {
              if (resp) {
                this.formGroupProntoPago
                  .get('PDFFacturaIdProntoPago')
                  .setValue(resp.stored_file_uuid);
              } else {
                this.formGroupProntoPago
                  .get('PDFFacturaIdProntoPago')
                  .setValue(1);
              }
            });
          };
        } else {
          if (this.formGroupProntoPago.get('PDFFacturaIdProntoPago').value) {
            this.storedFileService
              .unlikFile(
                this.formGroupProntoPago.get('PDFFacturaIdProntoPago').value
              )
              .subscribe(() => {
                this.formGroupProntoPago
                  .get('PDFFacturaIdProntoPago')
                  .setValue(null);
              });
          }
        }
      });
  }

  submit() {
    this.exportForm();
  }

  exportForm() {
    const forms = new Array<FormGroup>();
    forms.push(this.formGroup);
    forms.push(this.formGroupProntoPago);
    this.formGroupEmiter.emit(forms);
  }

  is_numeric(value) {
    return !isNaN(Number(value)) && isFinite(value);
  }

  prontoPagoEnFactura(event: MatSlideToggleChange) {
    this.prontoPagoIncluidoCheck = event.checked;
    this.formGroup
      .get('importeProntoPagoCheck')
      .setValue(this.prontoPagoIncluidoCheck);

    if (event.checked) {
      this.prontoPagoIncluido = 'SI';
      this.inputs.find((c) => c.key === 'importeProntoPago').layout =
        'col-md-12 col-lg-6';
      this.formGroup
        .get('importeProntoPago')
        .setValue(
          this.utilsService.formatAutocalculateAmountInput(
            this.importeProntoPago
          )
        );
      if (this.inversionSujetoPasivo === 'NO') {
        this.formGroup
          .get('importeIVA')
          .setValue(
            this.utilsService.formatAutocalculateAmountInput(
              (this.utilsService.formatAmount(
                this.formGroup.get('baseImponible').value
              ) +
                this.utilsService.formatAmount(
                  this.formGroup.get('importeProntoPago').value
                )) *
                Number(this.vatType?.vat_perone)
            )
          );
      }
    } else {
      this.prontoPagoIncluido = 'NO';
      this.inputs.find((c) => c.key === 'importeProntoPago').layout =
        'col-md-12 col-lg-6 invisible';
      this.formGroup.get('importeProntoPago').setValue(0);
      this.alertService.showDetailInfo(
        '',
        'Si no incluye el descuento por pronto pago en la misma factura, es necesario que además adjunte la factura de abono correspondiente en esta misma ventana para poder registrar la factura.'
      );
      if (this.inversionSujetoPasivo === 'NO') {
        this.formGroup
          .get('importeIVA')
          .setValue(
            this.utilsService.formatAutocalculateAmountInput(
              this.utilsService.formatAmount(
                this.formGroup.get('baseImponible').value
              ) * Number(this.vatType?.vat_perone)
            )
          );
      }
    }
    this.facturaAbonoProntoPago = !event.checked;

    // Calculo del importe total = Base - Retención - Pronto pago + IVA
    this.calcularImporteTotal(
      this.formGroup.get('baseImponible').value,
      this.formGroup.get('retencion').value,
      this.formGroup.get('importeProntoPago').value,
      this.formGroup.get('importeIVA').value
    );
  }

  calcularImporteTotal(
    base: string | number,
    retencion: number,
    prontoPago: string | number,
    iva: string | number
  ) {
    base =
      base === undefined ? 0 : this.utilsService.formatAmount(base as string);
    retencion = retencion === undefined ? 0 : retencion;
    prontoPago =
      prontoPago === undefined
        ? 0
        : this.utilsService.formatAmount(prontoPago as string);
    iva = iva === undefined ? 0 : this.utilsService.formatAmount(iva as string);
    this.formGroup
      .get('importeTotalFactura')
      .setValue(
        this.utilsService.formatAutocalculateAmountInput(
          base + retencion + prontoPago + iva
        )
      );
  }

  limpiarFormulario() {
    this.formGroup.get('baseImponible').setValue(null);
    this.formGroup.get('formaDePago').setValue(null);
    this.formGroup.get('importeIVA').setValue(null);
    this.formGroup.get('importeTotalFactura').setValue(null);
    this.formGroup.get('importeProntoPagoCheck').setValue(null);
    this.formGroup.get('importeProntoPago').setValue(null);
    this.formGroup.get('retencion').setValue(null);
    this.retencion = 0;
    this.porcentajeRentencion = 0;

    this.formGroupProntoPago.get('numeroFacturaProntoPago').setValue(null);
    this.formGroupProntoPago.get('fechaFacturaProntoPago').setValue(null);
    this.formGroupProntoPago.get('baseImponibleProntoPago').setValue(null);
    this.formGroupProntoPago.get('importeIVAProntoPago').setValue(null);
    this.formGroupProntoPago
      .get('importeTotalFacturaProntoPago')
      .setValue(null);
    this.formGroupProntoPago.get('PDFFacturaProntoPago').setValue(null);
    this.formGroupProntoPago.get('PDFFacturaIdProntoPago').setValue(null);
    const slide = new MatSlideToggleChange(null, true); // true
    this.prontoPagoEnFactura(slide);
  }

  changeInputProperty(key: string, property: string, value: any) {
    this.inputs.find((c) => c.key === key)[property] = value;
  }

  backStepOne() {
    this.back.emit(false);
  }
}
