import { MatCheckboxModule } from '@angular/material/checkbox';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { CookieService } from 'ngx-cookie-service';

// Translation
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SharedModule } from './../shared/shared.module';

import { LogoutIconComponent } from './icons/logout-icon/logout-icon.component';
import { AccountIconComponent } from './icons/account-icon/account-icon.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/authentication/login/login.component';
import { ErrorInterceptor } from './services/error.interceptor';
import { OAuthInterceptor } from './services/oauth.interceptor';
import { LoginContainerComponent } from './components/authentication/login-container/login-container.component';
import { SignupComponent } from './components/authentication/signup/signup.component';
import { RecoverComponent } from './components/authentication/recover/recover.component';
import { RecoverResetComponent } from './components/authentication/recover-reset/recover-reset.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { LayoutModule } from '@angular/cdk/layout';
import { SignupConfirmationComponent } from './components/authentication/signup-confirmation/signup-confirmation.component';
import { InvoicesFilterInternalComponent } from './components/invoices/invoices-filter-internal/invoices-filter-internal.component';
import { InvoicesFilterExternalComponent } from './components/invoices/invoices-filter-external/invoices-filter-external.component';
import { InvoicesListContainerComponent } from './components/invoices/invoices-list-container/invoices-list-container.component';
import {
  RecaptchaFormsModule,
  RecaptchaModule,
  RECAPTCHA_BASE_URL,
} from 'ng-recaptcha';
import { CompanySelectorComponent } from './components/company-selector/company-selector.component';
import { InvoiceProformaDeObraComponent } from './components/invoices/invoices-create-second-step/invoice-proforma-de-obra/invoice-proforma-de-obra.component';
import { InvoiceStateToIconPipe } from './pipes/invoice-state-to-icon.pipe';
import { InvoiceTypeToIconPipe } from './pipes/invoice-type-to-icon.pipe';
import { InvoicesCancelConfirmModalComponent } from './components/invoices/invoices-cancel-confirm-modal/invoices-cancel-confirm-modal.component';
import { InvoiceDetailComponent } from './components/invoices/invoice-detail/invoice-detail.component';
import { NoWhiteSpacesPipe } from './pipes/no-white-spaces.pipe';
import { UsersManageContainerComponent } from './components/users/users-manage-container/users-manage-container.component';
import { UsersManageFilterInternalComponent } from './components/users/users-manage-filter-internal/users-manage-filter-internal.component';
import { UsersManageFilterExternalComponent } from './components/users/users-manage-filter-external/users-manage-filter-external.component';
import { UserDismissConfirmModalComponent } from './components/users/user-dismiss-confirm-modal/user-dismiss-confirm-modal.component';
import { UsersRegistryRequestsContainerComponent } from './components/users/users-registry-requests-container/users-registry-requests-container.component';
import { UsersRegistryRequestsListExternalComponent } from './components/users/users-registry-requests-list-external/users-registry-requests-list-external.component';
import { UsersRegistryRequestsListInternalComponent } from './components/users/users-registry-requests-list-internal/users-registry-requests-list-internal.component';
import { UserRegistryConfirmContainerComponent } from './components/users/user-registry-confirm-container/user-registry-confirm-container.component';
import { UserRegistryConfirmInternalComponent } from './components/users/user-registry-confirm-internal/user-registry-confirm-internal.component';
import { UserRegistryDetailContainerComponent } from './components/users/user-registry-detail-container/user-registry-detail-container.component';
import { UserRegistryDetailInternalComponent } from './components/users/user-registry-detail-internal/user-registry-detail-internal.component';
import { UserSettingsContainerComponent } from './components/authentication/settings/user-settings-container/user-settings-container.component';
import { UserSettingsComponent } from './components/authentication/settings/user-settings/user-settings.component';
import { UserRegistryDetailExternalComponent } from './components/users/user-registry-detail-external/user-registry-detail-external.component';
import { InvoicesCreateNewComponent } from './components/invoices/invoices-create-new/invoices-create-new.component';
import { FacturaGenericaComponent } from './components/invoices/invoices-create-second-step/factura-generica/factura-generica.component';
import { AbonoFacturaComponent } from './components/invoices/invoices-create-second-step/abono-factura/abono-factura.component';
import { InvoicesCreateConfirmationComponent } from './components/invoices/invoices-create-confirmation/invoices-create-confirmation.component';

// Creo un loaderFactory para servir los assets en caso de un ng build con AOT
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    LogoutIconComponent,
    AccountIconComponent,
    HomeComponent,
    LoginComponent,
    LoginContainerComponent,
    SignupComponent,
    RecoverComponent,
    RecoverResetComponent,
    WelcomeComponent,
    SignupConfirmationComponent,
    InvoicesFilterInternalComponent,
    InvoicesFilterExternalComponent,
    InvoicesListContainerComponent,
    InvoiceProformaDeObraComponent,
    CompanySelectorComponent,
    InvoiceStateToIconPipe,
    InvoiceTypeToIconPipe,
    InvoicesCancelConfirmModalComponent,
    InvoiceDetailComponent,
    NoWhiteSpacesPipe,
    UsersManageContainerComponent,
    UsersManageFilterInternalComponent,
    UsersManageFilterExternalComponent,
    NoWhiteSpacesPipe,
    UserDismissConfirmModalComponent,
    UsersRegistryRequestsListInternalComponent,
    UsersRegistryRequestsListExternalComponent,
    UsersRegistryRequestsContainerComponent,
    UserRegistryConfirmContainerComponent,
    UserRegistryConfirmInternalComponent,
    UserRegistryDetailExternalComponent,
    UserRegistryDetailContainerComponent,
    UserRegistryDetailInternalComponent,
    UserSettingsContainerComponent,
    UserSettingsComponent,
    InvoicesCreateNewComponent,
    FacturaGenericaComponent,
    AbonoFacturaComponent,
    InvoicesCreateConfirmationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatAutocompleteModule,
    MatCardModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatExpansionModule,
    MatTooltipModule,
    MatBadgeModule,
    MatToolbarModule,
    MatSelectModule,
    MatGridListModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatTabsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TranslateModule,
    LayoutModule,
  ],
  providers: [
    CookieService,
    CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OAuthInterceptor, multi: true },
    {
      provide: RECAPTCHA_BASE_URL,
      useValue: 'https://recaptcha.net/recaptcha/api.js', // use recaptcha.net script source for some of our users
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
