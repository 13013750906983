<div [formGroup]="fGroup" ngClass="{{ 'arpada-select-tipo-1 col-12 ' + input.key }}" [class.ng-select-container-mat-error]="(fGroup.dirty || fGroup.touched ) && fGroup.controls[input.key].errors">

    <mat-label class="labelStyle">{{ input.label | translate }}
        <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
    </mat-label>

    <i *ngIf="input.infoIconShow" class="material-icons material-icons-outlined input-info-icon" matTooltip="{{input.infoIconText | translate}}" matTooltipPosition="below" aria-hidden="true">info
  </i>

    <ng-select class="arpada-custom" [formControlName]="input.key" [items]="(filteredOptions !== null && filteredOptions !== undefined) ? filteredOptions : []" [bindLabel]="input.selectBindLabel" [labelForId]="'combo' + input.key" [readonly]="input.readonly"
        [multiple]="true" [hideSelected]="true" [maxSelectedItems]="multiple ? 999999 : 1" [virtualScroll]="true" (search)="input?.filteredOptions ? find($event) : []" (focus)="input?.filteredOptions ? find('') : []" (remove)="remove($event, input)" (clear)="remove($event, input)"
        placeholder="{{ input.placeholder | translate }}">
        <!-- [disabled]="input.readonly || false" -->

        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">

            <!-- Plantilla option personalizada-->
            <ng-container *ngIf="input?.selectOptionTemplate">
                <ng-template [ngTemplateOutlet]="input?.selectOptionTemplate" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
            </ng-container>

            <!-- Plantilal option genérica -->
            <ng-container *ngIf="!input?.selectOptionTemplate">
                <small>{{ item[input.selectOptionLabel] }}</small>
            </ng-container>

        </ng-template>

    </ng-select>
    <mat-error aria-live="assertive" aria-atomic="true" *ngFor="let message of input.validatorMessages">
        <ng-container *ngIf="(fGroup.controls[input.key]?.dirty || fGroup.controls[input.key]?.touched) && fGroup.controls[input.key]?.errors && fGroup.controls[input.key]?.errors[message.key]">
            {{message.value | translate}}
        </ng-container>
    </mat-error>
</div>

<!-- FILTERED OPTIONS
{{ filteredOptions | json }}
<br>
INPUT OPTIONS:
{{ input.options | json }} -->
