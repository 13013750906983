import { InvoiceGet } from 'src/app/core/models/invoices/invoice-get';
import { InputField } from 'src/app/shared/shared.module';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { REQUIRED_MESSAGE } from 'src/app/app.constants';

@Component({
  selector: 'app-invoices-cancel-confirm-modal',
  templateUrl: './invoices-cancel-confirm-modal.component.html',
  styleUrls: ['./invoices-cancel-confirm-modal.component.scss']
})
export class InvoicesCancelConfirmModalComponent implements OnInit {

  @Output() emitValues = new EventEmitter<any>();

  fGroup: FormGroup;
  inputs: InputField[] = [];
  invoice: InvoiceGet;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<InvoicesCancelConfirmModalComponent>,
  private fb: FormBuilder) {

    this.invoice = data && data.invoice || {};
    this.fGroup = this.fb.group({
      reason: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.configurarInputs();
  }

  private configurarInputs(): void {
    this.inputs.push(
      {
      key: 'reason',
      type: 'textarea',
      label: 'Motivo de la cancelación',
      placeholder: 'Indique brevemente el motivo de la cancelación',
      controlValue: '',
      layout: 'col-12',
      validatorMessages: [{key:'required', value: REQUIRED_MESSAGE}],
    })
  }

  onSubmit() {
    this.emitValues.emit(this.fGroup.get('reason').value);
  }
}
