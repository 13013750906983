import { Component, OnInit, Input, Output, EventEmitter, Renderer2, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutoCompleteComponent implements OnInit, AfterViewInit {

  selectedItem: any;
  parentElement: any;
  arrowKeyPosition = -1;


  @Input() data: Array<Object>;
  @Output() selectedItemEmit = new EventEmitter<Object>();
  @Output() closeEmit = new EventEmitter();

  constructor(private rd: Renderer2) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.parentElement = this.rd.selectRootElement(`.search-results`, true);
  }

  selectNext(): void {
    if (this.arrowKeyPosition < (this.data.length - 1)) {
      this.arrowKeyPosition++;
      const listElement = this.rd.selectRootElement(`li[data-index = "${this.arrowKeyPosition}"]`, true);
      this.handleScroll(this.parentElement, listElement);
    }
  }

  selectPrevious(): void {
    if (this.arrowKeyPosition > 0) {
      this.arrowKeyPosition--;
      const listElement = this.rd.selectRootElement(`li[data-index = "${this.arrowKeyPosition}"]`, true);
      this.handleScroll(this.parentElement, listElement);
    }
  }

  handleScroll(parentElement, listElement): void {
    if (parentElement.getBoundingClientRect().top > listElement.getBoundingClientRect().top) {
      parentElement.scrollTop = (parentElement.scrollTop
        - parentElement.getBoundingClientRect().top
        + listElement.getBoundingClientRect().top);
    } else if (parentElement.getBoundingClientRect().bottom < listElement.getBoundingClientRect().bottom) {
      parentElement.scrollTop = (parentElement.scrollTop
        - parentElement.getBoundingClientRect().bottom
        + listElement.getBoundingClientRect().bottom);
    }
  }

  selectItem(): void {
    if (this.arrowKeyPosition >= 0) {
      this.rd.selectRootElement(`li[data-index = "${this.arrowKeyPosition}"]`, true).click();
    }
  }

  setArrowKeyPosition(i: number): void {
    this.arrowKeyPosition = i;
  }

  onClick(item): void {
    this.selectedItem = item;
    this.selectedItemEmit.emit(item);
  }

  close(): void {
    this.closeEmit.emit(true);
  }

}
