<form [formGroup]="form" (ngSubmit)="submit(form.value)">
  <div class="row">
    <div class="col">
      <mat-form-field [ngClass]="'w-100'">
        <mat-label class="pl-2">{{'Voluntaria' | translate}}</mat-label>
        <input matInput placeholder="{{label}}" [matAutocomplete]="auto" formControlName="inputValue">
        <span matSuffix *ngIf="!form.controls['inputValue'].disabled" (click)="clearFilter()" class="p-2 pb-4">
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
        <mat-autocomplete aria-label="lista elementos" role="listbox" [displayWith]="showDisplay" #auto="matAutocomplete"
          (optionSelected)="optionSelected(form.value)">
          <mat-option role="listitem" *ngFor="let option of filteredOptions | async" [value]="option">
            <span>{{option[bindLabel] || option}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div *ngIf="showButton" class="col-auto mb-2 text-right align-self-center">
      <button mat-raised-button type="submit" color="primary" class="btn-select-add"
      aria-label="Botón submit" matTooltip="Botón submit"
        [disabled]="disabled">{{ submitButtonText }}</button>
    </div>
  </div>
</form>
