import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Parametros } from './../models/parametros.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class ConfigService implements OnDestroy {

  // Ruta base.
  public baseURL: string = environment.apiBaseUrl;
  // Ruta específica.
  private urlApi = `/api/v1/config`;

  private fconCookie = 'FCON';
  private pconCookie = 'PCON';
  private parametros: Parametros;
  private funcionalidades: any;

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  /**
   * Realiza petición get.
   */
  public realizarPeticionGet(url: string): any {
    const urlFinal = `${this.baseURL}${url}`;

    const hs = new HttpHeaders().set('Content-Type', 'application/json' );

    return this.http.get(urlFinal, { headers: hs, withCredentials: false});
  }

  ngOnDestroy(): void {
    this.cookieService.delete(this.pconCookie,'/');
    this.cookieService.delete(this.fconCookie,'/');
  }

  /**
   * Obtiene la versión de la API.
   */
  public obtenerVersionApi(): Observable<any> {
    const url = `${this.urlApi}`;

    return this.realizarPeticionGet(url);
  }

  /**
   * Obtener funcionalidades generales de la aplicación.
   * @returns Observable parámetros.
   */
  public obtenerFuncionalidades(forzarHttp?: boolean): Observable<any> {

    // Buscar si ya están en localstorage
    if (this.obtenerFuncionalidadesCookie() && !forzarHttp) {
      return of(this.funcionalidades);
    } else {
      return this.obtenerFuncionalidadesHttp().pipe(
        map(
          data => {
            this.funcionalidades = data;
            // Se fuerza el refresco de la cookie FCON en toda la aplicación
            this.cookieService.delete(this.fconCookie,'/');
            this.cookieService.delete(this.fconCookie,'/home');
            // Se fuerza el refresco de la cookie PCON en toda la aplicación
            this.cookieService.delete(this.pconCookie,'/');
            this.cookieService.delete(this.pconCookie,'/home');
            this.cookieService.set(this.fconCookie, btoa(JSON.stringify(this.funcionalidades)));
            return data;
          }
        )
      );
    }
  }

  private obtenerFuncionalidadesHttp(): Observable<any> {
    const url = `${this.urlApi}` + "/funcionalidades";
    return this.realizarPeticionGet(url);
  }

  private obtenerFuncionalidadesCookie(): boolean {
    if (this.cookieService.check(this.fconCookie)) {
      this.funcionalidades = JSON.parse(atob(this.cookieService.get(this.fconCookie)));
      return true;
    }
    return false;
  }

  /**
   * Obtener parámetros generales de la aplicación.
   * @returns Observable parámetros.
   */
  public obtenerParametros(forzarHttp?: boolean): Observable<any> {

    // Buscar si ya están en localstorage
    if (this.obtenerParametrosCookie() && !forzarHttp) {
      return of(this.parametros);
    } else {
      return this.obtenerParametrosHttp().pipe(
        map(
          data => {
            this.parametros = new Parametros(data);
            this.cookieService.set(this.pconCookie, btoa(JSON.stringify(this.parametros)));
            return this.parametros;
          }
        )
      );
    }
  }

  private obtenerParametrosHttp(): Observable<any> {
    const url = `${this.urlApi}` + "/parametros";
    return this.realizarPeticionGet(url);
  }

  private obtenerParametrosCookie(): boolean {
    if (this.cookieService.check(this.pconCookie)) {
      this.parametros = JSON.parse(atob(this.cookieService.get(this.pconCookie)));
      return true;
    }
    return false;
  }
}
