<div class="container mt-3" role="main">
    <mat-card class="fullscreen" style="width: 100%">
      <h1><img class="logoImg" src="../../../../assets/logo.png" alt="Logo" role="img"></h1>
      <mat-card-content>
        <div class="col-12">
          <h1 class="text-monospace text-black-50 mt-5">
            <mat-icon matPrefix>
              <i class="fa fa-exclamation-triangle"
                style="font-size: 30px; color: red"
                aria-hidden="true"></i>
            </mat-icon>
            ERROR</h1>
          <h2>{{ 'Ocurrió un error al realizar la solicitud' | translate}}</h2>
        </div>
      </mat-card-content>
    </mat-card>
</div>
