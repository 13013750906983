import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor() { }

  public getNumberFormatted(value: any, locale: string = 'es') {
    let formated = formatNumber(value, locale);
    return formated;
  }
}
