import { UserRelCompanyGet } from '../../../../shared/models/company.model';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputField } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-user-dismiss-confirm-modal',
  templateUrl: './user-dismiss-confirm-modal.component.html',
  styleUrls: ['./user-dismiss-confirm-modal.component.scss']
})
export class UserDismissConfirmModalComponent implements OnInit {

  @Output() emitValues = new EventEmitter<any>();

  fGroup: FormGroup;
  inputs: InputField[] = [];
  user: UserRelCompanyGet;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<UserDismissConfirmModalComponent>,
  private fb: FormBuilder) {

    this.user = data && data.user || {};
    this.fGroup = this.fb.group({
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.emitValues.emit();
  }
}
