import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noWhiteSpaces'
})
export class NoWhiteSpacesPipe implements PipeTransform {

  transform(text: string): string {
    if (text)  {
      return text.replace(/\s/g, "");
    }
    return text;
  }

}
