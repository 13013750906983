import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.css']
})
export class BackButtonComponent implements OnInit {

  constructor(private location: Location, private router: Router) { }

  @Input() routerLink;
  @Input() isDisabled = false;
  ngOnInit(): void {
  }

  navigate(): void {
    if (this.routerLink) {
      this.router.navigateByUrl(this.routerLink);
    } else {
      this.location.back();
    }
  }



}
