import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Observer } from 'rxjs/internal/types';


@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor() { }


  private dictKeyObservableData: object = {};
  private readonly observerDictKey: string = 'observer';
  private readonly timeoutDictKey: string = 'timeoutId';

  public delayCallXMillisecondsAvoidRepetition<T>(
    uniqueKey: string, methodCall: () => Observable<T>, msTime: number = 300,
  ): Observable<T>{
    let observerControl: Observer<T> = null;
    let observableToReturn: Observable<T> = new Observable<T>((observer: Observer<T>) => {
      observerControl=observer;
    });
    if(this.dictKeyObservableData && this.dictKeyObservableData[uniqueKey]){
      const timeoutId = this.dictKeyObservableData[uniqueKey][this.timeoutDictKey];
      const observerToCancel: Observer<T> = this.dictKeyObservableData[uniqueKey][this.observerDictKey];
      try{
        window.clearTimeout(timeoutId);
        if (observerToCancel)
        {
          observerToCancel.complete();
        }
        delete this.dictKeyObservableData[uniqueKey];
      }catch(error){
        console.error(error)
      }
    }
    const timeoutId = window.setTimeout(()=>{
      const mainObservable: Observable<T> = methodCall();
      mainObservable.subscribe((dataReturn: T)=>{
        observerControl.next(dataReturn);
      },(error) => {
        observerControl.error(error);
      },() => {
        observerControl.complete();
        try{
          delete this.dictKeyObservableData[uniqueKey];
        }catch(error){
          console.error(error);
        }
      });
    }, msTime)
    const objToSave: object = {};
    objToSave[this.timeoutDictKey] = timeoutId;
    objToSave[this.observerDictKey] = observerControl;
    this.dictKeyObservableData[uniqueKey] = objToSave;
    return observableToReturn;
  }

}
