<ng-container [formGroup]="fGroup" *ngIf="fGroup && inputFields">
    <div class="row">
        <ng-container *ngFor="let input of inputFields; let i = index;">
            <ng-container [ngSwitch]="input.type">
                <!------------------------------>
                <!-- <ng-template #typeText > -->
                <!------------------------------>
                <ng-container *ngSwitchCase="'text'">
                    <div class="input-wrapper" ngClass="{{(input.layout || layout)}}">
                        <mat-label class="labelStyle">{{ input.label | translate}}
                            <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
                        </mat-label>
                        <i *ngIf="input.infoIconShow" class="material-icons material-icons-outlined input-info-icon" matTooltip="{{input.infoIconText | translate}}" #tooltip="matTooltip" (click)="mobile ? tooltip.toggle() : null" matTooltipPosition="below" aria-hidden="true">info
                        </i>
                        <mat-form-field ngClass="{{ 'col-12 ' + input.key + getReadOnlyClass(input.readonly) }}">
                            <!-- Free typing -->
                            <input matInput type="text" name="{{input.key}}" [formControlName]="input.key" placeholder="{{ input.placeholder | translate }}" maxlength="{{ input.maxLength || 255}}" [autocomplete]="input.autocomplete" [readonly]="input.readonly" [value]="input.controlValue.value || input.controlValue"
                                (focusout)="focusOut(input.key, input)" />

                            <button *ngIf="input.associatedButton" [attr.aria-label]="input.associatedButton.ariaLabel" mat-icon-button matSuffix (click)="input.associatedButton.function.apply(null)" color="primary" type="button" [matTooltip]="input.associatedButton.ariaLabel" class="noOutline">
                                <mat-icon aria-hidden="true">{{input.associatedButton.icon}}</mat-icon>
                                <!-- <i class="{{input.associatedButton.icon}} sufix" aria-hidden="true"></i> -->
                            </button>
                            <mat-error aria-live="assertive" aria-atomic="true" *ngFor="let message of input.validatorMessages">
                                <ng-container *ngIf="(fGroup.dirty  || fGroup.touched ) && fGroup.controls[input.key].errors && fGroup.controls[input.key].errors[message.key]">
                                    {{message.value | translate}}
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>

                <!------------------------------>
                <!-- <ng-template #password > -->
                <!------------------------------>

                <ng-container *ngSwitchCase="'password'">
                    <div class="input-wrapper" ngClass="{{(input.layout || layout)}}">
                        <mat-label class="labelStyle">{{ input.label | translate}}
                            <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
                        </mat-label>
                        <i *ngIf="input.infoIconShow" class="material-icons material-icons-outlined input-info-icon" matTooltip="{{input.infoIconText | translate}}" #tooltip="matTooltip" (click)="mobile ? tooltip.toggle() : null" matTooltipPosition="below" aria-hidden="true">info
                        </i>
                        <mat-form-field ngClass="{{ 'col-12 ' + input.key + getReadOnlyClass(input.readonly) }}">
                            <input matInput type="password" name="{{input.key}}" [formControlName]="input.key" placeholder="{{ input.placeholder | translate }}" maxlength="{{ input.maxLength || 255}}" [readonly]="input.readonly" [value]="input.controlValue.value || input.controlValue"
                            />
                            <button *ngIf="input.associatedButton" [attr.aria-label]="input.associatedButton.ariaLabel" mat-icon-button [matTooltip]="input.associatedButton.ariaLabel" matSuffix (click)="input.associatedButton.function.apply(null)" color="primary" type="button" class="ml-2 btn-save">
                                <!-- <i class="{{input.associatedButton.icon}}" aria-hidden="true"></i> -->
                                <mat-icon aria-hidden="true">{{input.associatedButton.icon}}</mat-icon>
                            </button>
                            <mat-error aria-live="assertive" aria-atomic="true" *ngFor="let message of input.validatorMessages">
                                <ng-container *ngIf="(fGroup.dirty  || fGroup.touched ) && fGroup.controls[input.key].errors && fGroup.controls[input.key].errors[message.key]">
                                    {{message.value | translate}}
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>
                <!-------------------------------->
                <!-- <  ng-template #amount > -->
                <!-------------------------------->

                <ng-container *ngSwitchCase="'amount'">
                    <div ngClass="{{(input.layout || layout)}}">
                        <div *ngIf="input.display === undefined || input.display" class="input-wrapper">
                            <div class="importeTotalContainer">
                                <mat-label class="labelStyle">{{ input.label | translate}}
                                    <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
                                </mat-label>
                                <i *ngIf="input.infoIconShow" class="material-icons material-icons-outlined input-info-icon" matTooltip="{{input.infoIconText | translate}}" #tooltip="matTooltip" (click)="mobile ? tooltip.toggle() : null" matTooltipPosition="below" aria-hidden="true">info
            </i>
                                <mat-form-field ngClass="{{ 'col-12 ' + input.key + getReadOnlyClass(input.readonly) }}">
                                    <div class="container">
                                        <input class="amount-input" matInput appAmount [mode]="input.amountMode" name="{{input.key}}" [formControlName]="input.key" placeholder="{{ input.placeholder  || input.label | translate}}" [readonly]="input.readonly" (focus)="$event.target.select()" [errorStateMatcher]="matcher"
                                        />
                                        <span [ngStyle]="{'color': input.readonly ? 'darkgrey' : 'dark' }">€</span>
                                    </div>
                                    <mat-error aria-live="assertive" aria-atomic="true" *ngFor="let message of input.validatorMessages">
                                        <ng-container *ngIf="(fGroup.controls[input.key]?.dirty || fGroup.controls[input.key]?.touched ) && fGroup.controls[input.key]?.errors && fGroup.controls[input.key]?.errors[message.key]">
                                            {{message.value | translate}}
                                        </ng-container>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                        </div>
                    </div>

                </ng-container>


                <!-------------------------------->
                <!-- <ng-template #button > -->
                <!-------------------------------->

                <ng-container *ngSwitchCase="'button'">
                    <section ngClass="{{ (input.layout || layout) + ' ' + input.key }}">
                        <button (click)="input.function.apply(null)" mat-flat-button color="primary" type="button" class=" w-100 btn-save"> <i class="material-icons material-icons-outlined"
                                style="font-size: 17px" aria-hidden="true">{{input.icon}}</i> {{input.key | translate}}
                        </button>
                    </section>
                </ng-container>

                <!------------------------------>
                <!-- <ng-template #typeTextArea > -->
                <!------------------------------>
                <ng-container *ngSwitchCase="'textarea'">
                    <div class="input-wrapper" ngClass="{{(input.layout || layout)}}">
                        <mat-label class="labelStyle">{{ input.label | translate}}
                            <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
                        </mat-label>
                        <i *ngIf="input.infoIconShow" class="material-icons material-icons-outlined input-info-icon" matTooltip="{{input.infoIconText | translate}}" #tooltip="matTooltip" (click)="mobile ? tooltip.toggle() : null" matTooltipPosition="below" aria-hidden="true">info
                        </i>
                        <mat-form-field ngClass="{{ 'col-12 ' + input.key + getReadOnlyClass(input.readonly) }}">
                            <textarea matInput type="textarea" name="{{input.key}}" [formControlName]="input.key" placeholder="{{ input.placeholder || input.label | translate }}" maxlength="{{ input.maxLength || 255}}"></textarea>
                            <mat-error aria-live="assertive" aria-atomic="true" *ngFor="let message of input.validatorMessages">
                                <ng-container *ngIf="(fGroup.dirty || fGroup.touched )   && fGroup.controls[input.key].errors && fGroup.controls[input.key].errors[message.key]">
                                    {{message.value | translate}}
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>


                <!------------------------------>
                <!-- <ng-template #typeNumber > -->
                <!------------------------------>
                <ng-container *ngSwitchCase="'number'">
                    <div class="input-wrapper" ngClass="{{(input.layout || layout)}}">
                        <mat-label class="labelStyle">{{ input.label | translate}}
                            <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
                        </mat-label>
                        <i *ngIf="input.infoIconShow" class="material-icons material-icons-outlined input-info-icon" matTooltip="{{input.infoIconText | translate}}" #tooltip="matTooltip" (click)="mobile ? tooltip.toggle() : null" matTooltipPosition="below" aria-hidden="true">info
                        </i>
                        <mat-form-field ngClass="{{ 'col-12 ' + input.key + getReadOnlyClass(input.readonly) }}">
                            <input matInput type="number" name="{{input.key}}" [formControlName]="input.key" placeholder="{{ input.placeholder  || input.label | translate}}" [readonly]="input.readonly" />
                            <mat-error aria-live="assertive" aria-atomic="true" *ngFor="let message of input.validatorMessages">
                                <ng-container *ngIf="(fGroup.dirty || fGroup.touched )   && fGroup.controls[input.key].errors && fGroup.controls[input.key].errors[message.key]">
                                    {{message.value | translate}}
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>


                <!-------------------------------->
                <!-- <ng-template #typeSelect > -->
                <!-------------------------------->

                <ng-container *ngSwitchCase="'select'">
                    <ng-container *ngIf="!input.readonly && !input.loading">
                        <app-select [input]="input" [fGroup]="fGroup" (selectedEmit)="selectElement(input.key, $event)">
                        </app-select>
                    </ng-container>
                    <ng-container *ngIf="input.readonly || input.loading">
                        <mat-form-field ngClass="{{ (input.layout || layout) + ' ' + input.key + getReadOnlyClass(input.readonly) }}">
                            <mat-label class="labelStyle">{{ input.label | translate}}</mat-label>
                            <i *ngIf="input.infoIconShow" class="material-icons material-icons-outlined input-info-icon" matTooltip="{{input.infoIconText | translate}}" #tooltip="matTooltip" (click)="mobile ? tooltip.toggle() : null" matTooltipPosition="below" aria-hidden="true">info
                            </i>
                            <input matInput type="text" name="{{input.key}}" placeholder="{{ input.placeholder || input.label | translate }}" autocomplete="off" [readonly]="input.readonly" [value]="returnValue(input.controlValue)" />
                            <mat-progress-spinner matSuffix *ngIf="input.loading" [diameter]="18" aria-label="Cargando información" color="primary" mode="indeterminate" style="float: right; margin-left: 8px"></mat-progress-spinner>
                        </mat-form-field>
                    </ng-container>
                </ng-container>

                <!-------------------------------->
                <!-- <ng-template #multiselect > -->
                <!-------------------------------->
                <ng-container *ngSwitchCase="'multiselect'">
                    <mat-form-field ngClass="{{ (input.layout || layout) + ' ' + input.key + getReadOnlyClass(input.readonly) }}">
                        <mat-label class="labelStyle">{{ input.label | translate}}</mat-label>
                        <mat-select [formControlName]="input.key" multiple>
                            <mat-option *ngIf="!input.hideFirstOption">--</mat-option>
                            <mat-option *ngFor="let option of input.options || input.selectValues" [value]="option">
                                {{ option[input.selectBindLabel] || option }}</mat-option>
                        </mat-select>
                        <mat-error aria-live="assertive" aria-atomic="true" *ngFor="let message of input.validatorMessages">
                            <ng-container *ngIf="(fGroup.dirty || fGroup.touched )   && fGroup.controls[input.key].errors && fGroup.controls[input.key].errors[message.key]">
                                {{message.value | translate}}
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </ng-container>

                <!-------------------------------->
                <!-- <ng-template #typeSelectV2 > -->
                <!-------------------------------->
                <ng-container *ngSwitchCase="'selectV2'">
                    <app-selectv2 ngClass="{{ (input.layout || layout) + ' ' + input.key + getReadOnlyClass(input.readonly) }}" [formGroup]="fGroup" [items]="input.selectValues" [bindLabel]="input.selectBindLabel" [label]="input.label" [selectControl]="fGroup.controls[input.key]"
                        [selectControlName]="input.key" [validatorMessages]="input.validatorMessages">
                    </app-selectv2>
                </ng-container>

                <!----------------------------------->
                <!-- <ng-template #multiSelectV2 > -->
                <!----------------------------------->
                <ng-container *ngSwitchCase="'multiselectV2'">
                    <app-multi-selectv2 ngClass="{{ (input.layout || layout) + ' ' + input.key }}" [formGroup]="fGroup" [items]="input.selectValues" [bindLabel]="input.selectBindLabel" [label]="input.label | translate" [selectControl]="fGroup.controls[input.key]" [selectControlName]="input.key"
                        [validatorMessages]="input.validatorMessages">
                    </app-multi-selectv2>
                </ng-container>

                <!-------------------------------------->
                <!-- <ng-template #select-chip > -->
                <!-------------------------------------->
                <!-- <ng-container *ngSwitchCase="'select-chip'">
          <div class="input-wrapper" ngClass="{{(input.layout || layout)}}">
            <mat-label class="labelStyle">{{ input.label | translate}}
              <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
            </mat-label>
            <mat-form-field ngClass="{{ 'col-12 ' + input.key + getReadOnlyClass(input.readonly) }}">
              <mat-select [formControlName]="input.key" [placeholder]="input.placeholder || ''">
                  <mat-select-trigger>
                    <mat-chip-list [selectable]="false">
                      <mat-chip *ngIf="fGroup.get(input.key).value"
                        (removed)="input.remove? input.remove(fGroup.get(input.key).value) : removeChip(input.key, fGroup.get(input.key).value)">
                        <mat-icon matChipRemove>cancel</mat-icon>
                        {{ fGroup.get(input.key)?.value[input.selectBindLabel] }}
                      </mat-chip>
                    </mat-chip-list>
                </mat-select-trigger>
                <mat-option *ngFor="let option of input.options" [value]="option">{{ option[input.selectBindLabel] }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container> -->


                <!-------------------------------------->
                <!-- <ng-template #multiselect-chip > -->
                <!-------------------------------------->
                <!-- <ng-container *ngSwitchCase="'multiselect-chip'">
          <div class="input-wrapper" ngClass="{{(input.layout || layout)}}">
            <mat-label class="labelStyle">{{ input.label | translate}}
              <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
            </mat-label>
            <mat-form-field ngClass="{{ 'col-12 ' + input.key + getReadOnlyClass(input.readonly) }}">
              <mat-select [formControlName]="input.key" [placeholder]="input.placeholder || ''" multiple>
                  <mat-select-trigger>
                    <mat-chip-list [selectable]="false">
                      <mat-chip *ngFor="let option of fGroup.get(input.key).value" (removed)="input.remove? input.remove(option) : removeChip(input.key, option)">
                        <mat-icon matChipRemove>cancel</mat-icon>
                        {{ option[input.selectBindLabel] }}
                      </mat-chip>
                    </mat-chip-list>
                </mat-select-trigger>
                <mat-option *ngFor="let option of input.options" [value]="option">{{ option[input.selectBindLabel] }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container> -->

                <!--------------------------------------------------->
                <!-- <ng-template #autocomplete single selection > -->
                <!--------------------------------------------------->
                <ng-container *ngSwitchCase="'autocomplete-chip'">
                    <ng-container *ngIf="input.display === undefined || input.display">
                        <div class="input-wrapper mat-form-field-wrapper select-input-height" ngClass="{{(input.layout || layout)}}">
                            <app-autocomplete-chips [fGroup]="fGroup" [input]="input" [multiple]="false">
                            </app-autocomplete-chips>
                        </div>
                    </ng-container>
                </ng-container>

                <!----------------------------------------------------->
                <!-- <ng-template #autocomplete multiple selection > -->
                <!----------------------------------------------------->
                <ng-container *ngSwitchCase="'autocomplete-multi-chip'">
                    <ng-container *ngIf="input.display === undefined || input.display">
                        <div class="input-wrapper mat-form-field-wrapper select-input-height" ngClass="{{(input.layout || layout)}}">
                            <app-autocomplete-chips [fGroup]="fGroup" [input]="input" [multiple]="true">
                            </app-autocomplete-chips>
                        </div>
                    </ng-container>
                </ng-container>


                <!--------------------------------->
                <!-- <ng-template #typeCheckbox> -->
                <!--------------------------------->
                <div *ngSwitchCase="'checkbox'" class="{{ (input.layout || checkboxLayout) + ' ' + input.key }}">
                    <mat-checkbox color="primary" name="{{input.key}}" [formControlName]="input.key">
                        {{ input.label | translate}}</mat-checkbox>
                </div>


                <!------------------------------->
                <!-- <ng-template #datePicker> -->
                <!------------------------------->

                <ng-container *ngSwitchCase="'date'">
                    <div class="input-wrapper" ngClass="{{(input.layout || layout)}}">
                        <mat-label class="labelStyle">{{ input.label | translate}}
                            <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
                        </mat-label>
                        <i *ngIf="input.infoIconShow" class="material-icons material-icons-outlined input-info-icon" matTooltip="{{input.infoIconText | translate}}" #tooltip="matTooltip" (click)="mobile ? tooltip.toggle() : null" matTooltipPosition="below" aria-hidden="true">info
                        </i>
                        <mat-form-field ngClass="{{ 'col-12 ' + input.key }}">
                            <input matInput [matDatepicker]="picker_i" [matDatepickerFilter]="input.dateFilter" [formControlName]="input.key" class="{{input.key}} bg-initial w-100" name="{{ input.key }}">
                            <mat-datepicker-toggle touchUi matSuffix [for]="picker_i"></mat-datepicker-toggle>
                            <mat-datepicker #picker_i></mat-datepicker>
                            <mat-error aria-live="assertive" aria-atomic="true" *ngFor="let message of input.validatorMessages">
                                <ng-container *ngIf="(fGroup.dirty || fGroup.touched )   && fGroup.controls[input.key].errors && fGroup.controls[input.key].errors[message.key]">
                                    {{message.value | translate}}
                                </ng-container>
                            </mat-error>
                        </mat-form-field>

                    </div>
                </ng-container>

                <!------------------------------->
                <!-- <ng-template #dateFromTo> -->
                <!------------------------------->

                <ng-container *ngSwitchCase="'dateFromTo'">

                    <div class="input-wrapper input-wrapper-date-FromTo" ngClass="{{(input.layout || layout)}}" style="display: flex;">

                        <div class="input-wrapper-date-from" style="width:49%">
                            <mat-label class="labelStyle" style="display: block">{{ input.label | translate}}
                                <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
                            </mat-label>
                            <mat-form-field class="col-12 m-0 p-0">
                                <input matInput [matDatepicker]="frompicker_i" [matDatepickerFilter]="input.dateFromFilter" [formControlName]="'from' + input.key" name="from{{ input.key }}" class="w-100 bg-initial" placeholder="{{ 'Desde' | translate }}">
                                <mat-datepicker-toggle touchUi matSuffix [for]="frompicker_i"></mat-datepicker-toggle>
                                <mat-datepicker #frompicker_i></mat-datepicker>
                                <mat-error aria-live="assertive" aria-atomic="true" *ngFor="let message of input.validatorMessages">
                                    <ng-container *ngIf="(fGroup.dirty || fGroup.touched )   && fGroup.controls['from' + input.key].errors && fGroup.controls['from' + input.key].errors[message.key]">
                                        {{message.value | translate}}
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="input-wrapper-date-to" style="width:49%">
                            <mat-label class="labelStyle" style="display: block; visibility: hidden">{{ input.label | translate}}
                                <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
                            </mat-label>
                            <mat-form-field class="col-12">
                                <input matInput [matDatepicker]="topicker_i" [matDatepickerFilter]="input.dateToFilter" [formControlName]="'to' + input.key" name="to{{ input.key }}" class="w-100 bg-initial" placeholder="{{ 'Hasta' | translate }}">
                                <mat-datepicker-toggle touchUi matSuffix [for]="topicker_i "></mat-datepicker-toggle>
                                <mat-datepicker #topicker_i></mat-datepicker>
                                <mat-error aria-live="assertive" aria-atomic="true" *ngFor="let message of input.validatorMessages">
                                    <ng-container *ngIf="(fGroup.dirty || fGroup.touched )   && fGroup.controls['to' + input.key].errors && fGroup.controls['to' + input.key].errors[message.key]">
                                        {{message.value | translate}}
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>

                <!--------------------------------->
                <!-- <ng-template #dateRange> -->
                <!--------------------------------->
                <ng-container *ngSwitchCase="'dateRange'">
                    <div class="input-wrapper" ngClass="{{(input.layout || layout)}}">
                        <mat-label class="labelStyle">{{ input.label | translate}}
                            <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
                        </mat-label>
                        <mat-form-field ngClass="{{ 'col-12 ' + input.key + getReadOnlyClass(input.readonly) }}">
                            <input matInput name="{{input.key}}" [formControlName]="input.key" [placeholder]="input.placeholder" [satDatepicker]="picker" [value]="null">
                            <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
                            <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
                        </mat-form-field>
                    </div>
                </ng-container>


                <!--------------------------------->
                <!-- <ng-template #streetFinder> -->
                <!--------------------------------->
                <!-- <ng-container *ngSwitchCase="'streetFinder'">
                    <street-finder ngClass="{{ (input.layout || layout) + ' ' + input.key }}"
                        [formControlName]="input.key">
                    </street-finder>
                </ng-container> -->

                <!------------------------------>
                <!-- <ng-template #TimeInput> -->
                <!------------------------------>
                <!-- <ng-container *ngSwitchCase="'time'">
                    <time-input class="{{ (input.layout || layout) + ' ' + input.key }}" [fGroup]="fGroup"
                        [fControlName]="input.key" [placeholder]="input.label || input.placeholder"></time-input>
                </ng-container> -->


                <!------------------------------>
                <!-- <ng-template #file> ------->
                <!------------------------------>
                <ng-container *ngSwitchCase="'file'">
                    <attach-input class="{{ (input.layout || layout) + ' ' + input.key }}" [placeholder]="input.label || input.placeholder" [formControlName]="input.key" [extensions]="input.fileExtensions">
                    </attach-input>
                </ng-container>

                <!------------------------------>
                <!-- <ng-template #menu> ------->
                <!------------------------------>
                <ng-container *ngSwitchCase="'menu'">
                    <button mat-button style="max-width: 100%; border: 1px solid #ececec;
                        border-radius: 5px; font-weight: bold; line-height: 26px; height: 30px;" [matTooltip]="input.placeholder" [matMenuTriggerFor]="menu">
                        <span style="display: inline-flex;
                        align-items: center;">
                          <span style="display: block;
                          max-width: 8rem;
                          overflow: hidden;
                          white-space: nowrap;
                          text-overflow: ellipsis;">{{input.placeholder}}</span>
                          <mat-icon aria-hidden="true" style="color: #67b147;">expand_more</mat-icon>
                        </span>

                    </button>
                    <!-- Este menu es unico para la seleccion de compañia en el home, por lo que tiene harcodeos realizados para que se adapten las opciones a la compañia -->
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngFor="let item of input.options" [matTooltip]="item.company_name" (click)="selectElement(input.key, item)">
                          {{item.company_name}}
                        </button>
                    </mat-menu>
                </ng-container>

                <!------------------------------>
                <!-- <ng-template #fileDrop> ------->
                <!------------------------------>
                <ng-container *ngSwitchCase="'fileDrop'">
                    <ng-container *ngIf="input.display === undefined || input.display">
                        <div class="input-wrapper" ngClass="{{(input.layout || layout)}}">
                            <mat-label class="labelStyle">{{ input.label | translate}}
                                <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
                            </mat-label>
                            <i *ngIf="input.infoIconShow" class="material-icons material-icons-outlined input-info-icon" matTooltip="{{input.infoIconText | translate}}" #tooltip="matTooltip" (click)="mobile ? tooltip.toggle() : null" matTooltipPosition="below" aria-hidden="true">info
                        </i>
                            <attach-input-drop [display]="input.display === undefined || input.display" class="{{ (input.layout || layout) + ' ' + input.key }}" [placeholder]="input.label || input.placeholder" [formControlName]="input.key" [extensions]="input.fileExtensions" [htmlFileName]="input.controlValue">
                            </attach-input-drop>
                        </div>
                    </ng-container>

                </ng-container>

                <!--------------------------------->
                <!------ <ng-template #noneArea> ------>
                <!--------------------------------->
                <div *ngSwitchCase="'noneArea'" class="{{ (input.layout || layout) + ' ' + input.key }} mb-2">
                    <div><strong>{{ input.label }}:</strong></div>
                    <p class="{{input.key + ' pre-wrap mb-0'}}">
                        {{ input.controlValue == null ? '--' : input.controlValue.value != null ? input.controlValue.value : input.controlValue }}
                    </p>
                </div>


                <!--------------------------------->
                <!------ <ng-template #none> ------>
                <!--------------------------------->
                <div *ngSwitchCase="'none' || !input.type" class="{{ (input.layout || layout) + ' ' + input.key }} mb-2">
                    <span><strong>{{ input.label | translate }}: </strong>
                        <span class="{{input.key + ' pre-wrap'}}">{{ input.controlValue == null ? '--' :
                            input.controlValue.value != null ? input.controlValue.value : input.controlValue
                            }}</span></span>
                </div>

                <!--------------------------------->
                <!----- <ng-template #blank> ------>
                <!--------------------------------->
                <div *ngSwitchCase="'blank'" class="{{ (input.layout || layout) }}">
                </div>

                <!--------------------------------->
                <!----- <ng-template #inputTemplate> ------>
                <!--------------------------------->
                <div *ngSwitchCase="'inputTemplate'" class="{{ input.display === undefined || input.display ? (input.layout || layout) : '' }} mb-2">
                    <ng-container *ngIf="input.display === undefined || input.display" [ngTemplateOutlet]="input.inputTemplate" [ngTemplateOutletContext]="{ $implicit: input, index: i, formGroup: fGroup }">
                    </ng-container>
                </div>

                <!----------------------------------->
                <!------ <ng-template #submit> ------>
                <!----------------------------------->
                <div *ngSwitchCase="'submit'" class="{{ input.layout || 'col text-right align-self-center' }}">
                    <button mat-raised-button type="submit" color="primary" [disabled]="!fGroup.valid" class="btn-save" aria-label="Botón submit">{{ input.label }}</button>
                </div>

                <ng-container #toggleTemplate *ngSwitchCase="'toggle'">
                    <div style="min-height: 70px;" *ngIf="input.display === undefined || input.display" class="{{ (input.layout || layout) }}">
                        <mat-label style="display: inline-flex; align-items: center">{{ input.label | translate}}
                            <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
                            <i *ngIf="input.infoIconShow" class="material-icons material-icons-outlined info-icon-pronto-pago" [matTooltip]="input.infoIconText" matTooltipPosition="below" aria-hidden="true">info
                    </i>
                        </mat-label>
                        <br>
                        <mat-slide-toggle [color]="'primary'" style="margin-top: 0.5rem; font-weight: 500;" [formControlName]="input.key" (change)="input.toggleChange($event)">{{ input.toggleText }}</mat-slide-toggle>
                    </div>
                </ng-container>

                <div *ngSwitchCase="'divider'" class="{{ (input.layout || layout) }} mb-2 margin-top">
                    <ng-container *ngIf="input.display === undefined || input.display">
                        <span style="font-weight: bold; color: #3F6F55 !important;">{{input.label}}</span>
                        <mat-divider style="margin-top: 0.4rem;" [inset]="true"></mat-divider>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
