import { UtilsService } from 'src/app/shared/services/utils.service';
import { InvoiceCommandsService } from './../../../services/invoice-commands.service';
import { LoginService } from './../../../services/login.service';
import { Usuario } from './../../../../shared/models/usuario.model';
import { StoredFilesService } from './../../../services/stored-files.service';
import { InvoicingQueryService } from './../../../services/invoicing-query.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from './../../../../shared/services/alert.service';
import { InvoiceGet, VatLine } from './../../../models/invoices/invoice-get';
import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { InvoiceType } from 'src/app/core/models/invoices/invoice-type.model';
import { forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss'],
})
export class InvoiceDetailComponent implements OnInit {
  invoiceId: string;

  invoice: InvoiceGet;
  linkedInvoice: InvoiceGet;
  linkedRefundsInvoice: InvoiceGet[];
  user: Usuario;

  mobile = false;
  loading = true;
  invoiceTypes: InvoiceType[] = [];

  multipleVatLines = false;
  totalIva = 0;
  totalPerc = '';
  private sub: Subscription = new Subscription();

  isLoadingInvoiceTypes = false;

  @HostListener('window:resize', ['$event'])
  private onResize(event): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;
  }

  constructor(
    private invoicingQueryService: InvoicingQueryService,
    private invoiceCommandsService: InvoiceCommandsService,
    private loginService: LoginService,
    private location: Location,
    private storedFileService: StoredFilesService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    public utilsService: UtilsService
  ) {
    this.mobile = window.innerWidth <= 1020 ? true : false;
    this.user = this.loginService.getUser();
  }

  ngOnInit(): void {
    // Recuperar id de factura desde path
    this.loadInvoiceTypes();
    this.route.params.subscribe((params) => {
      this.invoiceId = params.id;

      // Recuperar factura
      this.getInvoiceDetails(params.id);
    });

    this.utilsService.toolbarTitle.emit({
      title: 'Detalle de factura',
      icon: null,
      back: true,
      url: '/home/invoices',
    });
  }

  private getInvoiceDetails(invoiceId: string): void {
    this.invoicingQueryService.getInvoices(this.invoiceId, 'id').subscribe(
      (data) => {
        if (data.count && data.count === 1) {
          this.composeInvoiceDetails(data.values[0]);
        } else {
          this.alertService.showDetailWarning(
            'Aviso',
            'No se encontró la factura ' + this.invoiceId
          );
          this.router.navigateByUrl('/home/invoices');
        }
      },
      (err) => {
        this.alertService.showDetailWarning(
          'Error',
          'No se pudo obtener la factura'
        );
        this.router.navigateByUrl('/home/invoices');
      }
    );
  }

  private composeInvoiceDetails(invoice: any) {
    this.invoice = InvoiceGet.buildFromDto(invoice);

    this.multipleVatLines = this.invoice.vatLines.length > 1 ? true : false;
    if (!this.multipleVatLines && this.invoice.vatLines.length === 1) {
      this.totalPerc = this.invoice.vatLines[0].vatPcentDescription;
    }
    this.invoice.vatLines.forEach((l) => (this.totalIva += l.amount));

    if (!this.multipleVatLines && this.invoice.vatLines.length === 0) {
      this.totalIva = this.invoice.total_amount - this.invoice.baseAmount;
    }

    if (
      this.invoice.refundsInvoiceUuid ||
      this.invoice.relatedToRefundUuid ||
      this.invoice.refundsThatRefundMeUuids.length > 0
    ) {
      if (this.invoice.refundsInvoiceUuid || this.invoice.relatedToRefundUuid) {
        this.getLinkedInvoice(
          this.invoice.refundsInvoiceUuid || this.invoice.relatedToRefundUuid
        );
      }
      if (this.invoice.refundsThatRefundMeUuids.length > 0) {
        this.getLinkedRefundsInvoice();
      }
    } else {
      this.loading = false;
    }
  }

  downloadFile(fileUuid: string) {
    this.storedFileService.downloadFile(fileUuid);
  }

  editInvoice(invoice: InvoiceGet) {
    this.router.navigateByUrl('/home/invoices/edit/' + invoice.id);
  }

  cancelInvoice(invoice: InvoiceGet) {
    this.invoiceCommandsService.cancelInvoiceConfirmation(invoice);
    this.sub = this.invoiceCommandsService.cancelEmit.subscribe((_) =>
      this.getInvoiceDetails(this.invoiceId)
    );
  }

  back() {
    this.router.navigateByUrl('/home/invoices');
  }

  seeFacturaProntoPago(id) {
    this.cleanData();
    this.router.navigateByUrl('/home/invoices/detail/' + id);
  }

  getBaseExentaIVA(): number {
    let baseExentaIva = 0;
    this.invoice?.vatLines?.forEach((iva) => {
      if (Number(iva?.vatPerone) === 0) {
        baseExentaIva += iva.baseAmount;
      }
    });

    return baseExentaIva;
  }

  displayIva(vatLine: VatLine) {
    return Number(vatLine.vatPerone) !== 0;
  }

  getLinkedInvoice(linkedInvoiceUuid) {
    this.invoicingQueryService
      .getInvoices(linkedInvoiceUuid, 'uuid')
      .subscribe((data) => {
        if (data.count && data.count === 1) {
          this.linkedInvoice = InvoiceGet.buildFromDto(data.values[0]);
        }
        this.loading = false;
      });
  }

  getLinkedRefundsInvoice() {
    const peticiones = [];

    this.invoice.refundsThatRefundMeUuids.forEach((invoiceUuid) => {
      peticiones.push(
        this.invoicingQueryService.getInvoices(invoiceUuid, 'uuid')
      );
    });

    this.linkedRefundsInvoice = [];

    if (peticiones.length > 0) {
      forkJoin(peticiones).subscribe((resp: any[]) => {
        resp.forEach((res) => {
          res.values.forEach((invoice) =>
            this.linkedRefundsInvoice.push(invoice)
          );
        });

        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  private loadInvoiceTypes() {
    this.isLoadingInvoiceTypes = true;
    this.invoicingQueryService.getInvoiceTypes().subscribe(
      (res) => {
        this.invoiceTypes = res.values || [];
        this.isLoadingInvoiceTypes = false;
      },
      (err) => (this.isLoadingInvoiceTypes = false)
    );
  }

  getIconInvoiceType(invoiceType) {
    return this.invoiceTypes.find((invoice) => invoice.id === invoiceType)
      .icon_code;
  }

  cleanData() {
    this.invoiceId = null;
    this.invoice = null;
    this.linkedInvoice = null;
    this.linkedRefundsInvoice = [];
    this.loading = true;
    this.multipleVatLines = false;
    this.totalIva = 0;
    this.totalPerc = '';
  }

  getFacturasAsociadasLabel(invoice) {
    let label = 'Factura asociada';

    if (invoice.refundsThatRefundMeUuids.length > 0) {
      label = 'Abonos asociados';
    }

    return label;
  }

  getIdentificationTitle() {
    return this.displayInternalExternal(
      'proveedor',
      'emisor',
      'destinatario'
    );
  }

  getEmisorOrDestinatarioLabel() {
    return this.displayInternalExternal(
      'Destinatario',
      'Emisor',
      'Destinatario'
    );
  }

  getEmisorOrDestinatario() {
    return this.displayInternalExternal(
      '',
      this.invoice.companyDescription,
      this.invoice.companyWhichIsInvoicedDescription
    );
  }

  getCifEmisorOrDestinatario() {
    return this.displayInternalExternal(
      '',
      this.invoice.companyVatNumber,
      this.invoice.companyWhichIsInvoicedVatNumber
    );
  }

  displayInternalExternal(defaultValue: any, internal: any, external: any) {
    let response = defaultValue;
    if(this.user.userType === 'internal') {
      response = internal;
    }
    if(this.user.userType === 'external') {
      response = external;
    }

    return response;
  }

  displayIdArpada() {
    return this.invoice.invoiceInternalId && this.user.userType === 'internal'
  }

  display() {
    return !this.loading && !this.isLoadingInvoiceTypes;
  }

}
