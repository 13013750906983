import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { InvoiceDto } from 'src/app/core/models/invoices/invoice-dto';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public mobile = false;
  @Output() autoComplateChange = new EventEmitter<any>();
  @Output() toolbarTitle = new EventEmitter<any>();
  @Output() backFromFilter = new EventEmitter<any>();

  private emitInvoiceDTO = new BehaviorSubject<InvoiceDto>(null);
  invoiceDTO = this.emitInvoiceDTO.asObservable();

  constructor(public http: HttpClient) {}

  public detectNavigation(): void {}

  public downloadPdfFromBase64(data: any, filename: string): void {
    const linkSource = 'data:application/pdf;base64,' + data;

    let link: HTMLAnchorElement;
    link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url: string;
      url = linkSource;
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
    }
  }

  public isNullOrUndefined(item: any): boolean {
    if (item === undefined || item === null) {
      return true;
    } else {
      return false;
    }
  }

  public stringValue(item: string): string {
    if (this.isNullOrUndefined(item)) {
      return '';
    } else {
      return item;
    }
  }

  public camelToSnake(camelText: string): string {
    return camelText
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
  }

  setInvoiceDTO(invoiceDTO: InvoiceDto) {
    this.emitInvoiceDTO.next(invoiceDTO);
  }

  formatAutocalculateAmountInput(value) {
    let inputValue = '';

    if (typeof value === 'number') {
      inputValue = value.toFixed(2);
      const amount = inputValue.split('.');
      let integers = amount[0];
      let decimals = amount[1];
      if (integers.length > 3) {
        const digits = integers.replace(/(\.|\,)/g, '').split('');
        const resto = digits.length % 3;

        let restoIndex = 0;

        switch (resto) {
          case 1:
            restoIndex = 0;
            break;
          case 2:
            restoIndex = 1;
            break;
          case 0:
            restoIndex = 2;
            break;
        }

        integers = '';

        digits.forEach((c, index) => {
          if (
            index % 3 === restoIndex &&
            index !== digits.length - 1 &&
            c !== '-'
          ) {
            integers += c + '.';
          } else {
            integers += c;
          }
        });
      }
      if (amount.length === 2) {
        inputValue = `${integers},${decimals}`;
      } else {
        inputValue = integers;
      }
    }

    return inputValue.replace(',,', ',');
  }

  formatAmount(amount: string) {
    return (
      Number(
        amount
          ?.toString()
          ?.replace(/\./g, '')
          .replace(/\,$/g, '')
          .replace(/\,/g, '.')
          .replace(/\-$/g, '')
      ) || 0
    );
  }

  chooseDisplayIcon(icon) {
    let displayDefault = true;
    if (icon) {
      if (this.getCustomIcon(icon)?.length > 0) {
        displayDefault = false;
      }
    }

    return displayDefault;
  }

  getCustomIcon(icon): string {
    let customIcon = null;
    const customIconFormat = icon?.split('custom:');
    if (customIconFormat.length === 2) {
      customIcon = `${customIconFormat[1]}.svg`;
    }

    return customIcon;
  }
}
