import { AlertService } from './../../../../shared/services/alert.service';
import { InputField } from 'src/app/shared/shared.module';
import { SignupService } from './../../../services/signup.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EMAIL_REGEX, MOBILE_REGEX, REQUIRED_MESSAGE } from 'src/app/app.constants';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  fGroup: FormGroup;
  inputs = new Array<InputField>();
  captchaKey = environment.captcha_key;
  captchaResponse: string;

  @Output() changeTab = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private signupService: SignupService,
    private alertService: AlertService,
  ) {

    this.fGroup = fb.group({
      nombre: ['', [Validators.required, Validators.maxLength(200)]],
      apellido1: ['', [Validators.required, Validators.maxLength(200)]],
      apellido2: ['', Validators.maxLength(200)],
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      telefono: ['', [Validators.required, Validators.pattern('([0-9][ -]*){9}')]],
      CIF: ['', Validators.required],
      puesto: ['', [Validators.required, Validators.maxLength(200)]],
      politicas: [false, Validators.requiredTrue],
      captcha: ['', Validators.required]
    })

    this.configurarInputs();
  }

  ngOnInit(): void {
  }

  resolved(e) {
    this.captchaResponse = e;
  }

  submit(): void {
    let data: any = new Object();
    data.user_captcha_token_response = environment.production ? this.captchaResponse : '0131ba7e4bc449f18153c4ed9ee015ed';
    data.user_captcha_alias = 'invoicing';
    data.user_registry_data = new Object();
    data.user_registry_data.firstname = this.fGroup.get('nombre').value;
    data.user_registry_data.lastname = this.fGroup.get('apellido1').value;
    data.user_registry_data.lastname2 = this.fGroup.get('apellido2').value;
    data.user_registry_data.email = this.fGroup.get('email').value;
    data.user_registry_data.contact_phone = this.fGroup.get('telefono').value;
    data.user_registry_data.company_vatnumber = this.fGroup.get('CIF').value;
    data.user_registry_data.job_position_in_company = this.fGroup.get('puesto').value;

    this.signupService.signupRequest(data).subscribe(
      data => {
        if (data.status === 'OK') {
          this.alertService.showDetailSuccess('Solicitud enviada correctamente', 'Confirme su solicitud en el email indicado');
          this.changeParentTab('login');
        } else {
          this.alertService.showDetailError(data.status, data.error_message);
        }
      },
      err => {

        console.log(err);
        this.alertService.showDetailError('Error', err.error_message);
      }
    );
  }


  /**
   * Configuración de formulario.
   */
  private configurarInputs(): void {
    this.inputs.push(
      {
        key: 'nombre',
        type: 'text',
        label: 'Nombre',
        controlValue: '',
        layout: 'col-12 col-sm-6',
        validatorMessages: [
          {key: 'required', value: REQUIRED_MESSAGE},
          {key: 'maxlength', value: 'Longitud máxima 200 caracteres'}]
      },
      {
        key: 'apellido1',
        type: 'text',
        label: 'Primer apellido',
        controlValue: '',
        layout: 'col-12 col-sm-6',
        validatorMessages: [
          {key: 'required', value: REQUIRED_MESSAGE},
          {key: 'maxlength', value: 'Longitud máxima 200 caracteres'}]
      },
      {
        key: 'apellido2',
        type: 'text',
        label: 'Segundo apellido',
        controlValue: '',
        layout: 'col-12 col-sm-6',
        validatorMessages: [
          {key: 'maxlength', value: 'Longitud máxima 200 caracteres'}]
      },
      {
        key: 'email',
        type: 'text',
        label: 'Correo electrónico',
        controlValue: '',
        layout: 'col-12 col-sm-6',
        focusOut: (value: string) => {
          this.fGroup.controls.email.setValue(value.trim().toLowerCase());
        },
        validatorMessages: [
          {key: 'required', value: REQUIRED_MESSAGE},
          {key: 'maxlength', value: 'Longitud máxima 200 caracteres'},
          {key: 'pattern', value: 'Email inválido'}]
      },
      {
        key: 'telefono',
        type: 'text',
        label: 'Teléfono',
        controlValue: '',
        layout: 'col-12 col-sm-6',
        validatorMessages: [
          {key: 'required', value: REQUIRED_MESSAGE},
          {key: 'pattern', value: 'Número de teléfono inválido'}]
      },
      {
        key: 'CIF',
        type: 'text',
        label: 'CIF de la empresa',
        controlValue: '',
        layout: 'col-12 col-sm-6',
        validatorMessages: [
          {key: 'required', value: REQUIRED_MESSAGE}]
      },
      {
        key: 'puesto',
        type: 'text',
        label: 'Puesto en la empresa',
        controlValue: '',
        layout: 'col-12',
        validatorMessages: [
          {key: 'required', value: REQUIRED_MESSAGE},
          {key: 'maxlength', value: 'Longitud máxima 200 caracteres'}]
      },

    );
  }

  /**
   * Solicita al contenedor padre que cambie a pestaña de login.
   * @param tab Pestaña
   */
  changeParentTab(tab: string): void {
    this.changeTab.emit(tab);
  }
  // Descarga del documento de politicas de privacidad
  downloadDocument(nombreFichero: string) {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = 'assets/documentos/' + nombreFichero;
    link.download = nombreFichero;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

}
