import { LocalStorageService } from './../../../../shared/services/local-storage.service';
import { Router } from '@angular/router';
import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  finalize,
} from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  Columns,
  InputField,
  TableConfiguration,
} from 'src/app/shared/shared.module';
import { InvoiceSite } from 'src/app/core/models/invoices/invoice-site.model';
import { InvoicingQueryService } from 'src/app/core/services/invoicing-query.service';
import {
  InvoiceEvent,
  InvoiceGet,
} from 'src/app/core/models/invoices/invoice-get';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { InvoiceCommandsService } from 'src/app/core/services/invoice-commands.service';
import { Usuario } from './../../../../shared/models/usuario.model';
import { LoginService } from './../../../services/login.service';
import { CustomPagination } from './../../../../shared/components/table/table-configuration/custom-pagination';
import { InvoicePageable } from './../../../models/pageable.model';
import { InputFieldArray } from './../../../../shared/components/autoform/input-fields/input-fields';
import { InvoiceType } from 'src/app/core/models/invoices/invoice-type.model';
import { InvoiceState } from './../../../models/invoices/invoice-state.model';
import { AlertService } from './../../../../shared/services/alert.service';
import { SearchService } from './../../../services/search.service';
import { EventsService } from './../../../services/events.service';
import { TablePaginationService } from 'src/app/shared/services/table-pagination.service';
import { FilterDataService } from 'src/app/shared/services/filter-data.service';
import { CustomFilters } from 'src/app/shared/components/table/table-configuration/custom-filters';
import * as moment from 'moment';
import { InvoiceCompany } from 'src/app/core/models/invoices/invoice-company.model';
import { CSiteCriteria } from 'src/app/core/models/csite_criteria.model';
import { InvoiceEntity } from 'src/app/core/models/invoices/invoice-entity.model';
import { Order } from 'src/app/core/models/order.model';
import { InvoicesExcel } from 'src/app/core/models/invoices_excel.model';
import { StoredFilesService } from 'src/app/core/services/stored-files.service';
import { InvoicesZip } from 'src/app/core/models/invoices_zip.model';
import { InvoiceSearchCriteria } from 'src/app/core/models/invoice_search_criteria.model';
import { saveAs } from 'file-saver';
import { MensajeService } from 'src/app/shared/services/mensaje.service';
import { ACEPTAR } from 'src/app/app.constants';

@Component({
  selector: 'app-invoices-filter-internal',
  templateUrl: './invoices-filter-internal.component.html',
  styleUrls: ['./invoices-filter-internal.component.scss'],
})
export class InvoicesFilterInternalComponent implements OnInit {
  user: Usuario;

  // CONFIGURACIÓN GENERAL
  DEFAULT_PAGE_SIZE = 10;
  DEFAULT_ORDER_DIRECTION = 'desc';
  DEFAULT_ORDER_NAME = 'registerDate';

  // Configuración de formulario de búsqueda
  fGroup: FormGroup;
  fGroupSmart: FormGroup; // Form individual de búsquedas por texto.
  inputs = new Array<InputField>();
  inputFieldsArray: InputFieldArray = new InputFieldArray();

  companies: InvoiceCompany[] = [];
  entities: InvoiceEntity[] = [];
  invoiceStates: InvoiceState[] = [];
  invoiceTypes: InvoiceType[] = [];
  constructionSite: InvoiceSite[] = [];
  invoiceList: InvoiceGet[];
  autoFilter: boolean = true;
  showFilters: boolean = false;
  showFilterSelection: boolean = false;
  filterMode = 1;
  mobile: boolean = false;
  positionRestored = false;
  previousPosition: number;
  loading = true;
  primeraEntrada = true;
  primeraEntradaAssetsCount = 0;
  maxItems = false;
  isDownloadingExcel = false;
  disableZipDownload = false;
  zipDownloadedMessage = '';
  private sub: Subscription = new Subscription();
  private autoCompleteSub: Subscription = new Subscription();
  private backFromFilterSub: Subscription = new Subscription();

  // Templates para filtros
  @ViewChild('stateOption', { static: true })
  stateOptionTemplate: TemplateRef<any>;
  @ViewChild('siteOption', { static: true })
  siteOptionTemplate: TemplateRef<any>;
  @ViewChild('invoiceTypeOption', { static: true })
  invoiceTypeOptionTemplate: TemplateRef<any>;
  @ViewChild('clearFilters', { static: true })
  clearFiltersTemplate: TemplateRef<any>;

  // Configuración para la Tabla de resultados de búsqueda
  public tableConfiguration = new TableConfiguration();
  public columnDefs: Columns[] = [];
  public columnDefsCards: Columns[] = [];
  public columnDefsListadoCards: Columns[] = [];
  public columnDefsListadoExpandedCards: Columns[] = [];
  public rowData = Array<InvoiceGet>();
  seeDetail: EventEmitter<any> = new EventEmitter();
  customPagination: CustomPagination = {} as CustomPagination;
  storedPagination: CustomPagination;
  customFilters: CustomFilters = {} as CustomFilters;
  storedFilters: CustomFilters;

  // Templates para tabla
  @ViewChild('stateChip', { static: true }) stateChipTemplate: TemplateRef<any>;
  @ViewChild('company', { static: true }) companyTemplate: TemplateRef<any>;
  @ViewChild('csite', { static: true }) csiteTemplate: TemplateRef<any>;
  @ViewChild('siteIcon', { static: true }) siteIconTemplate: TemplateRef<any>;
  @ViewChild('invoiceActions', { static: true })
  invoiceActionsTemplate: TemplateRef<any>;

  // Templates para tarjetas
  @ViewChild('cardInvoice', { static: true })
  cardInvoiceTemplate: TemplateRef<any>;

  paginationSub: Subscription;
  filterDataSub: Subscription;

  private readonly csiteCriteriaEventUniqueKey: string = 'invoicesFilterInternal.csiteCriteriaSmartSearch';
  private readonly millisecondsTimeWithoutRepeatedCallsToServer: number = 400;

  constructor(
    private fb: FormBuilder,
    private searchService: SearchService,
    private invoiceQueryService: InvoicingQueryService,
    private alertService: AlertService,
    private loginService: LoginService,
    private invoiceCommandsService: InvoiceCommandsService,
    public utils: UtilsService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private tablePaginationService: TablePaginationService,
    private filterDataService: FilterDataService,
    private storedFilesService: StoredFilesService,
    private mensajeService: MensajeService,
    private eventsService: EventsService,
  ) {
    this.mobile = window.innerWidth <= 1020 ? true : false;

    this.user = this.loginService.getUser();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;
  }

  @HostListener('window:scroll', ['$event'])
  private onScrollCard(event): void {
    this.customPagination.scrollY = window.scrollY;
    if (
      this.mobile &&
      window.innerHeight + window.scrollY === document.body.scrollHeight &&
      this.customPagination.scrollY !== 0 &&
      this.customPagination.totalItemsLoaded < this.customPagination.totalItems
    ) {
      this.pagination(this.customPagination);
    }
  }

  ngOnInit(): void {
    // Seteamos el storedPagination con el valor del currentData del servicio
    this.paginationSub = this.tablePaginationService.currentData.subscribe(
      (data) => {
        this.storedPagination = data;
      }
    );
    this.filterDataSub = this.filterDataService.currentData.subscribe(
      (data) => {
        this.storedFilters = data;
      }
    );

    this.backFromFilterSub = this.utils.backFromFilter.subscribe((data) => {
      if (data === 'filter') {
        this.showFilterSelection = false;
      }
      this.utils.toolbarTitle.emit({
        title: 'Listado de facturas',
        icon: null,
      });
    });

    this.autoCompleteSub = this.utils.autoComplateChange.subscribe((data) => {
      if (this.autoFilter) {
        if (data.value) {
          this.fGroup.controls[data.key].setValue(
            this.fGroup.controls[data.key].value.filter(
              (item) => item.id !== data.value?.id
            )
          );
        } else {
          this.fGroup.controls[data.key].setValue([]);
        }
        this.filterSubmit();
      }
    });
    // Configuración de formulario de búsqueda estricta
    this.setForm();
    // Configuración de formulario de búsqueda inteligente
    this.setFormSmart();

    this.configureTable();

    // Carga de assets
    this.loadInvoiceStates();
    this.loadInvoiceTypes();
    this.loadConstructionSites();
    this.loadCompanies();
    this.loadEntities();

  }

  ngOnDestroy(): void {
    this.paginationSub.unsubscribe();
    this.filterDataSub.unsubscribe();
    this.sub.unsubscribe();
    this.autoCompleteSub.unsubscribe();
    this.backFromFilterSub.unsubscribe();
  }

  /**
   * Actualiza modo de filtrado a Estricto,
   * inicializa la paginación y solicita registros.
   *
   */
  filterSubmit() {
    this.customPagination.totalItemsLoaded = 0;
    this.maxItems = false;
    this.filterMode = 1;
    this.initializePagination();
    if (this.primeraEntrada) {
      if (this.mobile) {
        if (this.primeraEntradaAssetsCount === 5) {
          let lastPage = 0;
          if (this.mobile) {
            lastPage = this.searchService.lastPageInvoice
              ? this.searchService.lastPageInvoice
              : this.customPagination.page === 0
              ? 1
              : this.customPagination.page;
          } else {
            lastPage =
              this.customPagination.page === 0 ? 1 : this.customPagination.page;
          }
          for (let index = 0; index < lastPage; index++) {
            // get items

            this.searchService.lastPageInvoice = index;

            this.customPagination.page = index;
            this.searchInvoices();
          }
          // En el listado infito al cargar todos los datos se va a la posición de la factura previamente seleccionada
          let scrollY = this.customPagination.scrollY;

          setTimeout(function () {
            window.scrollTo(window.scrollX, scrollY);
          }, 2000);
        }
      } else {
        this.searchInvoices();
      }
    } else {
      this.customPagination.page = 1;
      this.searchInvoices();
    }
  }

  /**
   * Actualiza modo de filtrado a Inteligente,
   * inicializa la paginación y solicita registros.
   *
   */
  smartFilterSubmit() {

    this.filterMode = 2;
    this.initializePagination();
  }

  /**
   * Inicializa campos de filtrado y solicita registros.
   *
   */
  filterReset() {
    this.filterMode = 1;
    this.fGroup.reset();

    if (!this.autoFilter) {
      this.initializePagination();
      this.filterSubmit();
    }
  }

  /**
   * Compone solicitud de facturas paginadas, ordenadas y filtradas.
   *
   */
  private searchInvoices() {


    let offset = 0;

    if (this.mobile) {
      offset =
        this.customPagination.pageSize *
        (this.customPagination.page === 0
          ? this.customPagination.page
          : this.searchService.lastPageInvoice
          ? this.customPagination.page
          : this.customPagination.page - 1);
    } else {
      offset =
        this.customPagination.pageSize *
        (this.customPagination.page === 0
          ? this.customPagination.page
          : this.customPagination.page - 1);
    }

    if (this.primeraEntradaAssetsCount === 5) {

      const pageable = new InvoicePageable();

      pageable.smartText = this.fGroupSmart?.controls.smartSearch.value;


      pageable.offset = offset;
      pageable.limit = this.customPagination.pageSize;
      pageable.registerDateFrom = this.fGroup.controls.fromfecRegistro.value;
      pageable.registerDateTo = this.fGroup.controls.tofecRegistro.value;
      pageable.invoiceDateFrom = this.fGroup.controls.fromfecFactura.value;
      pageable.invoiceDateTo = this.fGroup.controls.tofecFactura.value;
      pageable.emisionDateFrom = this.fGroup.controls.fromfecEmision.value;
      pageable.emisionDateTo = this.fGroup.controls.tofecEmision.value;
      pageable.invoiceNumber = this.fGroup.controls.invoiceNumber.value;
      pageable.internalIds =
        this.fGroup.controls.idArpada.value === '' ||
        !this.fGroup.controls.idArpada.value
          ? null
          : [this.fGroup.controls.idArpada.value];

      pageable.order = [
        new Order({
          direction: this.customPagination.order,
          name: this.utils.camelToSnake(this.customPagination.sort),
        }),
      ];

      // Agrega filtro inteligente o estricto según modo

      this.filterMode === 2
        ? this.assignSmartFormValues(pageable)
        : this.assignStrictFormValues(pageable);

      this.invoiceQueryService.getInvoicesPageable(pageable).subscribe(
        (data) => {
          const invoiceList: InvoiceGet[] = [];

          if (data.values || []) {
            (data.values as []).forEach((i: any) => {
              const invoice: InvoiceGet = InvoiceGet.buildFromDto(i);

              invoiceList.push(invoice);
            });

            this.rowData = invoiceList;

            // Refresca pie de tabla
            // Añadimos los datos para guardar la paginacion
            if (this.customPagination.totalItemsLoaded === undefined) {
              this.customPagination.totalItemsLoaded = 0;
            }
            this.customPagination.totalItemsLoaded += this.rowData.length;
            this.customPagination.totalItems = data.count;
            this.maxItems =
              this.customPagination.totalItems <=
              this.customPagination.totalItemsLoaded;
            this.customPagination.parent = this.router.url.split('/')[2];
            this.tablePaginationService.setData(this.customPagination);
            // Añadimos los datos para guardar los filtros
            this.customFilters.parent = this.router.url.split('/')[2];
            this.filterDataService.setData(this.customFilters);
            this.primeraEntrada = false;
          }
          this.loading = false;
        },
        (err) => {
          this.loading = false
        }
      );
    }
  }

  assignStrictFormValues(
    pageable: InvoicePageable | InvoicesExcel | InvoiceSearchCriteria
  ): void {
    if (this.customFilters?.filters === undefined) {
      this.customFilters.filters = new Map<string, string>();
    }

    // Fecha Factura
    const fromfecFacturaValue = this.fGroup.get('fromfecFactura')?.value;
    if (fromfecFacturaValue) {
      this.customFilters?.filters.set('fromfecFactura', fromfecFacturaValue);
    } else {
      this.customFilters?.filters.delete('fromfecFactura');
    }
    const tofecFacturaValue = this.fGroup.get('tofecFactura')?.value;
    if (tofecFacturaValue) {
      this.customFilters?.filters.set('tofecFactura', tofecFacturaValue);
    } else {
      this.customFilters?.filters.delete('tofecFactura');
    }

    // Fecha Registro
    const fromfecRegistroValue = this.fGroup.get('fromfecRegistro')?.value;
    if (fromfecRegistroValue) {
      this.customFilters?.filters.set('fromfecRegistro', fromfecRegistroValue);
    } else {
      this.customFilters?.filters.delete('fromfecRegistro');
    }
    const tofecRegistroValue = this.fGroup.get('tofecRegistro')?.value;
    if (tofecRegistroValue) {
      this.customFilters?.filters.set('tofecRegistro', tofecRegistroValue);
    } else {
      this.customFilters?.filters.delete('tofecRegistro');
    }

    const fromfecEmisionValue = this.fGroup.get('fromfecEmision')?.value;
    if (fromfecEmisionValue) {
      this.customFilters?.filters.set('fromfecEmision', fromfecEmisionValue);
    } else {
      this.customFilters?.filters.delete('fromfecEmision');
    }
    const tofecEmisionValue = this.fGroup.get('tofecEmision')?.value;
    if (tofecEmisionValue) {
      this.customFilters?.filters.set('tofecEmision', tofecEmisionValue);
    } else {
      this.customFilters?.filters.delete('tofecEmision');
    }

    // Empresa
    const companiesValue = this.fGroup.get('companies')?.value;
    const companies: Array<InvoiceCompany> =
      companiesValue && companiesValue.length > 0 ? companiesValue : null;
    if (companies) {
      if (pageable instanceof InvoiceSearchCriteria) {
        pageable.company_uuids = companies.map((s) => s.uuid);
      } else {
        pageable.companyuuids = companies.map((s) => s.uuid);
      }
      // Añadimos el filtro seleccionado a la lista de filtros para guardarla en el servicio para poder recuperarla luego
      // Guardamos el key del filtro con el mismo nombre del campo del formulario
      this.customFilters?.filters.set(
        'companies',
        companies.map((s) => s?.uuid).toString()
      );
    } else {
      this.customFilters?.filters.delete('companies');
    }

    // Estados de factura
    const statesValue = this.fGroup.get('states')?.value;
    const states: InvoiceState[] =
      statesValue && statesValue.length > 0 ? statesValue : null;
    if (states) {
      if (pageable instanceof InvoiceSearchCriteria) {
        pageable.searchstate_keys = states.map(
          (s) => s?.stateForSearchUniquekey
        );
      } else {
        pageable.stateGroupKeys = states.map((s) => s?.stateForSearchUniquekey);
      }
      // Añadimos el filtro seleccionado a la lista de filtros para guardarla en el servicio para poder recuperarla luego
      // Guardamos el key del filtro con el mismo nombre del campo del formulario
      this.customFilters?.filters.set(
        'states',
        states.map((s) => s?.stateForSearchUniquekey).toString()
      );
    } else {
      this.customFilters?.filters.delete('states');
    }

    // entidades bancarias
    const entitiesValue = this.fGroup.get('entities')?.value;
    const entities: InvoiceEntity[] =
      entitiesValue && entitiesValue.length > 0 ? entitiesValue : null;
    if (entities) {
      if (pageable instanceof InvoiceSearchCriteria) {
        pageable.bankings_entities_ids = entities.map((s) => parseInt(s?.id));
      } else {
        pageable.entitiesIds = entities.map((s) => s?.id);
      }
      // Añadimos el filtro seleccionado a la lista de filtros para guardarla en el servicio para poder recuperarla luego
      // Guardamos el key del filtro con el mismo nombre del campo del formulario
      this.customFilters?.filters.set(
        'entities',
        entities.map((s) => s?.id).toString()
      );
    } else {
      this.customFilters?.filters.delete('entities');
    }

    // Obras
    const sitesValue = this.fGroup.get('sites')?.value;
    const sites: Array<InvoiceSite> =
      sitesValue && sitesValue.length > 0 ? sitesValue : null;
    if (sites) {
      if (pageable instanceof InvoiceSearchCriteria) {
        pageable.csite_uuids = sites.map((s) => s.uuid);
      } else {
        pageable.csiteuuids = sites.map((s) => s.uuid);
      }
      // Añadimos el filtro seleccionado a la lista de filtros para guardarla en el servicio para poder recuperarla luego
      // Guardamos el key del filtro con el mismo nombre del campo del formulario
      this.customFilters?.filters.set(
        'sites',
        sites.map((s) => s?.uuid).toString()
      );
    } else {
      this.customFilters?.filters.delete('sites');
    }

    // Numero de factura
    const invoiceNumber = this.fGroup.get('invoiceNumber')?.value;
    if (invoiceNumber) {
      this.customFilters?.filters.set('invoiceNumber', invoiceNumber);
    } else {
      this.customFilters?.filters.delete('invoiceNumber');
    }

    // Tipo de factura
    const invoiceTypeValue = this.fGroup.get('invoiceType')?.value;
    const invoiceType: Array<InvoiceType> =
      invoiceTypeValue && invoiceTypeValue.length > 0 ? invoiceTypeValue : null;
    if (invoiceType) {
      if (pageable instanceof InvoiceSearchCriteria) {
        pageable.invoice_type_uniquekeys = invoiceType.map((s) => s.id);
      } else {
        pageable.invoiceType = invoiceType.map((s) => s.id);
      }
      // Añadimos el filtro seleccionado a la lista de filtros para guardarla en el servicio para poder recuperarla luego
      // Guardamos el key del filtro con el mismo nombre del campo del formulario
      this.customFilters?.filters.set(
        'invoiceType',
        invoiceType.map((s) => s?.id).toString()
      );
    } else {
      this.customFilters?.filters.delete('invoiceType');
    }

    // ID Arpada
    const idArpada = this.fGroup.get('idArpada')?.value;
    if (idArpada) {
      this.customFilters?.filters.set('idArpada', idArpada);
    } else {
      this.customFilters?.filters.delete('idArpada');
    }

    // SMART SEARCH
    const smartSearchValue = this.fGroupSmart.get('smartSearch')?.value;
    if (smartSearchValue) {
      this.customFilters?.filters.set('smartSearch', smartSearchValue);
      //this.smartFilterSubmit();
    } else {
      this.customFilters?.filters.delete('smartSearch');
    }
  }

  assignSmartFormValues(
    pageable: InvoicePageable | InvoicesExcel | InvoiceSearchCriteria
  ): void {
    // Esto es para el buscado con lupa
    const smartSearchValue = this.fGroupSmart.get('smartSearch')?.value;
    if (smartSearchValue) {
      this.customFilters?.filters.set('smartSearch', smartSearchValue);
    } else {
      this.customFilters?.filters.delete('smartSearch');
    }
  }

  /**
   * Configuración de formulario de búsqueda inteligente al escribir.
   */
  private setFormSmart(): void {
    this.fGroupSmart = this.fb.group({
      smartSearch: '',
    });

    // Control de cambios en búsqueda
    this.fGroupSmart.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {

        this.filterMode = 2;
        this.customFilters.filters = new Map<string, string>();
        this.fGroup.reset(); // Limpia filtros generales
      });
    this.setSmartFilterValue('smartSearch');
  }

  /**
   * Configuración de formulario.
   */
  private setForm(): void {
    this.fGroup = this.fb.group(
      {
        fromfecFactura: [''],
        tofecFactura: [''],
        fromfecRegistro: [''],
        tofecRegistro: [''],
        fromfecEmision: [''],
        tofecEmision: [''],
        companies: [[]],
        states: [[]],
        sites: [[]],
        entities: [[]],
        invoiceNumber: [''],
        invoiceType: [''],
        idArpada: [''],
      },
      { updateOn: 'blur' }
    );

    // Control de eventos de formulario para búsqueda automática on blur
    // (Solo si la búsqueda automática está activa)
    this.fGroup.valueChanges.subscribe(() => {
      this.disableZipDownload = false;
      if (this.autoFilter) {
        this.tablePaginationService.resetStore.emit(true);
        this.filterSubmit();
      }
    });

    // Configuración de inputfields
    this.inputs.push(
      {
        key: 'fecFactura',
        type: 'dateFromTo',
        label: 'Fecha factura',
        controlValue: '',
        layout: 'col-12 col-sm-4 arpada-input-labelled-small',
      },
      {
        key: 'fecRegistro',
        type: 'dateFromTo',
        label: 'Fecha registro',
        controlValue: '',
        layout: 'col-12 col-sm-4 arpada-input-labelled-small',
      },
      {
        key: 'fecEmision',
        type: 'dateFromTo',
        label: 'Fecha emisión pago',
        controlValue: '',
        layout: 'col-12 col-sm-4 arpada-input-labelled-small',
      },
      {
        key: 'sites',
        type: 'autocomplete-multi-chip',
        placeholder: 'Todas las ubicaciones',
        controlValue: '',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        layout: 'col-12 col-sm-4 arpada-input-small',
        selectOptionTemplate: this.siteOptionTemplate,
        filteredOptions: (value: string) => {
          const csiteCriteria: CSiteCriteria = new CSiteCriteria();
          csiteCriteria.only_csites_with_invoices_for_company_which_is_invoiced_id = this.user.company.id;
          return this.eventsService.delayCallXMillisecondsAvoidRepetition<any>(this.csiteCriteriaEventUniqueKey, ()=>{
            return this.searchService.getConstructionSiteSmartSearch(value, csiteCriteria).pipe(
              debounceTime(2000),
              map((res) => {
                const temp: InvoiceSite[] = [];
                (res.values || []).forEach((type) => {
                  temp.push(
                    new InvoiceSite({
                      id: type.id,
                      internalNumber: type.internal_number,
                      description: `${type.internal_number} - ${type.description}`,
                      order: type.order,
                      address: type.address,
                      uuid: type.uuid,
                    })
                  );
                });
                this.constructionSite = [].concat(temp);
                return this.constructionSite;
              })
            );
          }, this.millisecondsTimeWithoutRepeatedCallsToServer);
        },
      },
      {
        key: 'companies',
        type: 'autocomplete-multi-chip',
        placeholder: 'Todos los emisores',
        controlValue: '',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        layout: 'col-12 col-sm-4 arpada-input-small',
        filteredOptions: (value: string) => {
          return this.searchService.getCompanies(value).pipe(
            debounceTime(2000),
            map((res) => {
              let empresas = res.values as Array<any>;
              this.companies = empresas.map(function (emp) {
                return {
                  id: emp.id,
                  name: `${emp.description}`,
                  uuid: emp.uuid,
                  description: `${emp.vat_number} | ${emp.description}`,
                };
              });
              return this.companies;
            })
          );
        },
      },
      {
        key: 'entities',
        type: 'autocomplete-multi-chip',
        placeholder: 'Entidad bancaria',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        controlValue: '',
        layout: 'col-12 col-sm-4 arpada-input-small',
        filteredOptions: (value: string) => {
          return this.searchService
            .getBankingEntities({
              smart_text: value,
              used_to_pay_received_invoices_by_company_id:
                this.loginService.getUser().company?.id,
            })
            .pipe(
              debounceTime(2000),
              map((res) => {
                let entidades = res.values as Array<any>;
                this.entities = entidades.map(function (ent) {
                  return {
                    id: ent.id,
                    name: `${ent.name}`,
                    uuid: ent.code,
                    description: `${ent.name} | ${ent.confirming_phone}`,
                  };
                });
                return this.entities;
              })
            );
        },
      },
      {
        key: 'states',
        type: 'autocomplete-multi-chip',
        placeholder: 'Estado de facturas',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        controlValue: '',
        layout: 'col-12 col-sm-4 arpada-input-small',
        options: this.invoiceStates,
        filteredOptions: (value) => of(this.invoiceStates),
        selectOptionTemplate: this.stateOptionTemplate,
      },
      {
        key: 'invoiceType',
        type: 'autocomplete-multi-chip',
        label: 'Tipo de factura',
        placeholder: 'Tipo de facturas',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        controlValue: '',
        layout: 'col-12 col-sm-4 arpada-input-small',
        options: this.invoiceTypes,
        filteredOptions: (value) => of(this.invoiceTypes),
        selectOptionTemplate: this.invoiceTypeOptionTemplate,
      },
      {
        key: 'invoiceNumber',
        type: 'text',
        label: 'Número de factura',
        placeholder: 'Número de factura',
        controlValue: '',
        layout: 'col-12 col-sm-4 arpada-input-small',
      },
      {
        key: 'idArpada',
        type: 'text',
        label: 'ID Arpada',
        placeholder: 'ID Arpada',
        controlValue: '',
        layout: 'col-12 col-sm-4 arpada-input-small',
      },
      this.decideSearchButton(),
      {
        key: 'Limpiar',
        type: 'inputTemplate',
        label: 'Eliminar filtros',
        layout: 'col-12 col-sm-2 mt-2',
        icon: 'filter',
        inputTemplate: this.clearFiltersTemplate,
        function: () => this.filterReset(),
      }
    );
    this.setFiltersValues('');
  }

  private setFiltersValues(filter: string) {
    if (
      this.storedFilters?.parent === this.router.url.split('/')[2] &&
      this.storedFilters
    ) {
      //this.customFilters = this.storedFilters;
      this.showFilters = this.storedFilters.filters.size > 0 ? true : false;

      this.storedFilters.filters.forEach((value: string, key: string) => {
        // Fecha Factura
        if (key === 'fromfecFactura' && filter === '') {
          this.fGroup
            .get(key)
            .setValue(moment.utc(value, ['YYYY/MM/DD']).format());
        }
        if (key === 'tofecFactura' && filter === '') {
          this.fGroup
            .get(key)
            .setValue(moment.utc(value, ['YYYY/MM/DD']).format());
        }
        // Fecha Registro
        if (key === 'fromfecRegistro' && filter === '') {
          this.fGroup
            .get(key)
            .setValue(moment.utc(value, ['YYYY/MM/DD']).format());
        }

        if (key === 'tofecRegistro' && filter === '') {
          this.fGroup
            .get(key)
            .setValue(moment.utc(value, ['YYYY/MM/DD']).format());
        }

        // Fecha Emisión
        if (key === 'fromfecEmision' && filter === '') {
          this.fGroup
            .get(key)
            .setValue(moment.utc(value, ['YYYY/MM/DD']).format());
        }

        if (key === 'tofecEmision' && filter === '') {
          this.fGroup
            .get(key)
            .setValue(moment.utc(value, ['YYYY/MM/DD']).format());
        }

        // Empresa
        if (key === 'companies' && filter === 'companies') {
          if (value.split(',').length > 1) {
            let companies = value.split(',');
            let companieList = new Array<InvoiceCompany>();
            companies.forEach((company) => {
              companieList.push(
                this.companies.find((is) => is.uuid.toString() === company)
              );
            });
            this.fGroup.get(key).setValue(companieList);
          } else {
            this.fGroup
              .get(key)
              .setValue([
                this.companies.find((s) => s.uuid.toString() === value),
              ]);
          }
        }
        // Estados de factura
        if (key === 'states' && filter === 'states') {
          if (value.split(',').length > 1) {
            let states = value.split(',');
            let statesList = new Array<InvoiceState>();
            states.forEach((state) => {
              statesList.push(
                this.invoiceStates.find(
                  (is) => is.stateForSearchUniquekey === state
                )
              );
            });
            this.fGroup.get(key).setValue(statesList);
          } else {
            this.fGroup
              .get(key)
              .setValue([
                this.invoiceStates.find(
                  (s) => s.stateForSearchUniquekey === value
                ),
              ]);
          }
        }
        // Entidad
        if (key === 'entities' && filter === 'entities') {
          if (value.split(',').length > 1) {
            let entities = value.split(',');
            let entitiesList = new Array<InvoiceEntity>();
            entities.forEach((entitie) => {
              entitiesList.push(
                this.entities.find(
                  (is) => Number(is.id) === Number(entitie)
                )
              );
            });
            this.fGroup.get(key).setValue(entitiesList);
          } else {
            this.fGroup
              .get(key)
              .setValue([
                this.entities.find(
                  (s) => Number(s.id) === Number(value)
                ),
              ]);
          }
        }

        // Obras
        if (key === 'sites' && filter === 'sites') {
          if (value.split(',').length > 1) {
            let sites = value.split(',');
            let sitesList = new Array<InvoiceSite>();
            sites.forEach((site) => {
              sitesList.push(
                this.constructionSite.find((is) => is.uuid.toString() === site)
              );
            });
            this.fGroup.get(key).setValue(sitesList);
          } else {
            this.fGroup
              .get(key)
              .setValue([
                this.constructionSite.find((s) => s.uuid.toString() === value),
              ]);
          }
        }

        // Numero de factura
        if (key === 'invoiceNumber' && filter === '') {
          this.fGroup.get(key).setValue(value);
        }

        // Tipo de factura
        if (key === 'invoiceType' && filter === 'invoiceType') {
          if (value.split(',').length > 1) {
            let invoiceTypes = value.split(',');
            let invoiceTypeList = new Array<any>();
            invoiceTypes.forEach((type) => {
              invoiceTypeList.push(
                this.invoiceTypes.find((is) => is.id === type)
              );
            });
            this.fGroup.get(key).setValue(invoiceTypeList);
          } else {
            this.fGroup
              .get(key)
              .setValue([this.invoiceTypes.find((s) => s.id === value)]);
          }
        }

        // ID Arpada
        if (key === 'idArpada' && filter === '') {
          this.fGroup.get(key).setValue(value);
        }
      });
    }
  }

  setSmartFilterValue(key: string) {
    if (
      this.storedFilters?.parent === this.router.url.split('/')[2] &&
      this.storedFilters
    ) {
      this.storedFilters.filters.forEach((value: string, key: string) => {
        // SMART SEARCH
        if (key === 'smartSearch') {
          this.fGroupSmart.get(key).setValue(value);
        }
      });
    }
  }

  private decideSearchButton(): InputField {
    let input: InputField;
    if (this.autoFilter) {
      input = {
        key: 'Buscar',
        type: 'blank',
        layout: 'col-12 col-sm-2',
        icon: 'search',
      };
    } else {
      input = {
        key: 'Buscar',
        type: 'button',
        label: 'Buscar',
        layout: 'col-12 col-sm-2',
        icon: 'search',
        function: () => this.filterSubmit(),
      };
    }
    return input;
  }

  private loadCompanies() {
    this.searchService.getCompanies('').subscribe(
      (res) => {
        const temp: any[] = [];
        (res.values || []).forEach((emp) => {
          temp.push({
            id: emp.id,
            uuid: emp.uuid,
            name: `${emp.description}`,
            description: `${emp.vat_number} | ${emp.description}`,
          });
        });

        this.companies = [].concat(temp);

        this.setFiltersValues('companies');
        this.primeraEntradaAssetsCount++;
        this.filterSubmit();
      },
      (err) => console.log('Error loadCompanies')
    );
  }

  private loadInvoiceStates() {
    this.searchService.getInvoiceStates('').subscribe(
      (res) => {
        const temp: InvoiceState[] = [];
        (res.values || []).forEach((type) => {
          temp.push(
            new InvoiceState({
              id: type.id,
              description: type.description,
              order: type.order,
              include: type.include_states,
              stateForSearchUniquekey: type.state_for_search_uniquekey,
            })
          );
        });

        this.invoiceStates = [].concat(temp);
        this.setFiltersValues('states');
        this.primeraEntradaAssetsCount++;
        this.filterSubmit();
      },
      (err) => console.log('Error loadInvoiceStates')
    );
  }

  private loadConstructionSites() {
    this.searchService.getConstructionSite(new CSiteCriteria()).subscribe(
      (res) => {
        const temp: InvoiceSite[] = [];
        (res.values || []).forEach((type) => {
          temp.push(
            new InvoiceSite({
              id: type.id,
              internalNumber: type.internal_number,
              description: `${type.internal_number} - ${type.description}`,
              order: type.order,
              address: type.address,
              uuid: type.uuid,
            })
          );
        });
        this.constructionSite = [].concat(temp);
        this.setFiltersValues('sites');
        this.primeraEntradaAssetsCount++;
        this.filterSubmit();
      },
      (err) => console.log('Error loadConstructionSites')
    );
  }

  private loadInvoiceTypes() {
    this.invoiceQueryService.getInvoiceTypes().subscribe(
      (res) => {
        this.invoiceTypes = res.values || [];
        this.setFiltersValues('invoiceType');
        this.primeraEntradaAssetsCount++;
        this.filterSubmit();
      },
      (err) => console.log('Error loadInvoiceTypes')
    );
  }

  private loadEntities() {
    this.searchService
            .getBankingEntities({
              smart_text: null,
              used_to_pay_received_invoices_by_company_id:
                this.loginService.getUser().company?.id,
            }).subscribe((res) => {
                let entidades = res?.values as Array<any>;
                this.entities = entidades?.map((ent) => {
                  return {
                    id: ent.id,
                    name: `${ent.name}`,
                    uuid: ent.code,
                    description: `${ent.name} | ${ent.confirming_phone}`,
                  };
                });
                this.setFiltersValues('entities');
                this.primeraEntradaAssetsCount++;
                this.filterSubmit();
              });
  }

  /* Método de configuración de la tabla */
  private configureTable(): void {
    this.initializePagination();

    this.tableConfiguration.details = true;
    this.tableConfiguration.sorting = true;
    this.tableConfiguration.delete = true;
    this.tableConfiguration.hideExport = true;
    this.tableConfiguration.pageSizes = [this.DEFAULT_PAGE_SIZE, 25, 50];
    this.tableConfiguration.pageable = true;
    this.tableConfiguration.pagination = true;
    this.tableConfiguration.defaultPageSize = this.customPagination.pageSize;

    this.tableConfiguration.detailColumnKeys = [
      'Estado',
      'Emisor',
      'Ubicación',
      'Tipo',
      'Número',
      'F.Factura',
      'F.Registro',
      'Acciones',
    ];
    this.tableConfiguration.hidenCardDetailsFields = [
      'companyDescription',
      'invoiceNumber',
      'invoiceRegisterDate',
      'invoiceTypeDescription',
      'stateDescription',
      'invoiceTotal',
    ];

    this.initializeColumnDefs();
    this.initializeColumnDefsListadoCards();
  }

  private initializePagination(persistOrder?: boolean): void {
    // Restaura paginación en tabla
    if (
      this.storedPagination?.parent === this.router.url.split('/')[2] &&
      this.storedPagination
    ) {
      this.customPagination = this.storedPagination;
    } else {
      this.customPagination.sort = this.DEFAULT_ORDER_NAME;
      this.customPagination.order = this.DEFAULT_ORDER_DIRECTION;
      this.customPagination.totalItems = 0;
      this.customPagination.page = 1;
      this.customPagination.pageSize = 10;
      this.customPagination = Object.assign({}, this.customPagination);
    }
  }

  private initializePagination2(persistOrder?: boolean): void {
    // versión móvil
    if (this.mobile) {
      this.backup();
    } else {
      this.restoreStatus();
    }
  }

  /* Método de inicialización de columnas tabla normal */
  private initializeColumnDefs(): void {
    this.columnDefs = new Array<Columns>();
    this.columnDefs.push({
      title: 'Icon',
      key: 'icon',
      orderDisabled: true,
      isCentered: true,
    });
    this.columnDefs.push({
      title: 'Estado',
      key: 'state',
      cellTemplate: this.stateChipTemplate,
      isCentered: true,
    });
    this.columnDefs.push({
      title: 'Emisor',
      key: 'company',
      cellTemplate: this.companyTemplate,
    });
    this.columnDefs.push({
      title: 'Ubicación',
      key: 'csite',
      cellTemplate: this.csiteTemplate,
    });
    this.columnDefs.push({
      title: 'Tipo',
      key: 'invoice_type',
      cellTemplate: this.siteIconTemplate,
      isCentered: true,
    });
    this.columnDefs.push({
      title: 'Número',
      key: 'invoice_number',
      isCentered: true,
    });
    this.columnDefs.push({
      title: 'F.Factura',
      key: 'invoiceDate',
      isDate: true,
      isCentered: true,
    });
    this.columnDefs.push({
      title: 'F.Registro',
      key: 'registerDate',
      isDate: true,
      isCentered: true,
    });
    this.columnDefs.push({
      title: 'Total',
      key: 'total_amount',
      isCurrency: true,
    });
    this.columnDefs.push({
      title: 'Acciones',
      key: 'invoicePermissions',
      cellTemplate: this.invoiceActionsTemplate,
      orderDisabled: true,
      isRight: true,
    });
  }

  private initializeColumnDefsListadoCards(): void {
    this.columnDefsListadoCards = new Array<Columns>();
    this.columnDefsListadoCards.push({ title: 'Estado', key: 'state' });
    this.columnDefsListadoCards.push({ title: 'Emisor', key: 'company' });
    this.columnDefsListadoCards.push({ title: 'Ubicación', key: 'csite' });
    this.columnDefsListadoCards.push({ title: 'Tipo', key: 'invoice_type' });
    this.columnDefsListadoCards.push({
      title: 'Número de Factura',
      key: 'invoice_number',
    });
    this.columnDefsListadoCards.push({
      title: 'Fecha de Factura',
      key: 'invoiceDate',
    });
    this.columnDefsListadoCards.push({
      title: 'Fecha de Registro',
      key: 'registerDate',
    });
    this.columnDefsListadoCards.push({
      title: 'Importe Total',
      key: 'total_amount',
      isCurrency: true,
    });
  }

  /**
   * Control evento de paginación de tabla
   *
   * @param {*} e Nuevo estado de paginación
   */
  pagination(updatePagination) {
    this.customPagination = updatePagination;
    this.searchInvoices();
  }

  seeDetails(e) {
    this.saveStatus(e.id);
    this.tablePaginationService.setData(this.customPagination);
    this.router.navigateByUrl('/home/invoices/detail/' + e.id);
  }

  getInvoiceLatestEvent(events: InvoiceEvent[]): InvoiceEvent[] {
    if (!events) return null;
    return [events[events.length - 1]];
  }

  editInvoice(invoice: InvoiceGet) {
    this.router.navigateByUrl('/home/invoices/edit/' + invoice.id);
  }

  cancelInvoice(invoice: InvoiceGet) {
    this.invoiceCommandsService.cancelInvoiceConfirmation(invoice);
    this.sub = this.invoiceCommandsService.cancelEmit.subscribe((_) =>
      this.searchInvoices()
    );
  }

  sort() {
    document.getElementById('cardSortButton').click();
  }

  // Posiciona al usuario en la tarjeta anterior
  ngAfterViewChecked(): void {
    if (!this.positionRestored && this.mobile && this.previousPosition) {
      try {
        document
          .getElementById(`cardlist${this.previousPosition}`)
          .scrollIntoView();
        this.positionRestored = true;
      } catch {}
    }
  }

  backup() {
    let lastPageSize = 10;
    let lastPage = 3;

    if (this.mobile) {
      // Restaura paginación cardview
      this.customPagination.sort = this.DEFAULT_ORDER_NAME;
      this.customPagination.order = this.DEFAULT_ORDER_DIRECTION;
      this.customPagination.totalItems = 0;
      this.customPagination.page = lastPage;
      this.customPagination.pageSize = 200;
      this.customPagination = Object.assign({}, this.customPagination);

      for (let index = 0; index < lastPage; index++) {
        // get items
        this.customPagination.page++;
      }
    } else {
      // Restaura paginación en tabla
      this.customPagination.sort = this.DEFAULT_ORDER_NAME;
      this.customPagination.order = this.DEFAULT_ORDER_DIRECTION;
      this.customPagination.totalItems = 0;
      this.customPagination.page = lastPage;
      this.customPagination.pageSize = lastPageSize;
      this.customPagination = Object.assign({}, this.customPagination);
    }
  }

  position() {
    document.getElementById('1442')[0].scrollIntoView();
  }

  saveStatus(id: any) {
    // Guardar estado
    let status: any = new Object();
    status.form = this.fGroup.value;
    status.pagination = this.customPagination;
    status.detail = id;
    this.localStorageService.setItem('filter', JSON.stringify(status));
  }

  restoreStatus() {
    let status: any = this.localStorageService.getItemJson('filter');
    if (status) {
      Object.keys(status.form).map((k) =>
        this.fGroup.get(k).setValue(status.form[k])
      );
      this.customPagination = Object.assign({}, status.pagination);
      this.previousPosition = status.id;
    }
    this.localStorageService.removeItem('filter');
  }

  saveLastPage() {
    this.searchService.lastPageInvoice = this.customPagination.page;
    this.tablePaginationService.resetStore.emit(true);
  }

  showFilter() {
    this.utils.toolbarTitle.emit({
      title: 'Filtrar',
      icon: null,
      back: true,
      event: true,
    });
  }

  displayIVA(vatLines: [any], reverseVatLiability: boolean) {
    let result = false;
    let amount = 0;
    vatLines.forEach((vatLine) => {
      amount += vatLine.amount;
    });

    result = amount === 0 ? true : false;
    result = reverseVatLiability;

    return result;
  }

  displayCardActions(item, user) {
    return (
      (item.stateId === 'sent' &&
        user.permissionsInCompany.invoices_edit &&
        item.invoicePermissions.invoiceEditable) ||
      (item.stateId === 'sent' && item.invoicePermissions.invoiceCancelable)
    );
  }

  downloadFile(fileUuid: string) {
    this.storedFilesService.downloadFile(fileUuid);
  }

  downloadInvoicesZip() {
    const data = new InvoicesZip();

    data.invoice_criteria = new InvoiceSearchCriteria();

    data.invoice_criteria.smart_text =
      this.fGroupSmart?.controls.smartSearch.value;
    data.invoice_criteria.limit = this.customPagination.pageSize;
    data.invoice_criteria.register_date_from =
      this.fGroup.controls.fromfecRegistro.value;
    data.invoice_criteria.register_date_to =
      this.fGroup.controls.tofecRegistro.value;
    data.invoice_criteria.invoice_date_from =
      this.fGroup.controls.fromfecFactura.value;
    data.invoice_criteria.invoice_date_to =
      this.fGroup.controls.tofecFactura.value;
    data.invoice_criteria.payment_date_from =
      this.fGroup.controls.fromfecEmision.value;
    data.invoice_criteria.payment_date_to =
      this.fGroup.controls.tofecEmision.value;
    data.invoice_criteria.invoice_number =
      this.fGroup.controls.invoiceNumber.value;
    data.invoice_criteria.internal_ids =
      this.fGroup.controls.idArpada.value === '' ||
      !this.fGroup.controls.idArpada.value
        ? null
        : [this.fGroup.controls.idArpada.value];

    data.invoice_criteria.order = [
      new Order({
        direction: this.customPagination.order,
        name: this.utils.camelToSnake(this.customPagination.sort),
      }),
    ];

    this.filterMode === 2
      ? this.assignSmartFormValues(data.invoice_criteria)
      : this.assignStrictFormValues(data.invoice_criteria);

    this.invoiceCommandsService.downloadInvoicesZip(data).subscribe(
      (res) => {
        if (res.message_body) {
          this.zipDownloadedMessage = res.message_body;
        } else {
          this.zipDownloadedMessage =
            'Este proceso puede tardar unos minutos. Recibirá un correo electrónico con la documentación solicitada en cuanto finalice la descarga.';
        }
        this.mensajeService.mostrarMensaje(
          '400px',
          'Descarga de facturas',
          ACEPTAR,
          null,
          this.zipDownloadedMessage
        );
        this.disableZipDownload = true;
      },
      (err) => {
        console.log('ERR descarga masiva', err);
      }
    );
  }

  canDownloadZip() {
    return this.user?.permissionsInCompany?.invoices_export_zip;
  }

  downloadInvoicesAsExcel() {
    const data = new InvoicesExcel();

    data.smartText = this.fGroupSmart?.controls.smartSearch.value;
    data.limit = this.customPagination.pageSize;
    data.registerDateFrom = this.fGroup.controls.fromfecRegistro.value;
    data.registerDateTo = this.fGroup.controls.tofecRegistro.value;
    data.invoiceDateFrom = this.fGroup.controls.fromfecFactura.value;
    data.invoiceDateTo = this.fGroup.controls.tofecFactura.value;
    data.paymentDateFrom = this.fGroup.controls.fromfecEmision.value;
    data.paymentDateTo = this.fGroup.controls.tofecEmision.value;
    data.invoiceNumber = this.fGroup.controls.invoiceNumber.value;
    data.internalIds =
      this.fGroup.controls.idArpada.value === '' ||
      !this.fGroup.controls.idArpada.value
        ? null
        : [this.fGroup.controls.idArpada.value];

    data.order = [
      new Order({
        direction: this.customPagination.order,
        name: this.utils.camelToSnake(this.customPagination.sort),
      }),
    ];

    this.isDownloadingExcel = true;

    this.filterMode === 2
      ? this.assignSmartFormValues(data)
      : this.assignStrictFormValues(data);

    this.storedFilesService
      .getInvoicesAsExcel(data.toService())
      .pipe(finalize(() => (this.isDownloadingExcel = false)))
      .subscribe((res) => {
        saveAs(res, 'Facturas.xlsx');
      });
  }

  canDownloadExcel() {
    return this.user?.permissionsInCompany?.invoices_export_xlsx;
  }

  displayStateSteps(item) {
    return item.stateDetailsProgressText;
  }

  getIconInvoiceType(invoiceType) {
    return this.invoiceTypes.find((invoice) => invoice.id === invoiceType)
      .icon_code;
  }
}
