import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { REQUIRED_MESSAGE } from './../../../../app.constants';
import { ControlValue } from './../../../../shared/components/autoform/input-fields/input-fields';
import { InputField, ValidatorMessages } from 'src/app/shared/shared.module';
import { RecoverService } from './../../../services/recover.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { EMAIL_REGEX } from 'src/app/app.constants';
import { environment } from '../../../../../environments/environment';

export interface RequestResetPassword {
  login: string;
  user_captcha_token_response: string;
  url: string;
  base_url: string;
  user_captcha_alias: string;
}

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

  fGroup: FormGroup;

  inputs = new Array<InputField>();
  mobile = false;
  captchaKey = environment.captcha_key;
  captchaResponse: string;

  constructor(private fb: FormBuilder,
    private router: Router,
    private recoverService: RecoverService) {

    this.fGroup = fb.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      captcha: ['', Validators.required]
    })


    /** Configuración de formulario */
    this.inputs.push({
      key: 'email',
      type: 'text',
      label: 'Correo electrónico',
      controlValue: '',
      layout: 'col-12',
      hideRequired: true,
      focusOut: (value: string) => {
        this.fGroup.controls.email.setValue(value.trim().toLowerCase());
      },
      validatorMessages: [
        {key: 'required', value: REQUIRED_MESSAGE},
        {key: 'pattern', value: 'Emáil inválido'}]
    });
  }

  ngOnInit(): void {
  }

  public submit(): void {

    let data: RequestResetPassword = {} as RequestResetPassword;
    data.login = this.fGroup.get('email').value;
    const baseUrl = window.location.href.split('/login/recover')[0];
    data.url = `${baseUrl}/login`;
    data.base_url = baseUrl;
    data.user_captcha_alias = 'invoicing';
    data.user_captcha_token_response =  environment.production ? this.captchaResponse : '0131ba7e4bc449f18153c4ed9ee015ed';

    this.recoverService.recoverRequest(data);
  }

  resolved(e) {
    this.captchaResponse = e;
  }

  back(): void {
    this.router.navigateByUrl('/login');
  }
}
