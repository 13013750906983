<!-- SI LA TARJETA ES UN TEMPLATE COMPLETO NO SE RECORREN LOS CAMPOS,
  SOLO SE USARÁN PARA ORDENACIÓN -->
<ng-container *ngIf="fullCardTemplate && item">
  <div class="col-12">
    <div class="row p-1">
      <ng-container class="col-12" [ngTemplateOutlet]="fullCardTemplate"
      [ngTemplateOutletContext]="{ $implicit: item}"></ng-container>
    </div>
  </div>
</ng-container>

<!-- SI NO HAY TEMPLATE COMPLETO DEFINIDO SE LISTAN LOS CAMPOS DENTRO DE LA TARJETA -->
<ng-container *ngIf="!fullCardTemplate && item">
  <!-- <section class="row w-100"> -->
    <ng-container *ngFor="let label of labels; let icolumn = index">
      <div *ngIf="!hidenFields.includes(label.key)" class="col-12">
        <ng-container>
          <ng-container *ngIf="label.key === 'checkbox'">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? changeCheckbox($event, item) : null"
              [checked]="selection.isSelected(item)" [aria-label]="checkboxLabel(item)" [disabled]="enableCheckbox(item)">
            </mat-checkbox>
          </ng-container>
          <ng-container *ngIf="label.key !== 'checkbox' && label.key !=='buttons'">
            <div
              *ngIf="(item | getPropertyFromString: label.key) !== true && (item | getPropertyFromString: label.key) !== false else boolean">
              <div class="row p-1">
                <ng-container *ngIf="!label.cellTemplate else customTemplate">
                  <strong [innerHTML]="label.title"></strong>:
                  <!--  -->
                  <!-- currency on euros -->
                  <ng-container *ngIf="label.isCurrency && label.key !== 'importeTotal'">
                    {{ item | getPropertyFromString: label.key | currency}}
                  </ng-container>
                  <!-- Numeric -->
                  <ng-container *ngIf="label.isNumber">
                    {{ getNumberFormatted( item | getPropertyFromString: label.key) }}
                  </ng-container>
                  <!-- date -->
                  <ng-container *ngIf="label.isDate">
                    <span>{{ (item | getPropertyFromString: label.key) !== '--' ? (item | getPropertyFromString: label.key | date: label.dateFormat || 'dd/MM/yyyy') : '--' }}</span>
                  </ng-container>
                  <!-- any other type -->
                  <ng-container *ngIf="label.key === 'importeTotal'">
                    <!-- <button mat-button (click)="columnDetail(item, label.key);$event.stopPropagation()"><span
                      class="screen-reader-text">{{'Botón detalle columna ' +  label.title | translate}}</span>

                    <ng-container >
                      {{ item | getPropertyFromString: label.key | currency: 'EUR' }}
                    </ng-container>

                  </button> -->
                  <a (click)="columnDetail(item, label.key);$event.stopPropagation()">{{item | getPropertyFromString: label.key | currency: 'EUR'}}</a>
                  </ng-container>
                  <ng-container *ngIf="!label.isNumber && !label.isCurrency && !label.isDate ">
                    {{ item | getPropertyFromString: label.key }}
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>

        </ng-container>

        <ng-template #boolean>
          <div>
            <span><strong [innerHTML]="label.title"></strong>:
              <span class="ml-2" [innerHTML]="item | getPropertyFromString: label.key | icon"></span>
            </span>
          </div>
        </ng-template>

        <ng-template #customTemplate let-row>
          <ng-container [ngTemplateOutlet]="label.cellTemplate"
            [ngTemplateOutletContext]="{ $implicit: item, column: label }">
          </ng-container>
        </ng-template>

      </div>
    </ng-container>
  <!-- </section> -->
</ng-container>


<ng-template #dateTimeTemplate let-item let-column='column'>
  <span>{{ (item | getPropertyFromString: column.key) !== '--' ? (item | getPropertyFromString: column.key | date: 'dd/MM/yyyy HH:mm') : '--' }}</span>
</ng-template>

<ng-template #timeTemplate let-item let-column='column'>
  <span>{{ (item | getPropertyFromString: column.key) !== '--' ? (item | getPropertyFromString: column.key | date: 'HH:mm') : '--' }}</span>
</ng-template>
