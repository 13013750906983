import { InvoiceSearchCriteria } from './../models/invoice_search_criteria.model';
import { AlertService } from './../../shared/services/alert.service';
import { UtilsService } from './../../shared/services/utils.service';
import { InvoicePageable } from './../models/pageable.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KeyValue } from 'src/app/shared/models/key-value.model';
import { environment } from 'src/environments/environment';
import {
  InvoiceType,
  InvoiceTypeResponse,
} from '../models/invoices/invoice-type.model';
import { BaseComunicationService } from './base-comunication.service';
import { StoredFileGet } from '../models/stored-file.model';
import { VatTypeResponse } from '../models/vat-type.model';
import { InvoiceTypeSearchCriteria } from '../models/invoice_type_search_criteria.model';

@Injectable({
  providedIn: 'root',
})
export class InvoicingQueryService extends BaseComunicationService {
  private urlApi = `${environment.apiInvoicingQueryBaseUrl}`;

  constructor(
    public http: HttpClient,
    private utilsService: UtilsService,
    private alertService: AlertService
  ) {
    super(http);
  }

  /**
   * Obtener empresas
   */
  checkUniqueInvoiceNumber(
    invoiceNumber: string,
    companyVat: string,
    invoiceDate: string
  ): Observable<any> {
    const url: string = `${this.urlApi}/check-unique-invoice-number`;
    let params = new Array<KeyValue>();
    params.push(new KeyValue('invoice_number', invoiceNumber));
    params.push(new KeyValue('company_vat_number', companyVat));
    params.push(new KeyValue('invoice_date', invoiceDate));

    return this.realizarPeticionGetConFiltros(url, params);
  }

  getInvoiceTypes(): Observable<InvoiceTypeResponse> {
    const url: string = `${this.urlApi}/invoice-type-search`;
    let data: any = new Object();
    return this.realizarPeticionPost(url, data);
  }

  getInvoiceTypesByDestinatario(
    destinatarioId: number
  ): Observable<InvoiceTypeResponse> {
    const url = `${this.urlApi}/invoice-type-search`;
    const data: InvoiceTypeSearchCriteria = new InvoiceTypeSearchCriteria();
    data.by_invoiceable_company = destinatarioId;
    data.b_only_selectables_in_user_interface = true;    
    return this.realizarPeticionPost(url, data);
  }

  getInvoices(id?: string, key?): Observable<any> {
    const url: string = `${this.urlApi}/invoices-search`;
    let data: KeyValue[] = new Array<KeyValue>();
    if (key) {
      data.push(new KeyValue(key, id));
    } else {
      if (id) {
        if (id.length === 36) {
          data.push(new KeyValue('uuid', id));
        } else {
          data.push(new KeyValue('id', id));
        }
      }
    }

    return this.realizarPeticionGetConFiltros(url, data);
  }

  getInvoicesByCriteria(
    invoiceSearchCriteria: InvoiceSearchCriteria | any
  ): Observable<any> {
    const url = `${this.urlApi}/invoices-search`;
    return this.realizarPeticionPost(url, invoiceSearchCriteria);
  }

  getInvoiceByUuid(uuid?: string): Observable<any> {
    const url: string = `${this.urlApi}/invoices-search`;
    let data: KeyValue[] = new Array<KeyValue>();
    if (uuid) {
      data.push(new KeyValue('uuid', uuid));
    }
    return this.realizarPeticionGetConFiltros(url, data);
  }

  getInvoicesPageable(pageable: InvoicePageable) {
    const url: string = `${this.urlApi}/invoices-search`;
    if (pageable.order[0].name === 'fullname') {
      pageable.order[0].name = 'register_date';
    }
    return this.realizarPeticionPost(url, pageable.toService());
  }

  getVatTypes(date?: string): Observable<VatTypeResponse> {
    const url = `${this.urlApi}/vat-type-search`;

    let data: any = new Object();
    if (date) {
      data = {
        active_on_date: date,
      };
    }
    return this.realizarPeticionPost(url, data);
  }

  getInvoicesAsZipFile(data: any) {
    const url: string = `${this.urlApi}/request-generate-zip-file-of-invoices-files`;
    return this.realizarPeticionPost(url, data);
  }
}
