import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { LoginService } from 'src/app/core/services/login.service';

@Component({
  selector: 'app-user-registry-confirm-container',
  templateUrl: './user-registry-confirm-container.component.html',
  styleUrls: ['./user-registry-confirm-container.component.scss']
})
export class UserRegistryConfirmContainerComponent implements OnInit {

  mobile = false;
  userType: string;

  constructor(public loginService: LoginService,
    private location: Location) { }

  ngOnInit(): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;

    this.userType = this.loginService.getUser().userType;
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;
  }

  back() {
    this.location.back();
  }

}
