import { Iva } from './../../../models/invoices/invoice-dto';
import {
  AfterContentInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, InputField } from 'src/app/shared/shared.module';
import { InvoicingQueryService } from 'src/app/core/services/invoicing-query.service';
import { InvoiceGet } from 'src/app/core/models/invoices/invoice-get';
import * as moment from 'moment';
import { SearchService } from 'src/app/core/services/search.service';
import { map, take } from 'rxjs/operators';
import {
  DATE_VALIDATION_MESSAGE,
  INVOICE_NUMBER_ALREADY_EXIST_MESSAGE,
  REQUIRED_MESSAGE,
} from 'src/app/app.constants';
import { InvoiceType } from 'src/app/core/models/invoices/invoice-type.model';
import {
  InvoiceSubtype,
  VatOptions,
} from 'src/app/core/models/invoices/invoice-subtype.model';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { LoginService } from 'src/app/core/services/login.service';
import { InvoiceDto } from 'src/app/core/models/invoices/invoice-dto';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { VatType } from 'src/app/core/models/vat-type.model';
import {
  InvoiceNew,
  InvoiceNewDto,
  VatLine,
} from 'src/app/core/models/invoices/invoice-new';
import { v4 as uuidv4 } from 'uuid';
import { InvoiceCommandsService } from 'src/app/core/services/invoice-commands.service';
import {
  InvoiceUpdate,
  InvoiceUpdateDto,
} from 'src/app/core/models/invoices/invoice-update';
import { Proforma } from 'src/app/core/models/proformas.model';

@Component({
  selector: 'app-invoices-create-new',
  templateUrl: './invoices-create-new.component.html',
  styleUrls: ['./invoices-create-new.component.scss'],
})
export class InvoicesCreateNewComponent implements OnInit {
  mobile = false;
  displayStepLabel = true;
  title: string;
  toolTipTitle: string;
  iconString: string;
  editOn: boolean;
  formStep1: FormGroup;
  formStep2: FormGroup;
  formStep2ProntoPago: FormGroup;
  inputsStep1 = new Array<InputField>();
  invoiceGet: InvoiceGet;
  invoiceCompanyId: number;
  invoiceTypes: Array<InvoiceType>;
  invoiceSubTypes: Array<InvoiceSubtype>;
  userType: string;
  invoiceDTO: InvoiceDto;
  companyId: number;
  destinatarioId: number;
  tipoFactura: InvoiceType;
  subTipoFactura: InvoiceSubtype;
  fechaFactura: string;
  changeFormFirstStep = false;
  @ViewChild('stepper') stepper: MatStepper;
  emisores: { id; name: string; description: string; vat_number: string }[] =
    [];
  destinatarios: {
    id;
    name: string;
    description: string;
    vat_number: string;
  }[] = [];
  isCheckInvoiceNumber = false;
  vatTypes: VatType[];
  dinamicVatTypes: VatType[];
  primeraVueltaEdicion = false;

  invoiceTypeChange = new BehaviorSubject<Array<InvoiceSubtype>>([]);
  emisorChange = new BehaviorSubject<any>(null);
  destinatarioChange = new BehaviorSubject<any>(null);

  get formStep1IsValid() {
    return this.formStep1.valid && this.isCheckInvoiceNumber
  }

  @HostListener('window:resize', ['$event'])
  private onResize(): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;
    this.chooseDisplayStepLabel();
  }

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private fb: FormBuilder,
    private invoicingQueryService: InvoicingQueryService,
    private searchService: SearchService,
    private utilsService: UtilsService,
    private loginService: LoginService,
    private invoiceCommandsService: InvoiceCommandsService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.formStep1 = new FormGroup({});
    this.userType = this.loginService.getUser().userType;
    this.route.params.subscribe((params) => {
      this.title =
        params.id !== undefined ? 'Editar factura' : 'Registrar nueva factura';
      this.toolTipTitle =
        params.id !== undefined ? 'Editar factura' : 'Alta nueva factura';
      this.iconString =
        params.id !== undefined ? 'mode_edit' : 'add_circle_outline';
      this.editOn = params.id !== undefined ? true : false;
      this.utilsService.toolbarTitle.emit({
        title: this.title,
        icon: this.iconString,
      });
      if (this.userType === 'external') {
        const userCompany = this.loginService.getUser().company;
        this.invoiceCompanyId = userCompany.id;
      }
      if (params.id) {
        this.invoicingQueryService.getInvoices(params.id, 'id').subscribe((resp) => {
          if (resp.values) {
            this.isCheckInvoiceNumber = true;
            this.primeraVueltaEdicion = true;
            this.invoiceGet = InvoiceGet.buildFromDto(resp.values[0]);
            this.formStep1Init();
            this.formStep1
              .get('fechaFactura')
              .setValue(
                moment.utc(this.invoiceGet.invoiceDate, ['YYYY/MM/DD']).format()
              );
            this.formStep1
              .get('numeroFactura')
              .setValue(this.invoiceGet.invoice_number);
            if (this.editOn) {
              this.invoiceCompanyId = this.invoiceGet.companyId;
            }
          }
        });
      }
    });
    this.mobile = window.innerWidth <= 1020 ? true : false;
    this.invoiceTypes = new Array<InvoiceType>();
    if (!this.editOn) {
      this.formStep1Init();
    }
    this.invoiceDTO = new InvoiceDto();
    this.formStep2 = new FormGroup({});
  }

  private chooseDisplayStepLabel() {
    this.displayStepLabel = window.innerWidth <= 550 ? false : true;
  }

  back() {
    this.location.back();
  }

  formStep1Init() {
    this.formStep1 = this.fb.group({
      emisor: ['', [Validators.required]],
      destinatario: ['', [Validators.required]],
      numeroFactura: [
        '',
        {
          validators: [Validators.required],
          updateOn: this.editOn ? 'change' : 'blur',
        },
      ],
      fechaFactura: [
        '',
        { validators: [Validators.required], updateOn: 'blur' },
      ],
      tipoFactura: [
        '',
        { validators: [Validators.required], updateOn: 'blur' },
      ],
      subTipoFactura: [
        '',
        { validators: [Validators.required], updateOn: 'blur' },
      ],
    });

    this.inputsStep1 = new Array<InputField>();
    this.inputsStep1.push(
      {
        key: 'emisor',
        type: 'autocomplete-chip',
        label: 'Emisor',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        placeholder: 'Seleccione un emisor',
        controlValue: '',
        layout: 'col-12 col-md-12 col-sm-12 mb-4',
        options: this.emisores,
        filteredOptions: (value: string) => {
          return this.searchService.getCompanies(value).pipe(
            take(1),
            map((res) => {
              const emisores = (res.values as Array<any>) || [];

              if (this.userType !== 'external') {
                emisores.forEach((emp) => {
                  if (this.invoiceCompanyId !== emp.id) {
                    this.emisores.push({
                      id: emp.id,
                      name: `${emp.description}`,
                      description: `${emp.vat_number} | ${emp.description}`,
                      vat_number: emp.vat_number,
                    });
                  }
                });
              }

              if (!this.editOn) {
                if (this.userType === 'external') {
                  this.formStep1
                    .get('emisor')
                    .setValue([
                      emisores.find((c) => c.id === this.invoiceCompanyId),
                    ]);
                  this.formStep1.get('emisor').disable();
                }
                // if (emisores.length === 1) {
                //   this.formStep1
                //     .get('emisor')
                //     .setValue([
                //       emisores.find((c) => c.id === this.invoiceCompanyId),
                //     ]);
                //   this.formStep1.get('emisor').disable();
                // }
              }

              if (this.editOn && this.primeraVueltaEdicion) {
                this.formStep1
                  .get('emisor')
                  .setValue([
                    emisores.find((c) => c.id === this.invoiceCompanyId),
                  ]);
                this.formStep1.get('emisor').disable();
              }

              this.emisores = emisores;
              return emisores;
            })
          );
        },
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
      },
      {
        key: 'destinatario',
        type: 'autocomplete-chip',
        label: 'Destinatario',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        placeholder: 'Seleccione un destinatario',
        controlValue: '',
        layout: 'col-12 col-md-12 col-sm-12 mb-4',
        options: this.destinatarios,
        filteredOptions: (destinatario) => {
          this.getDestinatariosFiltered(destinatario);
          return this.destinatarioFilteredOptions();
        },
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
      },
      {
        key: 'fechaFactura',
        type: 'date',
        label: 'Fecha de factura',
        controlValue: '',
        layout: 'col-lg-6 col-md-12 col-sm-12',
        validatorMessages: [
          { key: 'required', value: REQUIRED_MESSAGE },
          { key: 'invalidDate', value: DATE_VALIDATION_MESSAGE },
        ],
      },
      {
        key: 'numeroFactura',
        type: 'text',
        label: 'Nº de factura',
        controlValue: '',
        layout: 'col-lg-6 col-md-12 col-sm-12',
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
      },
      {
        key: 'tipoFactura',
        type: 'autocomplete-chip',
        label: 'Tipo de factura',
        placeholder: 'Seleccione una opción',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        controlValue: '',
        options: this.invoiceTypes,
        layout: 'col-12  col-md-12 col-sm-12 mb-4',
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
        filteredOptions: () => {
          return this.destinatarioChange.asObservable().pipe(
            map((res: any) => {
              if (res?.count > 0) {
                this.invoiceTypes = res.values as Array<InvoiceType>;
                if (this.editOn && this.primeraVueltaEdicion) {
                  this.formStep1
                    .get('tipoFactura')
                    .setValue([
                      this.invoiceTypes.find(
                        (t) => t.id === this.invoiceGet.invoiceTypeId
                      ),
                    ]);

                  this.formStep1.get('tipoFactura').disable();
                }
                return this.invoiceTypes;
              } else {
                this.formStep1.get('tipoFactura').setValue(null);
                return [];
              }
            })
          );
        },
      },
      {
        key: 'subTipoFactura',
        type: 'autocomplete-chip',
        label: 'Subtipo de factura',
        placeholder: 'Seleccione una opción',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        controlValue: '',
        options: this.invoiceSubTypes,
        layout: 'col-12  col-md-12 col-sm-12 mb-4 invisible',
        validatorMessages: [{ key: 'required', value: REQUIRED_MESSAGE }],
        filteredOptions: () => {
          return this.invoiceTypeChange.asObservable().pipe(
            map((res: InvoiceSubtype[]) => {
              if (res?.length > 0) {
                this.invoiceSubTypes = res as Array<InvoiceSubtype>;

                if (!this.editOn) {
                  if (this.invoiceSubTypes.length === 1) {
                    this.inputsStep1.find(
                      (c) => c.key === 'subTipoFactura'
                    ).layout = 'col-12  col-md-12 col-sm-12 mb-4 invisible';
                    this.formStep1
                      .get('subTipoFactura')
                      .setValue([this.invoiceSubTypes[0]]);
                  } else {
                    this.inputsStep1.find(
                      (c) => c.key === 'subTipoFactura'
                    ).layout = 'col-12  col-md-12 col-sm-12 mb-4';
                    this.formStep1.get('subTipoFactura').reset();
                  }
                }

                if (this.editOn && this.primeraVueltaEdicion) {
                  this.formStep1
                    .get('subTipoFactura')
                    .setValue([
                      this.invoiceSubTypes.find(
                        (t) => t.id === this.invoiceGet.invoiceSubtypeId
                      ),
                    ]);
                  if (this.invoiceSubTypes.length === 1) {
                    this.inputsStep1.find(
                      (c) => c.key === 'subTipoFactura'
                    ).layout = 'col-12  col-md-12 col-sm-12 mb-4 invisible';
                  } else {
                    this.inputsStep1.find(
                      (c) => c.key === 'subTipoFactura'
                    ).layout = 'col-12  col-md-12 col-sm-12 mb-4';
                  }

                  this.formStep1.get('subTipoFactura').disable();
                }

                this.primeraVueltaEdicion = false;
                return this.invoiceSubTypes;
              } else {
                this.formStep1.get('subTipoFactura').setValue(null);
                this.inputsStep1.find(
                  (c) => c.key === 'subTipoFactura'
                ).layout = 'col-12  col-md-12 col-sm-12 mb-4 invisible';
                return [];
              }
            })
          );
        },
      }
    );

    this.formStep1.get('tipoFactura').valueChanges.subscribe((tipoFactura) => {
      if (tipoFactura !== null && tipoFactura?.length > 0) {
        const tipoSeleccionado: InvoiceType = tipoFactura[0];
        if (tipoSeleccionado !== undefined) {
          this.invoiceSubTypes = new Array<InvoiceSubtype>();
          this.invoiceSubTypes = tipoSeleccionado.subtypes;
        }
      } else {
        this.invoiceSubTypes = [];
      }

      this.invoiceTypeChange.next(this.invoiceSubTypes);
    });

    this.formStep1.get('emisor').valueChanges.subscribe((emisor) => {
      this.companyId = emisor?.length > 0 ? emisor[0]?.id : null;
      this.destinatarios = [];
      if (this.companyId) {
        this.searchService.getDestinatarios(this.companyId).subscribe((res) => {
          this.destinatarios = res;
          this.emisorChange.next(this.destinatarios);
        });
      } else {
        this.emisorChange.next(this.destinatarios);
      }
    });

    this.formStep1
      .get('destinatario')
      .valueChanges.subscribe((destinatario) => {
        if(destinatario && destinatario?.length === 0) {
          this.getDestinatariosFiltered(null)
        }
        this.destinatarioId =
          destinatario?.length > 0 ? destinatario[0]?.id : null;
        if (this.destinatarioId) {
          this.invoicingQueryService
            .getInvoiceTypesByDestinatario(this.destinatarioId)
            .pipe(take(1))
            .subscribe((resp) => {
              this.destinatarioChange.next(resp);
            });
        } else {
          this.destinatarioChange.next(null);
        }
      });

    this.formStep1.get('fechaFactura').valueChanges.subscribe(() => {
      if (this.formStep1) {
        if (
          this.formStep1.get('fechaFactura') &&
          this.formStep1.get('fechaFactura').value
        ) {
          const date = new DatePipe('es').transform(
            this.formStep1.get('fechaFactura').value,
            'yyyy-MM-dd'
          );
          this.getVatTypes(date);
          const today = new Date();
          if (today < this.formStep1.get('fechaFactura').value) {
            this.formStep1.get('fechaFactura').setErrors({ invalidDate: true });
          }
        }
      }
    });

    if (!this.editOn) {
      combineLatest([
        this.formStep1.get('numeroFactura').valueChanges,
        this.formStep1.get('fechaFactura').valueChanges,
        this.formStep1.get('emisor').valueChanges,
      ]).subscribe(([numeroFactura, fechaFactura, emisor]) => {
        if (
          numeroFactura !== '' &&
          fechaFactura !== null &&
          emisor?.length > 0
        ) {
          if (
            this.formStep1 &&
            (this.formStep1.get('fechaFactura').touched ||
              this.formStep1.get('numeroFactura').touched)
          ) {
            if (
              this.formStep1.get('numeroFactura') &&
              this.formStep1.get('numeroFactura').value
            ) {
              this.checkInvoiceNumber();
            }
          }
        }
      });
    }

    if (this.editOn) {
      this.formStep1.get('numeroFactura').valueChanges.subscribe((value) => {
        this.checkInvoiceNumberEdit(
          value,
          this.formStep1.get('fechaFactura').value
        );
      });

      this.formStep1.get('fechaFactura').valueChanges.subscribe((value) => {
        this.checkInvoiceNumberEdit(
          this.formStep1.get('numeroFactura').value,
          value
        );
      });
    }

    this.formStep1.valueChanges.subscribe(() => {
      this.changeFormFirstStep = true;
    })
  }

  submitStep1() {
    this.invoiceDTO.emisor = this.formStep1.get('emisor').value[0];
    this.invoiceDTO.destinatario = this.formStep1.get('destinatario').value[0];
    this.invoiceDTO.numeroFactura = this.formStep1.get('numeroFactura').value;
    this.invoiceDTO.fechaFactura = this.formStep1.get('fechaFactura').value;
    this.invoiceDTO.tiposFactura = this.formStep1.get('tipoFactura').value[0];
    this.invoiceDTO.subTipoFactura =
      this.formStep1.get('subTipoFactura').value[0];
    this.tipoFactura = this.invoiceDTO.tiposFactura;
    this.subTipoFactura = new InvoiceSubtype(this.invoiceDTO.subTipoFactura);
    this.fechaFactura = new DatePipe('es').transform(
      this.invoiceDTO.fechaFactura,
      'yyyy-MM-dd'
    );
  }

  confirmInvoice() {
    if (this.editOn) {
      this.updateInvoice();
    } else {
      this.createInvoice();
    }
  }

  updateInvoice() {
    const updateInvoiceDTO = new InvoiceUpdateDto();
    const updateInvoice = new InvoiceUpdate();
    updateInvoiceDTO.refund_prompt_payment_invoice = null;
    updateInvoiceDTO.refund_prompt_payment_invoice_uuid = null;
    const date = new DatePipe('es').transform(
      this.invoiceDTO.fechaFactura,
      'yyyy-MM-dd'
    );
    updateInvoice.invoice_number = this.invoiceDTO.numeroFactura;
    updateInvoice.invoice_date = date;
    updateInvoice.base_amount = Number(this.invoiceDTO.baseImponible);
    updateInvoice.invoice_total = this.invoiceDTO.totalFactura;
    updateInvoice.reverse_vat_liability = this.invoiceDTO.inversionSujetoPasivo;
    updateInvoice.invoice_file_uuid = this.invoiceDTO.idFacturaAdjunta;
    updateInvoice.invoice_type_id = this.invoiceDTO.tiposFactura.id;
    updateInvoice.invoice_subtype_id = this.invoiceDTO.subTipoFactura
      ? this.invoiceDTO.subTipoFactura.id
      : null;
    updateInvoice.company_id = this.invoiceDTO.emisor.id;
    if (this.invoiceDTO.ubicacion) {
      updateInvoice.csite_id = this.invoiceDTO.ubicacion.id;
    }

    updateInvoice.vat_lines = new Array<VatLine>();
    this.invoiceDTO.ivas.forEach((iva) => {
      const vatLines = new VatLine();
      vatLines.amount = Number(iva.value);
      vatLines.vat_type_id = iva.tipoDeIva.id;
      vatLines.percent_description = iva.tipoDeIva.percent_description;
      updateInvoice.vat_lines.push(vatLines);
    });

    if (Number(this.invoiceDTO?.baseExentaIva) !== 0) {
      const vatLinesExentaIva = new VatLine();
      vatLinesExentaIva.vat_type_id = this.getIVAByKey(
        this.subTipoFactura?.templateParameters?.vatExentoOptions
          ?.vattypeUniquekey
      ).id;
      vatLinesExentaIva.percent_description = this.getIVAByKey(
        this.subTipoFactura?.templateParameters?.vatExentoOptions
          ?.vattypeUniquekey
      ).percent_description;
      vatLinesExentaIva.base_amount = Number(this.invoiceDTO?.baseExentaIva);
      updateInvoice.vat_lines.push(vatLinesExentaIva);
    }

    updateInvoice.has_prompt_payment = this.invoiceDTO?.prontoPagoIncluido;
    updateInvoice.invoice_attachment_file_uuid =
      this.invoiceDTO?.idFacturaAdjuntaAdicional;
    updateInvoice.irpf_value = Number(this.invoiceDTO?.irpf);
    updateInvoice.company_which_is_invoiced_id =
      this.invoiceDTO.destinatario.id;

    if (this.invoiceDTO?.partesDeTrabajo?.length > 0) {
      const linkedWorksheets = new Array<string>();
      this.invoiceDTO.partesDeTrabajo.forEach((p) => {
        linkedWorksheets.push(p.id);
      });
      updateInvoice.linked_worksheets = linkedWorksheets;
    }

    if (this.invoiceDTO?.facturaAbono) {
      updateInvoice.refunded_invoice_id = this.invoiceDTO?.facturaAbono[0]?.id;
    }

    updateInvoice.withholding_amount = null;
    updateInvoice.withholding_perone = null;
    updateInvoice.prompt_payment_amount = null;
    updateInvoice.linked_proforma = null;

    if (this.invoiceDTO.tiposFactura.id === 'construction_site_proforma') {
      const vatLinesProforma = new VatLine();
      vatLinesProforma.vat_type_id =
        this.invoiceDTO.proforma?.vat_lines[0]?.vat_type_id;
      vatLinesProforma.amount = this.invoiceDTO.proforma?.vat_lines[0]?.amount;
      updateInvoice.vat_lines = new Array<VatLine>();
      updateInvoice.vat_lines.push(vatLinesProforma);
      updateInvoice.has_prompt_payment = this.invoiceDTO.tieneProntoPago;
      updateInvoice.prompt_payment_amount = this.invoiceDTO.prontoPago
        ? this.invoiceDTO.prontoPago
        : 0;
      updateInvoice.withholding_amount = this.invoiceDTO.retencion;
      updateInvoice.withholding_perone =
        this.invoiceDTO.porcentajeRetencion / 100;
      updateInvoice.invoice_subtype_id =
        this.invoiceDTO.tiposFactura?.subtypes[0]?.id;
      updateInvoice.linked_proforma = this.invoiceDTO.proforma.id.toString();
      if (
        !this.invoiceDTO.prontoPagoIncluido &&
        this.invoiceDTO.idFacturaAdjuntaAbono
      ) {
        const iva = Number(
          this.getIVASPermitidos().find(
            (vatType) =>
              vatType.id === this.invoiceDTO.proforma?.vat_lines[0]?.vat_type_id
          ).per_one_factor
        );
        vatLinesProforma.vat_type_id =
          this.invoiceDTO.proforma?.vat_lines[0]?.vat_type_id;
        vatLinesProforma.amount = this.invoiceDTO.baseImponible * iva;
        updateInvoice.vat_lines = new Array<VatLine>();
        updateInvoice.vat_lines.push(vatLinesProforma);
        const newInvoiceProntoPago = new InvoiceUpdate();
        const vatLinesAbono = new VatLine();
        const dateAbono = new DatePipe('es').transform(
          this.invoiceDTO.fechaFacturaAbono,
          'yyyy-MM-dd'
        );
        vatLinesAbono.vat_type_id =
          this.invoiceDTO.proforma?.vat_lines[0]?.vat_type_id;
        vatLinesAbono.amount = this.invoiceDTO.baseImponibleAbono * iva;
        newInvoiceProntoPago.invoice_number =
          this.invoiceDTO.numeroFacturaAbono;
        newInvoiceProntoPago.invoice_date = dateAbono;
        newInvoiceProntoPago.base_amount = this.invoiceDTO.baseImponibleAbono;
        newInvoiceProntoPago.invoice_total = this.invoiceDTO.totalFacturaAbono;
        newInvoiceProntoPago.has_prompt_payment =
          this.invoiceDTO.tieneProntoPago;
        newInvoiceProntoPago.invoice_file_uuid =
          this.invoiceDTO.idFacturaAdjuntaAbono;
        newInvoiceProntoPago.invoice_type_id = 'refund';
        newInvoiceProntoPago.invoice_subtype_id = 'prompt_payment';
        newInvoiceProntoPago.reverse_vat_liability =
          this.invoiceDTO.inversionSujetoPasivo;
        newInvoiceProntoPago.vat_lines = new Array<VatLine>();
        newInvoiceProntoPago.vat_lines.push(vatLinesAbono);
        newInvoiceProntoPago.company_id = this.invoiceDTO.emisor.id;
        newInvoiceProntoPago.company_which_is_invoiced_id =
          this.invoiceDTO.destinatario.id;
        if (this.invoiceDTO.ubicacion) {
          newInvoiceProntoPago.csite_id = this.invoiceDTO.ubicacion.id;
        }

        updateInvoiceDTO.refund_prompt_payment_invoice_uuid =
          this.invoiceGet.relatedToRefundUuid;
        updateInvoiceDTO.refund_prompt_payment_invoice = newInvoiceProntoPago;
      }
    }

    updateInvoiceDTO.invoice_update = updateInvoice;
    updateInvoiceDTO.id_to_update = this.invoiceGet.id;
    updateInvoiceDTO.invoice_uuid = this.invoiceGet.uuid;

    this.invoiceCommandsService
      .updateInvoice(updateInvoiceDTO)
      .subscribe((resp) => {
        if (resp) {
          if (resp.status === 'OK') {
            this.alertService.showDetailSuccess(
              '¡Éxito!',
              'Factura actualizada con exito.'
            );
            this.location.back();
          } else {
            this.alertService.showDetailError('Error', resp.error_message);
          }
        }
      });
  }

  createInvoice() {
    const newInvoiceDTO = new InvoiceNewDto();
    const newInvoice = new InvoiceNew();
    newInvoiceDTO.invoice_uuid = uuidv4();
    newInvoiceDTO.refund_prompt_payment_invoice = null;
    newInvoiceDTO.refund_prompt_payment_invoice_uuid = null;
    const date = new DatePipe('es').transform(
      this.invoiceDTO.fechaFactura,
      'yyyy-MM-dd'
    );
    newInvoice.invoice_number = this.invoiceDTO.numeroFactura;
    newInvoice.invoice_date = date;
    newInvoice.base_amount = Number(this.invoiceDTO.baseImponible);
    newInvoice.invoice_total = this.invoiceDTO.totalFactura;
    newInvoice.reverse_vat_liability = this.invoiceDTO.inversionSujetoPasivo;
    newInvoice.invoice_file_uuid = this.invoiceDTO.idFacturaAdjunta;
    newInvoice.invoice_type_id = this.invoiceDTO.tiposFactura.id;
    newInvoice.invoice_subtype_id = this.invoiceDTO.subTipoFactura
      ? this.invoiceDTO.subTipoFactura.id
      : null;
    newInvoice.company_id = this.invoiceDTO.emisor.id;
    if (this.invoiceDTO.ubicacion) {
      newInvoice.csite_id = this.invoiceDTO.ubicacion.id;
    }

    newInvoice.vat_lines = new Array<VatLine>();
    this.invoiceDTO.ivas.forEach((iva) => {
      const vatLines = new VatLine();
      if (iva?.tipoDeIva?.per_one_factor !== 0) {
        vatLines.amount = Number(iva?.value);
      } else {
        vatLines.base_amount = Number(iva?.value);
      }
      vatLines.vat_type_id = iva?.tipoDeIva?.id;
      vatLines.percent_description = iva?.tipoDeIva?.percent_description;
      if (iva?.tipoDeIva) {
        newInvoice.vat_lines.push(vatLines);
      }
    });

    if (Number(this.invoiceDTO?.baseExentaIva) !== 0) {
      const vatLinesExentaIva = new VatLine();
      vatLinesExentaIva.vat_type_id = this.getIVAByKey(
        this.subTipoFactura?.templateParameters?.vatExentoOptions
          ?.vattypeUniquekey
      ).id;
      vatLinesExentaIva.percent_description = this.getIVAByKey(
        this.subTipoFactura?.templateParameters?.vatExentoOptions
          ?.vattypeUniquekey
      ).percent_description;
      vatLinesExentaIva.base_amount = Number(this.invoiceDTO?.baseExentaIva);
      newInvoice.vat_lines.push(vatLinesExentaIva);
    }

    newInvoice.has_prompt_payment = this.invoiceDTO?.prontoPagoIncluido;
    newInvoice.invoice_attachment_file_uuid =
      this.invoiceDTO?.idFacturaAdjuntaAdicional;
    newInvoice.irpf_value = Number(this.invoiceDTO?.irpf);
    newInvoice.company_which_is_invoiced_id = this.invoiceDTO.destinatario.id;

    if (this.invoiceDTO?.partesDeTrabajo?.length > 0) {
      const linkedWorksheets = new Array<string>();
      this.invoiceDTO.partesDeTrabajo.forEach((p) => {
        linkedWorksheets.push(p.id);
      });
      newInvoice.linked_worksheets = linkedWorksheets;
    }

    if (this.invoiceDTO?.facturaAbono) {
      newInvoice.refunded_invoice_id = this.invoiceDTO?.facturaAbono[0]?.id;
    }

    newInvoice.withholding_amount = null;
    newInvoice.withholding_perone = null;
    newInvoice.prompt_payment_amount = null;
    newInvoice.linked_proforma = null;

    if (this.invoiceDTO.tiposFactura.id === 'construction_site_proforma') {
      const vatLinesProforma = new VatLine();
      vatLinesProforma.vat_type_id =
        this.invoiceDTO.proforma?.vat_lines[0]?.vat_type_id;
      vatLinesProforma.amount = this.invoiceDTO.proforma?.vat_lines[0]?.amount;
      newInvoice.vat_lines = new Array<VatLine>();
      newInvoice.vat_lines.push(vatLinesProforma);
      newInvoice.has_prompt_payment = this.invoiceDTO.tieneProntoPago;
      newInvoice.prompt_payment_amount = this.invoiceDTO.prontoPago
        ? this.invoiceDTO.prontoPago
        : 0;
      newInvoice.withholding_amount = this.invoiceDTO.retencion;
      newInvoice.withholding_perone = this.invoiceDTO.porcentajeRetencion / 100;
      newInvoice.invoice_subtype_id =
        this.invoiceDTO.tiposFactura?.subtypes[0]?.id;
      newInvoice.linked_proforma = this.invoiceDTO.proforma.id.toString();
      if (
        !this.invoiceDTO.prontoPagoIncluido &&
        this.invoiceDTO.idFacturaAdjuntaAbono
      ) {
        const iva = Number(
          this.getIVASPermitidos().find(
            (vatType) =>
              vatType.id === this.invoiceDTO.proforma?.vat_lines[0]?.vat_type_id
          ).per_one_factor
        );
        vatLinesProforma.vat_type_id =
          this.invoiceDTO.proforma?.vat_lines[0]?.vat_type_id;
        vatLinesProforma.amount = this.invoiceDTO.baseImponible * iva;
        newInvoice.vat_lines = new Array<VatLine>();
        newInvoice.vat_lines.push(vatLinesProforma);
        const newInvoiceProntoPago = new InvoiceNew();
        const vatLinesAbono = new VatLine();
        const dateAbono = new DatePipe('es').transform(
          this.invoiceDTO.fechaFacturaAbono,
          'yyyy-MM-dd'
        );
        vatLinesAbono.vat_type_id =
          this.invoiceDTO.proforma?.vat_lines[0]?.vat_type_id;
        vatLinesAbono.amount = this.invoiceDTO.baseImponibleAbono * iva;
        newInvoiceProntoPago.invoice_number =
          this.invoiceDTO.numeroFacturaAbono;
        newInvoiceProntoPago.invoice_date = dateAbono;
        newInvoiceProntoPago.base_amount = this.invoiceDTO.baseImponibleAbono;
        newInvoiceProntoPago.invoice_total = this.invoiceDTO.totalFacturaAbono;
        newInvoiceProntoPago.has_prompt_payment =
          this.invoiceDTO.tieneProntoPago;
        newInvoiceProntoPago.invoice_file_uuid =
          this.invoiceDTO.idFacturaAdjuntaAbono;
        newInvoiceProntoPago.invoice_type_id = 'refund';
        newInvoiceProntoPago.invoice_subtype_id = 'prompt_payment';
        newInvoiceProntoPago.reverse_vat_liability =
          this.invoiceDTO.inversionSujetoPasivo;
        newInvoiceProntoPago.vat_lines = new Array<VatLine>();
        newInvoiceProntoPago.vat_lines.push(vatLinesAbono);
        newInvoiceProntoPago.company_id = this.invoiceDTO.emisor.id;
        newInvoiceProntoPago.company_which_is_invoiced_id =
          this.invoiceDTO.destinatario.id;
        if (this.invoiceDTO.ubicacion) {
          newInvoiceProntoPago.csite_id = this.invoiceDTO.ubicacion.id;
        }
        newInvoiceDTO.refund_prompt_payment_invoice_uuid = uuidv4();
        newInvoiceDTO.refund_prompt_payment_invoice = newInvoiceProntoPago;
      }
    }

    newInvoiceDTO.invoice = newInvoice;

    this.invoiceCommandsService
      .createInvoice(newInvoiceDTO)
      .subscribe((resp) => {
        if (resp) {
          if (resp.status === 'OK') {
            this.alertService.showDetailSuccess(
              '¡Éxito!',
              'Factura creada con exito.'
            );
            if (this.userType === 'external') {
              this.router.navigateByUrl('/home/invoices');
            }
            if (this.userType === 'internal') {
              this.initAll();
              this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                this.router.navigateByUrl('/home/invoices/new', {skipLocationChange: true, replaceUrl: true})
              });
            }
          } else {
            this.alertService.showDetailError('Error', resp.error_message);
          }
        }
      });
  }

  formStep2Read(forms: Array<FormGroup>) {
    const formInvoice = forms[0];
    const formCreditInvoice = forms[1];

    this.invoiceDTO.ubicacion = formInvoice.get('ubicacion')?.value[0];
    this.invoiceDTO.proforma = formInvoice.get('proforma')?.value[0];
    this.invoiceDTO.baseImponible = this.utilsService.formatAmount(
      formInvoice.get('baseImponible')?.value
    );
    this.invoiceDTO.retencion = formInvoice.get('retencion')?.value;
    this.invoiceDTO.porcentajeRetencion = formInvoice.get(
      'porcientonRetencion'
    )?.value;
    this.invoiceDTO.formaPago = formInvoice.get('formaDePago')?.value;
    this.invoiceDTO.prontoPago = this.utilsService.formatAmount(
      formInvoice.get('importeProntoPago')?.value
    );
    this.invoiceDTO.totalFactura = this.utilsService.formatAmount(
      formInvoice.get('importeTotalFactura')?.value
    );
    this.invoiceDTO.nombreFacturaAdjunta = formInvoice.get('adjuntarFactura')
      ?.value?.name
      ? formInvoice.get('adjuntarFactura')?.value?.name
      : formInvoice.get('adjuntarFactura')?.value;
    this.invoiceDTO.idFacturaAdjunta =
      formInvoice.get('adjuntarFacturaId')?.value;
    this.invoiceDTO.nombreFacturaAdjuntaAdicional = formInvoice.get(
      'adjuntarDocumentacionAdicional'
    )?.value?.name
      ? formInvoice.get('adjuntarDocumentacionAdicional')?.value?.name
      : formInvoice.get('adjuntarDocumentacionAdicional')?.value;
    this.invoiceDTO.idFacturaAdjuntaAdicional = formInvoice.get(
      'adjuntarDocumentacionAdicionalId'
    )?.value;
    this.invoiceDTO.prontoPagoIncluido = formInvoice.get(
      'importeProntoPagoCheck'
    )?.value;
    this.invoiceDTO.tieneProntoPago = formInvoice.get('tieneProntoPago')?.value;
    this.invoiceDTO.inversionSujetoPasivo = formInvoice.get(
      'inversionDeSujetoPasivo'
    )?.value;
    this.invoiceDTO.partesDeTrabajo = formInvoice.get('partesDeTrabajo')?.value;
    this.invoiceDTO.baseExentaIva = this.utilsService.formatAmount(
      formInvoice.get('baseExentaIva')?.value
    );

    this.invoiceDTO.facturaAbono = formInvoice.get('facturaAbono')?.value;

    this.invoiceDTO.ivas = [];

    if (formInvoice.get('ivaToggle')?.value) {
      this.getIVASPermitidos().forEach((vatType) => {
        if (formInvoice.get(vatType.type_key)) {
          const iva: Iva = {
            value: this.utilsService.formatAmount(
              formInvoice.get(vatType.type_key).value
            ),
            tipoDeIva: vatType,
          };
          this.invoiceDTO.ivas.push(iva);
        }
      });
      this.invoiceDTO.totalIVA = this.utilsService.formatAmount(
        formInvoice.get('importeVariosIVA')?.value
      );
    } else {
      const iva: Iva = {
        value: this.utilsService.formatAmount(
          formInvoice.get('importeIVA')?.value
        ),
        tipoDeIva: this.getIVASPermitidos().find(
          (vatType) =>
            this.subTipoFactura?.templateParameters?.vatOptions
              ?.preferedVattypeUniquekey === vatType.type_key
        ),
      };
      this.invoiceDTO.ivas.push(iva);
      this.invoiceDTO.totalIVA = this.utilsService.formatAmount(
        formInvoice.get('importeIVA')?.value
      );
    }

    this.invoiceDTO.irpf = this.utilsService.formatAmount(
      formInvoice.get('importeIrpf')?.value
    );

    if (!this.invoiceDTO.prontoPagoIncluido && formCreditInvoice) {
      this.invoiceDTO.numeroFacturaAbono = formCreditInvoice.get(
        'numeroFacturaProntoPago'
      )?.value;
      this.invoiceDTO.fechaFacturaAbono = formCreditInvoice.get(
        'fechaFacturaProntoPago'
      )?.value;
      this.invoiceDTO.baseImponibleAbono = formCreditInvoice.get(
        'baseImponibleProntoPago'
      )?.value;
      this.invoiceDTO.totalIVAAbono = formCreditInvoice.get(
        'importeIVAProntoPago'
      )?.value;
      this.invoiceDTO.totalFacturaAbono = formCreditInvoice.get(
        'importeTotalFacturaProntoPago'
      )?.value;
      this.invoiceDTO.nombreFacturaAdjuntaAbono = formCreditInvoice.get(
        'PDFFacturaProntoPago'
      )?.value?.name
        ? formCreditInvoice.get('PDFFacturaProntoPago')?.value?.name
        : formCreditInvoice.get('PDFFacturaProntoPago')?.value;
      this.invoiceDTO.idFacturaAdjuntaAbono = formCreditInvoice.get(
        'PDFFacturaIdProntoPago'
      )?.value;
    }

    this.utilsService.setInvoiceDTO(this.invoiceDTO);
  }

  getVatTypes(date: string) {
    this.invoicingQueryService.getVatTypes(date).subscribe((data) => {
      this.vatTypes = data.values as VatType[];
      this.dinamicVatTypes = this.vatTypes;
    });
  }

  initAll() {
    this.formStep1Init();
    this.invoiceDTO = new InvoiceDto();
    this.invoiceGet = null;
    this.userType = this.loginService.getUser().userType;
    this.formStep2ProntoPago = new FormGroup({});
    this.formStep2 = new FormGroup({});
    this.stepper.reset();
  }

  getIVASPermitidos() {
    if(this.subTipoFactura?.templateParameters?.vatOptions?.allowedVattypeUniquekeys === undefined || this.subTipoFactura?.templateParameters?.vatOptions?.allowedVattypeUniquekeys === null) {
      return this.dinamicVatTypes;
    }
    const ivasOrdenados: VatType[] = [];

    this.subTipoFactura?.templateParameters?.vatOptions?.allowedVattypeUniquekeys?.forEach(
      (ivaKey) => {
        const iva = this.getIVAByKey(ivaKey);
        if(iva) {
          ivasOrdenados.push(iva);
        }
      }
    );

    return ivasOrdenados;
  }

  getIVAByKey(key: string) {
    return this.dinamicVatTypes?.find((vatType) => {
      return vatType.type_key === key;
    });
  }

  checkInvoiceNumber() {
    this.isCheckInvoiceNumber = false;
    const companyId =
      this.formStep1.get('emisor').value[0] != null
        ? this.formStep1.get('emisor').value[0].vat_number
        : 0;
    const date = `${new Date(this.formStep1.get('fechaFactura').value)
      .getFullYear()
      .toString()}-${(
      new Date(this.formStep1.get('fechaFactura').value).getMonth() + 1
    ).toString()}-${new Date(this.formStep1.get('fechaFactura').value)
      .getDate()
      .toString()}`;
      this.invoicingQueryService
        .checkUniqueInvoiceNumber(
          this.formStep1.get('numeroFactura').value,
          companyId,
          date
        )
        .subscribe((resp) => {
          this.isCheckInvoiceNumber = true;
          if (resp.is_unique) {
            this.formStep1.get('numeroFactura').setErrors(null);
          } else {
            this.inputsStep1.find(
              (i) => i.key === 'numeroFactura'
            ).validatorMessages = [
              { key: 'required', value: REQUIRED_MESSAGE },
              {
                key: 'invalidInvoiceNumber',
                value:
                  INVOICE_NUMBER_ALREADY_EXIST_MESSAGE +
                  new Date(this.formStep1.get('fechaFactura').value)
                    .getFullYear()
                    .toString(),
              },
            ];
            this.formStep1
              .get('numeroFactura')
              .setErrors({ invalidInvoiceNumber: true });
            this.formStep1.get('numeroFactura').markAsTouched();
          }
        });
  }

  setIvas(ivas: VatType[]) {
    this.dinamicVatTypes = ivas;
  }

  setIvasProforma(proforma: Proforma) {
    if (proforma) {
      const date = new DatePipe('es').transform(
        proforma.proforma_date,
        'yyyy-MM-dd'
      );

      this.getVatTypes(date);
      if (!this.subTipoFactura.templateParameters.vatOptions) {
        this.subTipoFactura.templateParameters.vatOptions = new VatOptions();
      }
      this.subTipoFactura.templateParameters.vatOptions.preferedVattypeUniquekey =
        proforma?.vat_lines[0]?.vat_type_type_key;
    }
  }

  checkInvoiceNumberEdit(numero, fecha) {
    if (
      numero !== this.invoiceGet.invoice_number ||
      new DatePipe('es').transform(fecha, 'yyyy') !==
        new DatePipe('es').transform(this.invoiceGet.invoiceDate, 'yyyy')
    ) {
      this.checkInvoiceNumber();
    }
  }

  destinatarioFilteredOptions() {
    return this.emisorChange.asObservable().pipe(
      map((res: any) => {
        if (res?.count > 0) {
          this.destinatarios = [];
          const destinatarios = res.values;
          if (this.userType !== 'external') {
            destinatarios.forEach((emp) => {
              if (this.invoiceCompanyId !== emp.id) {
                this.destinatarios.push({
                  id: emp.id,
                  name: `${emp.description}`,
                  description: `${emp.vat_number} | ${emp.description}`,
                  vat_number: emp.vat_number,
                });
              }
            });
          }

          if (!this.editOn) {
            if (destinatarios.length === 1 && this.userType !== 'external') {
              this.formStep1
                .get('destinatario')
                .setValue([destinatarios[0]]);
                this.formStep1.get('destinatario').disable();
            } else {
              this.formStep1.get('destinatario').reset();
              this.formStep1.get('destinatario').enable();
            }
          }

          if (this.editOn && this.primeraVueltaEdicion) {
            this.formStep1
              .get('destinatario')
              .setValue([
                destinatarios.find(
                  (c) => c.id === this.invoiceGet.companyWhichIsInvoicedId
                ),
              ]);
            this.formStep1.get('destinatario').disable();
          }

          this.destinatarios = destinatarios;
          return this.destinatarios;
        } else {
          if (!this.editOn && !this.primeraVueltaEdicion) {
            this.formStep1.get('destinatario').reset();
            this.formStep1.get('destinatario').enable();
          }
          return [];
        }
      })
    );
  }

  getDestinatariosFiltered(smartText) {
    const idEmisor = this.formStep1.get('emisor').value[0]?.id;
    if(idEmisor) {
       this.searchService.getDestinatarios(idEmisor, smartText).subscribe(resp => {
        this.emisorChange.next(resp);
      })
    } else {
      this.emisorChange.next(null);
    }

  }
}
