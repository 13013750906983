import { AlertService } from './../../shared/services/alert.service';
import { UtilsService } from './../../shared/services/utils.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KeyValue } from 'src/app/shared/models/key-value.model';
import { environment } from 'src/environments/environment';
import {
  StoredFile,
  StoredFileGet,
  StoredFileResponse,
} from '../models/stored-file.model';
import { BaseComunicationService } from './base-comunication.service';
import { InvoicesExcel } from '../models/invoices_excel.model';

@Injectable({
  providedIn: 'root',
})
export class StoredFilesService extends BaseComunicationService {
  private urlApiCommands = `${environment.apiStoredFilesCommandsBaseUrl}`;
  private urlApiQueries = `${environment.apiStoredFilesQueryBaseUrl}`;
  private urlApiExcel = `${environment.apiExcelGenerator}`;

  constructor(
    public http: HttpClient,
    private utilsService: UtilsService,
    private alertService: AlertService
  ) {
    super(http);
  }

  uploadFile(fileToUpload: StoredFile): Observable<StoredFileResponse> {
    const url: string = `${this.urlApiCommands}/upload-small-file-from-base64`;
    return this.realizarPeticionPost(url, fileToUpload);
  }

  unlikFile(uuid: string): Observable<any> {
    const url = `${this.urlApiCommands}/stored-unlink`;
    const body = {
      stored_file_uuid: uuid,
    };
    return this.realizarPeticionPost(url, body);
  }

  downloadFile(storedFileUuid: string) {
    const url: string = `${this.urlApiQueries}/stored-file-download`;
    let data: KeyValue[] = [new KeyValue('stored_file_uuid', storedFileUuid)];
    this.realizarPeticionGetConFiltros(url, data).subscribe(
      (data) => {
        const file: StoredFileGet = StoredFileGet.buildFromDto(data);
        this.utilsService.downloadPdfFromBase64(
          file.fileBase64,
          file.fileFullName
        );
      },
      (err) => {
        this.alertService.showDetailError(
          'Error',
          'No se pudo descargar el documento'
        );
      }
    );
  }

  getFileInfo(fileId: string) {
    const url: string = `${this.urlApiQueries}/get-stored-files`;
    let params = new Array<KeyValue>();
    params.push(new KeyValue('uuid', fileId));
    return this.realizarPeticionGetConFiltros(url, params);
  }

  getInvoicesAsExcel(data: InvoicesExcel) {
    const url: string = `${this.urlApiExcel}/all-invoices-excel-file-response`;
    return this.realizarPeticionPostNoJson(url, data);
  }
}
