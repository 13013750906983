import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  public showSpinner = false;
  private currentRequests: Array<HttpRequest<any>> = new Array<HttpRequest<any>>();
  constructor() { }

  addRequest(req: HttpRequest<any>) {
    this.currentRequests.push(req);
    this.showSpinner = true;
  }

  requestEnded(req: HttpRequest<any>) {
    this.currentRequests = this.currentRequests.filter(item => item !== req);
    if (this.currentRequests.length === 0) {
      this.showSpinner = false;
    } else {
      this.showSpinner = true;
    }
  }


}
