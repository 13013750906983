import { LocalStorageService } from './services/local-storage.service';
import { SpinnerInterceptor } from './services/spinner.interceptor';
import { LocaleService } from './services/locale.service';
import { MatIconModule } from '@angular/material/icon';
import { PdfIconComponent } from './icons/pdf-icon/pdf-icon.component';
import { ExportPdfComponent } from './export/export-pdf/export-pdf.component';
import { ExportExcelComponent } from './export/export-excel/export-excel.component';
import { ExportComponent } from './export/export/export.component';
import { DeleteIconComponent } from './components/delete-icon/delete-icon.component';
import { DetailsIconComponent } from './components/details-icon/details-icon.component';
import { ExcelIconComponent } from './icons/excel-icon/excel-icon.component';
import { DetailsContentComponent } from './components/details-content/details-content.component';
import { AccessibilityService } from './services/accessibility.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { AlertService } from './services/alert.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AutoCompleteComponent } from './components/autocomplete/autocomplete.component';
import { Selectv2SoloComponent } from './components/autocomplete/selectv2-solo/selectv2-solo/selectv2-solo.component';
import { Selectv2Component } from './components/autocomplete/selectv2/selectv2.component';
import { MultiSelectv2Component } from './components/autocomplete/multi-selectv2/multi-selectv2.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ToIconPipe } from './pipes/to-icon.pipe';
import { TablePaginationService } from './services/table-pagination.service';
import { TableComponent } from './components/table/table.component';
import { RouterEventsService } from 'src/app/shared/services/router-events.service';
import { InputsGeneratorComponent } from './components/autoform/inputs-generator/inputs-generator.component';
import { InlineFormComponent } from './components/autoform/inline-form/inline-form.component';
import { FilterComponent } from './components/autoform/filter/filter.component';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DetailsContentFormComponent } from './components/autoform/details-content-form/details-content-form.component';
import { Router, RouterModule } from '@angular/router';
import { CardViewComponent } from './components/card-view/card-view.component';
import { FilterDataService } from './services/filter-data.service';
import { MatTableModule } from '@angular/material/table';
import { GetPropertyFromString } from './pipes/get-property-from-string';
import {
  MatPaginatorModule,
  MatPaginatorIntl,
} from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AgTableComponent } from './components/ag-table/ag-table.component';
import { AgGridModule } from 'ag-grid-angular';
import { getSpanishPaginatorIntl } from './components/ag-table/es-paginator-intl';
import { TranslationComponent } from './components/translation/translation.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { MatChipsModule } from '@angular/material/chips';
// Translation
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material/core';
import { AccesibilityComponent } from './components/accesibility/accesibility.component';
import { SelectComponent } from './components/autoform/select/select.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { ModalMensajeComponent } from './components/modal-mensaje/modal-mensaje.component';
import { MatSortModule } from '@angular/material/sort';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { GenericIconComponent } from './components/generic-icon/generic-icon.component';
import { HttpSpinnerComponent } from './components/http-spinner/http-spinner.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ErrorComponent } from './components/error/error.component';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';
import { CustomInlineSnackbarComponent } from './components/custom-inline-snackbar/custom-inline-snackbar.component';
import {
  RecaptchaFormsModule,
  RecaptchaModule,
  RECAPTCHA_BASE_URL,
} from 'ng-recaptcha';
import { InputFieldRequiredPipe } from './pipes/input-field-required.pipe';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { AutocompleteChipsComponent } from './components/autoform/autocomplete-chips/autocomplete-chips.component';
import { Select2Module } from 'ng-select2-component';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { AttachInputComponent } from './components/attach-input/attach-input.component';
import { AttachInputDropComponent } from './components/attach-input-drop/attach-input-drop.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { TableCardComponent } from './components/table-card/table-card.component';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { ThousandsPipe } from './pipes/thousands.pipe';
import { ModalMensajeCookiesComponent } from './components/modal-mensaje-cookies/modal-mensaje-cookies.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { AmountDirective } from './directives/amount.directive';

// LoaderFactory para servir los assets en caso de un ng build con AOT
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: ' €',
  thousands: '.',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL,
};

@NgModule({
  declarations: [
    DetailsContentFormComponent,
    FilterComponent,
    InlineFormComponent,
    InputsGeneratorComponent,
    TableComponent,
    CardViewComponent,
    GetPropertyFromString,
    ToIconPipe,
    MultiSelectv2Component,
    Selectv2Component,
    Selectv2SoloComponent,
    AutoCompleteComponent,
    AgTableComponent,
    TranslationComponent,
    BackButtonComponent,
    AccesibilityComponent,
    DetailsContentComponent,
    SelectComponent,
    ExcelIconComponent,
    DetailsIconComponent,
    DeleteIconComponent,
    ExportComponent,
    ExportExcelComponent,
    ExportPdfComponent,
    PdfIconComponent,
    ExcelIconComponent,
    ModalMensajeComponent,
    ModalMensajeCookiesComponent,
    NotFoundComponent,
    GenericIconComponent,
    HttpSpinnerComponent,
    SpinnerComponent,
    ErrorComponent,
    CustomSnackbarComponent,
    CustomInlineSnackbarComponent,
    InputFieldRequiredPipe,
    AutocompleteChipsComponent,
    AttachInputComponent,
    AttachInputDropComponent,
    TableCardComponent,
    ThousandsPipe,
    AmountDirective,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MatTableModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatCardModule,
    MatExpansionModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatSortModule,
    MatSelectModule,
    AgGridModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTooltipModule,
    MatGridListModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatIconModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatMenuModule,
    MatDatepickerModule,
    MatDividerModule,
    HttpClientModule,
    NgxFileDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // usamos la factoria para AOT
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatNativeDateModule,
    MatSnackBarModule,
    MatChipsModule,
    SatDatepickerModule,
    SatNativeDateModule,
    NgSelectModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    MatMomentDateModule,
  ],
  providers: [
    Router,
    RouterEventsService,
    Location,
    MatDatepickerModule,
    FilterDataService,
    AlertService,
    TablePaginationService,
    ModalMensajeComponent,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'es-ES',
    },
    {
      provide: RECAPTCHA_BASE_URL,
      useValue: 'https://recaptcha.net/recaptcha/api.js', // use recaptcha.net script source for some of our users
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'L',
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'L',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
    AccessibilityService,
    GetPropertyFromString,
    DatePipe,
    LocaleService,
    LocalStorageService,

    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
  ],
  exports: [
    TableComponent,
    FilterComponent,
    MultiSelectv2Component,
    Selectv2Component,
    Selectv2SoloComponent,
    AutoCompleteComponent,
    AgTableComponent,
    TranslateModule,
    TranslationComponent,
    DetailsContentFormComponent,
    BackButtonComponent,
    AccesibilityComponent,
    DetailsContentComponent,
    SelectComponent,
    GetPropertyFromString,
    DatePipe,
    HttpSpinnerComponent,
    SpinnerComponent,
    ErrorComponent,
    ExportExcelComponent,
    InputsGeneratorComponent,
    CustomInlineSnackbarComponent,
    TableCardComponent,
    ThousandsPipe,
    InputFieldRequiredPipe,
    NotFoundComponent,
  ],
  entryComponents: [ModalMensajeComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor(
    private ngSelectConfig: NgSelectConfig,
    private translate: TranslateService
  ) {
    ngSelectConfig.notFoundText = 'Sin elementos';
  }
}
export * from './components/autoform/input-fields/input-fields';
export * from './components/ag-table/value-formatters';
export * from './components/ag-table/es-paginator-intl';
export * from './components/ag-table/column-definition.model';
export * from './services/alert.service';
export * from './services/spinner.service';
export * from './components/table/table-configuration/columns';
export * from './components/table/table-configuration/table-configuration';
export * from './models/api-error.model';
