import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputField } from '../input-fields/input-fields';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() input: InputField;
  @Input() fGroup: FormGroup;
  @Input() layout = 'w-100';

  @Output() selectedEmit = new EventEmitter<any>();

  public selectedElement: any;

  constructor() { }

  ngOnInit(): void {
  }

  public selectedItem(): void {
    this.selectedEmit.emit(this.selectedElement);
  }

  compareWith = (object1: any, object2: any) => {

    if (this.input && this.input.compareFunc) {
      const result = this.input.compareFunc(object1, object2);
      return result;

    } else {
      return object1 && object2 && object1 === object2;
    }
  }

}
