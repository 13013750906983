<mat-toolbar class="toolbar sub-toolbar">
    <i *ngIf="utilsService.chooseDisplayIcon(subTipoFactura?.templateParameters?.step2IconCode || tipoFactura?.icon_code); else customIcon" class="material-icons material-icons-outlined invoice-icon" aria-hidden="true">{{subTipoFactura?.templateParameters?.step2IconCode || tipoFactura?.icon_code }}</i>
    <ng-template #customIcon>
        <img class="invoice-icon color-filter-black-green" style="width: 37px; height: 37px" src="{{'../../../../../../assets/icons/' + utilsService.getCustomIcon(subTipoFactura?.templateParameters?.step2IconCode || tipoFactura?.icon_code)}}" alt="">
    </ng-template>
    <h1 style="overflow: hidden; text-overflow: ellipsis;">{{subTipoFactura?.templateParameters?.step2HeaderText || tipoFactura?.description | translate}}</h1>
</mat-toolbar>

<app-inputs-generator [fGroup]="formGroup" [inputFields]="inputs"></app-inputs-generator>
<!-- facturaAbonoProntoPago -->
<section *ngIf="facturaAbonoProntoPago">
    <mat-toolbar class="toolbar sub-toolbar">
        <i class="material-icons material-icons-outlined proforma-icon" matTooltip="{{'Factura de abono por pronto pago' | translate}}" matTooltipPosition="left" aria-hidden="true">credit_score</i>
        <h1>{{'Factura de abono por pronto pago' | translate}}</h1>

    </mat-toolbar>

    <app-inputs-generator [fGroup]="formGroupProntoPago" [inputFields]="inputsProntoPago"></app-inputs-generator>

</section>

<!-- Button row -->
<section class="row footer">
    <div class="col ml-auto">
        <ng-container *ngIf="facturaAbonoProntoPago; then thenTemplate; else elseTemplate"></ng-container>
        <ng-template #thenTemplate>
            <button mat-flat-button matStepperNext type="submit" color="primary" class="btn-apply btn-next" (click)="submit()" [disabled]="!formGroup.valid || !formGroupProntoPago.valid">{{ 'Siguiente' | translate }}</button>
        </ng-template>
        <ng-template #elseTemplate>
            <button mat-flat-button matStepperNext type="submit" color="primary" class="btn-apply btn-next" (click)="submit()" [disabled]="!formGroup.valid ">{{ 'Siguiente' | translate }}</button>
        </ng-template>

        <button mat-stroked-button matStepperPrevious color="primary" class="btn-apply btn-back mr-4" (click)="backStepOne()">{{ 'Anterior' | translate }}</button>
    </div>
</section>

<!-- Retencion Section -->
<ng-template #retencionSection let-row>
    <div class="col-md-12 retencion">
        <span *ngIf="retencion !== null && retencion !== undefined" class="retencionSpan">Retencion {{porcentajeRentencion}}%: {{retencion | currency}}</span>
        <i class="material-icons material-icons-outlined info-icon" matTooltip="{{'Importe retención = Base Imponible x Porcentaje Retención' | translate}}" matTooltipPosition="below" aria-hidden="true">info
        </i>
    </div>
</ng-template>

<!-- Pronto Pago Section -->
<ng-template #prontoPagoIncluidoSection let-row>
    <div class="">
        <span class="mt-2 mb-2">Pronto pago incluido en la factura: <mat-slide-toggle [color]="'primary'" [checked]="prontoPagoIncluidoCheck" (change)="prontoPagoEnFactura($event)">{{ prontoPagoIncluido }}</mat-slide-toggle>
        <i class="material-icons material-icons-outlined info-icon-pronto-pago"
          matTooltip="{{infoProforma | translate}}"
          matTooltipPosition="below"
          aria-hidden="true">info
        </i>
      </span>
    </div>
</ng-template>

<!-- Inversion sujeto pasivo Section -->
<ng-template #inversionSujetoPasivoSection let-row>
    <div class="col-md-12">
        <span class="blue mt-2 mb-2">Inversión sujeto pasivo: <b>{{inversionSujetoPasivo}}</b></span>
    </div>
</ng-template>

<!-- Divider Section -->
<ng-template #dividerSection let-row>
    <mat-divider [inset]="true"></mat-divider>
</ng-template>

<!-- Pronto pago Base Section -->
<ng-template #prontoPagoBaseImponibleSection let-row>
    <div class="col-md-12">
        <span class="mt-2 mb-2"><b>Base imponible: &nbsp; &nbsp; </b> {{prontoPagoBaseImponible | currency}}</span>
    </div>
</ng-template>

<!-- Pornto pago importe Section -->
<ng-template #prontoPagoImporteIVASection let-row>
    <div class="col-md-12">
        <span class="mt-2"><b>Importe IVA:</b> &nbsp; &nbsp; {{prontoPagoIVA | currency}}</span>
    </div>
    <div>
        <span>(21%)</span>
    </div>
</ng-template>

<!-- Pronto pago importe total Section -->
<ng-template #prontoPagoImporteTotalSection let-row>
    <div class="col-md-12 importe-total-PP">
        <p class=""><b>Importe total factura de abono: </b></p>
        <p class=""><b>  {{prontoPagoTotal | currency}}</b></p>
    </div>
    <div>

    </div>
</ng-template>

<!----------------------------------------------------------->
<!-------- TEMPLATE PARA OBRA EN SELECT --------------------->
<!----------------------------------------------------------->
<ng-template #siteOption let-item>
    <span style="display: block">{{ item.description }}</span>
    <small>{{ item?.address }}</small>
</ng-template>

<!----------------------------------------------------------->
<!-------- TEMPLATE PARA PROFORMA EN SELECT --------------------->
<!----------------------------------------------------------->
<ng-template #proformaOption let-item>
    <span style="display: block"> Contrato: {{ item.contract_number }} ({{ item.activity }})</span>
    <small>Proforma: {{ item.proforma_number }} (Base Imponible: {{ item.base_amount | currency | thousandsPipe }})</small>
</ng-template>
