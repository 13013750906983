import { Order } from './order.model';

export class ToSnakeCase {}

export class Pageable {
  order: Order[];
  offset: number;
  limit: number;

  constructor(data?: any) {
    this.order = (data && data.order) || [];
    this.offset = (data && data.offset) || 0;
    this.limit = (data && data.limit) || 0;
  }
}

export class InvoicePageable extends Pageable {
  stateGroupKey: string;
  stateGroupKeys: string[];
  registerDateFrom: string;
  registerDateTo: string;
  invoiceDateFrom: string;
  invoiceDateTo: string;
  emisionDateFrom: string;
  emisionDateTo: string;
  entitiesIds: string[];
  companyuuids: string[];
  companyinvoiceduuids: string[];
  csiteuuids: string[];
  smartText: string;
  invoiceType: string[];
  internalIds: string[];
  id: number;
  invoiceNumber: string;

  constructor(data?: any) {
    super(data);
    this.stateGroupKey = (data && data.stateGroupKey) || null;
    this.stateGroupKeys = (data && data.stateGroupKeys) || null;
    this.registerDateFrom = (data && data.registerDateFrom) || null;
    this.registerDateTo = (data && data.registerDateTo) || null;
    this.invoiceDateFrom = (data && data.invoiceDateFrom) || null;
    this.invoiceDateTo = (data && data.invoiceDateTo) || null;
    this.emisionDateFrom = (data && data.emisionDateFrom) || null;
    this.emisionDateTo = (data && data.emisionDateTo) || null;
    this.entitiesIds = (data && data.entitiesIds) || null;
    this.companyuuids = (data && data.companyuuids) || null;
    this.companyinvoiceduuids = (data && data.companyinvoiceduuids) || null;
    this.csiteuuids = (data && data.csiteuuids) || null;
    this.smartText = (data && data.smartText) || null;
    this.invoiceType = (data && data.invoiceType) || null;
    this.id = (data && data.id) || null;
    this.internalIds = (data && data.internalIds) || null;
    this.invoiceNumber = (data && data.invoiceNumber) || null;
  }

  toService(): any {
    const data: any = {};
    data.order = this.order;
    data.offset = this.offset;
    data.limit = this.limit;
    data.searchstate_key = this.stateGroupKey;
    data.searchstate_keys = this.stateGroupKeys;
    data.register_date_from = this.registerDateFrom;
    data.register_date_to = this.registerDateTo;
    data.invoice_date_from = this.invoiceDateFrom;
    data.invoice_date_to = this.invoiceDateTo;
    data.payment_date_from = this.emisionDateFrom;
    data.payment_date_to = this.emisionDateTo;
    data.bankings_entities_ids = this.entitiesIds;
    data.company_uuids = this.companyuuids;
    data.company_which_is_invoiced_uuids = this.companyinvoiceduuids;
    data.csite_uuids = this.csiteuuids;
    data.smart_text = this.smartText;
    data.invoice_type_uniquekeys = this.invoiceType;
    data.id = this.id;
    data.internal_ids = this.internalIds;
    data.invoice_number = this.invoiceNumber;
    return data;
  }
}

export class UserPageable extends Pageable {
  id: string;
  ids: string[];
  smartText: string;
  smartFirstnameOrSurnames: string;
  contactEmail: string;
  companyuuids: string[];
  statusKeys: string[];
  userTypeKeys: string[];

  constructor(data?: any) {
    super(data);
    this.id = (data && data.id) || null;
    this.ids = (data && data.ids) || null;
    this.smartText = (data && data.smartText) || null;
    this.smartFirstnameOrSurnames =
      (data && data.smartFirstnameOrSurnames) || null;
    this.contactEmail = (data && data.contactEmail) || null;
    this.companyuuids = (data && data.companyuuids) || null;
    this.statusKeys = (data && data.statusKeys) || null;
    this.userTypeKeys = (data && data.userTypeKeys) || null;
  }

  toService(): any {
    const data: any = {};
    data.order = this.order;
    data.offset = this.offset;
    data.limit = this.limit;
    data.ids = this.ids;
    data.id = this.id;
    data.smart_text = this.smartText;
    data.smart_firstname_or_surnames = this.smartFirstnameOrSurnames;
    data.contact_email = this.contactEmail;
    data.company_uuids = this.companyuuids;
    data.status_keys = this.statusKeys;
    data.user_type_keys = this.userTypeKeys;
    return data;
  }
}
