import { UtilsService } from 'src/app/shared/services/utils.service';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { InputField } from 'src/app/shared/shared.module';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { Observable, of, timer } from 'rxjs';
import {
  map,
  startWith,
  switchMap,
  delay,
  tap,
  debounceTime,
} from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-autocomplete-chips',
  templateUrl: './autocomplete-chips.component.html',
  styleUrls: ['./autocomplete-chips.component.scss'],
})
export class AutocompleteChipsComponent implements OnInit, OnChanges {
  @Input() input: InputField;
  @Input() fGroup: FormGroup;
  @Input() layout = 'w-100';
  @Input() multiple: boolean = true;

  filteredOptions: Array<any> = [];

  constructor(private utilsService: UtilsService) {}

  ngOnInit(): void {
    if (!this.input.filteredOptions) {

      this.filteredOptions = this.input.options;
    } else {
      this.find('');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  find(e) {
    const searchText = e.term || null;

    if (this.input?.filteredOptions) {
      this.input?.filteredOptions(searchText)?.subscribe(
        (data) => {
          this.filteredOptions =
            data !== null && data !== undefined ? data : [];
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  remove(event, input) {
    this.utilsService.autoComplateChange.emit({
      key: input.key,
      value: event?.value,
    });
  }
}
