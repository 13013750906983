<app-http-spinner></app-http-spinner>
<mat-sidenav-container role="main" class="sidenav-container" [ngClass]="!mobile ? '' : 'arpada-small-screen'">

    <mat-sidenav role="navigation" position="end" #sidenav opened="false" [mode]="!mobile ? 'side' : 'over'" fixedInViewport="true">
        <div class="sidebar">
            <div>

                <a *ngIf="!mobile" class="navbar-brand" aria-label="Ir a Inicio" [matTooltip]="'Inicio'" (click)="navega()"></a>

                <div *ngIf="mobile && isAuth" class="user-sidenav row p-1 m-1">
                    <span style="display: inline-flex; align-items: center;">
                      <!-- <div class="col-9 my-auto"> -->
                        <span style="font-size: 14px !important; font-weight: bold;" *ngIf="companies?.length <= 1; else menuCompanies">{{companies ? companies[0]?.company_name : ''}}</span>
                    <ng-template #menuCompanies>
                        <app-inputs-generator style="margin-right: 1.5rem;" [fGroup]="fGroup" [inputFields]="inputs" (selectedEmit)="changeCompany($event)"></app-inputs-generator>
                    </ng-template>
                    <!-- </div> -->
                    <!-- <div class="col-1"> -->
                    <!-- <div style="margin-left: auto; margin-right: 0;"> -->
                    <button aria-label="perfil" matTooltip="Perfil" style="margin-left: auto; margin-right: 0;" [routerLink]="'/home/settings/profile'" (click)="goToSettings()" mat-icon-button>
                        <mat-icon style="font-size: 16px;">settings</mat-icon>
                      </button>
                    <!-- </div>
                    <div class="col-1"> -->
                    <button aria-label="Cerrar sesión" matTooltip="Cerrar sesión" style="margin-right: 0;" mat-icon-button (click)="logout()">
                        <mat-icon style="font-size: 16px;">logout</mat-icon>
                     </button>
                    <!-- </div> -->
                    <!-- </div> -->

                    </span>


                    <!-- <app-inputs-generator style="margin-right: 1.5rem;" [fGroup]="fGroup" [inputFields]="inputs" (selectedEmit)="changeCompanie($event)"></app-inputs-generator> -->
                    <!-- <button aria-label="perfil" matTooltip="Perfil" [routerLink]="'/home/settings/profile'" mat-icon-button>
                      <mat-icon style="font-size: 16px;">settings</mat-icon>
                    </button> -->
                    <!-- <button aria-label="Cerrar sesión" matTooltip="Cerrar sesión" mat-icon-button (click)="logout()">
                      <mat-icon style="font-size: 16px;">logout</mat-icon>
                   </button> -->
                    <div align="end" style="margin-top: 0.8rem; margin-bottom: 0.4rem; text-align: initial;">
                        <span style=" display: inline-flex; align-items: center;">
                        <img style="margin-right: 0.3rem;" src="../../../../assets/svg/Icon-user.svg">{{ usuario.fullname }}
                      </span>
                    </div>

                    <!-- <div class="col-5 mx-0 my-auto">
                    </div> -->
                </div>

            </div>
            <!-- Menú -->
            <div>
                <mat-selection-list aria-label="Lista de secciones de Portal de Facturación" class="ml-2 mr-2">
                    <!-- Listado de facturas -->
                    <mat-nav-list role="option">

                        <a mat-list-item color="primary" *ngIf="functionSearch" aria-label="enlace listado de facturas" routerLink="/home/invoices" routerLinkActive="active-list-item" [routerLinkActiveOptions]="{exact: true}" (click)="decideToogle()">
                            <i class="material-icons material-icons-outlined" matTooltip="{{'Listado de facturas' | translate}}" matTooltipPosition="right" [matTooltipDisabled]="sidenav.opened" aria-hidden="true">format_list_bulleted</i>
                            <span *ngIf="sidenav.opened">{{ 'Listado de facturas' | translate}}</span>
                        </a>

                        <a mat-list-item color="primary" *ngIf="functionCreate" aria-label="enlace nueva facturas" routerLink="/home/invoices/new" routerLinkActive="active-list-item" (click)="decideToogle()">
                            <i class="material-icons material-icons-outlined" matTooltip="{{'Alta nueva factura' | translate}}" matTooltipPosition="right" [matTooltipDisabled]="sidenav.opened" aria-hidden="true">add_circle_outline</i>
                            <span *ngIf="sidenav.opened">{{ 'Alta nueva factura' | translate}}</span>
                        </a>

                        <a mat-list-item color="primary" *ngIf="functionUserAdmin" aria-label="enlace gestión de usuarios" routerLink="/home/users" routerLinkActive="active-list-item" (click)="decideToogle('GESTIÓN DE USUARIOS')">
                            <i class="material-icons material-icons-outlined" matTooltip="{{'Gestión de usuarios' | translate}}" matTooltipPosition="right" [matBadge]="registryRequestNotification ? '0': null" matBadgeSize="small" [matTooltipDisabled]="sidenav.opened" aria-hidden="true">manage_accounts</i>
                            <span *ngIf="sidenav.opened">{{ 'Gestión de usuarios' | translate}}</span>
                        </a>

                    </mat-nav-list>
                </mat-selection-list>
            </div>
        </div>

    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
        <mat-toolbar class="toolbar" *ngIf="dataToolbar" [class.arpada-mobile]="screenWidth <= 1020">
            <mat-icon class="back" [ngClass]="{'pointer': dataToolbar?.back}" (click)="back()" *ngIf="dataToolbar?.back" style="font-size: 20px;" inline>arrow_back_ios</mat-icon>
            <h1 *ngIf="mobile" [ngClass]="{'pointer': dataToolbar?.back}" (click)="back()">{{dataToolbar.title | translate}}</h1>
            <!-- <mat-toolbar-row> -->
            <div *ngIf="!sidenav.opened" class="paddingIfClosed"></div>

            <app-translation></app-translation>
            <!-- <h1>{{nombreSitio}}</h1> -->
            <ng-container *ngIf="mobile">
                <h1></h1>
            </ng-container>


            <ng-container *ngIf="!mobile">
                <h1 style="font-family: 'Maven Pro'; font-weight: 200;">Área Virtual • {{headerTittle}}</h1>

                <span style="font-size: 14px !important; font-weight: bold; padding-right: 0.5rem;" *ngIf="companies?.length <= 1; else menuCompanies">{{companies ? companies[0]?.company_name : ''}}</span>

                <ng-template #menuCompanies>
                    <app-inputs-generator style="margin-right: 1.5rem;" [fGroup]="fGroup" [inputFields]="inputs" (selectedEmit)="changeCompany($event)"></app-inputs-generator>
                </ng-template>

                <h4 style="font-weight: bold; display: inline-flex; align-items: center;">
                    <img style="margin-right: 0.3rem;" src="../../../../assets/svg/Icon-user.svg">{{ usuario.fullname }}</h4>
                <span class="spacer"></span>

                <button aria-label="Configuración de usuario" matTooltip="{{'Configuración de usuario' | translate}}" [routerLink]="'/home/settings/profile'" (click)="goToSettings()" mat-icon-button>
          <mat-icon>settings</mat-icon>
        </button>

                <mat-divider [vertical]="true"></mat-divider>

                <button aria-label="Cerrar sesión" matTooltip="Cerrar sesión" mat-icon-button (click)="logout()">
          <mat-icon>logout</mat-icon>
        </button>

            </ng-container>

            <!-- Solo en móviles-->
            <button *ngIf="mobile" aria-label="Desplegar menú" matTooltip="Desplegar menú" id="ocultarMenu" value="plegar desplegar menu" mat-icon-button color="primary" class="menubutton" (click)="sidenav.toggle()">
        <span *ngIf="!sidenav.opened" style="z-index: 99;">
          <mat-icon color="primary">menu</mat-icon>
        </span>
        <span *ngIf="sidenav.opened">
          <mat-icon color="primary" style="z-index: 99">menu_open</mat-icon>
        </span>
      </button>

        </mat-toolbar>

        <div class="custom-container">
            <div [class]=" (!sidenav.opened) ? 'contentPaddingIfClosed col-sm-12' : 'col-sm-12'">
                <div class="flex-grow-1 px-3 pb-5 router-outlet-container">
                    <!-- <div> -->
                    <!-- {{ usuario | json}} -->
                    <router-outlet></router-outlet>
                    <!-- {{ registryRequestNotification }} -->

                    <div class="row mt-5">
                        <h4 class="col-12 text-center secondary removeMarginBottom">{{ copyright }}</h4>
                    </div>
                    <div class="row">
                        <h5 class="col-12 text-center secondary">{{ 'versionFooter' | translate: {versionString: versionString} }}</h5>
                    </div>

                    <div class="end-page w-100"></div>
                </div>
            </div>

        </div>
    </mat-sidenav-content>

</mat-sidenav-container>


<!-- Menú inferior de navegación para móviles -->
<div *ngIf="mobile" id="arpada-bottom-menu">
    <a mat-icon-button color="primary" *ngIf="functionSearch" aria-label="enlace listado de facturas" routerLink="/home/invoices" (click)="decideToogle()">
        <i class="material-icons material-icons-outlined" matTooltip="{{'Listado de facturas' | translate}}" aria-hidden="true">format_list_bulleted</i>
    </a>
    <a mat-icon-button color="primary" *ngIf="functionCreate" aria-label="enlace nueva facturas" routerLink="/home/invoices/new" (click)="decideToogle()">
        <i class="material-icons material-icons-outlined" matTooltip="{{'Alta nueva factura' | translate}}" [matTooltipDisabled]="sidenav.opened" aria-hidden="true">add_circle_outline</i>
    </a>

    <a mat-icon-button color="primary" *ngIf="functionUserAdmin" aria-label="enlace gestión de usuarios" routerLink="/home/users" (click)="decideToogle('GESTIÓN DE USUARIOS')">
        <i class="material-icons material-icons-outlined" matTooltip="{{'Gestión de usuarios' | translate}}" [matTooltipDisabled]="sidenav.opened" aria-hidden="true">manage_accounts</i>
    </a>
</div>
