import { startWith } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { KeyValue } from './../../shared/models/key-value.model';
import { BaseComunicationService } from './base-comunication.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
import { ConstructionSiteResponse } from '../models/construction-sites.model';
import { ProformaResponse } from '../models/proformas.model';
import { WorksheetsResponse } from '../models/worksheets.model';
import { Moment } from 'moment';
import * as moment from 'moment';
import { CSiteCriteria } from '../models/csite_criteria.model';
import { ProformaCriteria } from '../models/proforma_criteria.model';
import { WorkSheetsCriteria } from '../models/work_sheets_criteria.model';
import { LoginService } from './login.service';
import { BankingEntitiesCriteria } from '../models/banking-entitites.model';
import { InvoceableCompanySearchCriteria } from '../models/invoceable_company_search_criteria.model';

@Injectable({
  providedIn: 'root',
})
export class SearchService extends BaseComunicationService {
  private urlApi = `${environment.apiSearchExternalBaseUrl}`;
  public lastPageInvoice: number = null;
  public lastPageUser: number = null;

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private loginService: LoginService,
    private routerService?: Router
  ) {
    super(http);
  }

  /**
   * Obtener empresas para filtros
   */
  getCompanies(smartText: string): Observable<any> {
    const url: string = `${this.urlApi}/companies-search`;
    let params = new Array<KeyValue>();
    params.push(new KeyValue('smart_text', smartText || ''));

    return this.realizarPeticionGetConFiltros(url, params);
  }

  /**
   * Obtener empresas para filtros
   */
  getInvoiceableCompanies(smartText: string): Observable<any> {
    const url: string = `${this.urlApi}/invoiceable-companies-search`;
    let params = new Array<KeyValue>();
    params.push(new KeyValue('smart_text', smartText || ''));

    return this.realizarPeticionGetConFiltros(url, params);
  }

  /**
   * Obtener empresas para filtros por id
   */
  getDestinatarios(idEmisor: number, smartText: string = null): Observable<any> {
    const url: string = `${this.urlApi}/invoiceable-companies-search`;

    let invoceableCompanySearchCriteria = new InvoceableCompanySearchCriteria();
    invoceableCompanySearchCriteria.only_invoiceables_by_company_id = idEmisor;
    invoceableCompanySearchCriteria.smart_text = smartText;

    return this.realizarPeticionPost(url, invoceableCompanySearchCriteria);
  }

  /**
   * Obtener empresas para filtros por id
   */
  getCompaniesById(id: number): Observable<any> {
    const url: string = `${this.urlApi}/companies-search`;
    let params = new Array<KeyValue>();
    params.push(new KeyValue('id', id || ''));

    return this.realizarPeticionGetConFiltros(url, params);
  }

  /**
   * Obtener estados de factura para filtros
   */
  getInvoiceStates(smartText: string): Observable<any> {
    const url: string = `${this.urlApi}/states-for-search-search`;
    let params = new Array<KeyValue>();
    params.push(new KeyValue('smart_text', smartText || ''));

    return this.realizarPeticionGetConFiltros(url, params);
  }

  /**
   * Obtener tipos de obra para filtros
   */
  getConstructionSite(criteria: CSiteCriteria | any): Observable<any> {
    const url: string = `${this.urlApi}/construction-site-search`;
    return this.realizarPeticionPost(url, criteria);
  }

  /**
   * Obtener tipos de obra para filtros
   */
  getConstructionSiteSmartSearch(smartText: string, extraCriteria: CSiteCriteria=null): Observable<any> {
    const url: string = `${this.urlApi}/construction-site-search`;
    let criteria = null;
    if(!extraCriteria){
      criteria = new CSiteCriteria();
    }else{
      criteria = new CSiteCriteria(extraCriteria);
    }
    criteria.smart_text=smartText;
    return this.realizarPeticionPost(url, criteria);
  }

  getConstructionSiteById(
    value: string,
    keyValue: string = 'id'
  ): Observable<any> {
    const url: string = `${this.urlApi}/construction-site-search`;
    let params = new Array<KeyValue>();
    params.push(new KeyValue(keyValue, value));
    return this.realizarPeticionGetConFiltros(url, params);
  }

  getProformas(
    proformaCriteria: ProformaCriteria
  ): Observable<ProformaResponse> {
    const url: string = `${this.urlApi}/proformas-search`;
    return this.realizarPeticionPost(url, proformaCriteria);
  }

  getProformasById(proformaId: string): Observable<any> {
    const url: string = `${this.urlApi}/proformas-search`;
    let params = new Array<KeyValue>();
    params.push(new KeyValue('id', proformaId));
    return this.realizarPeticionGetConFiltros(url, params);
  }

  getWorksheets(
    criteria: WorkSheetsCriteria | any
  ): Observable<WorksheetsResponse> {
    const url = `${this.urlApi}/worksheets-search`;
    return this.realizarPeticionPost(url, criteria);
  }

  getWorksheetById(workSheetId: string): Observable<WorksheetsResponse> {
    const url: string = `${this.urlApi}/worksheets-search`;
    let params = new Array<KeyValue>();
    params.push(new KeyValue('id', workSheetId));
    return this.realizarPeticionGetConFiltros(url, params);
  }

  getPostsaleISP(csId: number, date: Moment): Observable<any> {
    const url: string = `${this.urlApi}/default-reverse-vat-liability-for-postsale-workorder-invoices`;
    date = moment(date);

    let params = new Array<KeyValue>();
    params.push(new KeyValue('construction_site_id', csId));
    let dateString =
      date.year() +
      '/' +
      (Number.parseInt(date.month().toString()) + 1) +
      '/' +
      (Number.parseInt(date.day().toString()) + 1);
    params.push(new KeyValue('invoice_date', dateString));
    return this.realizarPeticionGetConFiltros(url, params);
  }

  /**
   * Obtener entidades bancarias
   */
  getBankingEntities(criteria: BankingEntitiesCriteria): Observable<any> {
    const url: string = `${this.urlApi}/banking-entities-search`;
    return this.realizarPeticionPost(url, criteria);
  }

  getBankingEntitiesSmartSearch(smartText: string): Observable<any> {
    const url: string = `${this.urlApi}/banking-entities-search`;
    let params = new Array<KeyValue>();
    params.push(new KeyValue('smart_text', smartText || ''));

    return this.realizarPeticionGetConFiltros(url, params);
  }
}
