import { REQUIRED_MESSAGE } from 'src/app/app.constants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UserRelCompany } from '../../../shared/models/company.model';
import { InputField } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit, OnDestroy {

  @Output() emitValues = new EventEmitter<any>();

  companies: Array<any>;

  fGroup: FormGroup;
  public inputs = new Array<InputField>();

  private sub: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<CompanySelectorComponent>,
  private fb: FormBuilder) {

    this.fGroup = this.fb.group({
      company: ['', Validators.required]
    });

    this.companies = this.data.companies;
  }


  ngOnInit(): void {

    this.configurarInputs();
    this.cargarComboCompany();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onSubmit() {
    this.emitValues.emit(this.fGroup.get('company').value);
  }

  private cargarComboCompany() {
    this.inputs.find(i => i.key === 'company').options = this.companies;
  }

  private configurarInputs(): void {
    this.inputs.push(
      {
      key: 'company',
      type: 'select',
      label: 'Empresa',
      controlValue: '',
      layout: 'col-12 col-lg-8',
      selectBindLabel: 'company_name',
      validatorMessages: [{key:'required', value: REQUIRED_MESSAGE}],
      options: this.companies && this.companies.length ?
      this.companies.map(c => new UserRelCompany({id: c.id, name: c.name})) : new Array<any>(),
    })
  }
}
