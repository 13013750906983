import { InvoicingUserUpdate, UserUpdate } from './../../../../models/users-update.model';
import { UserRelCompany, UserRelCompanyGet } from './../../../../../shared/models/company.model';
import { InvoicingUserService } from './../../../../services/invoicing-user.service';
import { RecoverService } from './../../../../services/recover.service';
import { EMAIL_REGEX, MOBILE_REGEX, REQUIRED_MESSAGE, USER_PHONE_MESSAGE } from './../../../../../app.constants';
import { LoginService } from './../../../../services/login.service';
import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { Validators, FormBuilder, FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { of, timer, pipe } from 'rxjs';
import { map, switchMap, delay } from 'rxjs/operators';
import { AlertService, InputField } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  user: Usuario;

  userInfo: any;
  companyInfo: any;

  fGroupUser: FormGroup;
  inputsUser = new Array<InputField>();

  fGroupPassword: FormGroup;
  inputsPassword = new Array<InputField>();
  passwordValidationMessage: string = '';
  passwordValidationErrorMessage: string;
  apiMessage: string;

  constructor(private loginService: LoginService,
    private invoicingUserService: InvoicingUserService,
    private recoverService: RecoverService,
    private alertService: AlertService,
    private fb: FormBuilder) {

    this.user = this.loginService.getUser();
  }

  ngOnInit(): void {
    // Configurar formularios
    this.setFormUser();
    this.setFormPassword();

    // Cargar políticas de contraseña
    this.getPolicy();

    // Obtener datos actuales del usuario identificado
    this.loadUserInfo();
  }

  loadUserInfo(): void {

    this.invoicingUserService.getUserInfo().subscribe( userInfo => {
      // Cargar User info.
      this.userInfo= {};
      this.userInfo.firstname = userInfo.firstname;
      this.userInfo.lastname = userInfo.lastname;
      this.userInfo.lastname2 = userInfo.lastname2;
      this.userInfo.status = userInfo.status;

      const companyId = this.user.company.id;
      const relCompanies = userInfo.user_rel_companies;

      this.companyInfo = relCompanies.filter(rc => rc.company_id === companyId)[0];
      this.userInfo.email = this.companyInfo.user_email;
      this.userInfo.contact_phone = this.companyInfo.contact_phone;
      this.userInfo.job_position_in_company = this.companyInfo.job_position_in_company;

      this.setFormUserValues();
    })
  }

  submitUserSettings(): void {

    const userUpdate: UserUpdate = new UserUpdate();
    // datos inmutables, siempre serán iguales en token y en servidor
    userUpdate.id = this.user.company.userCompanyId;
    userUpdate.user_email = this.user.email;
    userUpdate.company_id = this.user.company.id;
    userUpdate.company_vat_number = this.user.company.company_vat_number;
    // datos obtenidos de la api (El token de sesión podría estar obsoleto)
    let user_type: 'internal' | 'external' | null = null;
    if (this.user.userType === 'internal') user_type = 'internal';
    if (this.user.userType === 'external') user_type = 'external';
    userUpdate.user_type = user_type;
    userUpdate.status = this.companyInfo.status;
    userUpdate.user_roles = this.companyInfo.user_roles;
    // datos informados en el formulario
    userUpdate.firstname = this.fGroupUser.get('firstname').value;
    userUpdate.lastname = this.fGroupUser.get('lastname').value;
    userUpdate.lastname2 = this.fGroupUser.get('lastname2').value;
    userUpdate.fullname = this.composeFullname(userUpdate.firstname, userUpdate.lastname, userUpdate.lastname2);
    userUpdate.contact_phone = this.fGroupUser.get('contact_phone').value;
    userUpdate.job_position_in_company = this.fGroupUser.get('job_position_in_company').value;

    this.invoicingUserService.updateUser(new InvoicingUserUpdate({invoicing_user: userUpdate})).subscribe(data => {
      if (data.status === 'OK') {
        this.alertService.showDetailSuccess('Correcto', 'Sus datos han sido actualizados correctamente');
      } else {
        this.alertService.showDetailError('Error', data.error_message);
      }
    })
  }

  submitPassword(): void {
    this.loginService.userChangePassword(
      this.fGroupPassword.get('password').value,
      this.fGroupPassword.get('newPassword').value,
      this.fGroupPassword.get('confirmNewPassword').value).subscribe(data => {
        if (data.status === 'OK') {
          this.alertService.showDetailSuccess('Correcto', data.error_message || 'Su contraseña ha sido actualizada correctamente');
          this.fGroupPassword.reset();
        } else {
          this.alertService.showDetailError('Error', data.error_message);
        }
      });
  }

  setFormUser(): void {
    this.fGroupUser = this.fb.group({
        firstname: ['',[Validators.required]],
        lastname: ['',[Validators.required]],
        lastname2: [''],
        email: ['',[Validators.required, Validators.pattern(EMAIL_REGEX)]],
        contact_phone: ['',[Validators.required, Validators.pattern('([0-9][ -]*){9}')]],
        job_position_in_company: ['',[Validators.required]],
      });

    this.inputsUser.push(
      {
        key: 'firstname',
        type: 'text',
        label: 'Nombre',
        controlValue: '',
        layout: 'col-12',
        validatorMessages: [ { key: 'required', value: REQUIRED_MESSAGE }]
      },
      {
        key: 'lastname',
        type: 'text',
        label: 'Primer apellido',
        controlValue: '',
        layout: 'col-12',
        validatorMessages: [ { key: 'required', value: REQUIRED_MESSAGE }]
      },
      {
        key: 'lastname2',
        type: 'text',
        label: 'Segundo apellido',
        controlValue: '',
        layout: 'col-12',
      },
      {
        key: 'email',
        type: 'text',
        label: 'Correo electrónico',
        controlValue: '',
        layout: 'col-12',
        readonly: true,
        focusOut: (value: string) => {
          this.fGroupUser.controls.email.setValue(value.trim().toLowerCase());
        },
        validatorMessages: [ { key: 'required', value: REQUIRED_MESSAGE }]
      },
      {
        key: 'contact_phone',
        type: 'text',
        label: 'Teléfono',
        controlValue: '',
        layout: 'col-12',
        validatorMessages: [ { key: 'required', value: REQUIRED_MESSAGE },  { key: 'pattern', value: USER_PHONE_MESSAGE }]
      },
      {
        key: 'job_position_in_company',
        type: 'text',
        label: 'Puesto en su empresa',
        placeholder: '',
        controlValue: '',
        layout: 'col-12',
        validatorMessages: [ { key: 'required', value: REQUIRED_MESSAGE }]
      }
    );
  }

  setFormUserValues(): void {
    this.fGroupUser.get('firstname').setValue(this.userInfo.firstname);
    this.fGroupUser.get('lastname').setValue(this.userInfo.lastname);
    this.fGroupUser.get('lastname2').setValue(this.userInfo.lastname2);
    this.fGroupUser.get('email').setValue(this.userInfo.email);
    this.fGroupUser.get('contact_phone').setValue(this.userInfo.contact_phone);
    this.fGroupUser.get('job_position_in_company').setValue(this.userInfo.job_position_in_company);
  }

  setFormPassword(): void {

    this.fGroupPassword = this.fb.group({
      password: ['', [Validators.required]],
      newPassword: ['', Validators.required, this.validatePasswordAsync()],

      confirmNewPassword: ['', Validators.required]
    },
    {
      asyncValidators: this.validatePasswordConfirmAsync('newPassword', 'confirmNewPassword')
    }
    );

    this.inputsPassword.push(
      {
        key: 'password',
        type: 'password',
        label: 'Contraseña actual',
        controlValue: '',
        layout: 'col-12',
        validatorMessages: [{key: 'required', value: REQUIRED_MESSAGE}],
        associatedButton: {
          icon: 'visibility',
          arialabel: 'Mostrar/ocultar',
          function: () => {
            this.inputsPassword[0].type = this.inputsPassword[0].type === 'text' ? 'password' : 'text';
            this.inputsPassword[0].associatedButton.icon = this.inputsPassword[0].associatedButton.icon === 'visibility' ? 'visibility_off': 'visibility';
          }
        }
      },
      {
        key: 'newPassword',
        type: 'password',
        label: 'Nueva contraseña',
        controlValue: '',
        layout: 'col-12',
        validatorMessages: [
          {key: 'required', value: REQUIRED_MESSAGE},
          {key: 'passwordInvalid', value: 'Contraseña inválida'}],
        associatedButton: {
          icon: 'visibility',
          arialabel: 'Mostrar/ocultar',
          function: () => {
            this.inputsPassword[1].type = this.inputsPassword[1].type === 'text' ? 'password' : 'text';
            this.inputsPassword[1].associatedButton.icon = this.inputsPassword[1].associatedButton.icon === 'visibility' ? 'visibility_off': 'visibility';
          }
        }
      },
      {
        key: 'confirmNewPassword',
        type: 'password',
        label: 'Confirmar nueva contraseña',
        controlValue: '',
        layout: 'col-12',
        validatorMessages: [
          {key: 'required', value: REQUIRED_MESSAGE}],
        associatedButton: {
          icon: 'visibility',
          arialabel: 'Mostrar/ocultar',
          function: () => {
            this.inputsPassword[2].type = this.inputsPassword[2].type === 'text' ? 'password' : 'text';
            this.inputsPassword[2].associatedButton.icon = this.inputsPassword[2].associatedButton.icon === 'visibility' ? 'visibility_off': 'visibility';
          }
        }
      }
    );
  }

  validatePasswordAsync() {
    return (input: FormControl) => {
      return timer(1000).pipe(
        switchMap(() => this.recoverService.validatePassword(input.value)),
        map(res => {
          return res.status !== 'INVALID' ? null : { passwordInvalid: true };
        })
      );
    };
  }

  validatePasswordConfirmAsync(password: string, passwordConfirm: string) {
    return (input: AbstractControl) => {
      const p1 = input.get(password).value;
      const p2 = input.get(passwordConfirm).value;
      // Optimización de validaciones contra el servidor
      if (!p1 || !p2) {
        return { passwordConfirmInvalid: true };
      }

      return timer(1000).pipe(
        switchMap(() => this.recoverService.validatePasswordConfirm(p1 ,p2)),
        map(res => {
          this.passwordValidationErrorMessage = res.validation_message;
          return res.status !== 'INVALID' ? null : { passwordConfirmInvalid: true };
        },
        err => {
          this.passwordValidationErrorMessage = null
          return { passwordConfirmInvalid: true }
        })
      );
    };
  }

  private getPolicy(): void {
    const sub = this.recoverService.getPolicy().subscribe(
      res => {
        this.apiMessage = res.password_policy_message;
      },
      err => {
        console.log(err);
      }
    );
  }

  private composeFullname(firstname: string, lastname: string, lastname2: string): string {
    let fullname = '';
    if (firstname) fullname += firstname + ' ';
    if (lastname) fullname += lastname + ' ';
    if (lastname2) fullname += lastname2;
    return fullname;
  }

}
