<div class="row m-3 justify-content-center">
  <mat-card class="col-12 col-md-6 col-lg-4 m-3 pt-0" >
    <div class="row justify-content-center">
      <div class="col-12 mt-3">

        <form [formGroup]="fGroup" (ngSubmit)="submit()" class="col-12">

          <!-- Email field -->
          <app-inputs-generator [fGroup]="fGroup" [inputFields]="inputs"></app-inputs-generator>

          <!-- Captcha -->
          <div class="ctm-captcha col-12 col-sm-12 col-lg-8 my-3">
            <re-captcha formControlName="captcha"
            (resolved)="resolved($event)"
            [siteKey]="captchaKey"></re-captcha>
          </div>

          <!-- Submit button -->
          <button mat-flat-button color="primary"
          [disabled]="!fGroup.valid"
          aria-label="Botón recuperar contraseña"
          type="submit"
          class="col-12 arpada-button-large">{{ 'RecoverPassword' | translate }}</button>
        </form>

        <div class="col-12 mt-3 text-center">
          <a routerLink="/login" class="primary">{{ 'Iniciar sesión' | translate }}</a>
        </div>
      </div>
    </div>

  </mat-card>
</div>
