import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-excel-icon',
  templateUrl: './excel-icon.component.html',
  styleUrls: ['./excel-icon.component.css']
})
export class ExcelIconComponent implements OnInit {

  @Input() cssClass: string;
  @Input() disabled = true;

  @Output() clickEmit = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void { }


  public clickButton(e: any): void {
      this.clickEmit.emit(e);
  }
}
