<section class="mb-3">
  <div class="row header">
    <label class="col pl-3 pointer toggle-filter" (click)="showFilters = !showFilters">{{'Filtros' | translate }}
      <small>({{filtrosAplicados}}
        {{'aplicados' | translate}}) </small>
      <i [ngClass]="(showFilters)?'fa fa-angle-up':'fa fa-angle-down'" aria-hidden="true"></i>
    </label>
  </div>


  <mat-card *ngIf="showFilters">
    <form [formGroup]="contentForm" (ngSubmit)="filterSubmit(contentForm)">
      <section>
        <app-inputs-generator [fGroup]="contentForm" [inputFields]="inputFields" [layout]="layout"
          [dateToFromLayout]="dateFromToLayout" (selectedEmit)="selectElement($event)"></app-inputs-generator>
      </section>


      <!-- Button row -->
      <section class="row footer ">
        <div class="col ml-auto text-right ">
          <button mat-raised-button type="button" class="btn-clean mr-2"
            (click)="resetFilter()">{{'Limpiar' | translate }}</button>
          <button mat-raised-button type="submit" color="primary" class="btn-apply"
            [disabled]="!contentForm.valid">{{ 'Aplicar' | translate }}</button>
        </div>
      </section>
    </form>
  </mat-card>
</section>
