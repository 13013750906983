<section>
  <div class="d-flex mb-2">
    <!-- <div class="d-flex flex-grow-1 justify-content-end align-self-end" *ngIf="!_configuration.hideExport"> -->
    <!-- <div class="d-flex flex-grow-1"
      [ngClass]="{'d-flex flex-grow-1 mb-2 justify-content-end align-self-end': windowsWidth > 770}">
      <ng-container *ngIf="_configuration && !_configuration.hideExport && items !== undefined && items !== null">
        <app-export-excel [standalone]="false" [pagination]="null" [items]="items" [columns]="columns"></app-export-excel>
      </ng-container>
    </div> -->
  </div>
</section>

<section class="customtable  position-relative" [style.display]="mobileVersion ? 'none':''">
  <table class="table" mat-table matSort [dataSource]="dataSource" matSortDisableClear class="w-100"
    multiTemplateDataRows>
    <ng-container *ngFor="let column of columns; let icolumn = index">

      <ng-container *ngIf="column.key !== 'buttons'">
        <ng-container *ngIf="!column.cellTemplate">

          <ng-container *ngIf="column.key !== 'checkbox'" matColumnDef="{{ column.key }}">

            <th scope="col" class="py-2" mat-header-cell *matHeaderCellDef mat-sort-header disableClear
              aria-label="columna ordenación"
              [disabled]="!_configuration.sorting || column.orderDisabled"
              [class.ct-hidden-col]="column.hideColumn">
              <strong *ngIf="column.key !== 'icon'" >{{ column.title }}</strong></th>
            <td *matCellDef="let item; let i = index" mat-cell class="no-height"
              [ngClass]="column.cssClass ? column.cssClass : ' pr-2 py-1'"
              [class.ct-hidden-col]="column.hideColumn"
              [class.no-height]="column.key !== 'importeTotal'"
              [class.rowCursor]="(clickRow || item.expandable) && allowExpand"

              (click)="expandedElement = expandedElement === item ? null : item; clickedItem(item)">
              <div *ngIf="column.key !== 'icon'" class="revert">
                <!-- adds button in case of details column -->
                <ng-container *ngIf="(this._configuration.details
                && this._configuration.detailColumnKeys
                && this._configuration.detailColumnKeys.includes(column.key)) else simpleColumn">
                  <!-- <button mat-button (click)="columnDetail(item, column.key);$event.stopPropagation()"><span
                      class="screen-reader-text noBorder">{{'Botón detalle columna ' +  column.title | translate}}</span>
                    Inserto el campo simple dentro del boton
                    <ng-container [ngTemplateOutlet]="simpleColumn"
                      [ngTemplateOutletContext]="{ $implicit: item, index: i, column: column, icolumn: icolumn }">
                    </ng-container>

                  </button> -->
                    <a (click)="columnDetail(item, column.key);$event.stopPropagation()">{{item | getPropertyFromString: column.key | currency }}</a>
                </ng-container>
                <!-- Simple Column -->
                <ng-template #simpleColumn>
                  <ng-container *ngIf="column.isCurrency === true">
                    <span class="alignRight">{{ isNumber(item | getPropertyFromString: column.key) ? (item | getPropertyFromString: column.key | currency) : (item | getPropertyFromString: column.key) }}</span>
                  </ng-container>
                  <ng-container *ngIf="column.isNumber  === true">
                    <span
                      class="alignRight  {{column.validationFunc ? validateColumn(item, column) : ''}}">{{ getNumberFormatted(column, item | getPropertyFromString: column.key) }}</span>
                  </ng-container>
                  <ng-container *ngIf="column.isCentered  === true">
                    <span class="alignCenter">{{ item | getPropertyFromString: column.key }}</span>
                  </ng-container>
                  <ng-container *ngIf="column.isRight  === true">
                    <span class="alignRight">{{ item | getPropertyFromString: column.key }}</span>
                  </ng-container>
                  <span *ngIf="!column.isNumber && !column.isCurrency && !column.isCentered && !column.isRight"
                    class="{{column.validationFunc ? validateColumn(item, column) : ''}} ">{{ item | getPropertyFromString: column.key }}</span>
                </ng-template>

                <!-- <span (click)="(this._configuration.details
                  && this._configuration.detailColumnKeys
                  && this._configuration.detailColumnKeys.includes(column.key))? columnDetail(item, column.key): ''"
                  class="{{ (this._configuration.details
                    && this._configuration.detailColumnKeys && this._configuration.detailColumnKeys.includes(column.key)) ? 'pointer hover--link' : '' }}">
                  <span
                    class="{{column.validationFunc ? validateColumn(item, column) : ''}}">{{ item | getPropertyFromString: column.key }}</span>
                </span> -->
              </div>
              <div class="columnIcon" *ngIf="column.key === 'icon' && item.expandable">
                <i [ngClass]="(expandedElement === item)?'fa fa-angle-up':'fa fa-angle-down'"
                  aria-hidden="true"></i>
              </div>
            </td>
          </ng-container>

          <!-- <i [ngClass]="(expandedElement === row)?'fa fa-angle-up':'fa fa-angle-down'" aria-hidden="true"></i> -->
          <ng-container *ngIf="column.key === 'checkbox'">
            <ng-container matColumnDef="{{ column.key }}">
              <th mat-header-cell *matHeaderCellDef
                scope="col"
                aria-label="columna ordenación"
                [ngClass]="(column.key === 'enabled' || column.key === 'active' || column.key === 'available' || column.isBoolean) ? ' py-2' : 'py-2'"
                >
                <mat-checkbox alt="Seleccionar o Deseleccionar Todas las filas"
                  aria-label="Seleccionar o Deseleccionar Todas las filas" (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                  <span class="screen-reader-text">{{'Seleccionar o Deseleccionar Todas las filas' | translate}}</span>
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row"
                [ngClass]="{'rowCursor': clickRow || row.expandable}"
                class=" no-height"
                (click)="expandedElement = expandedElement === row && allowExpand ? null : row; clickedItem(row)">
                <!-- <i class="fa fa-angle-down" aria-hidden="true"></i> -->
                <mat-checkbox alt="Seleccionar fila" aria-label="Seleccionar fila" (click)="$event.stopPropagation()"
                  class="checkbox"
                  (change)="$event ? changeCheckbox($event, row) : null" [checked]="selection.isSelected(row)"
                  [aria-label]="checkboxLabel(row)" [disabled]="disableCheckbox(row)">
                  <span class="screen-reader-text">{{'Seleccionar o Deseleccionar fila' | translate}}</span>
                </mat-checkbox>
              </td>
            </ng-container>
          </ng-container>

        </ng-container>

        <ng-container *ngIf="column.cellTemplate">

          <ng-container matColumnDef="{{ column.key }}">
            <th scope="col" *matHeaderCellDef mat-header-cell mat-sort-header disableClear
              aria-label="columna ordenación"
              [disabled]="!_configuration.sorting || column.orderDisabled"
              [ngClass]="(column.key === 'enabled' || column.key === 'active' || column.key === 'available' || column.isBoolean) ? 'center-header py-2' : 'py-2'">
              <strong class="px-auto" >{{ column.title }}</strong></th>
            <td *matCellDef="let item; let i = index" mat-cell
              class="no-height rowCursor"
              [ngClass]="column.cssClass ? column.cssClass : 'pr-2 py-1'"
              (click)="expandedElement = expandedElement === item ? null : item;"
              [ngClass]="{'rowCursor': (clickRow || item.expandable) && allowExpand}">
              <!-- If is detail column -->
              <span
                *ngIf="this._configuration.details && this._configuration.detailColumnKeys && this._configuration.detailColumnKeys.includes(column.key); else notDetails"
                (click)="columnDetail(item, column.key)"
                class="rowCursor {{  (this._configuration.details  && this._configuration.detailColumnKeys && this._configuration.detailColumnKeys.includes(column.key)) ? 'pointer hover--link' : '' }}">
                <ng-container [ngTemplateOutlet]="column.cellTemplate"
                  [ngTemplateOutletContext]="{ $implicit: item, index: i, column: column, icolumn: icolumn }">
                </ng-container>
              </span>
              <!-- Not details column -->
              <ng-template #notDetails>
                <ng-container [ngTemplateOutlet]="column.cellTemplate"
                  [ngTemplateOutletContext]="{ $implicit: item, index: i, column: column, icolumn: icolumn }">
                </ng-container>
              </ng-template>
            </td>
          </ng-container>

        </ng-container>
      </ng-container>
      <!-- Dynamic button column -->
      <ng-container *ngIf="column.key === 'buttons'">
        <ng-container matColumnDef="{{ column.key }}">
          <th scope="col" class="py-2" *matHeaderCellDef mat-header-cell mat-sort-header disableClear
            aria-label="columna ordenación"
            [disabled]="!_configuration.sorting || column.orderDisabled">
            <strong>{{ column.title }}</strong>
          </th>

          <td *matCellDef="let item; let i = index" [ngClass]="column.cssClass ? column.cssClass : 'text-right py-1'"
            mat-cell [ngClass]="{'rowCursor': (clickRow || item.expandable) && allowExpand}"
            (click)="expandedElement = expandedElement === item && allowExpand ? null : item; clickedItem(item)">
            <ng-container *ngIf="column.cellTemplate">
              <ng-container [ngTemplateOutlet]="column.cellTemplate"
                [ngTemplateOutletContext]="{ $implicit: item, index: i, column: column, icolumn: icolumn }">
              </ng-container>
            </ng-container>
            <mat-button-toggle-group class="noHover" name="fontStyle" aria-label="Font Style">

              <ng-container *ngFor="let funcion of column.functions; let icolumn = index">
                <mat-button-toggle *ngIf="decideShowButton(funcion, item)" class="buttonTable noHover" (click)="funcion.evento.emit(item); $event.stopPropagation()"
                [matTooltip]="funcion.detalleToolTip || 'Botón Acción'"
                [aria-label]="funcion.detalleToolTip">
                  <i class="fa fa-xs noHover {{funcion.icon}}" aria-hidden="true"></i>
                </mat-button-toggle>
              </ng-container>
            </mat-button-toggle-group>

          </td>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let item" [attr.colspan]="columns.length" class="arpada-table-row-expanded">
        <div class="example-element-detail"
          [@detailExpand]="item == expandedElement && item.expandable ? 'expanded' : 'collapsed'">
          <div class="example-element-diagram" *ngIf="item == expandedElement && item.expandable">

            <!-- AQUI -->
            <ng-container [ngTemplateOutlet]="rowExpandTemplateRef" [ngTemplateOutletContext]="{$implicit: item}"></ng-container>

            <!-- <app-card-view [hidenColumns]="_configuration.hidenCardDetailsFields"
              (detailsEmit)="emitirDetalle($event)"
              [columns]="columnsCard" [configuration]="_configurationExpandable" [keyCard]="keyCard">
            </app-card-view> -->
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="h-40" [hidden]="_configuration.hideHeaders"
      [ngClass]="{'rowCursor': clickRow}"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.example-expanded-row]="expandedElement === row"
      [ngClass]="{'rowCursor': clickRow}"></tr>
    <ng-container *ngIf="allowExpand">
      <tr mat-row *matRowDef="let row;  columns: ['expandedDetail']" class="example-detail-row"></tr>
    </ng-container>
    <!------------------------->
    <!------ No Result -------->
    <!------------------------->
    <tr mat-footer-row *matFooterRowDef="['noResults']"
      [style.display]="dataSource.data.length === 0 ? 'table-row' : 'none'"></tr>

    <ng-container matColumnDef="noResults">
      <td [attr.colSpan]="displayedColumns.length" class="text-center" mat-footer-cell *matFooterCellDef>
        <div class="no-results text-center py-2" *ngIf="_configuration.noResult">
          <span *ngIf="!isLoading"
            class="font-weight-normal mb-0">{{'Sin elementos' | translate}}</span>
          <span *ngIf="isLoading" id="table-padding"></span>
        </div>
        <div class="text-center py-2" *ngIf="!_configuration.noResult">
          <h2 id="py-4"></h2>
        </div>
      </td>
    </ng-container>

  </table>


  <!----- Paginator ----->
  <mat-paginator *ngIf="_configuration.pagination" [hidePageSize]="false" [pageSize]="_configuration.defaultPageSize"
    [pageSizeOptions]="_configuration.pageSizes || [25]" [length]="pagination.totalItems || (items && items.length)" showFirstLastButtons>
  </mat-paginator>
  <!------ Spinner -------->
  <div id="table-spinner" class="loading-shade" *ngIf="isLoading">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
</section>

<!---------------->
<!-- Responsive -->
<!---------------->
<section class="cardViewSection" [style.display]="mobileVersion ? '': 'none'">
  <app-card-view #cardView [items]="mobileVersion? items : []" [columns]="columnsListadoCards" [columnsExpanded]="columnsExpandedCard" [clickRow]="clickRow"
    [allowExpand]="allowExpand"
    [configuration]="_configuration" (clickEmit)="clickedItem($event)" [totalItems]="pagination.totalItems"
    [discriminator]="discriminator"
    [hidenColumns]="_configuration.hidenCardDetailsFields"
    [fullCardTemplate]="fullCardTemplate"
    (paginationEmit)="updatePagination($event)" [pagination]="pagination" [isLoading]="isLoading"
    (detailsEmit)="emitirDetalle($event)"
    (deleteEmit)="remove($event.item, $event.index)" [selection]="selection" (selectedEmit)="selectedEmit.emit($event)">
  </app-card-view>
</section>



<!------------------>
<!-- First column -->
<!------------------>
<ng-template #firstColumn let-item>
  <a tabindex=0 [innerHTML]="item | getPropertyFromString: columns[0].key" (click)="details(item)"></a>
</ng-template>


<!------------------>
<!-- Date column  -->
<!------------------>
<ng-template #dateTemplate let-item let-column='column'>
  <span
    [ngClass]="{'rowCursor': (clickRow || item.expandable) && allowExpand}">{{ (item | getPropertyFromString: column.key) !== '--' ? (item | getPropertyFromString: column.key | date: column.dateFormat || 'dd/MM/yyyy') : '--' }}</span>
</ng-template>

<ng-template #dateTimeTemplate let-item let-column='column'>
  <span>{{ (item | getPropertyFromString: column.key) !== '--' ? (item | getPropertyFromString: column.key | date: 'dd/MM/yyyy HH:mm') : '--' }}</span>
</ng-template>

<ng-template #timeTemplate let-item let-column='column'>
  <span>{{ (item | getPropertyFromString: column.key) !== '--' ? (item | getPropertyFromString: column.key | date: 'HH:mm') : '--' }}</span>
</ng-template>

<!---------------------------->
<!-- Active/Available icons -->
<!---------------------------->
<ng-template #activeIcon let-item>
  <div class="text-center">
    <span [innerHTML]="item.active | icon"></span>
  </div>
</ng-template>

<ng-template #availableIcon let-item>
  <div class="text-center">
    <span [innerHTML]="item.available | icon"></span>
  </div>
</ng-template>

<ng-template #enabledIcon let-item>
  <div class="text-center">
    <span [innerHTML]="item.enabled | icon"></span>
  </div>
</ng-template>

<ng-template #booleanIcon let-item let-column="column">
  <div class="text-center">
    <span [innerHTML]="item | getPropertyFromString: column.key | icon"></span>
  </div>
</ng-template>

<ng-template #numeric let-item let-column="column">
  <div class="text-center">
    <!-- 'minIntegerDigits.minFractionDigits-maxFractionDigits'  -->
    <span [innerHTML]="item | getPropertyFromString: column.key | number: '1.2-2'"></span>
  </div>
</ng-template>
<ng-template #decimal let-item let-column="column">
  <div class="text-right">
    <!-- 'minIntegerDigits.minFractionDigits-maxFractionDigits'  -->
    <span [innerHTML]="item | getPropertyFromString: column.key | number: '1.2-2'"></span>
  </div>
</ng-template>

<ng-template #currency let-item let-column="column">
  <div class="text-right">
    <span [innerHTML]="item | getPropertyFromString: column.key | currency"></span>
  </div>
</ng-template>
