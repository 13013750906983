import { ChildInherit } from './export-excel-configuration/child-inherit';
import { MatSnackBar } from '@angular/material/snack-bar';

import { GetPropertyFromString } from './../../pipes/get-property-from-string';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';


import * as XLSX from 'xlsx';
import { CustomPagination } from '../../components/table/table-configuration/custom-pagination';
import { Columns } from '../../shared.module';



@Component({
  selector: 'app-export-excel',
  templateUrl: './export-excel.component.html',
  styleUrls: ['./export-excel.component.css']
})
export class ExportExcelComponent implements OnInit {

  @Input() standalone = false;
  @Input() items: Array<any>;
  @Input() columns: Columns[];
  @Input() pagination: CustomPagination;
  @Input() itemChildsRow: ChildInherit;
  @Input() disabled = true;
  xlsxName: string;

  @Output() exportExcel = new EventEmitter<any>();

  constructor(private propertyFromString: GetPropertyFromString,
    private datePipe: DatePipe,
    private route: Router,
    private snackBar : MatSnackBar) { }

  ngOnInit(): void {
    this.xlsxName = '';
    this.route.url.split('/').forEach((str, index) => {
      if (index === 1) {
        this.xlsxName = this.xlsxName.concat(str);
      } else if (index > 1) {
        this.xlsxName = this.xlsxName.concat('_').concat(str);
      }
    });
    this.xlsxName = this.xlsxName.concat('.xlsx');
  }

  doExport(): void {
    if (this.standalone) {
      this.exportExcel.emit(true);
    } else {
      this.snackBar.open('Exportando datos a Excel...', 'Cerrar', {duration: 5000, verticalPosition: 'top'});
      this.xlsx();
      this.snackBar.dismiss();
    }
  }

  xlsx(): void {
    const xlsItems: any[] = [];

    if (this.pagination) {
      if (this.pagination.sort) {
        if (this.pagination.order === 'asc') {
          this.items.sort((a, b) => {
            if (typeof a[this.pagination.sort] === 'string') {
              return a[this.pagination.sort].toLowerCase() > b[this.pagination.sort].toLowerCase() ? 1 : -1;
            } else {
              return a[this.pagination.sort] > b[this.pagination.sort] ? 1 : -1;
            }
          });
        } else if (this.pagination.order === 'desc') {
          this.items.sort((a, b) => {
            if (typeof a[this.pagination.sort] === 'string') {
              return a[this.pagination.sort].toLowerCase() > b[this.pagination.sort].toLowerCase() ? -1 : 1;
            } else {
              return a[this.pagination.sort] > b[this.pagination.sort] ? -1 : 1;
            }
          });
        }
      }
    }

    this.generateXlsRows(this.items, xlsItems);

    const xls = XLSX.utils.json_to_sheet(xlsItems);
    xls['!cols'] = this.calculateWidth(xlsItems);
    const workbook = { Sheets: { data: xls }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, this.xlsxName);

  }

  generateXlsRows(items: any[], xlsItems: any[], parentItem?: any): void {
    items.forEach(item => {
      item = this.enumTranslation(item);

      // Se comprueba si el elemento tiene hijos:
      let childs;
      if (this.itemChildsRow && this.itemChildsRow.childsFieldLoc) {
        childs = this.propertyFromString.transform(item, this.itemChildsRow.childsFieldLoc);
      }

      if (childs && childs !== null && childs!== '--' && childs.length > 0) {
        // El elemento contiene hijos, se generan filas para hijos y se ignora fila padre en excel.
        this.generateXlsRows(childs, xlsItems, item);
      } else {

        if (parentItem) {
          // Si el elemento a cargar en excel tiene padre se pisan los datos configurados con los del padre.
          if (this.itemChildsRow && this.itemChildsRow.inheritFields) {
              this.itemChildsRow.inheritFields.forEach( inheritField => {
                let prop = this.propertyFromString.transform(parentItem,inheritField);
                item[inheritField] = prop;
              })
          }
        }

        const tempObject = new Object();
        this.columns.forEach(col => {
          if (col.exportable === undefined || col.exportable === true) {
            if (col.title) {
              let itemProperty = this.propertyFromString.transform(item, col.key);
              if (col.isDate) {
                itemProperty = this.datePipe.transform(itemProperty, 'dd/MM/yyyy');
              }
              if (col.isDateTime) {
                itemProperty = this.datePipe.transform(itemProperty, 'dd/MM/yyyy HH:mm:ss');
              }
              if (col.isBoolean) {
                itemProperty = itemProperty ? 'Si' : 'No';
              }
              tempObject[col.title] = itemProperty;
            }
          }
        });
        xlsItems.push(tempObject);
      }
    });
  }

  enumTranslation(item: any): any {
    const resItem = Object.assign({}, item);
    if (resItem.active != null) {
      if (resItem.active === true) {
        resItem.active = 'Sí';
      } else if (resItem.active === false) {
        resItem.active = 'No';
      }
    }

    if (resItem.available != null) {
      if (resItem.available === true) {
        resItem.available = 'Sí';
      } else if (resItem.available === false) {
        resItem.available = 'No';
      }
    }

    if (resItem.enabled != null) {
      if (resItem.enabled === true) {
        resItem.enabled = 'Sí';
      } else if (resItem.enabled === false) {
        resItem.enabled = 'No';
      }
    }


    return resItem;
  }

  calculateWidth(xlsItems: any[]): any {
    const objectMaxLength = [];

    this.columns.forEach(col => {
      col.exportable == undefined || col.exportable == true? objectMaxLength.push(col.title.length) : null;
      });

    xlsItems.forEach(item => {
      const values = Object.values(item) as string[];
      values.forEach((value, index) => {
        let valueStr = value === undefined || value === null ? '' : String(value);
        objectMaxLength[index] = objectMaxLength[index] >= valueStr.length ? objectMaxLength[index] : valueStr.length;
      });
    });

    const wscols: any[] = [];
    objectMaxLength.forEach(obj => wscols.push({ width: obj }));
    return wscols;

  }


}
