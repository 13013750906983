import { ButtonTableConfig } from './../../../../core/models/button-table.model';
import { TemplateRef } from '@angular/core';

export class Columns {
  key: string;
  title: string;
  cellTemplate?: TemplateRef<any>;
  orderDisabled ? = false;
  orderBy?: 'asc' | 'desc';
  hideColumn?: boolean;
  isNumber ? = false;
  isCurrency ? = false;
  isCentered ? = false;
  isRight ? = false;
  isDate ? = false;
  isTime?: boolean;
  isDateTime?: boolean;
  dateFormat?: string;
  isBoolean?: boolean;
  cssClass?: string;
  functions?: Array<ButtonTableConfig>;
  validationFunc?: any;
  /** CSS Class to apply in case of faulted validation */
  validationErrorCssClass?: string;
  exportable ? = true;
}
