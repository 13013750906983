<mat-toolbar class="toolbar sub-toolbar" *ngIf="!mobile" [class.arpada-mobile-subtoolbar]="mobile">
    <h1>{{title | translate}}</h1>
    <i class="material-icons material-icons-outlined invoice-add-icon" matTooltip="{{toolTipTitle | translate}}" matTooltipPosition="right" aria-hidden="true">{{iconString}}</i>
</mat-toolbar>

<ng-container *ngIf="editOn">
    <a [routerLink]="" (click)="back()" *ngIf="!mobile" class="arpada-bold-2 arpada-back-button align-icon-text">
        <mat-icon inline>arrow_back_ios</mat-icon>{{'Volver' | translate }}
    </a>
</ng-container>

<div class="stepper-container">
    <mat-horizontal-stepper [linear]="false" #stepper>

        <mat-step [stepControl]="formStep1">
            <mat-card class="mt-3">
                <form [formGroup]="formStep1" (ngSubmit)="submitStep1()">
                    <ng-template matStepLabel *ngIf="displayStepLabel">{{'Datos' | translate }} </ng-template>
                    <app-inputs-generator [fGroup]="formStep1" [inputFields]="inputsStep1"></app-inputs-generator>
                    <div>
                        <section class="row footer">
                            <div class="col ml-auto">
                                <button mat-flat-button matStepperNext type="submit" color="primary" class="btn-apply btn-next" [disabled]="!formStep1IsValid">{{ 'Siguiente' | translate }}</button>
                            </div>
                        </section>
                    </div>
                </form>
            </mat-card>
        </mat-step>

        <mat-step [stepControl]="formStep2">
            <ng-template matStepLabel *ngIf="displayStepLabel">{{'Detalles' | translate }}</ng-template>
            <mat-card class="mt-3" *ngIf="subTipoFactura?.templateParameters?.step2Template === 'generic_invoice'">
                <app-factura-generica [formGroup]="formStep2" [invoiceGet]="invoiceGet" [tipoFactura]="tipoFactura" [subTipoFactura]="subTipoFactura" [companyId]="companyId" [destinatarioId]="destinatarioId" [editOn]="editOn" [vatTypes]="vatTypes" [fechaFactura]="fechaFactura"
                  [changeFormFirstStep]="changeFormFirstStep" (formGroupEmiter)="formStep2Read($event)" (back)="changeFormFirstStep = $event"></app-factura-generica>
            </mat-card>
            <mat-card class="mt-3" *ngIf="subTipoFactura?.templateParameters?.step2Template === 'proforma'">
                <app-invoice-proforma-de-obra [formGroup]="formStep2" [formGroupProntoPago]="formStep2ProntoPago" [companyId]="companyId" [invoiceGet]="invoiceGet" [editOn]="editOn" [tipoFactura]="tipoFactura" [subTipoFactura]="subTipoFactura" (formGroupEmiter)="formStep2Read($event)"
                  [changeFormFirstStep]="changeFormFirstStep" (emitProforma)="setIvasProforma($event)" (back)="changeFormFirstStep = $event"></app-invoice-proforma-de-obra>
            </mat-card>
            <mat-card class="mt-3" *ngIf="subTipoFactura?.templateParameters?.step2Template === 'generic_refund'">
                <app-abono-factura [formGroup]="formStep2" [invoiceGet]="invoiceGet" [tipoFactura]="tipoFactura" [subTipoFactura]="subTipoFactura" [companyId]="companyId" [destinatarioId]="destinatarioId" [editOn]="editOn" [vatTypes]="vatTypes" [fechaFactura]="fechaFactura"
                  [changeFormFirstStep]="changeFormFirstStep" (formGroupEmiter)="formStep2Read($event)" (changeIvaEmitter)="setIvas($event)" (back)="changeFormFirstStep = $event"></app-abono-factura>
            </mat-card>
        </mat-step>

        <mat-step [stepControl]="">
            <mat-card class="mt-3">
                <ng-template matStepLabel *ngIf="displayStepLabel">{{'Confirmar' | translate }}</ng-template>
                <app-invoices-create-confirmation [invoice]="invoiceDTO" [tipoFactura]="tipoFactura" [subTipoFactura]="subTipoFactura" (confirmInvoice)="confirmInvoice()"></app-invoices-create-confirmation>
            </mat-card>
        </mat-step>
    </mat-horizontal-stepper>
</div>
