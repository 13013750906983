import { Order } from './order.model';

export class InvoiceTypeSearchCriteria {
  order: Order[];
  offset: number;
  limit: number;
  id: number;
  ids: number[];
  uuid: string;
  uuids: string[];
  smart_text: string;
  by_invoiceable_company: number;
  b_only_selectables_in_user_interface: boolean;
  only_invoicetypes_with_invoices_on_company_id: number;

  constructor(data?: any) {
    this.order = (data && data.order) || [];
    this.offset = (data && data.offset) || 0;
    this.limit = (data && data.limit) || 0;
    this.id = (data && data.id) || 0;
    this.ids = (data && data.ids) || [];
    this.uuid = (data && data.uuid) || null;
    this.uuids = (data && data.uuids) || [];
    this.smart_text = (data && data.smart_text) || '';
    this.by_invoiceable_company = (data && data.by_invoiceable_company) || null;
    this.b_only_selectables_in_user_interface = (data && data.b_only_selectables_in_user_interface) || null;
    this.only_invoicetypes_with_invoices_on_company_id =
      (data && data.only_invoicetypes_with_invoices_on_company_id) || null;
  }
}
