export class VatLine {
  amount: number;
  baseAmount?: number;
  vatTypeId: string;
  vatTypeTypeKey: string;
  vatTypeDescription: string;
  vatTypeVersionId: string;
  vatPerone: number;
  vatPcentDescription: string;

  constructor(data?: any) {
    this.amount = (data && data.amount) || 0;
    this.baseAmount = (data && data.baseAmount) || 0;
    this.vatTypeId = (data && data.vatTypeId) || '';
    this.vatTypeDescription = (data && data.vatTypeDescription) || '';
    this.vatTypeVersionId = (data && data.vatTypeVersionId) || '';
    this.vatPerone = (data && data.vatPerone) || 0;
    this.vatPcentDescription = (data && data.vatPcentDescription) || '';
    this.vatTypeTypeKey = (data && data.vatTypeTypeKey) || '';
  }

  static buildFromDto(data?): VatLine {
    const vatLine = new VatLine();
    vatLine.amount = (data && data.amount) || 0;
    vatLine.baseAmount = (data && data.base_amount) || 0;
    vatLine.vatTypeId = (data && data.vat_type_id) || null;
    vatLine.vatTypeDescription = (data && data.vat_type_description) || null;
    vatLine.vatTypeVersionId = (data && data.vat_type_version_id) || null;
    vatLine.vatPerone = (data && data.vat_perone) || 0;
    vatLine.vatPcentDescription = (data && data.vat_pcent_description) || null;
    vatLine.vatTypeTypeKey = (data && data.vat_type_type_key) || null;
    return vatLine;
  }
}

export class PaymentLine {
  id: string;
  paymentDate: Date;
  concept: string;
  confirmingPhone: string;
  bankingEntity: string;
  amount: number;

  constructor(data?: any) {
    this.id = (data && data.id) || null;
    this.paymentDate = (data && data.paymentDate) || null;
    this.concept = (data && data.concept) || '';
    this.confirmingPhone = (data && data.confirmingPhone) || '';
    this.bankingEntity = (data && data.bankingEntity) || '';
    this.amount = (data && data.amount) || 0;
  }

  static buildFromDto(data?: any): PaymentLine {
    const paymentLine = new PaymentLine();
    paymentLine.id = (data && data.id) || null;
    paymentLine.paymentDate = (data && data.payment_date) || null;
    paymentLine.concept = (data && data.concept) || '';
    paymentLine.confirmingPhone = (data && data.confirming_phone) || '';
    paymentLine.bankingEntity = (data && data.banking_entity) || '';
    paymentLine.amount = (data && data.amount) || 0;
    return paymentLine;
  }
}

export class InvoiceEvent {
  eventCode: string;
  eventName: string;
  eventDatetime: Date;
  actor: string;
  comments: string;

  constructor(data?: any) {
    this.eventCode = (data && data.eventCode) || null;
    this.eventName = (data && data.eventName) || null;
    this.eventDatetime = (data && data.eventDatetime) || null;
    this.actor = (data && data.actor) || '';
    this.comments = (data && data.comments) || '';
  }

  static buildFromDto(data?: any): InvoiceEvent {
    const invoiceEvent = new InvoiceEvent();
    invoiceEvent.eventCode = (data && data.event_code) || null;
    invoiceEvent.eventName = (data && data.event_name) || null;
    invoiceEvent.eventDatetime = (data && data.event_datetime) || null;
    invoiceEvent.actor = (data && data.actor) || '';
    invoiceEvent.comments = (data && data.comments) || '';
    return invoiceEvent;
  }
}

export class InvoicePermissions {
  invoiceCancelable: boolean;
  invoiceEditable: boolean;

  static buildFromDto(data?: any): InvoicePermissions {
    const invoicePermissions = new InvoicePermissions();
    invoicePermissions.invoiceCancelable =
      (data && data.invoice_cancelable) || false;
    invoicePermissions.invoiceEditable =
      (data && data.invoice_editable) || false;
    return invoicePermissions;
  }
}

export class Worksheet {
  id: string;
  description: string;
  order: string;
  totalAmount: number;
  externalUniquekey: string;

  constructor(data?: any) {
    this.id = (data && data.id) || '';
    this.description = (data && data.description) || '';
    this.order = (data && data.order) || '';
    this.totalAmount = data && data.totalAmount;
    this.externalUniquekey = data && data.external_uniquekey;
  }

  static buildFromDto(data?: any): Worksheet {
    const worksheet = new Worksheet();
    worksheet.id = (data && data.id) || '';
    worksheet.description = (data && data.description) || '';
    worksheet.order = (data && data.order) || '';
    worksheet.totalAmount = data && data.total_amount;
    worksheet.externalUniquekey = data && data.external_uniquekey;
    return worksheet;
  }
}

export class LinkedProforma {
  id: number;
  totalAmount: number;
  description: string;
  paymentMethod: string;

  constructor(data?: any) {
    this.id = data && data.id;
    this.totalAmount = data && data.totalAmount;
    this.description = (data && data.description) || '';
    this.paymentMethod = (data && data.paymentMethod) || '';
  }

  static buildFromDto(data?: any): LinkedProforma {
    const linkedProforma = new LinkedProforma();
    linkedProforma.id = data && data.id;
    linkedProforma.totalAmount = data && data.total_amount;
    linkedProforma.description = (data && data.description) || '';
    linkedProforma.paymentMethod = (data && data.payment_method) || '';
    return linkedProforma;
  }
}

export class InvoiceGet {
  id: number;
  uuid: string;
  invoice_number: string;
  invoiceInternalId: string;
  invoiceDate: Date;
  registerDate: Date;
  baseAmount: number;
  total_amount: number;
  withholdingAmount: number;
  withholdingPerone: number;
  hasPromptPayment: boolean;
  promptPaymentAmount: number;
  invoiceFileUuid: string;
  invoiceAttachmentFileUuid: string;
  irpfPerone: string;
  irpfValue: string;
  stateId: string;
  stateDescription: string;
  stateDetailsText: string;
  stateDetailsTlf: string;
  stateDetailsEmail: string;
  eventDates: string[];
  events: InvoiceEvent[];
  invoiceTypeId: string;
  invoiceTypeDescription: string;
  invoiceSubtypeId: string;
  invoiceSubtypeDescription: string;
  reverseVatLiability: boolean;
  vatLines: VatLine[];
  paymentsLines: PaymentLine[];
  invoicePermissions: InvoicePermissions;
  companyId: number;
  companyDescription: string;
  companyVatNumber: string;
  companyWhichIsInvoicedDescription: string;
  companyWhichIsInvoicedVatNumber: string;
  csiteId: number;
  csiteDescription: string;
  linkedWorksheets: Worksheet[];
  linkedProforma: any;
  relatedToRefundUuid: string;
  refundsInvoiceUuid: number;
  expandable = true;
  csiteInternalNumber: string;
  csiteAddress: string;
  companyWhichIsInvoicedId: number;
  stateDetailsProgressMaxSteps: any;
  stateDetailsProgressStep: any;
  refundsThatRefundMeUuids: string[];
  stateDetailsProgressText: string;

  constructor(data?: any) {
    this.id = (data && data.id) || null;
    this.uuid = (data && data.uuid) || null;
    this.invoice_number = (data && data.invoice_number) || '';
    this.invoiceInternalId = (data && data.invoice_internal_id) || null;
    this.invoiceDate = (data && data.invoiceDate) || null;
    this.registerDate = (data && data.invoice_register_date) || null;
    this.baseAmount = (data && data.baseAmount) || null;
    this.total_amount = (data && data.invoiceTotal) || null;
    this.withholdingAmount = (data && data.withholdingAmount) || null;
    this.withholdingPerone = (data && data.withholdingPerone) || null;
    this.hasPromptPayment = data && data.hasPromptPayment;
    this.promptPaymentAmount = (data && data.promptPaymentAmount) || null;
    this.invoiceFileUuid = (data && data.invoiceFileUuid) || '';
    this.invoiceAttachmentFileUuid =
      (data && data.invoiceAttachmentFileUuid) || '';
    this.irpfPerone = (data && data.irpfPerone) || '';
    this.irpfValue = (data && data.irpfValue) || '';
    this.stateId = (data && data.stateId) || '';
    this.stateDescription = (data && data.stateDescription) || '';
    this.stateDetailsText = (data && data.stateDetailsText) || '';
    this.stateDetailsTlf = (data && data.stateDetailsTlf) || '';
    this.stateDetailsEmail = (data && data.stateDetailsEmail) || '';
    this.eventDates = (data && data.eventDates) || [];
    this.events = (data && data.events) || new Array<InvoiceEvent>();
    this.invoiceTypeId = (data && data.invoiceTypeId) || '';
    this.invoiceTypeDescription = (data && data.invoiceTypeDescription) || '';
    this.invoiceSubtypeId = (data && data.invoiceSubtypeId) || '';
    this.invoiceSubtypeDescription =
      (data && data.invoiceSubtypeDescription) || '';
    this.reverseVatLiability = data && data.reverseVatLiability;
    this.vatLines = (data && data.vatLines) || new Array<VatLine>();
    this.paymentsLines =
      (data && data.paymentsLines) || new Array<PaymentLine>();
    this.invoicePermissions =
      (data && data.invoicePermissions) || ({} as InvoicePermissions);
    this.companyId = (data && data.companyId) || null;
    this.companyDescription = (data && data.companyDescription) || '';
    this.companyVatNumber = (data && data.companyVatNumber) || '';
    this.csiteId = (data && data.csiteId) || null;
    this.csiteDescription = (data && data.csiteDescription) || '';
    this.linkedWorksheets =
      (data && data.linkedWorksheets) || new Array<Worksheet>();
    this.linkedProforma =
      (data && data.linkedProforma) || LinkedProforma.buildFromDto();
    this.relatedToRefundUuid = (data && data.relatedToRefundUuid) || '';
    this.csiteInternalNumber = (data && data.csite_internal_number) || '';
    this.csiteAddress = (data && data.csite_address) || '';
    this.refundsInvoiceUuid = (data && data.refunds_invoice_uuid) || '';
    this.companyWhichIsInvoicedId =
      (data && data.company_which_is_invoiced_id) || null;
    this.stateDetailsProgressMaxSteps =
      (data && data.state_details_progress_max_steps) || null;
    this.stateDetailsProgressStep =
      (data && data.state_details_progress_step) || null;
    this.refundsThatRefundMeUuids =
      (data && data.refunds_that_refund_me_uuids) || [];
    this.stateDetailsProgressText =
      (data && data.state_details_progress_text) || [];
  }

  static buildFromDto(data?: any): InvoiceGet {
    const invoice = new InvoiceGet();
    invoice.id = (data && data.id) || null;
    invoice.uuid = (data && data.uuid) || null;
    invoice.invoice_number = (data && data.invoice_number) || null;
    invoice.invoiceInternalId = (data && data.invoice_internal_id) || null;
    invoice.invoiceDate = (data && data.invoice_date) || null;
    invoice.registerDate = (data && data.invoice_register_date) || null;
    invoice.baseAmount = (data && data.base_amount) || null;
    invoice.total_amount = (data && data.invoice_total) || null;
    invoice.withholdingAmount = (data && data.withholding_amount) || null;
    invoice.withholdingPerone = (data && data.withholding_perone) || null;
    invoice.hasPromptPayment = data && data.has_prompt_payment;
    invoice.promptPaymentAmount = (data && data.prompt_payment_amount) || null;
    invoice.invoiceFileUuid = (data && data.invoice_file_uuid) || null;
    invoice.invoiceAttachmentFileUuid =
      (data && data.invoice_attachment_file_uuid) || null;
    invoice.irpfPerone = (data && data.irpf_perone) || null;
    invoice.irpfValue = (data && data.irpf_value) || null;
    invoice.stateId = (data && data.state_id) || null;
    invoice.stateDescription = (data && data.state_description) || null;
    invoice.stateDetailsText = (data && data.state_details_text) || null;
    invoice.stateDetailsTlf = (data && data.state_details_tlf) || null;
    invoice.stateDetailsEmail = (data && data.state_details_email) || null;
    invoice.eventDates = (data && data.event_dates) || [];
    invoice.events =
      data && data.events
        ? (data.events as []).map((v) => InvoiceEvent.buildFromDto(v))
        : new Array<InvoiceEvent>();
    invoice.invoiceTypeId = (data && data.invoice_type_id) || null;
    invoice.invoiceTypeDescription =
      (data && data.invoice_type_description) || null;
    invoice.invoiceSubtypeId = (data && data.invoice_subtype_id) || null;
    invoice.invoiceSubtypeDescription =
      (data && data.invoice_subtype_description) || null;
    invoice.reverseVatLiability = data && data.reverse_vat_liability;
    invoice.vatLines =
      data && data.vat_lines
        ? (data.vat_lines as []).map((v) => VatLine.buildFromDto(v))
        : new Array<VatLine>();
    invoice.paymentsLines =
      data && data.payments_lines
        ? (data.payments_lines as []).map((p) => PaymentLine.buildFromDto(p))
        : new Array<PaymentLine>();
    invoice.invoicePermissions =
      data && data.invoice_permissions
        ? InvoicePermissions.buildFromDto(data.invoice_permissions)
        : ({} as InvoicePermissions);
    invoice.companyId = (data && data.company_id) || null;
    invoice.companyDescription = (data && data.company_description) || null;
    invoice.companyVatNumber = (data && data.company_vat_number) || null;
    invoice.companyWhichIsInvoicedDescription =
      (data && data.company_which_is_invoiced_description) || null;
    invoice.companyWhichIsInvoicedVatNumber =
      (data && data.company_which_is_invoiced_vat_number) || null;
    invoice.csiteId = (data && data.csite_id) || null;
    invoice.csiteDescription = (data && data.csite_description) || null;
    invoice.linkedWorksheets =
      data && data.linked_worksheets
        ? (data.linked_worksheets as []).map((p) => Worksheet.buildFromDto(p))
        : new Array<Worksheet>();
    invoice.linkedProforma =
      data && data.linked_proforma
        ? LinkedProforma.buildFromDto(data.linked_proforma)
        : null;
    invoice.relatedToRefundUuid = (data && data.related_to_refund_uuid) || null;
    invoice.csiteInternalNumber = (data && data.csite_internal_number) || null;
    invoice.csiteAddress = (data && data.csite_address) || null;
    invoice.refundsInvoiceUuid = (data && data.refunds_invoice_uuid) || null;
    invoice.companyWhichIsInvoicedId =
      (data && Number(data.company_which_is_invoiced_id)) || null;
    invoice.refundsThatRefundMeUuids =
      (data && data.refunds_that_refund_me_uuids) || [];
    invoice.stateDetailsProgressMaxSteps =
      (data && data.state_details_progress_max_steps) || null;
    invoice.stateDetailsProgressStep =
      (data && data.state_details_progress_step) || null;
    invoice.stateDetailsProgressText =
      (data && data.state_details_progress_text) || [];
    return invoice;
  }
}
