import { Rol } from "./rol.model";

export class Usuario {
  id: string;
  username: string;
  fullname: string;
  firstname: string;
  lastname: string;
  lastname2: string;
  email: string;
  roles: Array<Rol>;
  token: any;
  tokenExpireDate: number;

  company: any;
  rolesInCompany: string[];
  permissionsInCompany: any;
  userType: string;
  job_position_in_company: string;
  status: string;
  contact_phone: string;

  constructor(data?) {
    this.id = data && data.id;
    this.username = data && data.username || '';
    this.fullname = data && data.fullname || '';
    this.firstname = data && data.firstname || '';
    this.lastname = data && data.lastname || '';
    this.lastname2 = data && data.lastname2 || '';
    this.email = data && data.email || '';
    this.roles = data && data.roles || new Array<Rol>();
    this.token = data && data.token || '';
    this.tokenExpireDate = data && data.tokenExpireDate || 0;
    this.company = data && data.company || null;
    this.rolesInCompany = data && data.rolesInCompany || [];
    this.permissionsInCompany = data && data.permissionsInCompany || {};
    this.userType = data && data.userType || null;
    this.job_position_in_company = data && data.job_position_in_company || '';
    this.status = data && data.status;
    this.contact_phone = data && data.contact_phone || '';
  }

  getNameFromUserType(): string {
    let name;
    if('internal' === this.userType) {
      name = this.fullname;
    } else {
      name = this.company?.name + ' (' + this.fullname + ')';
    }
    return name;
  }

}


export class UserType {
  user_type_key: string;
  user_type_description: string;

  constructor(data?: any) {
    this.user_type_key = data && data.user_type_key;
    this.user_type_description = data && data.user_type_description;
  }
}

export class UserState {
  user_state_key: string;
  user_state_description: string;

  constructor(data?: any) {
    this.user_state_key = data && data.user_state_key;
    this.user_state_description = data && data.user_state_description;
  }
}
