export class AgTableColumnDefinition {
  public field: string;
  public headerName: string;
  public sortable: boolean;
  public valueFormatter: any;


  constructor(parameters) {
    this.headerName = parameters && parameters.headerName || '';
    this.field = parameters && parameters.field || '';
    this.sortable = parameters && parameters.sortable || false;
    this.valueFormatter = parameters && parameters.valueFormatter || undefined;
  }
}
