import { UtilsService } from 'src/app/shared/services/utils.service';
import { LoginService } from './../../../services/login.service';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoices-list-container',
  templateUrl: './invoices-list-container.component.html',
  styleUrls: ['./invoices-list-container.component.scss']
})
export class InvoicesListContainerComponent implements OnInit {

  mobile = false;
  userType: string;

  constructor(
              public loginService: LoginService,
              private utilsService: UtilsService
              ) { }

  ngOnInit(): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;

    this.userType = this.loginService.getUser().userType;

    this.utilsService.toolbarTitle.emit({title: 'Listado de facturas', icon: null});

  }

  @HostListener('window:resize', ['$event'])
  private onResize(event): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;
  }

}
