import { UtilsService } from 'src/app/shared/services/utils.service';
import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InvoiceCommandsService } from 'src/app/core/services/invoice-commands.service';
import { InvoicingQueryService } from 'src/app/core/services/invoicing-query.service';
import { LoginService } from 'src/app/core/services/login.service';
import { CustomFilters } from 'src/app/shared/components/table/table-configuration/custom-filters';
import { CustomPagination } from 'src/app/shared/components/table/table-configuration/custom-pagination';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { FilterDataService } from 'src/app/shared/services/filter-data.service';
import { TablePaginationService } from 'src/app/shared/services/table-pagination.service';
import { AlertService } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-user-settings-container',
  templateUrl: './user-settings-container.component.html',
  styleUrls: ['./user-settings-container.component.scss']
})
export class UserSettingsContainerComponent implements OnInit {

  mobile: boolean = false;
  loading: boolean = true;

  @HostListener('window:resize', ['$event'])
  private onResize(event): void {
    this.mobile = window.innerWidth <= 1020 ? true : false;
  }

  constructor(private invoicingQueryService: InvoicingQueryService,
    private invoiceCommandsService: InvoiceCommandsService,
    private loginService: LoginService,
    private location: Location,
    private alertService: AlertService,
    private router: Router,
    private tablePaginationService: TablePaginationService,
    private filterDataService: FilterDataService,
    private utilsService: UtilsService,
    ) {

      this.mobile = window.innerWidth <= 1020 ? true : false;
  }

  ngOnInit(): void {
    this.SetParent();
    this.utilsService.toolbarTitle.emit({title: 'Configuración de usuario', icon: null, back: true})
  }

  SetParent() {
    let customPagination = new CustomPagination();
    customPagination.parent = 'userSettings';
    this.tablePaginationService.setData(customPagination);

    let customFilters = new CustomFilters();
    customFilters.parent = 'userSettings';
    this.filterDataService.setData(customFilters);
  }


  back(): void {
    this.location.back();
  }

}
