import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, SimpleChange, TemplateRef, ViewChild, OnDestroy, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import { Columns } from '../table/table-configuration/columns';
import { TableConfiguration } from '../table/table-configuration/table-configuration';
import { CustomPagination } from '../table/table-configuration/custom-pagination';
import { DeleteItem } from '../table/table-configuration/delete-item';
import { FilterDataService } from '../../services/filter-data.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ButtonTableConfig } from 'src/app/core/models/button-table.model';
import { DetailsItem } from '../table/table-configuration/details-item';
import { Subscription } from 'rxjs';
import { TablePaginationService } from '../../services/table-pagination.service';



export declare interface PaginationType {
  pageIndex: number;
  pageSize?: number;
  active?: string;
  direction?: string;
}


@Component({
  selector: 'app-card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.scss']
})
export class CardViewComponent implements OnInit, OnDestroy, OnChanges {

  @Input() columns: Array<Columns>;
  @Input() columnsExpanded: Array<Columns>;
  @Input() hidenColumns: Array<string> = new Array<string>();
  @Input() items: Array<any>;
  @Input() renderedItems: Array<any>;
  @Input() isLoading: boolean;
  @Input() configuration: TableConfiguration;
  @Input() totalItems: number;
  @Input() pagination: CustomPagination;
  @Input() selection = new SelectionModel<any>(true, []);
  @Input() keyCard = '';
  @Input() clickRow = false;
  @Input() allowExpand = false;
  @Input() discriminator = '';
  @Input() fullCardTemplate: TemplateRef<any>;

  @Output() openDetailsEmit = new EventEmitter();
  @Output() paginationEmit = new EventEmitter();
  @Output() deleteEmit = new EventEmitter();
  @Output() selectedEmit = new EventEmitter<any>();
  @Output() clickEmit = new EventEmitter<any>();
  @Output() detailsEmit = new EventEmitter<DetailsItem>();

  public botones: Array<any>;

  public expanded = false;

  public arrow = 'keyboard_arrow_down';

  public paginationResponsive: PaginationType = { pageIndex: 0, direction: 'asc', active: '' };

  expandedElement: any | null;

  windowWith: number;
  windowHeight: number;
  private sub: Subscription = new Subscription;

  @ViewChild('scrollContainer', { static: true }) container: any;
  pageSize = 25;
  sum = 25;
  startIndex = 0;
  endIndex = 0;
  hasFired = false;
  routePath: string;
  buttons: Columns;
  cardDetails: Array<Columns>;

  showOrderSelection: boolean = false;

  stored: Array<any> = [];

  constructor(private router: Router, private filterDataService: FilterDataService, private tablePaginationService: TablePaginationService) { }

  ngOnChanges(changes: SimpleChanges): void {
    const items: SimpleChange = changes.items;
    const columns: SimpleChange = changes.columns;
    const pagination: SimpleChange = changes.pagination;


    if (pagination && pagination.currentValue) {
      if (this.paginationResponsive.pageIndex !== this.pagination.page) {
        this.paginationResponsive.pageIndex = (this.pagination.page - 1);
      }

      this.sum = pagination.currentValue.pageSize;
      this.pageSize = pagination.currentValue.pageSize;


      if (1 === pagination.currentValue.page) {
        this.stored = [];
      }

    }
    if (this.windowWith === window.innerWidth && this.windowHeight === window.innerHeight) {
      if (items && items.currentValue) {

        // Refreshing current rendered items
        this.renderedItems = items.currentValue.slice(0, this.sum) || [];
        if (this.paginationResponsive.pageIndex === 0) {
            this.stored = [];
        }
        this.stored = this.stored.concat(this.renderedItems);
      }
    }
    this.windowWith = window.innerWidth;
    this.windowHeight = window.innerHeight;

    if (columns && columns.currentValue) {
      this.cardDetails = this.columns.slice(1);

      this.buttons = this.columns.find(column => column.key === 'buttons');
    }
  }

  ngOnInit(): void {

    this.sub = this.tablePaginationService.resetStore.subscribe(data => {
      if (data) {
        this.stored = [];
        this.paginationResponsive.pageIndex = 0;
      }
    });

    this.windowWith = window.innerWidth;
    if (this.items) {
      this.renderedItems = this.items.slice(0, this.sum);
    }
    this.routePath = this.router.url;
    this.paginationResponsive.active = this.pagination && this.pagination.sort || '';
    this.paginationResponsive.direction = this.pagination && this.pagination.order || 'asc';
    if (this.pagination && this.pagination.pageSize) {  //} && this.items && this.items.length > this.pagination.pageSize) {

      this.sum = this.pagination.pageSize;
      this.pageSize = this.pagination.pageSize;
    }
    this.checkButtons();
  }

  private checkButtons(): void {
    if (this.columns) {
      this.columns.forEach((element, index) => {
        if (element.functions) {
          this.botones = element.functions;
          /* this.columns.splice(index, 1); */
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public controlArrow(item: any): void {
    if ( this.expandedElement === undefined) {
      this.expandedElement = item;
    } else {
      if (this.expandedElement === item) {
        this.expandedElement = undefined;
      } else {
        this.expandedElement = item;
      }
    }

    if (!this.expanded) {
      this.arrow = 'keyboard_arrow_down';
    } else {
      this.arrow = 'keyboard_arrow_up';
    }
  }

  routerLinkDetails(id: string): void {
    this.filterDataService.setParentUrl(this.routePath.split('/')[2]);
    this.router.navigate([this.routePath, 'detalle', id]);
  }

  public emitirDetalle(e: any): void {
    this.detailsEmit.emit(e);
  }




  onScroll(event: any): void {
    if (!this.hasFired) {
      this.hasFired = true;
      const windowScroll = window.scrollY;
      const containerHeight = this.container.nativeElement.scrollHeight;

      const scrollPercent = windowScroll / containerHeight;


      if (scrollPercent >= 0.8) {
        if (this.stored.length < this.totalItems) {
          this.appendRenderedItems();
        }
      }
      window.setTimeout(() => this.hasFired = false, 500);
    }
  }

  appendRenderedItems(): void {
    // Adds 25 more elements, starting on the current -1 (zero based index)
    // Slice returns an Array, so we need to use '...' to push every object on that array, not the array object itself
    this.sum += this.pageSize;

    if (this.configuration.pageable) {
      // Decouple the object sended to the table from the local paginationResponsive
      // allowing to compare diferences between paginationResponsive and pagination on posterior events
      const auxPagination: PaginationType = { pageIndex: this.paginationResponsive.pageIndex };
      Object.assign(auxPagination, this.paginationResponsive);
      // Increments on pageIndex
      auxPagination.pageIndex += 1;
      this.paginationEmit.emit(auxPagination);
    }
  }

  onGlobalSeach(value: string): void {
    this.sum = 25;

    // Another solution, it search for all the value individually, avoiding colisions between keys and values when filtering.

    // this.renderedItems = this.items.filter(item => Object.keys(item).some(key => {
    //   if (typeof item[key] === 'string') { return item[key].toLowerCase().includes(value.toLowerCase()); }
    //   if (item[key] && typeof item[key] === 'object') {
    //     let tempItem = item[key];
    //     return Object.keys(tempItem).some(childKey => {
    //       if (typeof tempItem[childKey] === 'string') {
    //         return tempItem[childKey].toLowerCase().includes(value.toLowerCase());
    //       }
    //     });
    //   }
    // return false;
    // }));
  }

  activeFieldChanged(): void {
    if (this.configuration.pageable) {
      // Resets the pages to 1 on back-end pagination
      this.paginationResponsive.pageIndex = 0;
    }
    this.updateSorting();
  }
  updateSorting(): void {
    this.stored = [];
    if (this.configuration.pageable && !this.configuration.disablePersistence) {
      this.paginationEmit.emit(this.paginationResponsive);
    } else {
      this.paginationEmit.emit(this.paginationResponsive);
      this.sortProgramatically();
    }
  }

  sortProgramatically(): void {
    if (this.paginationResponsive) {
      if (this.paginationResponsive.active) {
        if (this.paginationResponsive.direction === 'asc') {
          this.renderedItems = [].concat(this.items.sort((a, b) => {
            if (typeof a[this.paginationResponsive.active] === 'string') {
              return a[this.paginationResponsive.active].toLowerCase() > b[this.paginationResponsive.active].toLowerCase() ? 1 : -1;
            } else {
              return a[this.paginationResponsive.active] > b[this.paginationResponsive.active] ? 1 : -1;
            }
          }));
        } else if (this.paginationResponsive.direction === 'desc') {
          this.renderedItems = [].concat(this.items.sort((a, b) => {
            if (typeof a[this.paginationResponsive.active] === 'string') {
              return a[this.paginationResponsive.active].toLowerCase() > b[this.paginationResponsive.active].toLowerCase() ? -1 : 1;
            } else {
              return a[this.paginationResponsive.active] > b[this.paginationResponsive.active] ? -1 : 1;
            }
          }));
        }
      }
    }
  }

  toggleSortOrder(): void {
    if (this.configuration.pageable) {
      // Resets the pages to 1 on back-end pagination
      this.paginationResponsive.pageIndex = 0;
    }
    if (!this.paginationResponsive.direction || this.paginationResponsive.direction === 'none') {
      this.paginationResponsive.direction = 'asc';
    } else {
      if (this.paginationResponsive.direction === 'asc') {
        this.paginationResponsive.direction = 'desc';
      } else {
        this.paginationResponsive.direction = 'asc';
      }
    }

    // this.updateSorting();
  }

  public emitItem(item: any): void {
    if (this.clickRow) {
      this.clickEmit.emit(item);
    }

  }

  remove(item: any, index: number): void {
    const itemEmitted: DeleteItem = { item, index };
    this.deleteEmit.emit(itemEmitted);
  }

  public setData(e: any): void {

  }

  /**
   * Control de excepciones a la hora de mostrar botones de acción para un registro.
   */
  public decideShowButton(funcion: ButtonTableConfig, item: any): boolean {

    switch(true) {

      // Si el botón no es ningun caso controlado se muestra por defecto
      default : {
        return true;
      }
    }
  }

  /**
   *Actualiza ordenación de tarjetas.
   *
   */
  setSort() {
    this.updateSorting();
    this.activeFieldChanged();
    this.showOrderSelection = false;
  }

}
