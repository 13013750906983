import { LoginService } from './../../../services/login.service';
import { MensajeService } from './../../../../shared/services/mensaje.service';
import { UserRequestGet } from './../../../models/users.model';
import { AlertService } from './../../../../shared/services/alert.service';
import { InvoicingUserService } from './../../../services/invoicing-user.service';
import { REQUIRED_MESSAGE, ACEPTAR, CANCELAR } from './../../../../app.constants';
import { SearchService } from './../../../services/search.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { InputField } from 'src/app/shared/shared.module';
import { debounceTime, map } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-registry-confirm-internal',
  templateUrl: './user-registry-confirm-internal.component.html',
  styleUrls: ['./user-registry-confirm-internal.component.scss']
})
export class UserRegistryConfirmInternalComponent implements OnInit, OnDestroy {

  @Input() mobile: boolean = false;

  private userId: string;
  private userRequest: UserRequestGet;
  user: Usuario;

  fGroup: FormGroup;
  private sub: Subscription = new Subscription;
  inputs = new Array<InputField>();

  companies: {id, name: string}[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private location: Location,
    private alertService: AlertService,
    private searchService: SearchService,
    private mensajeService: MensajeService,
    private loginService: LoginService,
    private invoicingUserService: InvoicingUserService) { }

  ngOnInit(): void {
    this.setForm();

    // Cargar datos sesión
    this.user = this.loginService.getUser();

    // CARGAR ID DE RUTA
    const sub = this.route.params.subscribe(
      params => {
        this.userId = params['userId'];
        this.getUserRequest();
      },
      err => {
        this.alertService.showDetailError('Error', 'No se encontró ninguna solicitud');
        this.router.navigateByUrl('/home/users/requests/pending');
      }
    );

    this.sub.add(sub);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private getUserRequest(): void {
    const sub = this.invoicingUserService.getUserRegistryRequest(this.userId)
      .subscribe(
        res => {
          if (res && res.values && res.values.length > 0) {
            this.userRequest = new UserRequestGet(res.values[0]);
            this.loadForm();
          } else {
            this.alertService.showDetailError('Error', 'No se encontró ninguna solicitud');
            this.router.navigateByUrl('/home/users/requests/pending');
          }
        },
        err => {
          this.alertService.showDetailError('Error', 'No se pudo obtener la información');
          this.router.navigateByUrl('/home/users/requests/pending');
        }
      )
    this.sub.add(sub);
  }

  acceptConfirmation(): void {
    const messageTitle = `¿Seguro que desea aceptar la solicitud?`;
    let username: string = '';
    if (this.userRequest.firstname) username += ' ' + this.userRequest.firstname;
    if (this.userRequest.lastname) username += ' ' + this.userRequest.lastname;
    if (this.userRequest.lastname2) username += ' ' + this.userRequest.lastname2;

    const messageBody = `Se va a proceder al alta del usuario ${username}. A continuación asignará los permisos.`

    this.mensajeService.mostrarMensaje(null, messageTitle, ACEPTAR, CANCELAR, messageBody);

    const confirm = this.mensajeService.acceptEmit.subscribe(
      res => {
        this.accept();
      });
    this.sub.add(confirm);
  }

  private accept(): void {
    this.router.navigateByUrl('/home/users/new/' + this.userRequest.id);
  }

  rejectConfirmation(): void {
    const messageTitle = `¿Seguro que desea rechazar la solicitud?`;
    const messageBody = 'Se va a proceder al rechazo de la solicitud de alta del usuario '
      + (this.userRequest.firstname || '')
      + ' ' + (this.userRequest.lastname  || '')
      + ' ' + (this.userRequest.lastname2 || '');
    this.mensajeService.mostrarMensaje(null, messageTitle, ACEPTAR, CANCELAR, messageBody);

    const confirm = this.mensajeService.acceptEmit.subscribe(
      res => {
        this.reject();
      });
    this.sub.add(confirm);
  }

  private reject(): void {
    const sub = this.invoicingUserService.rejectUserRegistryRequest(this.userRequest.id.toString())
      .subscribe(res => {
          if (res.status && res.status === 'OK') {
            this.alertService.showDetailWarning('Solicitud denegada', res.error_message);
            this.router.navigateByUrl('/home/users');
          } else {
            this.alertService.showDetailError('Error', res.error_message);
          }
        });
    this.sub.add(sub);
  }

  /**
   * Configuración de formulario.
   */
  private setForm(): void {

    this.fGroup = this.fb.group({
      firstname:['', [Validators.required]],
      lastname: ['', [Validators.required]],
      lastname2: ['',],
      companies: [[], [Validators.required]],
      user_email: ['', [Validators.required]],
      contact_phone: ['', [Validators.required]],
      company: ['', [Validators.required]],
      job_position_in_company: ['', [Validators.required]],
    })

    // Configuración de inputfields
    this.inputs.push(
      {
        key: 'firstname',
        type: 'text',
        label: 'Nombre',
        controlValue: '',
        layout: 'col-12',
        readonly: true,
        validatorMessages: [ { key: 'required', value: REQUIRED_MESSAGE }]
      },
      {
        key: 'lastname',
        type: 'text',
        label: 'Primer apellido',
        controlValue: '',
        layout: 'col-12 col-sm-6',
        readonly: true,
        validatorMessages: [ { key: 'required', value: REQUIRED_MESSAGE }]
      },
      {
        key: 'lastname2',
        type: 'text',
        label: 'Segundo apellido',
        controlValue: '',
        layout: 'col-12 col-sm-6',
        readonly: true,
      },
      {
        key: 'user_email',
        type: 'text',
        label: 'Correo electrónico',
        controlValue: '',
        layout: 'col-12 col-sm-6',
        readonly: true,
        validatorMessages: [ { key: 'required', value: REQUIRED_MESSAGE }]
      },
      {
        key: 'contact_phone',
        type: 'text',
        label: 'Teléfono',
        controlValue: '',
        layout: 'col-12 col-sm-6',
        readonly: true,
        validatorMessages: [ { key: 'required', value: REQUIRED_MESSAGE }]
      },
      {
        key: 'company',
        type: 'autocomplete-chip',
        label: 'CIF',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        controlValue: '',
        readonly: true,
        validatorMessages: [ { key: 'required', value: REQUIRED_MESSAGE }],
        layout: 'col-12'
      },
      {
        key: 'job_position_in_company',
        type: 'text',
        label: 'Puesto en su empresa',
        placeholder: '',
        controlValue: '',
        readonly: true,
        layout: 'col-12',
        validatorMessages: [ { key: 'required', value: REQUIRED_MESSAGE }]
      }
    );
  }

  loadForm(): void {
    this.fGroup.get('firstname').setValue(this.userRequest.firstname);
    this.fGroup.get('lastname').setValue(this.userRequest.lastname);
    this.fGroup.get('lastname2').setValue(this.userRequest.lastname2);
    this.fGroup.get('user_email').setValue(this.userRequest.email);
    this.fGroup.get('contact_phone').setValue(this.userRequest.contact_phone);

    this.fGroup.get('company').setValue([{
      id: this.userRequest.foundbyvat_company_id,
      name: this.userRequest.foundbyvat_company_name,
      vatnumber: this.userRequest.company_vatnumber,
      description: `${this.userRequest.company_vatnumber} | ${this.userRequest.foundbyvat_company_name}`
    }]);
    this.fGroup.get('job_position_in_company').setValue(this.userRequest.job_position_in_company);
  }

  back() {
    this.location.back();
  }

}
