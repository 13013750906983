<mat-form-field [ngClass]="layout" [formGroup]="formGroup">
  <input matInput placeholder="{{label}}" [matAutocomplete]="auto" [formControlName]="selectControlName">
  <span matSuffix *ngIf="!formGroup.controls[selectControlName].disabled" (click)="clearFilter()"
    class="p-2 pb-4">x</span>
  <mat-autocomplete [displayWith]="showDisplay" #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      <span>{{option[bindLabel] || option}}</span>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngFor="let message of validatorMessages">
    <ng-container
      *ngIf="formGroup.controls[selectControlName].errors && formGroup.controls[selectControlName].errors[message.key]">
      {{message.value}}
    </ng-container>
  </mat-error>
</mat-form-field>
