import { CustomSnackbarComponent, CustomSnackbarData } from './../components/custom-snackbar/custom-snackbar.component';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class AlertService {

  duration = 7 * 1000;

  constructor(private snackbar: MatSnackBar) { }

  private getSnackBarConfigDefaultInstance(config: MatSnackBarConfig<any>, message?: string): any {
    const defaultConfig = new MatSnackBarConfig();
    defaultConfig.verticalPosition = 'bottom';
    defaultConfig.duration = this.showTimeEstimation(message);
    return Object.assign({}, defaultConfig, config);
  }


  public showError(message: string, action?: string, config?: MatSnackBarConfig): void {
    this.showSnack(message, action, 'snack-bar-error', config);
  }

  public showWarning(message: string, action?: string, config?: MatSnackBarConfig): void {
    this.showSnack(message, action, 'snack-bar-warn', config);
  }

  public showSuccess(message: string, action?: string, config?: MatSnackBarConfig): void {
    this.showSnack(message, action, 'snack-bar-success', config);
  }

  public showSnack(message: string, action?: string, panelClass?: string | Array<string>, config?: MatSnackBarConfig): void {

    config = this.getSnackBarConfigDefaultInstance(config, message);

    if (!action) {
      action = 'Cerrar';
    }

    if (panelClass !== null && panelClass !== undefined) {
      config.panelClass = panelClass;
    }

    this.snackbar.open(message, action, config);
  }

  public showLoadingSnack(message: string, action?: string, panelClass?: string | Array<string>, config?: MatSnackBarConfig): void {

    config = this.getSnackBarConfigDefaultInstance(config, message);

    if (!action) {
      action = 'Cerrar';
    }

    if (panelClass !== null && panelClass !== undefined) {
      config.panelClass = panelClass;
    }
    config.duration = 60 * 1000;

    this.snackbar.open(message, action, config);
  }

  /**
   * Retorna los segundos de exposición del mensaje según la cantidad de texto.
   * Como mínimo siempre serán 5 segundos.
   */
  private showTimeEstimation(text: string): number {
    let miliseg = 0;
    let palabras = 0;
    if (text !== undefined) {
      palabras = text.split(' ').length;
      if (palabras > 0) {
        miliseg = Math.floor(palabras / 2) * 1000;
      }
    }

    if (miliseg < this.duration) {
      miliseg = this.duration;
    }
    return miliseg;
  }

  public closeSnack(): void {
    this.snackbar.dismiss();
  }

  showDetailSuccess(title: string, message: string) {
    this.openSnackBar(this.autoConfigCustomSnackbar(title, message), 'snack-bar-success');
  }

  showDetailWarning(title: string, message: string) {
    this.openSnackBar(this.autoConfigCustomSnackbar(title, message), 'snack-bar-warn');
  }

  showDetailError(title: string, message: string) {
    this.openSnackBar(this.autoConfigCustomSnackbar(title, message), 'snack-bar-error');
  }

  showDetailInfo(title: string, message: string) {
    this.openSnackBar(this.autoConfigCustomSnackbar(title, message), 'snack-bar-info');
  }

  private autoConfigCustomSnackbar(title: string, message: string): CustomSnackbarData {
    return {
      title: title,
      message: message,
      duration: this.showTimeEstimation(title + ' ' + message),
    } as CustomSnackbarData;
  }

  private openSnackBar(config: CustomSnackbarData, panelClass: string) {
    config.class = panelClass;
    this.snackbar.openFromComponent(CustomSnackbarComponent, {
      data: config,
      panelClass: panelClass,
      duration: config.duration
    });
  }
}
