import { Component, OnInit, Input, ViewChild, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InputField, ValidatorMessages } from '../../autoform/input-fields/input-fields';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-multi-selectv2',
  templateUrl: './multi-selectv2.component.html',
  styleUrls: ['./multi-selectv2.component.scss']
})
export class MultiSelectv2Component implements OnInit, OnChanges {

  @Input() formGroup: FormGroup;
  @Input() label: string;
  @Input() bindLabel: string;
  @Input() items: Array<any> = [];
  @Input() layout = 'w-100';
  @Input() selectControl: FormControl;
  @Input() selectControlName: string;
  @Input() validatorMessages: Array<ValidatorMessages>;

  @ViewChild(MatAutocompleteTrigger, { static: true }) trigger: MatAutocompleteTrigger;

  filteredOptions: Observable<any[]>;
  selectedOptions: any[] = new Array<any>();

  constructor() { }


  ngOnInit(): void {
    this.filteredOptions = this.selectControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const items: SimpleChange = changes.items;

    if (items && items.currentValue) {
      if (!this.selectControl.value) { this.selectControl.setValue(null); }
    }
  }

  private _filter(value: any): string[] {

    let filterValue = (value && value[this.bindLabel] && (value[this.bindLabel] as string).toLowerCase())
      || value && typeof value === 'string' && value.toLowerCase() || value || '';

    if (value === '') {
      return this.items;
    } else {
      if (!(value instanceof Array)) {

        if (typeof filterValue === 'string') {
          const splittedValue = filterValue.split(',');
          filterValue = splittedValue[splittedValue.length - 1].trim();
        }

        return this.items.filter(option => {
          const optionString = option[this.bindLabel] || option;
          return optionString.toLowerCase().includes(filterValue);
        });

      } else {
        // If we had an Array, the filter is in fact a list of the selected items names,
        // so we returs all items, alowing the user to select or deselect any of them
        return this.items;
      }
    }
  }

  clearFilter(): void {
    this.selectControl.setValue('');
    this.selectedOptions = new Array<any>();
  }

  //  returns the arrow function that resolves the display element of the selected object
  showDisplay = (option) => {
    if (option instanceof Array) {
      const stringList = new Array<string>();
      option.forEach(element => {
        stringList.push(this.getBindLabelOrValue(element));
      }, stringList);
      return stringList.join(', ');
    } else {

      return option && option[this.bindLabel] || option || '';
    }
  }

  findSelectedIndex(option): number {
    return this.selectedOptions.findIndex(value => this.getBindLabelOrValue(value) === this.getBindLabelOrValue(option));
  }

  getBindLabelOrValue(option): any {
    return (option && option[this.bindLabel]) || option || '';
  }

  toggleSelection(option): void {
    const i = this.findSelectedIndex(option);
    if (i === -1) {
      this.selectedOptions.push(option);
    } else {
      this.selectedOptions.splice(i, 1);
    }

    this.selectControl.setValue(this.selectedOptions);
  }

}
