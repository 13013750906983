import { UserSettingsContainerComponent } from './core/components/authentication/settings/user-settings-container/user-settings-container.component';
import { UsersRegistryRequestsContainerComponent } from './core/components/users/users-registry-requests-container/users-registry-requests-container.component';
import { AuthRouteGuard } from './core/authentication/auth-route.guard';
import { UsersManageContainerComponent } from './core/components/users/users-manage-container/users-manage-container.component';
import { InvoiceDetailComponent } from './core/components/invoices/invoice-detail/invoice-detail.component';
import { InvoicesListContainerComponent } from './core/components/invoices/invoices-list-container/invoices-list-container.component';
import { InvoicesFilterInternalComponent } from './core/components/invoices/invoices-filter-internal/invoices-filter-internal.component';
import {
  PANTALLA_DETALLE_FACTURA,
  PANTALLA_HOME,
  PANTALLA_LOGIN,
} from './app.constants';
import { LoginRouteGuard } from './core/authentication/login-route-guard.service';
import { SignupConfirmationComponent } from './core/components/authentication/signup-confirmation/signup-confirmation.component';
import { RecoverResetComponent } from './core/components/authentication/recover-reset/recover-reset.component';
import { WelcomeComponent } from './core/components/welcome/welcome.component';
import { RecoverComponent } from './core/components/authentication/recover/recover.component';
import { LoginContainerComponent } from './core/components/authentication/login-container/login-container.component';
import { HomeComponent } from './core/components/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserRegistryConfirmContainerComponent } from './core/components/users/user-registry-confirm-container/user-registry-confirm-container.component';
import { UserRegistryDetailContainerComponent } from './core/components/users/user-registry-detail-container/user-registry-detail-container.component';
import { UserSettingsComponent } from './core/components/authentication/settings/user-settings/user-settings.component';
import { InvoicesCreateNewComponent } from './core/components/invoices/invoices-create-new/invoices-create-new.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'login',
    component: WelcomeComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LoginContainerComponent,
        data: { site: PANTALLA_LOGIN },
      },
      {
        path: 'recover',
        component: RecoverComponent,
      },
      {
        path: 'set-password/:token',
        component: RecoverResetComponent,
      },
      {
        path: 'signup/:token',
        component: SignupConfirmationComponent,
      },
    ],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [LoginRouteGuard],
    data: { site: PANTALLA_HOME },
    children: [
      {
        path: 'invoices',
        component: InvoicesListContainerComponent,
      },
      {
        path: 'invoices/new',
        component: InvoicesCreateNewComponent,
      },
      {
        path: 'invoices/detail/:id',
        component: InvoiceDetailComponent,
        data: { site: PANTALLA_DETALLE_FACTURA },
      },
      {
        path: 'invoices/edit/:id',
        component: InvoicesCreateNewComponent,
      },
      {
        path: 'users',
        component: UsersManageContainerComponent,
        canActivate: [AuthRouteGuard],
        data: { site: 'PGU' },
      },
      {
        path: 'users/new',
        component: UserRegistryDetailContainerComponent,
        canActivate: [AuthRouteGuard],
        data: { site: 'PGU' },
      },
      {
        path: 'users/new/:id',
        component: UserRegistryDetailContainerComponent,
        canActivate: [AuthRouteGuard],
        data: { site: 'PGU' },
      },
      {
        path: 'users/detail/:id',
        component: UserRegistryDetailContainerComponent,
        canActivate: [AuthRouteGuard],
        data: { site: 'PGU' },
      },
      {
        path: 'users/requests/pending',
        component: UsersRegistryRequestsContainerComponent,
        canActivate: [AuthRouteGuard],
        data: { site: 'PSP' },
      },
      {
        path: 'users/requests/confirm/:userId',
        component: UserRegistryConfirmContainerComponent,
        canActivate: [AuthRouteGuard],
        data: { site: 'PCS' },
      },
      {
        path: 'settings/profile',
        component: UserSettingsContainerComponent,
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
