import { Order } from './order.model';

export class InvoceableCompanySearchCriteria {
  order: Order[];
  offset: number;
  limit: number;
  id: number;
  ids: number[];
  only_invoiceables_by_company_id: number;
  smart_text: string;

  constructor(data?: any) {
    this.order = (data && data.order) || [];
    this.offset = (data && data.offset) || 0;
    this.limit = (data && data.limit) || 0;
    this.id = (data && data.id) || 0;
    this.ids = (data && data.ids) || [];
    this.only_invoiceables_by_company_id =
      (data && data.only_invoiceables_by_company_id) || null;
    this.smart_text = (data && data.smart_text) || '';
  }
}
