<!-- CONTENIDO DE CARDVIEW: <br> Pagination Responsive: {{ paginationResponsive | json}} <br>-->
<!-- <div style="position: fixed; top: 500px; left: 0; width:100%; background-color: rgb(255, 149, 149); z-index: 99">
  Pagination input(): {{ pagination | json }} <BR>
    Total items: {{ totalItems }} <BR>
    Stored: {{ stored.length }}
</div> -->

<div #scrollContainer (wheel)="onScroll($event)" (touchmove)="onScroll($event)">
    <ng-container *ngIf="stored && stored.length > 0 && !isLoading">
        <div class="row" *ngIf="configuration.sorting">
            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="noBorder">
                <div class="col-auto">

                    <button mat-button id="cardSortButton" (click)="showOrderSelection = true" class="order align-icon-text"><mat-icon>import_export</mat-icon>{{ 'Ordenar' | translate }}</button>

                    <div #orderSelection *ngIf="showOrderSelection" class="arpada-mobile-fullscreen-modal">
                        <div class="arpada-fullscreen-modal-toolbar">
                            <div class="arpada-fs-modal-toolbar-text">
                                <a class="primary" (click)="showOrderSelection = false">
                                    <h1 class="align-icon-text" style="font-weight: 500; font-size: 20px;">
                                        <mat-icon class="material-icons material-icons-outlined" style="font-size: 20px; padding-top: 3px;">arrow_back_ios_new</mat-icon>
                                        {{ 'Ordenar' | translate }}
                                    </h1>
                                </a>
                            </div>
                            <div class="arpada-fs-modal-toolbar-actions">
                                <button mat-stroked-button color="primary" (click)="setSort()">Aplicar</button>
                            </div>
                        </div>

                        <div class="arpada-fs-modal-body">
                            <div class="body-title">
                                <button aria-label="Acendente o Descendente" mat-button type="button" class="arrow-button align-icon-text" (click)="toggleSortOrder()" aria-label="Botón ordenación">
                  <span *ngIf="!paginationResponsive.direction || paginationResponsive.direction === 'none'">{{'Ordenar' | translate}}<mat-icon>import_export</mat-icon></span>
                  <span *ngIf="paginationResponsive.direction == 'asc'">{{'Ascendente' | translate}}<mat-icon>north</mat-icon></span>
                  <span *ngIf="paginationResponsive.direction == 'desc'">{{'Descendente' | translate}}<mat-icon>south</mat-icon></span>

                  <!-- <em *ngIf="!paginationResponsive.direction || paginationResponsive.direction === 'none'"
                    style="color:rgb(179, 179, 179);" class="fa fa-arrow-up"></em>
                  <em *ngIf="paginationResponsive.direction == 'asc'" class="fa fa-arrow-up">Ascendente</em>
                  <em *ngIf="paginationResponsive.direction == 'desc'" class="fa fa-arrow-down">Descendente</em> -->
              </button>
                            </div>
                            <div class="body-content">
                                <mat-radio-group class="radio-group" [(ngModel)]="paginationResponsive.active">
                                    <mat-radio-button class="radio-button" color="primary" *ngFor="let column of columns" [value]="column.key">{{ column.title }}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>

                    <!-- <mat-form-field>
           <mat-select [(ngModel)]="paginationResponsive.active" (selectionChange)="activeFieldChanged()"
              placeholder="Ordenación">
              <ng-container *ngFor="let column of columns">
                <ng-container *ngIf="column.key !== 'buttons' && column.key !== 'checkbox'">
                  <mat-option [value]="column.key">
                    {{column.title}}
                  </mat-option>
                </ng-container>
              </ng-container>
            </mat-select>
          </mat-form-field> -->
                </div>
                <!-- <div class="col-auto px-0">
          <button aria-label="Acendente o Descendente" mat-button type="button" class="arrow-button"
            (click)="toggleSortOrder()" aria-label="Botón ordenación">
            <em *ngIf="!paginationResponsive.direction || paginationResponsive.direction === 'none'"
              style="color:rgb(179, 179, 179);" class="fa fa-arrow-up"></em>
            <em *ngIf="paginationResponsive.direction == 'asc'" class="fa fa-arrow-up"></em>
            <em *ngIf="paginationResponsive.direction == 'desc'" class="fa fa-arrow-down"></em>
          </button>
        </div> -->
            </mat-button-toggle-group>

        </div>

        <div class="position-relative">
            <ng-container *ngFor="let item of stored; let i = index">
                <mat-card id="cardlist{{ item.id }}" (click)="emitItem(item)" class="mb-3 p-1 mat-elevation-z" [ngClass]="{'rowCursor': clickRow}">

                    <!-- Card header/upper row -->
                    <mat-card-header>

                        <!-- card header! -->

                        <!-- <mat-card-title>{{ keyCard }} {{i+1}}


          </mat-card-title> -->

                        <div *ngIf="buttons">
                            <!-- <ng-container *ngIf="buttons.cellTemplate">
              <ng-container [ngTemplateOutlet]="buttons.cellTemplate" [ngTemplateOutletContext]="{ $implicit: item }">
              </ng-container>
            </ng-container> -->

                            <!-- <ng-container *ngFor="let funcion of botones; let icolumn = index">
              <app-generic-icon class="float-right" [cssClass]="'ml-2 table--size icon--hover'"
                (click)="funcion.evento.emit(item)" [tooltip]="funcion.detalleToolTip" [icon]="funcion.icon">
              </app-generic-icon>
            </ng-container> -->
                        </div>
                    </mat-card-header>

                    <mat-card-content style="padding: 0.5rem; padding-bottom: 0; margin-bottom: -15px;">
                        <span *ngIf="buttons" class="float-right inline-block outlineNone">
            <mat-button-toggle-group class="outlineNone" name="fontStyle" aria-label="Font Style">
              <ng-container *ngFor="let funcion of botones; let icolumn = index">
                <mat-button-toggle class="outlineNone" *ngIf="decideShowButton(funcion, item)" (click)="funcion.evento.emit(item)"
                [aria-label]="funcion.detalleToolTip"
                [matTooltip]="funcion.detalleToolTip || 'Botón acción'">
                  <i class=" fa fa-sm {{funcion.icon}}" aria-hidden="true"></i>
                </mat-button-toggle>
              </ng-container>
            </mat-button-toggle-group>
          </span>

                        <details-content [hidenFields]="hidenColumns" [labels]="columns" [item]="item" [numRows]="items.length" [selection]="selection" [discriminator]="discriminator" [fullCardTemplate]="fullCardTemplate" (detailsEmit)="emitirDetalle($event)" (selectedEmit)="selectedEmit.emit($event)"></details-content>

                        <!-- EXPANSIÓN DE TARJETA -->
                        <!-- <button aria-label="expansion elementos asociados" class="outlineNone" mat-icon-button *ngIf="item.expandable && allowExpand"
          (click)="expanded = !expanded; controlArrow(item);">
          <i [ngClass]="(expandedElement === item)?'fa fa-angle-up':'fa fa-angle-down'"
          aria-hidden="true"></i>
          </button> -->

                    </mat-card-content>
                </mat-card>
            </ng-container>


            <!-- <div id="table-spinner" class="loading-shade" *ngIf="isLoading">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </div> -->
        </div>

    </ng-container>

    <!-- *ngIf="((stored && sum < stored.length && stored.length > 0) || configuration.pageable && totalItems > pageSize && stored.length < totalItems) && !isLoading" -->
    <ng-container *ngIf="(stored && stored.length > 0 && stored.length < totalItems) && !isLoading">
        <div class="text-center py-2 mt-2">
            <button mat-flat-button color="primary" (click)="appendRenderedItems()" aria-label="Cargar más" matTooltip="Cargar más">{{'Cargar más'| translate}}</button>
        </div>
    </ng-container>
</div>

<ng-template #loadingRes [ngIf]="isLoading">
    <mat-card class="d-flex align-items-center justify-content-center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>
</ng-template>

<ng-template #noResultsRes [ngIf]="!isLoading && stored && stored.length <= 0 && totalItems === 0">
    <mat-card class="text-center py-5">
        <h2 id="resNoResults">{{'Sin elementos'| translate}}</h2>
    </mat-card>
</ng-template>
