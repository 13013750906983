
export class UserNewGet {
  user_email: string;
  firstname: string;
  lastname: string;
  lastname2: string;
  contact_phone: string;
  user_roles: string[];
  job_position_in_company: string;
  user_type: 'internal' | 'external' | null;
  user_rel_companies: Array<UserRelCompany>;
  status: string;
  user_permissions: any;
  actions_permission: any;
  company_id: number;
  company_vat_number: string;

  constructor(data?: any) {
    this.user_email = data && (data.user_email || data.email);
    this.firstname = data && data.firstname;
    this.lastname = data && data.lastname;
    this.lastname2 = data && data.lastname2;
    this.contact_phone = data && data.contact_phone;
    this.user_roles = data && data.user_roles;
    this.user_type = data && data.user_type;
    this.user_rel_companies = data && data.user_rel_companies;
    this.job_position_in_company = data && data.job_position_in_company;
    this.status = data && data.status;
    this.user_permissions = data && data.user_permissions;
    this.actions_permission = data && data.actions_permission;
    this.company_id = data && (data.company_id || data.foundbyvat_company_id);
    this.company_vat_number = data && (data.company_vatnumber || data.company_vat_number);
  }
}


export class UserRelCompany {
  id: number;
  user_email: string;
  company_id: number;
  company_name: string;
  company_vat_number: string;
  firstname: string;
  lastname: string;
  lastname2: string;
  fullname: string;
  contact_phone: string;
  job_position_in_company: string;
  user_roles: string[];
  user_permissions: any;
  user_type: 'internal' | 'external' | null;
  user_status: string;
  status: string;

  constructor(data?: any) {
    this.id = data && data.id;
    this.user_email = data && data.user_email;
    this.company_id = data && data.company_id;
    this.company_name = data && data.company_name;
    this.company_vat_number = data && data.company_vat_number;
    this.firstname = data && data.firstname;
    this.lastname = data && data.lastname;
    this.lastname2 = data && data.lastname2;
    this.fullname = data && data.fullname;
    this.contact_phone = data && data.contact_phone;
    this.job_position_in_company = data && data.job_position_in_company;
    this.user_roles = data && data.user_roles;
    this.user_permissions = data && data.user_permissions;
    this.user_type = data && data.user_type;
    this.user_status = data && data.user_status;
  }
}

