import { Component, Input, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { InputField } from 'src/app/shared/components/autoform/input-fields/input-fields';
import { AccessibilityService } from '../../../../shared/services/accessibility.service';
import { CatpchaService } from 'src/app/shared/services/catpcha.service';
import { Captcha } from '../../../../shared/models/captcha.model';
import { environment } from '../../../../../environments/environment';
import { LoginService } from '../../../services/login.service';
import { ACEPTAR, EMAIL_REGEX, REQUIRED_MESSAGE } from 'src/app/app.constants';
import { MensajeService } from 'src/app/shared/services/mensaje.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { MsalService } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public captcha: Captcha;
  public inputs: Array<InputField>;
  public isLoading = true;

  private accesible = false;
  private mapeo: Map<string, any>;

  fGroup: FormGroup;

  isLoadingMsAuth: boolean = false;
  showUnexpectedErrorView: boolean = false;
  showCanNotOpenPopupErrorView: boolean = false;
  showForbiddenAccessErrorView: boolean = false;

  showPassword = false;

  constructor(
    private accesibillityService: AccessibilityService,
    private router: Router,
    private loginService: LoginService,
    private captchaService: CatpchaService,
    private fb: FormBuilder,
    private mensajeService: MensajeService,
    private localStorageService: LocalStorageService,
    private msalService: MsalService,
  ) {

    this.fGroup = fb.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      password: ['', Validators.required]
    })

  }

  ngOnInit(): void {
    /* LogOut if user is entering a login allways */

    this.inputs = [];

    this.configurarInputs();

    this.accesible = this.accesibillityService.getAccessibillityConfig();

    if (this.localStorageService.getItem('accept-cookies') !== 'true') {
      this.mensajeService.mostrarMensajeCookie();
    }

    this.msalService.instance = new PublicClientApplication({
      auth: {
        clientId: environment.msal_client_id,
        authority: environment.msal_authority_url,
      }
    });

  }

  /**
   * Se obtiene configuración de captcha.
   */
  private initCaptcha(): void {

    this.isLoading = false;
  }

  public submit(): void {
    const datos = JSON.parse(JSON.stringify(this.fGroup.value));

    const datosAEnviar = { user: datos.email, password: datos.password, app_key: '', lang: 'es_ES'};
    const datosRespuesta = this.loginService.login(datosAEnviar);

  }

  private configurarInputs(): void {
    this.inputs.push(
      {
      key: 'email',
      type: 'text',
      label: 'Correo electrónico',
      controlValue: '',
      layout: 'col-12',
      hideRequired: true,
      focusOut: (value: string) => {
        this.fGroup.controls.email.setValue(value.trim().toLowerCase());
      },
      validatorMessages: [
        {key: 'required', value: REQUIRED_MESSAGE},
        {key: 'pattern', value: 'Emáil inválido'}]
    },
    {
      key: 'password',
      type: 'password',
      label: 'Contraseña',
      controlValue: '',
      layout: 'col-12',
      hideRequired: true,
      validatorMessages: [
        {key: 'required', value: REQUIRED_MESSAGE}],
      associatedButton: {
        icon: 'visibility',
        arialabel: 'Mostrar/ocultar',
        function: () => {
          this.inputs[1].type = this.inputs[1].type === 'text' ? 'password' : 'text';
          this.inputs[1].associatedButton.icon = this.inputs[1].associatedButton.icon === 'visibility' ? 'visibility_off': 'visibility';
        }
      }
    });
  }

  private hideErrors(){
    this.showUnexpectedErrorView = false;
    this.showCanNotOpenPopupErrorView = false;
    this.showForbiddenAccessErrorView = false;
  }

  private loadingAndDisableLogins(){
    this.isLoadingMsAuth = true;
  }

  private notLoadingAndEnableLogins(){
    this.isLoadingMsAuth = false;
  }

  private showUnexpectedError(){
    this.showUnexpectedErrorView = true;
  }

  private showCanNotOpenPopupError(){
    this.showCanNotOpenPopupErrorView = true;
  }

  private showForbiddenAccessError(){
    this.showForbiddenAccessErrorView = true;
  }

  private processAcquireTokenResponse(response){
    if(response && response.accessToken){
      const msAccessToken = response.accessToken
      if (response.account && response.account.username){
        console.log('Account username: ' + response.account.username);
      }
      this.loginService.getLoginInfoFromMSToken(msAccessToken).subscribe((responseData)=> {
        this.notLoadingAndEnableLogins();
      },(error) => {
        this.notLoadingAndEnableLogins();
        this.showForbiddenAccessError();
      });
    } else {
      console.error("No se encontro el accessToken en la response de Msal");
      this.showUnexpectedError();
      this.notLoadingAndEnableLogins();
    }
  }

  // La version actual ya devuelve el accessToken, pero en futuras hay que llamar al acquireToken
  private msalAcquireToken(response): void {
    let userAccount = null;
    let username = null
    if(response && response.account && response.account.username){
      username = response.account.username
      userAccount = this.msalService.instance.getAccountByUsername(username);
    } else {
      console.error("Not found account or username in response")
    }
    if(userAccount){
      this.msalService.instance.setActiveAccount(userAccount);
      var tokenRequest = {
          scopes: ["user.read"]
      };
      this.msalService.acquireTokenSilent(tokenRequest).subscribe(
        response => {
            this.processAcquireTokenResponse(response)
        },
        err => {
          // could also check if err instance of InteractionRequiredAuthError if you can import the class.
          if (err.name === "InteractionRequiredAuthError") {
            return this.msalService.acquireTokenPopup(tokenRequest).subscribe(
              response => {
                  this.processAcquireTokenResponse(response);
              },
              err => {
                  this.showUnexpectedError();
                  this.notLoadingAndEnableLogins();
                  console.error("Error obteniendo el token con Interacción");
                  console.error(err)
              }
            );
          } else {
            this.showUnexpectedError();
            this.notLoadingAndEnableLogins();
          }
          console.error("Error obteniendo token");
          console.error(err);
        }
      );
    } else {
      console.error('Not found account for username: ' + username);
      console.error(userAccount);
      this.showUnexpectedError();
      this.notLoadingAndEnableLogins();
    }
  }

  public msalLoginPopup(): void {
    this.loadingAndDisableLogins();
    this.hideErrors();
    var loginRequest = {
	    scopes: ["user.read"], // optional Array<string>
	    forceRefresh: true,
	    prompt: 'select_account',
    };
    this.msalService.loginPopup(loginRequest).subscribe(
      response => {
	      this.processAcquireTokenResponse(response);
      }, err => {
        if (err.name && err.name == 'BrowserAuthError' && err.errorCode && err.errorCode.includes('popup_window_error')){
          console.error(err); // Error no se puede abrir popup
          this.showCanNotOpenPopupError();
        } else if (err.name && err.name == 'BrowserAuthError' && err.errorCode && err.errorCode.includes('user_cancelled')){
          console.error("El usuario cancelo la autenticacion");
	        console.error(err);
	      } else {
	        console.error("Sucedio un error");
	        console.error(err);
          this.showUnexpectedError();
	      }
        console.error(JSON.stringify(err))
        this.notLoadingAndEnableLogins();
      }
    );
  }
}
