import { AlertService } from './../../shared/services/alert.service';
import { InvoiceGet } from 'src/app/core/models/invoices/invoice-get';
import { InvoicesCancelConfirmModalComponent } from './../components/invoices/invoices-cancel-confirm-modal/invoices-cancel-confirm-modal.component';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InvoiceNewDto } from '../models/invoices/invoice-new';
import { BaseComunicationService } from './base-comunication.service';
import { InvoiceCancelDto } from '../models/invoices/invoice-cancel';
import { InvoiceUpdateDto } from '../models/invoices/invoice-update';
import { InvoicesZip } from '../models/invoices_zip.model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceCommandsService extends BaseComunicationService {
  private urlApi = `${environment.apiInvoicingCommandsBaseUrl}`;

  @Output() cancelEmit = new EventEmitter<any>();

  constructor(public http: HttpClient,
    private alertService: AlertService,
    private dialog: MatDialog,) {
    super(http);
  }

  createInvoice(invoiceNew: InvoiceNewDto): Observable<any> {
    const url: string = `${this.urlApi}/invoice-create`;
    let data: any = new Object();
    return this.realizarPeticionPost(url, invoiceNew);
  }

  updateInvoice(invoiceUpdate: InvoiceUpdateDto): Observable<any> {
    const url: string = `${this.urlApi}/invoice-update`;
    let data: any = new Object();
    return this.realizarPeticionPost(url, invoiceUpdate);
  }



  /** Solicitud de cancelación de factura */
  cancelInvoiceConfirmation(invoice: InvoiceGet) {
    // Configura ventana modal
    const dial = this.dialog.open(InvoicesCancelConfirmModalComponent, {
      data: { invoice: invoice },
      width: '600px'
    });

    // Formulario emergente de confirmación.
    const emit = dial.componentInstance.emitValues.subscribe(response => {

      this.cancelInvoice({ id_to_cancel: invoice.id, cancelation_comment: response }).subscribe(
        data => {

          if (data.status.startsWith('INVALID')) {
            if (data.status === 'INVALID') {
              this.alertService.showDetailError(data.status, data.error_message);
            } else {
              this.alertService.showDetailWarning(data.status, data.error_message)
            }
          } else {
            this.alertService.showDetailSuccess('Factura cancelada', 'Ha cancelado la factura satisfactoriamente.');
            emit.unsubscribe();
          }

          this.cancelEmit.emit();
        }, err => this.alertService.showDetailError('Error interno', 'No se pudo cancelar la factura')
      );


    })
  }

  /**
   * Petición de cancelación de factura.
   */
  cancelInvoice(invoice: InvoiceCancelDto): Observable<any> {
    const url: string = `${this.urlApi}/invoice-cancel`;
    return this.realizarPeticionPost(url, invoice);
  }

  downloadInvoicesZip(data: InvoicesZip) {
    const url: string = `${this.urlApi}/request-generate-zip-file-of-invoices-files`;
    return this.realizarPeticionPost(url, data);
  }


}
