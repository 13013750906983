import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccessibilityService } from '../../services/accessibility.service';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit {
  // Idioma por defecto
  public activeLang = localStorage.getItem('lang') || 'es';
  public accessible = false;

  constructor(private translate: TranslateService, private accService: AccessibilityService) {
    // Seteamos el lenguaje por defecto en el translateService
    this.translate.setDefaultLang(this.activeLang);
  }

  ngOnInit(): void {
    this.accessible = this.accService.getAccessibillityConfig();
  }

  // función de cambio de lenguaje
  public cambiarLenguaje(lang): void {
    if (lang && lang.target && lang.target.value) {

      this.activeLang = lang.target.value;
      localStorage.setItem('lang', this.activeLang);
      this.translate.use(lang.target.value);
    }
  }
}
