import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'attach-input',
  templateUrl: './attach-input.component.html',
  styleUrls: ['./attach-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AttachInputComponent,
      multi: true
    }
  ],
})
export class AttachInputComponent implements OnInit, ControlValueAccessor {
  @Input() extensions: Array<string>;
  @Input() placeholder: string;
  @Input() id: string;
  htmlFileName = 'Seleccionar Archivo';
  htmlExtensions: string;
  file: File;

  @Output() fileEmitter: EventEmitter<File> = new EventEmitter<File>();

  @ViewChild('attachInput', {static: true}) attachInput: ElementRef;

  propagateChange = (_: any) => { };

  constructor(private alertService: AlertService) { }

  ngOnInit() {
    this.htmlExtensions = '';
    this.extensions.forEach((ext, index) => {
      this.extensions.length - 1 === index ? this.htmlExtensions += ('.' + ext) : this.htmlExtensions += ('.' + ext + ',');
    });
  }


  writeValue(value: null) {
    // clear file input
    this.htmlFileName = 'Seleccionar Archivo';
    this.file = null;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
  }

  onChange(event) {
    const fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      this.file = fileList[0];

      if (!this.checkExtensions(this.file.name)) {
        this.removeFileFromInput();
        this.alertService.showWarning('La extensión del archivo debe ser alguna de las siguientes: ' + this.extensions.join(', '));
      } else {
        if (!this.checkSize(this.file)) {
          this.removeFileFromInput();
          this.alertService.showWarning('El tamaño del archivo no puede superar los 10 MB.');
        } else {
          this.htmlFileName = this.file.name;
          this.fileEmitter.emit(this.file);
          this.propagateChange(this.file);
        }
      }
    }
  }

  removeFileFromInput(): void {
    this.file = undefined;
    this.htmlFileName = 'Seleccionar Archivo';
    this.attachInput.nativeElement.value = '';
    this.propagateChange(this.file);
  }

  checkExtensions(fileName: string): boolean {
    let isCorrect = false;
    this.extensions.forEach(ext => {
      if (fileName.split('.').some(value => value.toLowerCase() === ext)) { isCorrect = true; }
    });

    return isCorrect;
  }

  checkSize(file: File) {
    return file && file.size < 10 * 1024 * 1024;
  }
}
