import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-logout-icon',
  templateUrl: './logout-icon.component.html'
})
export class LogoutIconComponent implements OnInit {

  @Input() cssClass: string;
  @Input() routerLink: any;

  constructor() { }

  ngOnInit(): void {
  }

}
