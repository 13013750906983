import { InputField, ValidatorMessages } from 'src/app/shared/shared.module';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputFieldRequired'
})
export class InputFieldRequiredPipe implements PipeTransform {

  /**
   * Evalua si un input field es requerido o no.
   *
   * @param input Input field configurado.
   * @returns True si es requerido.
   */
  transform(input: InputField): boolean {
    if(!input) {
      return false;
    }
    return  input.validatorMessages?.find(v => 'required' === v.key) !== undefined;
  }

}
