import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class OAuthInterceptor implements HttpInterceptor {

    constructor(private loginService: LoginService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newReq = request;
        if (this.loginService.isLogged()) {
          newReq = newReq.clone({
            headers: request.headers
              .append('x-bearer-token', this.loginService.getToken())
              .append('x-active-company-id', this.loginService.getUser().company?.id?.toString() || '')
          });
        }
        return next.handle(newReq);
    }
}
