import { CompanySelectorComponent } from './../components/company-selector/company-selector.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { LocalStorageService } from './../../shared/services/local-storage.service';
import { Usuario } from './../../shared/models/usuario.model';
import { AlertService } from 'src/app/shared/shared.module';
import { Rol } from 'src/app/shared/models/rol.model';
import { environment } from '../../../environments/environment';
import { BaseComunicationService } from './base-comunication.service';
import { UserRelCompany } from 'src/app/shared/models/company.model';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends BaseComunicationService {
  private urlApi = `${environment.apiAuthenticationBaseUrl}/auth`;
  private urlMsAuth = `${environment.apiAuthenticationBaseUrl}/auth-microsoft`;
  public usuario: Usuario;
  private APP_KEY = environment.app_key;
  private AUTH_TOKEN = 'auth_token';

  constructor(
    public http: HttpClient,
    private alertService: AlertService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private routerService?: Router
  ) {
    super(http);
  }

  /**
   * Autenticación.
   * @param datos Credenciales
   */
  public login(datos: any) {
    const url = `${this.urlApi}/user_login`;
    datos.app_key = this.APP_KEY;
    let responseData = this.realizarPeticionPost(url, datos);
    responseData.subscribe(
      (data) => this.sessionInfo(data.session_token),
      (err) =>
        this.alertService.showDetailError(
          'Error de identificación',
          'Revise su usuario o contraseña'
        )
    );
  }

  /**
   * Datos de sesión.
   * @param token Token de sesión.
   */
  private sessionInfo(token: string): void {
    const url = `${this.urlApi}/user_session_get`;

    let datos: any = new Object();
    datos.user_session_token = token;
    datos.app_key = this.APP_KEY;
    datos.lang = 'es_ES';

    let responseData = this.realizarPeticionPost(url, datos);
    responseData.subscribe(
      (data) => {
        // Registrar token de autenticación (Sin autorizaciones aún)
        this.saveToken(data.session_info);
        // Actualizar token de autenticación con Permisos
        // según la compañia que seleccione.
        this.getUserInfo();
      },
      (err) => console.error(err)
    );
  }

  /**
   * Obtener el login de session con un token de Microsoft
   * @param ms_token Access token obtenido del OAuth de Microsoft
   */
  public getLoginInfoFromMSToken(ms_token: string): Observable<any> {
    const url = `${this.urlMsAuth}/get_ms_login_info`;

    let requestHeaders: HttpHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('x-ms-bearer-token', ms_token);
    requestHeaders = requestHeaders.set('x-app-key', this.APP_KEY);
    requestHeaders = requestHeaders.set('lang', 'es_ES');

    let responseData = this.realizarPeticionGet(url, requestHeaders=requestHeaders);
    responseData.subscribe(
      (data) => {
        this.sessionInfo(data.grarp_token);
      },
      (err) => {
        this.alertService.showDetailError(
          'Error de identificación',
          'No tiene permiso para entrar al area privada',
        );
        console.log(err);
      }
    );
    return responseData;
  }

  /**
   * Consultar Compañías vinculadas para asignar roles y permisos.
   */
  private getUserInfo() {
    const url = `${environment.apiInvoicingUsersQueryBaseUrl}/get-user-info`;
    let responseData = this.realizarPeticionGet(url);
    responseData.subscribe(
      (data) => {
        this.usuario.id = data.id;
        this.usuario.firstname = data.firstname;
        this.usuario.lastname = data.lastname;
        this.usuario.lastname2 = data.lastname2;
        this.usuario.fullname = data.fullname;

        let value = JSON.parse(
          this.localStorageService.getItem(this.AUTH_TOKEN)
        );
        value.fullname = data.fullname;
        this.localStorageService.setItem(
          this.AUTH_TOKEN,
          JSON.stringify(value)
        );

        if (data.user_rel_companies) {
          const relCompanies = (data.user_rel_companies as Array<any>).map(
            (comp) => {
              return comp;
            }
          );
          this.companySelection(relCompanies);
        }
        return data;
      },
      (err) => {
        this.alertService.showDetailError(
          'Error de identificación',
          'No se pudieron obtener los datos de usuario'
        );
      }
    );
  }

  /**
   * Si tiene varias empresas se muestra listado en ventana modal.
   * @param companies compañias vinculadas al usuario.
   */
  private companySelection(relCompanies: any[]) {
    if (relCompanies && relCompanies.length === 1) {
      this.assignCompany(relCompanies, relCompanies[0].company_id);
      this.chooseInitialPage();
    } else {
      // Configura ventana modal
      const dial = this.dialog.open(CompanySelectorComponent, {
        data: { companies: relCompanies },
        width: '500px',
      });

      // Selección de compañia en ventana modal
      const emit = dial.componentInstance.emitValues.subscribe((company) => {
        this.assignCompany(relCompanies, company.company_id);
        this.chooseInitialPage();
        emit.unsubscribe();
      });
    }
  }

  private chooseInitialPage() {
    let permises = this.getUser().permissionsInCompany;

    if (permises.invoices_view || permises.invoices_edit) {
      this.routerService.navigateByUrl('home/invoices');
    } else if (
      permises.users_view ||
      permises.user_registry_request ||
      permises.users_disable
    ) {
      this.routerService.navigateByUrl('home/users');
    } else if (permises.invoices_create) {
      this.routerService.navigateByUrl('home/invoices/new');
    } else {
      this.routerService.navigateByUrl('home');
    }
  }

  /**
   * Asigna roles y permisos de la compañía seleccionada por el usuario.
   * @param relCompanies Compañías vinculadas al usuario.
   * @param companyId Compañia seleccionada por el usuario.
   */
  public assignCompany(relCompanies: any[], companyId: number): void {
    const selectedCompany = relCompanies.filter(
      (c) => c.company_id === companyId
    )[0];

    const relcompany = new UserRelCompany();
    relcompany.id = selectedCompany.company_id;
    relcompany.userCompanyId = selectedCompany.id;
    relcompany.name = selectedCompany.company_name;
    relcompany.company_vat_number = selectedCompany.company_vat_number;
    relcompany.company_registered_name =
      selectedCompany.company_registered_name;
    relcompany.company_email = selectedCompany.company_email;
    relcompany.company_phone = selectedCompany.company_phone;

    this.usuario.company = relcompany;
    this.usuario.rolesInCompany = selectedCompany.user_roles;

    this.usuario.permissionsInCompany = selectedCompany.user_permissions;
    this.usuario.userType = selectedCompany.user_type;
    this.usuario.contact_phone = selectedCompany.contact_phone;
    this.usuario.job_position_in_company =
      selectedCompany.job_position_in_company;
    this.usuario.status = selectedCompany.status;

    this.updateUserToken();
  }

  public isLogged(): boolean {
    this.usuario = this.getUser();
    if (
      this.usuario &&
      this.usuario.token != null &&
      this.usuario.tokenExpireDate > Date.now()
    ) {
      return true;
    } else {
      return false;
    }
  }

  public getRoles(): Array<Rol> {
    if (!this.usuario) {
      return new Array<Rol>();
    } else {
      return this.usuario.roles;
    }
  }

  public getUser(): Usuario {
    let usuario = this.usuario;
    if (!usuario) {
      const value = this.localStorageService.getItem(this.AUTH_TOKEN);
      if (value !== null) {
        usuario = new Usuario(JSON.parse(value));
      }
    }
    return usuario;
  }

  saveToken(token): void {
    const expireDate = new Date().getTime() + 1000 * token.valid_time_seconds;
    this.usuario = new Usuario();
    this.usuario.username = token.username;
    this.usuario.fullname = token.fullname;
    this.usuario.email = token.email;
    this.usuario.token = token.session_token;
    this.usuario.tokenExpireDate = expireDate;

    this.localStorageService.setItem(
      this.AUTH_TOKEN,
      JSON.stringify(this.usuario)
    );
  }

  /** Actualiza token de usuario */
  updateUserToken() {
    let usuario: Usuario = this.localStorageService.getItemJson(
      this.AUTH_TOKEN
    );
    usuario.id = this.usuario.id;
    usuario.firstname = this.usuario.firstname;
    usuario.lastname = this.usuario.lastname;
    usuario.lastname2 = this.usuario.lastname2;
    usuario.status = this.usuario.status;
    usuario.job_position_in_company = this.usuario.job_position_in_company;
    usuario.contact_phone = this.usuario.contact_phone;

    usuario.company = this.usuario.company;
    usuario.permissionsInCompany = this.usuario.permissionsInCompany;
    usuario.rolesInCompany = this.usuario.rolesInCompany;
    usuario.userType = this.usuario.userType;


    this.localStorageService.setItem(this.AUTH_TOKEN, JSON.stringify(usuario));
  }

  getToken(): string {
    return this.usuario.token;
  }

  public logout(): void {
    const url = `${this.urlApi}/user_logout`;

    if (this.isLogged()) {
      let datos: any = new Object();
      datos.session_token = this.getToken();
      datos.app_key = this.APP_KEY;

      let responseData = this.realizarPeticionPost(url, datos);
      responseData.subscribe(
        (data) => {
          if (this.usuario) {
            this.usuario = null;
          }
          this.localStorageService.clear();
          this.routerService.navigateByUrl('/');
          this.alertService.showDetailSuccess(
            'Cierre de sesión',
            'Su sesión ha finalizado correctamente'
          );
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.usuario = null;
      this.routerService.navigateByUrl('/');
    }
  }

  /* Método para navegar al login en caso de que expire la sesión o logout */
  public decideRoute(logout?: boolean): void {
    this.routerService.navigate(['/login']);

    if (logout) {
      this.logout();
    }
  }

  public userChangePassword(
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string
  ): Observable<any> {
    const url: string = `${this.urlApi}/user_change_password`;
    const data: any = new Object();
    data.app_key = environment.app_key;
    data.session_token = this.getUser()?.token;
    data.current_password = currentPassword;
    data.new_password = newPassword;
    data.confirm_new_password = confirmNewPassword;

    return this.realizarPeticionPost(url, data);
  }
}
