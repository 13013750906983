import { Injectable } from '@angular/core';
/**
 * Servicio para gestión de configuraciones de accesibilidad
 *
 * @export
 * @class AccessibilityService
 */
@Injectable({
  providedIn: 'root'
})
export class AccessibilityService {

  constructor() { }
  // *** Devuelve la configuración de accesibilidad del usuario */
  public getAccessibillityConfig(): boolean {
    const access = localStorage.getItem('accesible');
    return access && access === 'true' || false;


  }

  // *** setea la configuración de accesibilidad del usuario */
  public setAccessibillityConfig(accessibility: boolean): void {
    localStorage.setItem('accesible', '' + accessibility);
  }
}
