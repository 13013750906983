<!-- Contenedor de Opciones de acceso -->
<div class="row m-3 justify-content-center">

  <mat-card class="col-12 col-lg-7 m-3 pt-0 justify-content-center">
      <mat-tab-group mat-align-tabs="center" dynamicHeight [(selectedIndex)]="selectedTab" class="arpada-fixed-tabs">
        <mat-tab role="navigation" aria-label="Login por Usuario y Contraseña">
          <ng-template mat-tab-label>
            <mat-icon>login</mat-icon>
            {{'Acceder' | translate}}
          </ng-template>
          <ng-template matTabContent>
            <app-login></app-login>
          </ng-template>
        </mat-tab>
      <mat-tab role="navigation" aria-label="Solicitar Registro">
        <ng-template mat-tab-label>
          <mat-icon>how_to_reg</mat-icon>
          {{'Registro' | translate}}
        </ng-template>
        <ng-template matTabContent>
          <app-signup (changeTab)="changeTab($event)"></app-signup>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-card>

</div>
