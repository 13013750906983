import { UtilsService } from 'src/app/shared/services/utils.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvoiceDto } from 'src/app/core/models/invoices/invoice-dto';
import { InvoiceType } from 'src/app/core/models/invoices/invoice-type.model';
import { StoredFilesService } from 'src/app/core/services/stored-files.service';
import { InvoiceSubtype } from 'src/app/core/models/invoices/invoice-subtype.model';
import { LoginService } from 'src/app/core/services/login.service';
import { Usuario } from 'src/app/shared/models/usuario.model';

@Component({
  selector: 'app-invoices-create-confirmation',
  templateUrl: './invoices-create-confirmation.component.html',
  styleUrls: ['./invoices-create-confirmation.component.scss'],
})
export class InvoicesCreateConfirmationComponent implements OnInit {
  @Input() invoiceDTO: InvoiceDto;
  @Output() confirmInvoice = new EventEmitter();
  mobile: boolean;
  @Input() subTipoFactura: InvoiceSubtype;
  @Input() tipoFactura: InvoiceType;

  user: Usuario;

  constructor(
    public utilsService: UtilsService,
    private storedFileService: StoredFilesService,
    private loginService: LoginService,
  ) {
    this.user = this.loginService.getUser();
  }

  ngOnInit(): void {
    this.utilsService.invoiceDTO.subscribe((resp) => {
      this.invoiceDTO = resp;
    });

    this.mobile = window.innerWidth <= 1020 ? true : false;
  }

  confirm() {
    this.confirmInvoice.emit();
  }

  downloadFile(fileUuid: string) {
    this.storedFileService.downloadFile(fileUuid);
  }

  getBaseExentaIVA(): number {
    let baseExentaIva = this.invoiceDTO?.baseExentaIva || 0;
    this.invoiceDTO?.ivas?.forEach((iva) => {
      if (iva?.tipoDeIva?.per_one_factor === 0) {
        baseExentaIva += iva.value;
      }
    });

    return baseExentaIva;
  }

  getIdentificationTitle() {
    return this.displayInternalExternal(
      'PROVEEDOR',
      'EMISOR',
      'DESTINATARIO'
    );
  }

  getEmisorOrDestinatarioLabel() {
    return this.displayInternalExternal(
      'Destinatario',
      'Emisor',
      'Destinatario'
    );
  }

  getEmisorOrDestinatario() {
    return this.displayInternalExternal(
      '',
      this.invoiceDTO?.emisor?.name || this.invoiceDTO?.emisor?.description,
      this.invoiceDTO?.destinatario?.name || this.invoiceDTO?.destinatario?.description
    );
  }

  getCifEmisorOrDestinatario() {
    return this.displayInternalExternal(
      '',
      this.invoiceDTO?.emisor?.vat_number,
      this.invoiceDTO?.destinatario?.vat_number
    );
  }

  displayInternalExternal(defaultValue: any, internal: any, external: any) {
    let response = defaultValue;
    if(this.user.userType === 'internal') {
      response = internal;
    }
    if(this.user.userType === 'external') {
      response = external;
    }

    return response;
  }
}
