import { Order } from '../order.model';

export class InvoiceSubtype {
  id: string;
  subtypeUniquekey: string;
  description: string;
  order: string;
  templateParameters: Templateparameters;

  constructor(data?: any) {
    this.id = (data && data.id) || null;
    this.subtypeUniquekey = (data && data.subtype_uniquekey) || null;
    this.description = (data && data.description) || null;
    this.order = (data && data.order) || null;
    this.templateParameters =
      data && data.template_parameters
        ? new Templateparameters(data.template_parameters)
        : null;
  }
}

export class Templateparameters {
  step2IconCode: string;
  step2HeaderText: string;
  step2Template: string;
  csiteSection: CsiteSection;
  worksheetsSection: WorkSheetsSection;
  invoiceSection: InvoiceSection;
  baseAmountCalculationMode: string;
  baseAmountReadonly: boolean;
  vatExentoOptions: VatExentoOptions;
  vatOptions: VatOptions;
  irpfSection: IrpfSection;
  invoiceTotalHintText: string;
  invoiceFileInput: DefaultConfig;
  extraFileInput: DefaultConfig;

  constructor(data) {
    this.step2IconCode = (data && data.step2_icon_code) || null;
    this.step2HeaderText = (data && data.step2_header_text) || null;
    this.step2Template = (data && data.step2_template) || null;
    this.csiteSection =
      data && data.csite_section ? new CsiteSection(data.csite_section) : null;
    this.worksheetsSection =
      data && data.worksheets_section
        ? new WorkSheetsSection(data.worksheets_section)
        : null;
    this.invoiceSection =
      data && data.invoice_section
        ? new InvoiceSection(data.invoice_section)
        : null;
    this.baseAmountCalculationMode =
      (data && data.base_amount_calculation_mode) || null;
    this.baseAmountReadonly = (data && data.base_amount_readonly) || false;
    this.vatExentoOptions =
      data && data.vat_exento_options
        ? new VatExentoOptions(data.vat_exento_options)
        : null;
    this.vatOptions =
      data && data.vat_options ? new VatOptions(data.vat_options) : null;
    this.irpfSection =
      data && data.irpf_section ? new IrpfSection(data.irpf_section) : null;
    this.invoiceTotalHintText = (data && data.invoice_total_hint_text) || null;
    this.invoiceFileInput =
      data && data.invoice_file_input
        ? new DefaultConfig(data.invoice_file_input)
        : null;
    this.extraFileInput =
      data && data.extra_file_input
        ? new DefaultConfig(data.extra_file_input)
        : null;
  }
}

export class IrpfSection {
  show: boolean;
  appliesSlider: DefaultConfig;
  perone: DefaultConfig;
  amount: DefaultConfig;

  constructor(data) {
    this.show = (data && data.show) || false;
    this.appliesSlider =
      data && data.applies_slider
        ? new DefaultConfig(data.applies_slider)
        : null;
    this.perone = data && data.perone ? new DefaultConfig(data.perone) : null;
    this.amount = data && data.amount ? new DefaultConfig(data.amount) : null;
  }
}

export class DefaultConfig {
  show?: boolean;
  mandatory?: boolean;
  readonly?: boolean;
  default?: any;
  hintText?: string;
  labelText?: string;

  constructor(data) {
    this.mandatory = (data && data.mandatory) || false;
    this.readonly = (data && data.readonly) || false;
    this.default =
      (data && data.default) || (data && data.default_value) || null;
    this.hintText = (data && data.hint_text) || null;
    this.show = (data && data.show) || false;
    this.labelText = (data && data.label_text) || null;
  }
}

export class VatOptions {
  show: boolean;
  ispSection: IspSection;
  preferedVattypeUniquekey: string;
  allowedVattypeUniquekeys: string[];
  otherVattypesSlider: DefaultConfig;

  constructor(data?) {
    this.show = (data && data.show) || false;
    this.ispSection =
      data && data.isp_section ? new IspSection(data.isp_section) : null;
    this.preferedVattypeUniquekey =
      (data && data.prefered_vattype_uniquekey) ||
      (data && data.default_value) ||
      null;
    this.allowedVattypeUniquekeys =
      (data && data.allowed_vattype_uniquekeys) || null;
    this.otherVattypesSlider =
      data && data.other_vattypes_slider
        ? new DefaultConfig(data.other_vattypes_slider)
        : null;
  }
}

export class IspSection {
  ispSlider: DefaultConfig;
  receptionDateInfo: DefaultConfig;

  constructor(data) {
    this.ispSlider =
      data && data.isp_slider ? new DefaultConfig(data.isp_slider) : null;
    this.receptionDateInfo =
      data && data.reception_date_info
        ? new DefaultConfig(data.reception_date_info)
        : null;
  }
}

export class VatExentoOptions {
  show: boolean;
  vattypeUniquekey: string;
  sliderDefault: boolean;
  sliderHintText: string;
  baseAmountMandatory: boolean;
  baseAmountHintText: string;

  constructor(data) {
    this.show = (data && data.show) || false;
    this.vattypeUniquekey = (data && data.vattype_uniquekey) || null;
    this.sliderDefault =
      (data && data.slider_default) || (data && data.default_value) || false;
    this.sliderHintText = (data && data.slider_hint_text) || null;
    this.baseAmountMandatory = (data && data.base_amount_mandatory) || false;
    this.baseAmountHintText = (data && data.base_amount_hint_text) || null;
  }
}

export class InvoiceSection extends DefaultConfig {
  invoiceDateCriteriaFieldname?: string;
  extraEmiterCriteriaFieldname: string;
  fixedCriteria: any;

  constructor(data) {
    super(data);

    this.invoiceDateCriteriaFieldname =
      (data && data.invoice_date_criteria_fieldname) || null;
    this.extraEmiterCriteriaFieldname =
      (data && data.extra_emiter_criteria_fieldname) || null;
    this.fixedCriteria = (data && data.fixed_criteria) || null;
  }
}

export class WorkSheetsSection extends DefaultConfig {
  fixedCriteria: any;
  invoiceDateCriteriaFieldname?: string;

  constructor(data) {
    super(data);

    this.fixedCriteria = (data && data.fixed_criteria) || null;
    this.invoiceDateCriteriaFieldname =
      (data && data.invoice_date_criteria_fieldname) || null;
  }
}

export class CsiteSection extends DefaultConfig {
  labelText: string;
  invoiceDateCriteriaFieldname?: string;
  extraEmiterCriteriaFieldname: string;
  fixedCriteria: any;

  constructor(data) {
    super(data);

    this.labelText = (data && data.label_text) || null;
    this.invoiceDateCriteriaFieldname =
      (data && data.invoice_date_criteria_fieldname) || null;
    this.extraEmiterCriteriaFieldname =
      (data && data.extra_emiter_criteria_fieldname) || null;
    this.fixedCriteria = (data && data.fixed_criteria) || null;
  }
}
