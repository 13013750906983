import { ModalMensajeComponent } from './../components/modal-mensaje/modal-mensaje.component';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ModalMensajeCookiesComponent } from '../components/modal-mensaje-cookies/modal-mensaje-cookies.component';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class MensajeService {
  @Output() acceptEmit = new EventEmitter<any>();
  @Output() logoutEmit = new EventEmitter<any>();
  @Output() closedEmit = new EventEmitter<any>();

  private suscripciones: Subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    private localStorage: LocalStorageService
  ) { }

  public mostrarMensaje(
    size?: string,
    titulo?: string,
    aceptar?: string,
    cancelar?: string,
    msg?: string,
    accion = 'default'
  ): void {
    const dialogRef = this.dialog.open(ModalMensajeComponent, {
      width: size,
      data: { title: titulo, accept: aceptar, cancel: cancelar ? cancelar : null, mensaje: msg },
      autoFocus: false,
    });
    const sus = dialogRef.componentInstance.acceptEmit.subscribe(() => {
      accion === 'logout' ? this.logoutEmit.emit() : this.acceptEmit.emit();
      sus.unsubscribe();
    });

    dialogRef.afterClosed().subscribe(() => {
      this.closedEmit.emit();
    });
  }

  public mostrarMensajeCookie(
    size?: string,
    titulo?: string,
    aceptar?: string,
    cancelar?: string,
    msg?: string
  ): void {
    const dialogRef = this.dialog.open(ModalMensajeCookiesComponent, {
      disableClose: true,
      autoFocus: false,
    });
    const sus = dialogRef.componentInstance.acceptEmit.subscribe(() => {
      this.localStorage.setItem('accept-cookies', 'true');
      this.acceptEmit.emit();
      sus.unsubscribe();
    });

    dialogRef.afterClosed().subscribe(() => {
      this.closedEmit.emit();
    });
  }
}
