import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomPagination } from '../components/table/table-configuration/custom-pagination';

@Injectable({
  providedIn: 'root'
})
export class TablePaginationService {

  private parentUrl;

  private dataSource = new BehaviorSubject('default data');
  currentData: Observable<any> = this.dataSource.asObservable();
  @Output() resetStore = new EventEmitter<any>();
  private storedPagination: CustomPagination;

  constructor() {
    this.setData(null);
  }

  setData(data: any) {
    let flag = false;
    if (data) {
      Object.values(data).forEach(value => {
        if (value) {
          flag = true;
        }
      })
    }

    flag ? this.dataSource.next(data) : this.dataSource.next(null);
  }

  setParentUrl(parentUrl: string) {
    this.parentUrl = parentUrl;
  }

  getParentUrl() {
    return this.parentUrl;
  }

  cleanStoredPagination() {
    this.storedPagination = null;
  }

  setStoredPagination(pagination: CustomPagination) {
    this.storedPagination = pagination;
  }

  getStoredPagination() {
    return this.storedPagination;
  }
}
