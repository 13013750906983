import { FacturaGenericaComponent } from './../factura-generica/factura-generica.component';
import {
  Component,
  OnInit,
  SimpleChanges,
  OnChanges,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SearchService } from 'src/app/core/services/search.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ThousandsPipe } from 'src/app/shared/pipes/thousands.pipe';
import { InputField } from 'src/app/shared/shared.module';
import { map } from 'rxjs/operators';
import { REQUIRED_MESSAGE } from 'src/app/app.constants';
import { VatType } from 'src/app/core/models/vat-type.model';
import { InvoicingQueryService } from 'src/app/core/services/invoicing-query.service';
import { StoredFilesService } from 'src/app/core/services/stored-files.service';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { InvoiceGet } from 'src/app/core/models/invoices/invoice-get';

@Component({
  selector: 'app-abono-factura',
  templateUrl: '../factura-generica/factura-generica.component.html',
  styleUrls: ['../factura-generica/factura-generica.component.scss'],
  providers: [CurrencyPipe, ThousandsPipe],
})
export class AbonoFacturaComponent
  extends FacturaGenericaComponent
  implements OnInit, OnChanges
{
  @ViewChild('invoiceOption', { static: true })
  invoiceOptionTemplate: TemplateRef<any>;

  @Output() changeIvaEmitter = new EventEmitter();

  facturas: Array<VatType>;
  facturasCargadas = false;

  ubicacionChangeAbono = new BehaviorSubject<any>(null);
  loadFactura = false;

  constructor(
    fb: FormBuilder,
    searchService: SearchService,
    currencyPipe: CurrencyPipe,
    thousandsPipe: ThousandsPipe,
    storedFileService: StoredFilesService,
    utilsService: UtilsService,
    private invoicingQueryService: InvoicingQueryService
  ) {
    super(
      fb,
      searchService,
      currencyPipe,
      thousandsPipe,
      storedFileService,
      utilsService
    );
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if(
      changes.changeFormFirstStep?.currentValue || (changes.subTipoFactura?.currentValue?.id !== changes.subTipoFactura?.previousValue?.id)
    ) {
      this.loadFactura = false;
      this.ubicacionCargada = false;
      this.facturasCargadas = false;
      this.setCustomInput();
      this.inputAmountDefaultMode = 'negative';
      this.inputIrpfAmountDefaultMode = 'positive';
      this.inputBaseImponibleManualAmountDefaultMode = 'negative';

      this.initForm();
    }
  }

  generateCustomInput() {
    if (this.inputs?.length > 0) {
      const INDEX_FACTURAS =
        this.inputs.findIndex((input) => {
          return input.key === 'ubicacion';
        }) + 1;
      const inputFacturas: InputField = {
        key: 'facturaAbono',
        type: 'autocomplete-chip',
        label: 'Factura',
        controlValue: '',
        layout: 'col-md-12 col-lg-12',
        placeholder: 'Seleccione una opción',
        selectBindLabel: 'description',
        selectOptionLabel: 'description',
        options: this.facturas,
        readonly: this.subTipoFactura?.templateParameters?.invoiceSection?.readonly || false,
        infoIconShow:
          this.subTipoFactura?.templateParameters?.invoiceSection?.hintText !==
            null &&
          this.subTipoFactura?.templateParameters?.invoiceSection?.hintText !==
            undefined,
        infoIconText:
          this.subTipoFactura?.templateParameters?.invoiceSection?.hintText ||
          '',
        display:
          this.subTipoFactura?.templateParameters?.invoiceSection?.show ||
          false,
        filteredOptions: (value) => {
          this.getDataSecondSelect(value);
          return this.filteredOptionsFacturaAbono();
        },
        selectOptionTemplate: this.invoiceOptionTemplate,
        validatorMessages: this.subTipoFactura?.templateParameters
          ?.invoiceSection?.mandatory
          ? [{ key: 'required', value: REQUIRED_MESSAGE }]
          : null,
      };

      this.inputs.splice(INDEX_FACTURAS, 0, inputFacturas);
    }
  }

  generateCustomForm() {
    if (this.subTipoFactura?.templateParameters?.invoiceSection) {
      this.formGroup.addControl(
        'facturaAbono',
        new FormControl([])
      );

      this.formGroup
        .get('facturaAbono')
        .setValidators(
          this.subTipoFactura?.templateParameters?.invoiceSection?.mandatory
            ? Validators.required
            : null
        );
    }
  }

  changeCustomInputA(data: any) {
    if (data) {
      const dataTransformed = InvoiceGet.buildFromDto(data[0]);
      const date =
        new DatePipe('es').transform(dataTransformed.invoiceDate, 'yyyy-MM-dd') ||
        this.fechaFactura;

      this.getVatTypes(date, dataTransformed);
    } else {
      this.resetSelectedFacturaData();
      this.initializeToggles();
    }
  }

  importeIrpf() {
    return this.formatAmountInput('importeIrpf');
  }

  changeBaseImponible() {
    this.formGroup.get('baseImponible').valueChanges.subscribe((value) => {
      if (this.utilsService.formatAmount(value) > 0) {
        this.formGroup
          .get('baseImponible')
          .setValue(this.utilsService.formatAutocalculateAmountInput(this.utilsService.formatAmount(value) * -1));
      }
      this.formGroup
        .get('importeIVA')
        .setValue(
          this.utilsService.formatAutocalculateAmountInput(this.calcularIVA())
        );
    });
  }

  changeBaseExentaIva() {
    this.formGroup.get('baseExentaIva').valueChanges.subscribe((value) => {
      if (this.utilsService.formatAmount(value) > 0) {
        this.formGroup
          .get('baseExentaIva')
          .setValue(this.utilsService.formatAutocalculateAmountInput(this.utilsService.formatAmount(value) * -1));
      }
      this.formGroup
        .get('importeIVA')
        .setValue(
          this.utilsService.formatAutocalculateAmountInput(this.calcularIVA())
        );
    });
  }

  changeIvasPermitidos() {
    if (this.getIVASPermitidos()?.length > 0) {
      this.getIVASPermitidos().forEach((vatType) => {
        this.formGroup.get(vatType.type_key).valueChanges.subscribe((value) => {
          if (this.utilsService.formatAmount(value) > 0) {
            this.formGroup
              .get(vatType.type_key)
              .setValue(this.utilsService.formatAutocalculateAmountInput(this.utilsService.formatAmount(value) * -1));
          }
          this.formGroup
            .get('importeVariosIVA')
            .setValue(
              this.utilsService.formatAutocalculateAmountInput(
                this.calcularIVA()
              )
            );
        });
      });
    }
  }

  getDataSecondSelect(value: string = '') {
    const criteria =
      this.subTipoFactura.templateParameters.invoiceSection.fixedCriteria;
    const uuidUbicacion =
      this.formGroup.get('ubicacion')?.value?.length > 0
        ? this.formGroup.get('ubicacion')?.value[0]?.uuid
        : null;

    if (
      this.subTipoFactura?.templateParameters?.invoiceSection
        ?.invoiceDateCriteriaFieldname
    ) {
      criteria[
        this.subTipoFactura.templateParameters.invoiceSection.invoiceDateCriteriaFieldname
      ] = this.fechaFactura;
    }
    if (
      this.subTipoFactura?.templateParameters?.invoiceSection
        ?.extraEmiterCriteriaFieldname
    ) {
      criteria[
        this.subTipoFactura?.templateParameters?.invoiceSection?.extraEmiterCriteriaFieldname
      ] = this.companyId;
    }

    criteria.csite_uuids = [uuidUbicacion];
    criteria.company_ids = [this.companyId];
    criteria.company_which_is_invoiced_ids = [this.destinatarioId];
    criteria.smart_text = value;
    if (!this.editOn) {
      if (uuidUbicacion) {
        this.invoicingQueryService
          .getInvoicesByCriteria(criteria)
          .subscribe((resp) => {
            const facturas = resp.values.map((res) => {
              res.description = `${res.invoice_number} (${res.base_amount} €)`;
              return res;
            });
            this.ubicacionChangeAbono.next(facturas);
          });
      } else {
        this.ubicacionChangeAbono.next([]);
      }
    } else {
      if (this.editOn) {
        const subscripcionesFacturas = [];
        if (uuidUbicacion) {
          subscripcionesFacturas.push(
            this.invoicingQueryService.getInvoicesByCriteria(criteria)
          );
        }

        if (this.invoiceGet?.refundsInvoiceUuid && uuidUbicacion) {
          subscripcionesFacturas.push(
            this.invoicingQueryService.getInvoices(
              this.invoiceGet?.refundsInvoiceUuid.toString(), 'uuid'
            )
          );
        }

        if (subscripcionesFacturas.length > 0) {
          forkJoin(subscripcionesFacturas).subscribe((resp: any) => {
            let facturasAbonos = [];
            if (resp?.length > 0) {
              facturasAbonos = resp[0]?.values.map((res) => {
                res.description = `${res.invoice_number} (${res.base_amount} €)`;
                return res;
              });
              if (resp?.length === 2) {
                if (
                  facturasAbonos.find(
                    (faturaAbono) => faturaAbono.id === resp[1]?.values[0]?.id
                  ) === undefined &&
                  resp[1]?.values[0]?.csite_uuid === uuidUbicacion
                ) {
                  facturasAbonos.push(
                    resp[1]?.values.map((res) => {
                      res.description = `${res.invoice_number} (${res.base_amount} €)`;
                      return res;
                    })[0]
                  );
                }
              }
            }

            this.ubicacionChangeAbono.next(facturasAbonos);
          });
        } else {
          this.ubicacionChangeAbono.next([]);
        }
      }
    }
  }

  getVatTypes(date: string, data: InvoiceGet) {
    this.invoicingQueryService.getVatTypes(date).subscribe((res) => {
      const previousVatTypes = this.vatTypes;
      this.vatTypes = res.values as VatType[];
      this.changeIvaEmitter.emit(this.vatTypes);
      this.generateFgroupIvas();
      this.changeDefaultIva();
      this.changeIvasInputs(previousVatTypes);
      if(!this.editOn || this.loadFactura) {
        this.setSelectedFacturaData(data);
      } else {
        this.loadFactura = true;
      }

    });
  }

  changeDefaultIva() {
    const labelImporteIva = `Importe IVA (${
      this.getIVAByKey(
        this.subTipoFactura?.templateParameters?.vatOptions
          ?.preferedVattypeUniquekey
      )?.percent_description
    })`;
    this.changeInputProperty('importeIVA', 'label', labelImporteIva);
  }

  changeIvasInputs(previosVatTypes: VatType[]) {
    previosVatTypes.forEach((vatType) => {
      this.inputs = this.inputs.filter(
        (input) => input.key !== vatType.type_key
      );
    });

    if(this.formGroup.get('inversionDeSujetoPasivo').value) {
      this.generateIvasInputs();
    } else {
      this.generateIvasInputs(
        this.subTipoFactura?.templateParameters?.vatOptions?.show &&
          this.formGroup.get('ivaToggle').value
      );
    }
  }

  setBaseimponible(data: InvoiceGet) {
    const factura = data;
    let baseImponible = 0;
    if (
      this.subTipoFactura?.templateParameters?.baseAmountCalculationMode !==
      'manual'
    ) {

      baseImponible = factura.baseAmount;

      if (baseImponible > 0) {
        baseImponible = baseImponible * -1;
      }
      this.formGroup
        .get('baseImponible')
        .setValue(
          this.utilsService.formatAutocalculateAmountInput(baseImponible)
        );
    }
  }

  setCustomInput() {
    this.customInputA = null;
    if (this.subTipoFactura?.templateParameters?.invoiceSection) {
      this.customInputA = 'facturaAbono';
    }
  }

  filteredOptionsFacturaAbono() {
    return this.ubicacionChangeAbono.asObservable().pipe(
      map((res: any[]) => {
        if (res?.length > 0) {
          if (
            this.editOn &&
            !this.facturasCargadas &&
            this.invoiceGet?.refundsInvoiceUuid
          ) {
            this.formGroup
              .get('facturaAbono')
              .setValue([
                res?.find(
                  (resp) => resp.uuid === this.invoiceGet?.refundsInvoiceUuid
                ),
              ]);
            this.facturasCargadas = true;
          } else {
            if (
              res?.length === 1 &&
              this.formGroup.get('ubicacion')?.value?.length > 0
            ) {
              this.formGroup.get('facturaAbono').setValue([res[0]]);
            } else {
              if (!this.invoiceGet?.refundsInvoiceUuid) {
                this.formGroup.get('facturaAbono').setValue(null);
              }
            }
          }
        }

        return res;
      })
    );
  }

  setSelectedFacturaData(data: InvoiceGet) {
    this.resetSelectedFacturaData();
    this.setBaseimponible(data);
    if(this.subTipoFactura?.templateParameters?.vatExentoOptions?.show || false) {
      this.setBaseExenta(data);
    }
    if((
      this.subTipoFactura?.templateParameters?.vatOptions?.ispSection?.ispSlider?.show &&
      this.subTipoFactura?.templateParameters?.vatOptions?.show
      ) || false
    ) {
      this.setInversionDeSujetoPasivo(data);
    }
    if((
      this.subTipoFactura?.templateParameters?.irpfSection?.appliesSlider?.show &&
      this.subTipoFactura?.templateParameters?.irpfSection?.show
      ) || false
    ) {
      this.setRetencionIRPF(data);
    }
    this.setIVAS(data);

    this.initializeToggles();
  }

  resetSelectedFacturaData() {
    this.formGroup.get('baseImponible').setValue(0);
    this.formGroup.get('ivaExentoToggle').setValue(this.subTipoFactura?.templateParameters?.vatExentoOptions?.sliderDefault || false);
    this.formGroup.get('inversionDeSujetoPasivo').setValue(this.subTipoFactura?.templateParameters?.vatOptions?.ispSection?.ispSlider?.default || false);
    this.formGroup.get('retencionIrpfToggle').setValue(this.subTipoFactura?.templateParameters?.irpfSection?.appliesSlider?.default || false);
    this.formGroup.get('importeIrpf').setValue(this.subTipoFactura?.templateParameters?.irpfSection?.amount?.default || 0);
    this.formGroup.get('ivaToggle').setValue(this.subTipoFactura?.templateParameters?.vatOptions?.otherVattypesSlider?.default || false);
    this.clearIVASPermitidosInputs();
  }

  setIVAS(invoice: InvoiceGet) {

    if (this.vatLinesWhithoutBaseExenta(invoice)?.length === 1) {
      if (
        this.vatLinesWhithoutBaseExenta(invoice)[0].vatTypeTypeKey !==
          this.subTipoFactura.templateParameters.vatOptions
            .preferedVattypeUniquekey ||
        (this.vatLinesWhithoutBaseExenta(invoice)[0].vatTypeTypeKey ===
          this.subTipoFactura.templateParameters.vatOptions
            .preferedVattypeUniquekey &&
          this.vatLinesWhithoutBaseExenta(invoice)[0].amount !==
            this.formatAmountInput('importeIVA'))
      ) {
        this.formGroup
          .get(this.vatLinesWhithoutBaseExenta(invoice)[0].vatTypeTypeKey)
          .setValue(
            this.utilsService.formatAutocalculateAmountInput(
              this.vatLinesWhithoutBaseExenta(invoice)[0]?.amount
            )
          );
      }
    }

    if (this.vatLinesWhithoutBaseExenta(invoice)?.length > 1) {
      this.formGroup.get('ivaToggle').setValue(true);
      this.vatLinesWhithoutBaseExenta(invoice).forEach((vatLine) => {
        this.formGroup
          .get(vatLine.vatTypeTypeKey)
          ?.setValue(
            this.utilsService.formatAutocalculateAmountInput(vatLine.amount)
          );
      });
    }
  }
}
