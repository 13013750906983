<!-- Material select (not accesible because of bad role layout) -->

<ng-container *ngIf="!input.accesibility">
  <div class="input-wrapper" ngClass="{{(input.layout || layout)}}">
    <mat-label class="labelStyle">{{ input.label | translate}}
      <span class="input-required-mark" *ngIf="(input | inputFieldRequired) && !input.hideRequired">*</span>
    </mat-label>
    <div [formGroup]="fGroup" class="select-wrapper">
      <select aria-label="select items" [(ngModel)]="selectedElement" (ngModelChange)="selectedItem()" [formControlName]="input.key" class="select-css">
        <option class="option-css"  *ngIf="!input.hideFirstOption"></option>
        <option class="option-css" *ngFor="let option of input.options || input.selectValues" [ngValue]="option">
          {{ option[input.selectBindLabel] || option }}</option>
      </select>
      <mat-error aria-live="assertive" aria-atomic="true" *ngFor="let message of input.validatorMessages">
        <ng-container
          *ngIf="(fGroup.dirty || fGroup.touched)  && fGroup.controls[input.key].errors && fGroup.controls[input.key].errors[message.key]">
          {{message.value | translate}}
        </ng-container>
      </mat-error>
    </div>

    <!-- <mat-form-field [formGroup]="fGroup" ngClass="{{ (input.layout || layout) + ' ' + input.key }}">
      <mat-label>{{ input.label | translate}}</mat-label>
      <mat-select [formControlName]="input.key" [compareWith]="compareWith" (selectionChange)="selectedItem($event.value)">
        <mat-option *ngIf="!input.hideFirstOption">--</mat-option>
        <mat-option *ngFor="let option of input.options || input.selectValues" [value]="option">
          {{ option[input.selectBindLabel] || option }}</mat-option>
        </mat-select>
        <mat-error aria-live="assertive" aria-atomic="true" *ngFor="let message of input.validatorMessages">
          <ng-container
          *ngIf="(fGroup.dirty || fGroup.touched )   && fGroup.controls[input.key].errors && fGroup.controls[input.key].errors[message.key]">
          {{message.value | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field> -->

  </div>
</ng-container>

<!-- Select Accesible -->
<ng-container *ngIf="input.accesibility">
  <div [formGroup]="fGroup" ngClass="{{ (input.layout || layout) + ' ' + input.key }}">
    <label>{{ input.label | translate}}:

      <select [formControlName]="input.key">

        <option role="option" *ngIf="!input.hideFirstOption">

        </option>
        <option *ngFor="let option of input.options || input.selectValues" [value]="option" role="option">
          {{ option[input.selectBindLabel] || option }}</option>

        </select>
        <mat-error aria-live="assertive" aria-atomic="true" *ngFor="let message of input.validatorMessages">
          <ng-container
          *ngIf="(fGroup.dirty || fGroup.touched )   && fGroup.controls[input.key].errors && fGroup.controls[input.key].errors[message.key]">
          {{message.value | translate}}
        </ng-container>
      </mat-error>

    </label>
  </div>
</ng-container>


