import { Order } from './order.model';

export class InvoiceSearchCriteria {
  order: Order[];
  offset: number;
  limit: number;
  id: number;
  ids: number[];
  uuid: string;
  uuids: string[];
  smart_text: string;
  invoice_number: string;
  invoice_number_exactsearch: string;
  searchstate_key: string;
  searchstate_keys: string[];
  company_uuids: string[];
  csite_uuids: string[];
  invoice_type_uniquekeys: string[];
  internal_ids: string[];
  write_date_from: string;
  register_date_from: string;
  register_date_to: string;
  invoice_date_from: string;
  invoice_date_to: string;
  company_which_is_invoiced_uuids: string[];
  company_which_is_invoiced_ids: number[];
  company_which_is_invoiced_name: string;
  bankings_entities_ids: number[];
  payment_date_from: string;
  payment_date_to: string;
  b_refundable_invoices: boolean;
  invoiceType?: any;
}
