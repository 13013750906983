import { environment } from './../../../../../environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss']
})
export class LoginContainerComponent implements OnInit {

  selectedTab: number = 0;

  // Relación nombre de pestaña/índice.
  tabs: Map<string, number> = new Map([
    [ 'login', 0 ],
    [ 'signup', 1 ]
  ]);

  constructor() { }

  ngOnInit(): void { }

  changeTab(tabName: string): void {
    this.selectedTab = this.tabs[tabName];
  }
}
