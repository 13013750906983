import { Router } from '@angular/router';
import { BaseComunicationService } from './base-comunication.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from 'src/app/shared/shared.module';
import { Observable, pipe } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignupService extends BaseComunicationService {

  private urlApi = `${environment.apiInvoicingBaseUrl}`;

  constructor(private http: HttpClient,
    private alertService: AlertService,
    private routerService?: Router) {
    super(http);
  }

  /**
   * Email verification request.
   */
  signupRequest(data: any): Observable<any> {
    const url: string = `${this.urlApi}/user-registration-request/request-user-registry`
    return this.realizarPeticionPost(url, data);
  }

  /**
   * Account confirmation
   * @param data
   */
  signupConfirmation(data: any): void {

  }

  /**
   * Validate password reset token.
   */
  validateToken(token: string): Observable<any> {
    let data: any = new Object();
    data.email_token = token;
    const url = `${this.urlApi}/user-registration-request/validate-email-token`;
    return this.realizarPeticionPost(url, data);
  }

}
