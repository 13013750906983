import { Order } from './order.model';

export class Pageable {
  order: Order[];
  offset: number;
  limit: number;

  constructor(data?: any) {
    this.order = (data && data.order) || [];
    this.offset = (data && data.offset) || 0;
    this.limit = (data && data.limit) || 0;
  }
}

export class InvoicesExcel extends Pageable {
  id: number;
  ids: number[];
  internalIds: string[];
  uuid: string;
  uuids: string[];
  smartText: string;
  invoiceNumber: string;
  invoiceNumberExactsearch: string;
  searchstateKey: string;
  stateGroupKeys?: string[];
  companyuuids: string[];
  companyinvoiceduuids: string[];
  csiteuuids: string[];
  invoiceType: string[];
  writeDateFrom: string;
  registerDateFrom: string;
  registerDateTo: string;
  invoiceDateFrom: string;
  invoiceDateTo: string;
  paymentDateFrom: string;
  paymentDateTo: string;
  companyWhichIsInvoicedUuids: string[];
  companyWhichIsInvoicedIds: number[];
  companyWhichIsInvoicedName: string;
  filename: string;
  entitiesIds?: any;

  constructor(data?: any) {
    super(data);
    this.id = (data && data.id) || 0;
    this.ids = (data && data.ids) || [];
    this.internalIds = (data && data.internalIds) || null;
    this.uuid = (data && data.uuid) || '';
    this.uuids = (data && data.uuids) || [];
    this.smartText = (data && data.smartText) || null;
    this.invoiceNumber = (data && data.invoiceNumber) || null;
    this.invoiceNumberExactsearch = (data && data.invoiceNumberExactsearch) || null;
    this.searchstateKey = (data && data.searchstateKey) || null;
    this.stateGroupKeys = (data && data.stateGroupKeys) || null;
    this.companyuuids = (data && data.companyuuids) || null;
    this.companyinvoiceduuids = (data && data.companyinvoiceduuids) || null;
    this.csiteuuids = (data && data.csiteuuids) || null;
    this.invoiceType = (data && data.invoiceType) || null;
    this.writeDateFrom = (data && data.writeDateFrom) || null;
    this.registerDateFrom = (data && data.registerDateFrom) || null;
    this.registerDateTo = (data && data.registerDateTo) || null;
    this.invoiceDateFrom = (data && data.invoiceDateFrom) || null;
    this.invoiceDateTo = (data && data.invoiceDateTo) || null;
    this.paymentDateFrom = (data && data.paymentDateFrom) || null;
    this.paymentDateTo = (data && data.paymentDateTo) || null;
    this.companyWhichIsInvoicedUuids = (data && data.companyWhichIsInvoicedUuids) || null;
    this.companyWhichIsInvoicedIds = (data && data.companyWhichIsInvoicedIds) || null;
    this.companyWhichIsInvoicedName = (data && data.companyWhichIsInvoicedName) || null;
    this.entitiesIds = (data && data.entitiesIds) || null;
  }

  toService(): any {
    const data: any = {};
    data.order = this.order;
    data.offset = this.offset;
    data.limit = this.limit;
    data.id = this.id;
    data.ids = this.ids;
    data.uuid = this.uuid;
    data.uuids = this.uuids;
    data.smart_text = this.smartText;
    data.invoice_number = this.invoiceNumber;
    data.invoice_number_exactsearch = this.invoiceNumberExactsearch;
    data.searchstate_key = this.searchstateKey;
    data.searchstate_keys = this.stateGroupKeys;
    data.company_uuids = this.companyuuids;
    data.company_which_is_invoiced_uuids = this.companyinvoiceduuids;
    data.csite_uuids = this.csiteuuids;
    data.invoice_type_uniquekeys = this.invoiceType;
    data.internal_ids = this.internalIds;
    data.write_date_from = this.writeDateFrom;
    data.register_date_from = this.registerDateFrom;
    data.register_date_to = this.registerDateTo;
    data.invoice_date_from = this.invoiceDateFrom
    data.invoice_date_to = this.invoiceDateTo
    data.payment_date_from = this.paymentDateFrom
    data.payment_date_to = this.paymentDateTo
    data.company_which_is_invoiced_uuids = this.companyWhichIsInvoicedUuids;
    data.company_which_is_invoiced_ids = this.companyWhichIsInvoicedIds
    data.company_which_is_invoiced_name = this.companyWhichIsInvoicedName;
    data.filename = this.filename;
    data.bankings_entities_ids = this.entitiesIds;
    return data;
  }
}