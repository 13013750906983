import { LoginService } from 'src/app/core/services/login.service';
import { AlertService } from './../../shared/services/alert.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { InvoicingUserService } from './../services/invoicing-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthRouteGuard implements CanActivate {

  constructor(private invoicingUserService: InvoicingUserService,
    private loginService: LoginService,
    private alertService: AlertService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      return this.validateAccess(next.data.site, this.loginService.getUser().permissionsInCompany);
  }

  /**
   * Evalua autorización de acceso a una pantalla específica.
   *
   * Retorna TRUE si tiene autorización.
   *
   */
  private validateAccess(site: string, userPermissions: any): boolean {
    let canAccess: boolean = false;

    switch (site) {
      case 'PGU': canAccess = userPermissions && userPermissions.users_view;
                  break;
      case 'PSP':
      case 'PCS': canAccess = userPermissions &&
                  (userPermissions.users_view || userPermissions.user_registry_request);
                  break;
    }

    if (!canAccess) {
      this.alertService.showDetailError('Acceso denegado', 'Usuario no autorizado');
    }
    return canAccess;
  }

}
