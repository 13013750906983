import { style } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export interface CustomSnackbarData {
  title: string;
  message: string;
  class?: string;
  icon?: string;
  duration?: number;
}

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent implements OnInit {

  constructor(public snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: CustomSnackbarData) {

      if (!data.title) {
        data.title = 'Aviso';
      }

      if (!data.icon) {
        switch (data.class) {
          case 'snack-bar-success': data.icon = 'task_alt'; break;
          case 'snack-bar-warn':    data.icon = 'notification_important'; break;
          case 'snack-bar-error':   data.icon = 'report'; break;
          case 'snack-bar-info':   data.icon = 'info'; break;
          default: data.icon = 'task_alt'; break;
        }
      }
    }

  ngOnInit(): void { }

}
