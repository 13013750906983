<mat-toolbar *ngIf="!mobile" class="toolbar sub-toolbar" [class.arpada-mobile-subtoolbar]="mobile">
  <h1>{{'Gestión de usuarios' | translate}}</h1>
  <i *ngIf="!mobile" class="material-icons material-icons-outlined invoice-add-icon"
    aria-hidden="true">manage_accounts</i>
</mat-toolbar>

<ng-container *ngIf="userType === 'internal'">
  <app-users-manage-filter-internal [mobile]="mobile"></app-users-manage-filter-internal>
</ng-container>

<ng-container *ngIf="userType === 'external'">
  <app-users-manage-filter-external [mobile]="mobile"></app-users-manage-filter-external>
</ng-container>
